import React, { useState } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import Layout from '../DashboardLayout'
import { Button, ButtonGroup, Flex, Menu, MenuButton, MenuItem, MenuList, Popover, PopoverContent, PopoverTrigger, Stack, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, VStack } from '@chakra-ui/react'
import { MdArrowDropDown } from 'react-icons/md'

import "../css/campaignList.css"
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { TriangleDownIcon, TriangleUpDownIcon, useTablePagination } from '../menuItems';
import DateRangePicker from '../DateRangePicker';

const data = [
    {
        campaignId: '145612A',
        startDate: '24/05/2024',
        endDate: '24/06/2024',
        radioStation: 'LagFM, LagosTalksFM',
        status: 'Pending',
        amoount: 'N419,000'
    },
    {
        campaignId: '225612A',
        startDate: '24/05/2024',
        endDate: '24/06/2024',
        radioStation: 'LagFM, LagosTalksFM',
        status: 'Pending',
        amoount: 'N419,000'
    },
    {
        campaignId: '335612A',
        startDate: '24/05/2024',
        endDate: '24/06/2024',
        radioStation: 'LagFM, LagosTalksFM',
        status: 'Pending',
        amoount: 'N419,000'
    },
    {
        campaignId: '445612A',
        startDate: '24/05/2024',
        endDate: '24/06/2024',
        radioStation: 'LagFM, LagosTalksFM',
        status: 'Pending',
        amoount: 'N419,000'
    },
    {
        campaignId: '5445612A',
        startDate: '24/05/2024',
        endDate: '24/06/2024',
        radioStation: 'LagFM, LagosTalksFM',
        status: 'Pending',
        amoount: 'N419,000'
    },
    {
        campaignId: '645612A',
        startDate: '24/05/2024',
        endDate: '24/06/2024',
        radioStation: 'LagFM, LagosTalksFM',
        status: 'Pending',
        amoount: 'N419,000'
    },
    {
        campaignId: '746612A',
        startDate: '24/05/2024',
        endDate: '24/06/2024',
        radioStation: 'LagFM, LagosTalksFM',
        status: 'Pending',
        amoount: 'N419,000'
    },
    {
        campaignId: '847612A',
        startDate: '24/05/2024',
        endDate: '24/06/2024',
        radioStation: 'LagFM, LagosTalksFM',
        status: 'Pending',
        amoount: 'N419,000'
    },
    {
        campaignId: '948612A',
        startDate: '24/05/2024',
        endDate: '24/06/2024',
        radioStation: 'LagFM, LagosTalksFM',
        status: 'Pending',
        amoount: 'N419,000'
    },
    {
        campaignId: '1049612A',
        startDate: '24/05/2024',
        endDate: '24/06/2024',
        radioStation: 'LagFM, LagosTalksFM',
        status: 'Pending',
        amoount: 'N419,000'
    },
]

const TransmitCampaignLists = () => {
    const { currentPageData, currentPage, totalPages, handleNextPage, handlePreviousPage } = useTablePagination(data)

    return (
        <>
            <Layout>
                <Stack spacing={8} pt={10} color={'#000'}>
                    <Text as={'h1'} fontSize={'24px'} fontWeight={600} lineHeight={'29.09px'}>Campaign List</Text>
                    <Flex alignItems={'center'} flexWrap={'wrap'} gap={{ base: '20px' }}>
                        <Menu>
                            <MenuButton display={'flex'} alignItems={'center'} as={Button} rightIcon={<MdArrowDropDown size={20} />} fontWeight={400} border={'0.5px solid #D5D4DF'} padding={'8px 16px'} variant={'none'} bg={'white'} h={'42px'} borderRadius={'4px'}>
                                Radio Station
                            </MenuButton>
                            <MenuList>
                                <MenuItem>Download</MenuItem>
                                <MenuItem>Create a Copy</MenuItem>
                                <MenuItem>Mark as Draft</MenuItem>
                                <MenuItem>Delete</MenuItem>
                                <MenuItem>Attend a Workshop</MenuItem>
                            </MenuList>
                        </Menu>


                        <DateRangePicker />
                    </Flex>
                    <TableContainer>
                        <Table
                            variant='simple'
                            padding={'4px 0px 4px 0px'}
                            border={'0.5px solid #D5D5D5'}
                            sx={{
                                th: { fontFamily: 'Telegraf', fontSize: '16px', lineHeight: '21.84px', fontWeight: '400' },
                                td: { fontFamily: 'Telegraf', fontSize: '16px', lineHeight: '16.96px', fontWeight: '400' }
                            }}
                        >
                            <Thead fontSize={'16px'}>
                                <Tr>
                                    <Th borderBottom={'0.5px solid #D5D5D5'} color={'#000'}>C<span className='lower-text'>ampaign</span> ID</Th>
                                    <Th borderBottom={'0.5px solid #D5D5D5'} textTransform={'capitalize'} color={'#000'}>Start Date</Th>
                                    <Th borderBottom={'0.5px solid #D5D5D5'} color={'#000'} textTransform={'capitalize'}>End Date</Th>
                                    <Th borderBottom={'0.5px solid #D5D5D5'} color={'#000'} textTransform={'capitalize'}>Radio Station</Th>
                                    <Th borderBottom={'0.5px solid #D5D5D5'} color={'#000'} textTransform={'capitalize'}>
                                        <Popover placement='bottom-start'>
                                            <PopoverTrigger>
                                                <Flex gap={1} cursor={'pointer'}>Campaign Status <TriangleDownIcon /></Flex>
                                            </PopoverTrigger>
                                            <PopoverContent
                                                border="0.5px solid #868686"
                                                w="120px"
                                            >
                                                <VStack spacing="8px">
                                                    <Button p={0} m={0} h="100%" variant={'none'} fontWeight={400} width="100%" justifyContent="flex-start" _hover={{bg: '#D9D9D9'}} padding={'8px 4px'} borderRadius={'none'}>
                                                        Pending
                                                    </Button>
                                                    <Button p={0} m={0} h="100%" variant={'none'} fontWeight={400} width="100%" justifyContent="flex-start" _hover={{bg: '#D9D9D9'}} padding={'8px 4px'} borderRadius={'none'}>
                                                        Approved
                                                    </Button>
                                                    <Button p={0} m={0} h="100%" variant={'none'} fontWeight={400} width="100%" justifyContent="flex-start" _hover={{bg: '#D9D9D9'}} padding={'8px 4px'} borderRadius={'none'}>
                                                        Cancelled
                                                    </Button>
                                                </VStack>

                                            </PopoverContent>
                                        </Popover>

                                    </Th>
                                    <Th borderBottom={'0.5px solid #D5D5D5'} color={'#000'} textTransform={'capitalize'}><Flex alignItems={'center'} cursor={'pointer'}>Amount <TriangleUpDownIcon /> </Flex> </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {currentPageData.map((eachData) => (
                                    <Tr borderBottom={'0.5px solid #D5D5D5'} color={'#000'} key={eachData.campaignId}>
                                        <Td>{eachData.campaignId}</Td>
                                        <Td>{eachData.startDate}</Td>
                                        <Td>{eachData.endDate}</Td>
                                        <Td>{eachData.radioStation}</Td>
                                        <Td>{eachData.status}</Td>
                                        <Td>{eachData.amoount}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                    </Stack>
                    <Flex alignItems={'center'} fontSize={'14px'} mt={'10px'}>
                            <Text width={'32px'} height={'32px'} display={'flex'} alignItems={'center'} justifyContent={'center'} border={'0.5px solid #D9D9D9'}>{currentPage}</Text> <span className='lower-text' style={{ paddingLeft: '8px' }}>of</span> <Text padding={'10px 8px 10px 10px'}>{totalPages}</Text>
                            <Flex>
                                <ButtonGroup>
                                    <Button onClick={handlePreviousPage} minW={'24px'} p={0} m={0} variant={'none'} isDisabled={currentPage === 1}><ChevronLeftIcon boxSize={'24px'} /> </Button>
                                    <Button onClick={handleNextPage} minW={'24px'} p={0} m={0} variant={'none'} css={{ marginInlineStart: '0 !important' }} isDisabled={currentPage >= totalPages}><ChevronRightIcon boxSize={'24px'} /></Button>
                                </ButtonGroup>
                            </Flex>
                        </Flex>
               


            </Layout>
        </>
    )
}

export default TransmitCampaignLists


