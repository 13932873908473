const GraphDummyData = [
  {
    id: 'During Campaign',
    data: [
      { x: '2023-09-01', y: 2000 }, 
      { x: '2023-09-02', y: 0 },
      { x: '2023-09-03', y: 30000 },
      { x: '2023-09-04', y: 10000 },
      { x: '2023-09-05', y: 25000 },
      { x: '2023-09-06', y: 22000 },
      { x: '2023-09-07', y: 50000 },
      { x: '2023-09-08', y: 20000 },
      { x: '2023-09-09', y: 30000 },
      { x: '2023-09-10', y: 23000 },
      { x: '2023-09-11', y: 32000 },
      { x: '2023-09-12', y: 36000 },
    ],
  },
  {
    id: 'Before Campaign',
    data: [
      { x: '2023-09-01', y: 20000 }, 
      { x: '2023-09-02', y: 1000 }, 
      { x: '2023-09-03', y: 1500 }, 
      { x: '2023-09-04', y: 33000 },
      { x: '2023-09-05', y: 25000 },
      { x: '2023-09-06', y: 22000 },
      { x: '2023-09-07', y: 20000 },
      { x: '2023-09-08', y: 30000 },
      { x: '2023-09-09', y: 30000 },
      { x: '2023-09-10', y: 24000 },
      { x: '2023-09-11', y: 2000 }, 
      { x: '2023-09-12', y: 22000 },
    ],
  },
 
  
];

export const GraphForecastData = [
  {
    id: 'During Campaign',
    data: [
      { x: '2023-09-01', y: 2000 }, 
      { x: '2023-09-02', y: 0 },
      { x: '2023-09-03', y: 30000 },
      { x: '2023-09-04', y: 10000 },
      { x: '2023-09-05', y: 25000 },
      { x: '2023-09-06', y: 22000 },
      { x: '2023-09-07', y: 50000 },
      { x: '2023-09-08', y: 20000 },
      { x: '2023-09-09', y: 30000 },
      { x: '2023-09-10', y: 23000 },
      { x: '2023-09-11', y: 32000 },
      { x: '2023-09-12', y: 36000 },
    ],
  },
  {
    id: 'Before Campaign',
    data: [
      { x: '2023-09-01', y: 2000 }, 
      { x: '2023-09-02', y: 0 },
      { x: '2023-09-03', y: 30000 },
      { x: '2023-09-04', y: 10000 },
      { x: '2023-09-05', y: 25000 },
      { x: '2023-09-06', y: 22000 },
      { x: '2023-09-07', y: 50000 },
      { x: '2023-09-08', y: 20000 },
      { x: '2023-09-09', y: 30000 },
      { x: '2023-09-10', y: 24000 },
      { x: '2023-09-11', y: 2000 }, 
      { x: '2023-09-12', y: 22000 },
    ],
  },
];

export default GraphDummyData;
