import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_BANKS, GET_RADIOSTATION_PROFILE_DETAILS_FOR_ADMIN, GET_BANK_ACCOUNT_NAME } from "../GraphQL/Queries";

import { updateRadioStationProfileDetailsValidationSchema } from "../../utils/validations";
import {
  SAVE_BROADCASTER_BANK_ACCOUNT,
  UPDATE_BROADCASTER_BANK_ACCOUNT_BY_ADMIN,
  UPDATE_BROADCASTER_PASSWORD_BY_ADMIN,
  UPDATE_BROADCASTER_PROFILEDETAILS_BY_ADMIN,
} from "../GraphQL/Mutation";
import { listOfBanks } from "../../constant";
import { get } from "react-hook-form";

const RadioStationProfileDetails = ({ profile, bankDetails }) => {
  const [newBankDetails, setNewBankDetails] = useState(false);
  const [profileDetailsg, setProfileDetails] = useState({});
  const [selectedBankCode, setSelectedBankCode] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null)

  const { id } = useParams();
  const toast = useToast();

  const { data: { admin: { getBroadcasterDetailsById = [] } = {} } = {} } =
    useQuery(GET_RADIOSTATION_PROFILE_DETAILS_FOR_ADMIN, {
      variables: {
        broadcasterId: id,
      },
      onCompleted: () => {
        setProfileDetails(getBroadcasterDetailsById);
      },
    });


    const {data: {admin: {getBanks = [] } ={}} = {}} = useQuery(GET_BANKS)
  const [
    updateBroadcasterProfile,
    { loading: updateBroadcasterProfileLoading },
  ] = useMutation(UPDATE_BROADCASTER_PROFILEDETAILS_BY_ADMIN);
  const [
    updateBroadcasterBankAccount,
    { loading: updateBroadcasterBankAccountLoading },
  ] = useMutation(UPDATE_BROADCASTER_BANK_ACCOUNT_BY_ADMIN);
  const [
    updateBroadcasterPassword,
    { loading: updateBroadcasterPasswordLoading },
  ] = useMutation(UPDATE_BROADCASTER_PASSWORD_BY_ADMIN);

  const { handleSubmit, handleChange, values, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      radioStation: profile?.radioStation,
      nameOfPersonInCharge: profile?.nameOfPersonInCharge,
      position: profile?.position,
      contactNo: profile?.contactNo,
      address: profile?.address,
      country: profile?.country,
      state: profile?.state,
      city: profile?.city,
      zipcode: profile?.zipcode,
      radioCategory: profile?.radioCategory,
      website: profile?.website,
      digitalStreamUrl: profile?.digitalStreamUrl,
    },
    validationSchema: updateRadioStationProfileDetailsValidationSchema,
    onSubmit: (formValues) => {

      updateBroadcasterProfile({
        variables: {
          input: {
            broadcasterId: id,
            ...formValues,
          },
        },
        onCompleted: () => {
          toast({
            title: "Profile updated successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        },
        onError: (error) => {
          console.log(errors);

          const errorMessage =
            error.message || "Error updating user profile, Try Again";
          toast({
            title: errorMessage,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        },
      });
    },
  });

  const {
    handleSubmit: updatePasswordSubmitHandler,
    handleChange: updatePasswordChangeHandler,
    values: updatePasswordValue,
    // setValues: bankDetailsSetValues,
  } = useFormik({
    initialValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
    onSubmit: (passwordValues) => {
      updateBroadcasterPassword({
        variables: {
          input: {
            broadcasterId: id,
            ...passwordValues,
          },
        },
        onCompleted: () => {
          toast({
            title: "Password updated successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        },
        onError: () => {
          toast({
            title: "Error updating password, Try Again",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        },
      });
    },
  });

  const {
    handleSubmit: bankDetailsSubmitHandler,
    handleChange: bankDetailsChangeHandler,
    values: bankDetailsValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      bankName: bankDetails?.bankName,
      accountNumber: bankDetails?.accountNumber,
      accountName: bankDetails?.accountName,
    },
    onSubmit: (bankValues) => {
      updateBroadcasterBankAccount({
        variables: {
          input: {
            broadcasterId: id,
            ...bankValues,
            bankCode: selectedBankCode,
          },
        },
        onCompleted: () => {
          toast({
            title: "Bank information updated successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        },
        onError: () => {
          toast({
            title: "Error updating bank information, Try Again",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        },
      });
    },
  });

  // Custom function to handle bankName change
  const handleBankNameChange = (event) => {
    const selectedBankName = event.target.value;
    const selectedBank = getBanks.find(
      (bank) => bank.code === selectedBankName
    );

    // Assuming each bank object has a "code" property, update accordingly
    const selectedBankCode = selectedBank ? selectedBank.code : "";

    // Update React state for bank code
    setSelectedBankCode(selectedBankCode);

    // Use formik's handleChange to update form values
    bankDetailsChangeHandler(event);
  };

     // Run the query if both parameters are not null
     const shouldRunQuery = values.accountName !== null && selectedBankCode !== null && accountNumber?.length >=10 ;

  
  const { data: {admin: {getAccountName= ""} ={}} = {}, loading: accountNameLoading, error: bankNameError } = useQuery(GET_BANK_ACCOUNT_NAME, {
    variables: { accountNumber, bankCode: selectedBankCode },
    skip: !shouldRunQuery, // Skip the query if shouldRunQuery is false
  });

  if(bankNameError) {
    toast({
      title: "Something went wrong. Try Again.",
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  }


  return (
    <Box as="section" display="flex" w="100%" padding="0 30px" gap={30}>
      <Flex
        flexDirection="column"
        gap={8}
        className="profile_form_container"
        w="80%"
      >
        <form onSubmit={handleSubmit}>
          <Flex w="100%" flexDirection="column" gap={10}>
            <Box display="flex" gap={10}>
              <FormControl isRequired maxW={400} w="100%">
                <FormLabel>Radio Station</FormLabel>
                <Input
                  placeholder="Beat Fm"
                  value={values?.radioStation}
                  onChange={handleChange}
                  type="text"
                  name="radioStation"
                />
              </FormControl>
              <FormControl isRequired maxW={400} w="100%">
                <FormLabel>Name of Person in Charge</FormLabel>
                <Input
                  placeholder="Sharon Chuks-Nwoko"
                  value={values?.nameOfPersonInCharge}
                  onChange={handleChange}
                  name="nameOfPersonInCharge"
                />
              </FormControl>
            </Box>
            <Box display="flex" gap={10}>
              <FormControl isRequired maxW={400} w="100%">
                <FormLabel>Position</FormLabel>
                <Input
                  placeholder="Digital Marketing Executive"
                  value={values?.position}
                  onChange={handleChange}
                  name="position"
                />
              </FormControl>
              <FormControl isRequired maxW={400} w="100%">
                <FormLabel>Contact No.</FormLabel>
                <Input
                  placeholder="+2348157483322"
                  value={values?.contactNo}
                  onChange={handleChange}
                  name="contactNo"
                />
              </FormControl>
            </Box>
            <Box display="flex" gap={10}>
              <FormControl isRequired maxW={400} w="100%">
                <FormLabel>Address (street, buildings)</FormLabel>
                <Input
                  placeholder="26, Keffi Street, Ikoyi, Lagos "
                  value={values?.address}
                  onChange={handleChange}
                  name="address"
                />
              </FormControl>
              <FormControl isRequired maxW={400} w="100%">
                <FormLabel>Country</FormLabel>
                <Input
                  placeholder="Nigeria"
                  value={values?.country}
                  onChange={handleChange}
                  name="country"
                />
              </FormControl>
            </Box>
            <Box display="flex" gap={10}>
              <FormControl isRequired maxW={400} w="100%">
                <FormLabel>State</FormLabel>
                <Input
                  placeholder="Lagos State"
                  value={values?.state}
                  onChange={handleChange}
                  name="state"
                />
              </FormControl>
              <FormControl isRequired maxW={400} w="100%">
                <FormLabel>City</FormLabel>
                <Input
                  placeholder="Ikoyi"
                  value={values?.city}
                  onChange={handleChange}
                  name="city"
                />
              </FormControl>
            </Box>
            <Box display="flex" gap={10}>
              <FormControl isRequired maxW={400} w="100%">
                <FormLabel>Zipcode</FormLabel>
                <Input
                  placeholder="100200"
                  value={values?.zipcode}
                  onChange={handleChange}
                  name="zipcode"
                />
              </FormControl>
              <FormControl isRequired maxW={400} w="100%">
                <FormLabel>Radio Category</FormLabel>
                <Input
                  placeholder="Music & Lifestyle "
                  value={values?.radioCategory}
                  onChange={handleChange}
                  name="radioCategory"
                />
              </FormControl>
            </Box>
            <Box display="flex" gap={10}>
              <FormControl isRequired maxW={400} w="100%">
                <FormLabel>Website</FormLabel>
                <Input
                  placeholder="Https://Thebeat99.com "
                  value={values?.website}
                  onChange={handleChange}
                  name="website"
                />
              </FormControl>
              <FormControl isRequired maxW={400} w="100%">
                <FormLabel>Digital Stream URL</FormLabel>
                <Input
                  placeholder="Http://www.Thebeat99.com/Listen-Live"
                  value={values?.digitalStreamUrl}
                  onChange={handleChange}
                  name="digitalStreamUrl"
                />
              </FormControl>
            </Box>
            <Button
              p="10px 15px"
              bg=" #F99B28"
              w="150px"
              color="#fff"
              type="submit"
              loadingText="Saving"
              isLoading={updateBroadcasterProfileLoading}
            >
              Save
            </Button>
          </Flex>
        </form>
      </Flex>
      <Box as="aside">
        <VStack spacing={10}>
          <form onSubmit={updatePasswordSubmitHandler}>
            <Box>
              <Text mb={4}>Change Password</Text>
              <Box bg="#EEF6F4" p={10}>
                <Stack spacing={6}>
                  <FormControl isRequired maxW={240} w="100%">
                    <FormLabel>New Password</FormLabel>
                    <Input
                      placeholder=""
                      onChange={updatePasswordChangeHandler}
                      value={updatePasswordValue?.newPassword}
                      name="newPassword"
                    />
                  </FormControl>
                  <FormControl isRequired maxW={240} w="100%">
                    <FormLabel>Confirm Password</FormLabel>
                    <Input
                      placeholder=""
                      onChange={updatePasswordChangeHandler}
                      value={updatePasswordValue?.confirmNewPassword}
                      name="confirmNewPassword"
                    />
                  </FormControl>
                  <Button
                    p="10px 15px"
                    w="150px"
                    color="#004643"
                    border="1px solid #F99B28"
                    type="submit"
                    mt={20}
                    loadingText="Changing"
                    isLoading={updateBroadcasterPasswordLoading}
                  >
                    Change
                  </Button>
                </Stack>
              </Box>
            </Box>
          </form>

          <Box>
            <Box mb={4} display="flex" justifyContent="space-between">
              {newBankDetails ? (
                <Text>Add New Account</Text>
              ) : (
                <>
                  {/* <Text>Change Password</Text> */}
                  <Text color="#F99B28" onClick={() => setNewBankDetails(true)} cursor="pointer">
                    Add New Account
                  </Text>
                </>
              )}
            </Box>
            <form onSubmit={bankDetailsSubmitHandler}>
              <Box bg="#EEF6F4" p={10}>
                {accountNameLoading && <p style={{textAlign: "center", marginBottom: "5px"}}>Loading...</p>}
                <Stack spacing={6}>
                  <FormControl isRequired maxW={240} w="100%">
                    <FormLabel>Bank Name</FormLabel>
                    <Select
                      placeholder="Select Bank"
                      value={bankDetailsValue?.bankName}
                      onChange={handleBankNameChange}
                      name="bankName"
                    >
                      {getBanks.map((bank, index) => (
                        <option key={index} value={bank.code}>
                          {bank.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl isRequired maxW={240} w="100%">
                    <FormLabel>Account Number</FormLabel>
                    <Input
                      placeholder=""
                      value={bankDetailsValue.accountNumber}
                      onChange={(e)=>setAccountNumber(e.target.value)}
                      name="accountNumber"
                    />
                  </FormControl>
                  <FormControl isRequired maxW={240} w="100%">
                    <FormLabel>Account Name</FormLabel>
                    <Input
                      placeholder=""
                      value={getAccountName}
                      readOnly
                      // onChange={bankDetailsChangeHandler}
                      name="accountName"
                    />
                  </FormControl>
                  <Button
                    p="10px 15px"
                    w="150px"
                    color="#004643"
                    border="1px solid #F99B28"
                    type="submit"
                    loadingText={newBankDetails ? "Saving" : "Updating"}
                    isLoading={updateBroadcasterBankAccountLoading}
                  >
                    {newBankDetails ? "Add Account" : "Update Details"}
                  </Button>
                </Stack>
              </Box>
            </form>
          </Box>
        </VStack>
      </Box>
    </Box>
  );
};

export default RadioStationProfileDetails;
