import React, { useState, useEffect } from "react";
import "./radiostationHero.css";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import Aos from "aos";
import "aos/dist/aos.css";
import Tilt from "react-parallax-tilt";
import BroadCasterHero from "../../transmitAssets/radioTransmitter.gif";

const RadioStationHero = () => {
  const [openOption, setOpenOption] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({
      duration: 2000,
      offset: 0,
    });
  }, []);
  useEffect(() => {
    // track user event
    // if start a campaign button is clicked
    openOption === true && mixpanel.track("start_campaign_home");
  }, openOption);

  const routeHandler = (route) => {
    // register user event
    switch (route) {
      case "/book-campaign/":
        mixpanel.track("do_it_myself");
        break;

      case "/talk-with-an-expert":
        mixpanel.track("help_me_do_it");
        break;

      default:
        break;
    }

    navigate(route);
  };
  return (
    <div className="broadcasterHero-wrapper" data-aos="fade-up">
      <div className="broadcasterHero-sec1">
        <div className="broadcasterHero-text" data-aos="fade-right">
          <p>
            Make{" "}
            <span className="text-lower">
              <span className="broadcasterHero-color"> More </span>
              {"  "}
              <br />
              with your radio
              <br />
              station
            </span>
          </p>
        </div>
        <div className="broadcasterHero-motto" data-aos="fade-left">
          <p>
            F
            <span className="text-lower">
              ocused on helping you make money by utilizing cutting-edge ad
              technology
            </span>
          </p>
        </div>
        <article>
          <div
            className="broadcasterHero-btn"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
            onClick={() => setOpenOption(!openOption)}
          >
            <p>Start campaign</p>
          </div>
        </article>
        <div>
          {openOption && (
            <div className="option-btn">
              <button
                onClick={() => routeHandler("/book-campaign/")}
                className="broadcasterHero-option-btn"
              >
                Yes, I can do it myself.
              </button>
              <button
                onClick={() => routeHandler("/talk-with-an-expert")}
                className="broadcasterHero-option-btn"
              >
                No, Help me with it
              </button>
            </div>
          )}
        </div>
      </div>

      <div
        className="broadcasterHero-img-wrapper"
        data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
      >
        <Tilt tiltReverse={true} scale={1}>
          <img
            src={BroadCasterHero}
            className="broadcasterHero-image"
            alt="hero Image"
            width='60%'
          />
        </Tilt>
      </div>
    </div>
  );
};

export default RadioStationHero;
