import React, { useState } from "react";
import "./adminExchangeRate.css";
import filtersvg from "../../assets/icons/filter_up_down.svg";
import AdminTransactionLoader from "../Loader/AdminTransactionLoader/AdminTransactionLoader";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import SearchIcon from "../AdminTransactionTable/searchIcon";
import {
  getCountyByCurrency,
  getCurrencyName,
  getCurrencySymbols,
} from "../../utils/functions";
import { UPDATE_EXCHANGE_RATE } from "../GraphQL/Mutation";

const AdminExchangeRateTable = ({ data, header, loading, refetch }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [entries, setEntries] = useState(10);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchTerm, setSearchTerm] = useState("");
  const [updateItem, setUpdateItem] = useState({
    currency: "",
    rate: "",
  });
  const [rate, setRate] = useState(null);

  const [UpdateExchangeRate, { loading: isLoading }] =
    useMutation(UPDATE_EXCHANGE_RATE);

  const handlePagination = (action) => {
    if (action === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (action === "next" && data.length > currentPage * entries) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset current page when searching
  };
  const handleRate = (e) => {
    setRate(e.target.value);
  };

  const filteredData = data?.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const totalPages = Math.ceil(filteredData.length / entries);

  const paginatedData = filteredData?.slice(
    (currentPage - 1) * entries,
    currentPage * entries
  );

  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      // Display first two pages
      if (i <= 2) {
        pageNumbers.push(
          <span
            key={i}
            className={i === currentPage ? "active" : ""}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </span>
        );
      }
      // Display ellipsis for middle section
      else if (i > 2 && i < totalPages - 1 && Math.abs(i - currentPage) > 1) {
        // Display ellipsis only once in the middle section
        if (pageNumbers[pageNumbers.length - 1] !== ".") pageNumbers.push(" .");
      }
      // Display last two pages
      else if (i >= totalPages - 1) {
        pageNumbers.push(
          <span
            key={i}
            className={i === currentPage ? "active" : ""}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </span>
        );
      }
      // Display current and adjacent pages in the middle section
      else {
        pageNumbers.push(
          <span
            key={i}
            className={i === currentPage ? "active" : ""}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </span>
        );
      }
    }

    return pageNumbers;
  };

  const HandleUpdate = () => {
    UpdateExchangeRate({
      variables: {
        currency: updateItem?.currency,
        rate: parseFloat(rate),
      },
      onCompleted: (data) => {
        refetch();
        toast.success("Exchange rate updated successfully");

        refetch();
        setTimeout(() => {
          setRate("");
          setUpdateItem({
            currency: "",
            rate: "",
          });
          onClose();
        }, 2000);
      },
      onError(err) {
        toast.error("Operation Failed");
        setRate("");
        setUpdateItem({
          currency: "",
          rate: "",
        });
        onClose();
      },
    });
  };

  // console.log("updateItem", updateItem);
  return (
    <>
      {loading ? (
        <AdminTransactionLoader />
      ) : (
        <div className="wrapper">
          <div className="table_head">
            <div className="entry">
              <span>Show</span>
              <select
                value={entries}
                onChange={(e) => setEntries(parseInt(e.target.value))}
              >
                <option>10</option>
                <option>20</option>
                <option>50</option>
              </select>
              <span>entries</span>
            </div>

            <div className="input-box form-control">
              <input
                type="text"
                className="search-input form-control"
                placeholder="Search by currency"
                value={searchTerm}
                onChange={handleSearch}
              />
              <SearchIcon />
            </div>
          </div>
          {/* TABLE SECTION */}

          <div className="admintransaction_table_wrapper">
            <div className="table-body-container">
              <table
                id="data-table"
                className="table "
                width="100%"
                // height="0%"
              >
                <thead>
                  <tr>
                    {header.map((item, index) => {
                      return (
                        <th key={index}>
                          <div className="table_header">
                            <span>{item?.title}</span>
                            <span>
                              <img src={filtersvg} />
                            </span>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.length !== 0 ? (
                    paginatedData?.map((item, index) => {
                      return (
                        <tr className="odd gradeX" key={index}>
                          <td>{getCountyByCurrency(item?.currency)}</td>
                          <td>{getCurrencyName(item?.currency)}</td>
                          <td>{item.currency}</td>
                          <td>{item?.rate}</td>
                          <td>
                            <Button
                              variant="link"
                              onClick={() => {
                                setUpdateItem({
                                  currency: item?.currency,
                                  rate: item?.rate,
                                });
                                onOpen();
                              }}
                              style={{
                                color: "#fff",
                                paddingTop: "2px",
                                paddingBottom: "2px",
                                textDecoration: "none",
                              }}
                            >
                              Edit
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="not_found">
                      <td> No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="footer_wrapper">
              <div>
                <section className="pagination-section">
                  <div>
                    showing{" "}
                    {`${(currentPage - 1) * entries + 1} to ${Math.min(
                      currentPage * entries,
                      filteredData.length
                    )} of ${filteredData.length} entries`}
                  </div>
                </section>
              </div>
              {/* Pagination section */}
              <div className="pagination">
                <span onClick={() => handlePagination("prev")}>Previous</span>
                {renderPageNumbers()}
                <span onClick={() => handlePagination("next")}>Next</span>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent padding="20px">
          <div className="popover_wrapper_rate">
            <ModalHeader>
              <div className="popover_head">
                Set New Rate for {updateItem?.currency}
                <div onClick={onClose}>
                  <MdClose className="popover_closebtn" />
                </div>
              </div>
            </ModalHeader>

            <ModalBody>
              <div className="popover_content_rate">
                <div>
                  <p className="current_rate">Current Rate</p>
                  <p className="rate_val">
                    1NGN ={" "}
                    {`${getCurrencySymbols(updateItem?.currency)}${
                      updateItem?.rate
                    }`}
                  </p>
                </div>
                <div className="rate_input">
                  <p>Set New Rate</p>
                  <div>
                    <input
                      type="number"
                      className="search-input_rate"
                      placeholder={`Set new rate for ${updateItem?.currency}`}
                      value={rate}
                      onChange={handleRate}
                    />
                  </div>
                </div>
              </div>
            </ModalBody>

            <ModalFooter>
              <div className="popup_footer">
                <button
                  onClick={() => {
                    setUpdateItem({
                      currency: "",
                      rate: "",
                    });
                    setRate("");
                    onClose();
                  }}
                  style={{ color: "#000" }}
                  className={`${loading || isLoading ? "no-click" : "cancel"}`}
                  disabled={loading || isLoading}
                >
                  No, Cancel
                </button>
                <div onClick={HandleUpdate} className="approve">
                  {loading || isLoading ? (
                    <i class="fa fa-circle-o-notch fa-spin"></i>
                  ) : (
                    "Update Rate"
                  )}
                </div>
              </div>
            </ModalFooter>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AdminExchangeRateTable;