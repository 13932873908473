import React from "react";
import styled from "styled-components";

export const StationButtons = ({
  radioStationDetails,
  activeButton,
  onButtonClick,
}) => {
  return (
    <StationButtonWrapper>
      {radioStationDetails?.map((btn, index) => (
        <RadioStationName
          key={index}
          onClick={() => onButtonClick(btn.id)}
          className={btn.id === activeButton ? "active" : ""}
        >
          {btn.radioStation}
        </RadioStationName>
      ))}
    </StationButtonWrapper>
  );
};

const StationButtonWrapper = styled.div`
  max-width: 1100px;
  display: flex;
  gap: 10px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 2px;
    height: 5px;
    background-color: #fff;
    opacity: 0.3;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaa;
  }
`;

const RadioStationName = styled.button`
  width: fit-content;
  height: 50px;
  padding: 0 10px;
  /* padding: 10px 30px; */
  border: 1px solid rgba(89, 84, 84, 0.8);
  border-bottom: 0px;
  border-radius: 2px;
  text-transform: uppercase;
  white-space: nowrap;
  font-family: Adobe Clean;

  .active {
    background-color: #f99b28;
    color: #fff;
  }
`;
