import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, HStack, Link } from "@chakra-ui/react";
import { ChevronLeftIcon, DownloadIcon } from "../../icons";

const DownloadDocument = ({ campaignDetails, previousTab, isAdmin }) => {
  const navigate = useNavigate();
  return (
    <>
      <Button
        leftIcon={<ChevronLeftIcon />}
        border="none"
        bg="transparent"
        color="#F99B28"
        mb={10}
        pl={0}
        _hover={{ bg: "transparent" }}
        onClick={() => (isAdmin ? previousTab(0) : navigate(-1))}
      >
        Back
      </Button>
      <>
        <IdTextWrapper>#{campaignDetails?.id}</IdTextWrapper>
        <DownloadDocumentContainer>
          <CampaignDocumentHeaderText>
            Campaign Document
          </CampaignDocumentHeaderText>
          {campaignDetails?.campaignDocument
            .map((campaign, i) => {
              let mutatedCampaign = { ...campaign };
              if (i === 0) {
                mutatedCampaign["name"] = "Jingle Audio";
              }
              if (i === 1) {
                mutatedCampaign["name"] = "Paid Announcement Script";
              }
              if (i === 2) {
                mutatedCampaign["name"] = "APCON Certificate";
              }
              return mutatedCampaign;
            })
            ?.map(({ name = "", url = "" }) => {
              return (
                <HStack mt="18px">
                  <DownloadContainer>
                    <BookingHeaderText>{name}</BookingHeaderText>
                  </DownloadContainer>
                  <Link href={url} target="_blank" isExternal>
                    <DownloadIcon />
                  </Link>
                </HStack>
              );
            })}
        </DownloadDocumentContainer>
      </>
    </>
  );
};
export default DownloadDocument;

const IdTextWrapper = styled.span`
  margin-bottom: 25px;
  font-size: 20px;
  line-height: 20px;
  display: block;
  color: #f99b28;
`;

const DownloadDocumentContainer = styled.div`
  width: 318px;
  min-height: 106px;
  border: 0.8px dashed #aba8a8;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  padding: 18px 18px 25px 18px;
  margin-bottom: 40px;
`;

const CampaignDocumentHeaderText = styled.h1`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #004643;
`;

const BookingHeaderText = styled.p`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #004643;
`;

const DownloadContainer = styled.div`
  width: 248px;
  height: 29px;
  background: #ffffff;
  border: 0.21px solid #3b3950;
  border-radius: 2px;
  padding: 0px 19px;
  display: flex;
  align-items: center;
`;
