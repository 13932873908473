import React from "react";

const LanguageIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5893_8126)">
        <path
          d="M25.0013 2.5H11.668V6.66667H13.3346V4.16667H25.0013C25.5013 4.16667 25.8346 4.5 25.8346 5V14.1667C25.8346 14.6667 25.5013 15 25.0013 15H14.168V20.8333H9.7513L6.66797 23.25V20.8333H4.16797C3.66797 20.8333 3.33464 20.5 3.33464 20V10.8333C3.33464 10.3333 3.66797 10 4.16797 10H15.0013V8.33333H4.16797C2.7513 8.33333 1.66797 9.41667 1.66797 10.8333V20C1.66797 21.4167 2.7513 22.5 4.16797 22.5H5.0013V26.75L10.2513 22.5H15.8346V16.6667H25.0013C26.418 16.6667 27.5013 15.5833 27.5013 14.1667V5C27.5013 3.58333 26.418 2.5 25.0013 2.5Z"
          fill="#F99B28"
        />
        <path
          d="M5.16797 19.0827H7.16797L7.66797 17.7493H10.2513L10.7513 19.0827H12.7513L9.91797 11.666H7.91797L5.16797 19.0827ZM8.91797 13.7493L9.7513 16.3327H8.08464L8.91797 13.7493Z"
          fill="#F99B28"
        />
        <path
          d="M16.668 14.1667C17.5846 14.1667 18.8346 13.9167 20.0013 13.3333C21.168 13.9167 22.5013 14.1667 23.3346 14.1667V12.5C23.3346 12.5 22.5013 12.5 21.5846 12.1667C22.5846 11.1667 23.3346 9.66667 23.3346 7.5V6.66667H20.8346V5H19.168V6.66667H16.668V8.33333H21.5846C21.418 9.83333 20.7513 10.75 20.0013 11.3333C19.5013 10.9167 19.0013 10.3333 18.668 9.58333H16.918C17.2513 10.6667 17.7513 11.5 18.418 12.1667C17.5846 12.5 16.8346 12.5 16.668 12.5V14.1667Z"
          fill="#F99B28"
        />
      </g>
      <defs>
        <clipPath id="clip0_5893_8126">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LanguageIcon;
