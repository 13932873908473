import React from "react";

const PaymentIcon = () => {
  return (
    <svg
      width="27"
      height="21"
      viewBox="0 0 27 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3214 14.7C18.0657 14.7 17.8204 14.8106 17.6396 15.0075C17.4587 15.2044 17.3571 15.4715 17.3571 15.75C17.3571 16.0285 17.4587 16.2955 17.6396 16.4925C17.8204 16.6894 18.0657 16.8 18.3214 16.8H22.1786C22.4343 16.8 22.6796 16.6894 22.8604 16.4925C23.0413 16.2955 23.1429 16.0285 23.1429 15.75C23.1429 15.4715 23.0413 15.2044 22.8604 15.0075C22.6796 14.8106 22.4343 14.7 22.1786 14.7H18.3214ZM0 5.25C0 3.85761 0.50797 2.52226 1.41216 1.53769C2.31636 0.553123 3.54271 0 4.82143 0H22.1786C23.4573 0 24.6836 0.553123 25.5878 1.53769C26.492 2.52226 27 3.85761 27 5.25V15.75C27 17.1424 26.492 18.4777 25.5878 19.4623C24.6836 20.4469 23.4573 21 22.1786 21H4.82143C3.54271 21 2.31636 20.4469 1.41216 19.4623C0.50797 18.4777 0 17.1424 0 15.75V5.25ZM25.0714 6.3V5.25C25.0714 4.41457 24.7666 3.61335 24.2241 3.02261C23.6816 2.43187 22.9458 2.1 22.1786 2.1H4.82143C4.0542 2.1 3.31839 2.43187 2.77587 3.02261C2.23335 3.61335 1.92857 4.41457 1.92857 5.25V6.3H25.0714ZM1.92857 8.4V15.75C1.92857 16.5854 2.23335 17.3866 2.77587 17.9774C3.31839 18.5681 4.0542 18.9 4.82143 18.9H22.1786C22.9458 18.9 23.6816 18.5681 24.2241 17.9774C24.7666 17.3866 25.0714 16.5854 25.0714 15.75V8.4H1.92857Z"
        fill="#F99B28"
      />
    </svg>
  );
};

export default PaymentIcon;
