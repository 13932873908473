import React from "react";
import logo from "../../transmitAssets/logo.svg";
import signupSvg from "../../transmitAssets/signup.svg";
import loginSvg from "../../transmitAssets/login.svg";

import "./css/authLayout.css";

const TransmitAuthLayout = ({ children, authType }) => {
  return (
    <div className="transmitAuth">
      <div className="transmitAuthContainer">
        <div className="authWrapper">
          <div className="authWrapperLeft">
            <img src={logo} alt="logo" className="authSvg" />
            {authType === "signup" && (
              <div className="authWrapperTitle">
                <p>
                  Brand your <br /> <span>voice</span>
                </p>
              </div>
            )}
            <div className="signupSvgWrapper">
              <img
                src={authType === "signup" ? signupSvg : loginSvg}
                alt="logo"
                className="signupSvg"
              />
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default TransmitAuthLayout;
