import React from "react";
import AdminDashboardLayout from "./AdminDashboardLayout";
import AdminDashboardCard from "../components/AdminDashboardCard/AdminDashboardCard";
import advert from "../components/AdminDashboardCard/assets/advert.svg";
import vendor from "../components/AdminDashboardCard/assets/vendors.svg";
import orders from "../components/AdminDashboardCard/assets/orders.svg";
import campaign from "../components/AdminDashboardCard/assets/campaigns.svg";
import commission from "../components/AdminDashboardCard/assets/commission.svg";
import pending from "../components/AdminDashboardCard/assets/pending.svg";
import sales from "../components/AdminDashboardCard/assets/sales.svg";
import total from "../components/AdminDashboardCard/assets/totalearnings.svg";
import { GET_ADMIN_DASHBOARD_STATS } from "../components/GraphQL/Queries";
import { useQuery } from "@apollo/client";
import PlainDashboardCard from "../components/AdminDashboardCard/PlainDashboardCard";
import AdminDashboardTable from "../components/AdminDashboardTable/AdminDashboardTable";
import { Center, ChakraProvider } from "@chakra-ui/react";
import { TableSpinner } from "../components/Spinner";

const AdminDashboard = () => {
  const { data, loading } = useQuery(GET_ADMIN_DASHBOARD_STATS);

  const cardData = data?.admin?.getAminDashboardStats;

  // console.log("data--", data);

  return (
    <AdminDashboardLayout>
      {loading ? (
        <ChakraProvider>
          <Center h="100vh">
            <TableSpinner />
          </Center>
        </ChakraProvider>
      ) : (
        <>
          <div className="Home_Dashboard_wrap">
            {/* FIRST SECTION FOR THE CARD COMPONENT */}
            <div className="dashboard_card">
              <AdminDashboardCard
                svg={advert}
                title={cardData?.numberOfAdvertiser}
                content="Advertisers"
              />
              <AdminDashboardCard
                svg={vendor}
                title={cardData?.numberOfRadioStation}
                content="Radio Stations"
              />
              <AdminDashboardCard
                svg={orders}
                title={cardData?.numberOfRadioStation}
                content="Campaigns"
              />
            </div>

            <div className="dashboard_layout">
              {/* cards */}
              <div className="dashboard_content">
                <div className="dashboard_cards">
                  <PlainDashboardCard
                    svg={sales}
                    content={cardData?.totalValueOfCampaign}
                    title="Sales"
                  />
                  <PlainDashboardCard
                    svg={pending}
                    content={cardData?.pendingWithdraw}
                    title="Pending Withdrawal"
                  />
                  {/* <PlainDashboardCard
                    svg={campaign}
                    content={cardData?.numberOfCampaign}
                    title="Campaigns"
                  /> */}
                  <PlainDashboardCard
                    svg={total}
                    content={cardData?.commissonEarned}
                    title="Total Earnings"
                  />
                </div>
                {/* Table */}

                <div>
                  <AdminDashboardTable cardData={cardData} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </AdminDashboardLayout>
  );
};

export default AdminDashboard;
