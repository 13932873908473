import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";
import AdvertDashboardLayout from "../../ResponsiveAdvertiserDashboardLayout/AdvertDashboardLayout";
import AdvertiserDraftsLoader from "../../../../components/SkeletonLayoutLoader/AdvertiserDrafts";
import { EmptyState } from "../../../../components/Modal/ScheduleModal";

import "../css/draft.css";
import { Button, Flex, Input, InputGroup, InputRightElement, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import DateRangePicker from "../../../../TransmitDashboard/DateRangePicker";
import { MdArrowDropDown } from "react-icons/md";
import CampaignTable from "../../../../components/CampaignTable";
import { ADVERTISER_DRAFTS } from "../../../../components/GraphQL/Queries";

const Draft = () => {
  const [selectedItem, setSelectedItem] = useState('Advert Type');
  const [date, setDate] = useState(null);
  const [page, setPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  const { data, loading, refetch } = useQuery(ADVERTISER_DRAFTS, {
    variables: {
      page,
      itemsPerPage,
      draftType: selectedItem === 'Advert Type' ? null : selectedItem,
      date: date !== null && new Date(date),

    },
    notifyOnNetworkStatusChange: true,
  });

  const totalPages = Math.ceil(data?.advertiser.getAllDraft?.totalCount / itemsPerPage)

  const columnData = [
    {
      title: `radioStation`,
      isDropDown: false,
      isFilter: false,
      options: [],
    },
    {
      title: `Date`,
      isDropDown: false,
      isFilter: false,
      options: [],
    },
    {
      title: `advertType`,
      isDropDown: false,
      isFilter: false,
      options: [],
    },
    {
      title: `Duration`,
      isDropDown: false,
      isFilter: false,
      options: [],
    },
    {
      title: `Amount`,
      isDropDown: false,
      isFilter: true,
      options: [],
    },
  ];

  const shuffledData = data?.advertiser.getAllDraft?.drafts.map(
    (campaign) => {
      // Get the radio station name or use a placeholder if it's missing
      const radioStation =
        campaign?.radioStation && campaign?.radioStation.length > 0 ? (
          <p className="radio-badge">
            {campaign?.radioStation[0].name}
            {campaign?.radioStation?.length > 1 && (
              <span className="avatar-badge">
                +{campaign?.radioStation?.length - 1}
              </span>
            )}
          </p>
        ) : (
          "-- -- --"
        )

      return {
        id: campaign.id,
        radioStation: radioStation,
        date: moment(campaign.date).format("DD/MM/YYYY") || "-- -- --",
        advertType: campaign.type || "-- -- --",
        duration: campaign.duration || "-- -- --",
        amount: `₦${parseInt(campaign.subtotal).toLocaleString()}` || "-- -- --",
      }

    }
  );


  const columns = [
    // { key: "id", label: "ID" },
    { key: "radioStation", label: "Radio Station" },
    { key: "date", label: "Date" },
    { key: "advertType", label: "Advert Type" },
    { key: "duration", label: "Duraion" },
    { key: "amount", label: "Amount" },
  ];

  const handleNextOrPrevPage = (newPage) => {
    setPage(newPage);
    refetch({
      page: newPage,
      itemsPerPage,
      advertType: selectedItem === 'Advert Type' ? null : selectedItem,
      date: date !== null && new Date(date),
    });
  };

  const handlePerPageChange = (e) => {
    const selectedValue = Number(e.target.value);
    setItemsPerPage(selectedValue);
  };

  return (
    <AdvertDashboardLayout>
      <Stack spacing={8} pt={10} color={'#000'}>
        <Flex justifyContent={'space-between'} alignItems={{ base: 'flex-start', md: 'center' }} flexDirection={{ base: 'column', md: 'row' }} gap={'10px'}>
          <Text as={'h1'} fontSize={'24px'} fontWeight={600} lineHeight={'29.09px'}>Drafts</Text>
          <InputGroup maxW={{ md: '500px' }}>
            <Input type='text' placeholder='Search Draft' />
            <InputRightElement>
              <SearchIcon />
            </InputRightElement>

          </InputGroup>
        </Flex>

        <Flex alignItems={'center'} flexWrap={'wrap'} gap={{ base: '20px' }}>
          <Menu>
            <MenuButton display={'flex'} alignItems={'center'} as={Button} rightIcon={<MdArrowDropDown size={20} />} fontWeight={400} border={'0.5px solid #D5D4DF'} padding={'8px 16px'} variant={'none'} bg={'white'} h={'42px'} borderRadius={'4px'}>
              {selectedItem}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => setSelectedItem('Jingle')}>Jingle</MenuItem>
              <MenuItem onClick={() => setSelectedItem('Paid')}>Paid Announcement</MenuItem>
            </MenuList>
          </Menu>


          <DateRangePicker isDraftDate date={date} setDate={setDate} />
        </Flex>
      </Stack>
      {loading ? (
        <AdvertiserDraftsLoader />
      ) : (
        data?.advertiser.getAllDraft.drafts.length > 0 && (
          <Stack pt={10} color={'#000'}>
            <CampaignTable
              data={shuffledData}
              column={columnData}
              isPerPage={true}
              columns={columns}
              isClickable={false}
              hasMore={data?.advertiser.getAllDraft.hasMore}
              totalPage={totalPages}
              currentPage={data?.advertiser.getAllDraft.page}
              isPerginate={true}
              handleNextOrPrevPage={handleNextOrPrevPage}
              itemsPerPage={itemsPerPage}
              handlePerPageChange={handlePerPageChange}
              isDraftTable={true}
            />
          </Stack>
        )
      )}
      {data?.advertiser.getAllDraft.drafts.length === 0 && (
        <EmptyState
          title={date ? `No draft created for ${date}` : "No saved campaign draft"}
          text="Select a different date or Create a new campaign in order to view your draft campaigns"
          btnText="Create new campaign"
          route="/book-campaign/"
        />
      )}
    </AdvertDashboardLayout>
  );
};

export default Draft;


