import React, { useState, Suspense, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { ArrowBackIcon } from "@chakra-ui/icons";
import { MdHorizontalRule } from "react-icons/md";
import {
  Button,
  Box,
  HStack,
  Text,
  Card,
  CardBody,
  Stack,
  Skeleton,
  Flex,
  Input,
  useToast,
} from "@chakra-ui/react";
import styled from "styled-components";
import { InfoIcon } from "@chakra-ui/icons";
import { Line } from "react-chartjs-2";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Select,
} from "@chakra-ui/react";

import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useQuery, useSubscription } from "@apollo/client";
import {
  GET_RADIOSTATION_FOR_PORTFOLIO,
  GET_PORTFOLIO_METRICS,
  GET_PORTFOLIO_SUBSCRIPTION_CHARTS,
  GET_RADIO_STATIONS_FOR_PORTFOLIO_METRICS,
} from "../../../../../../components/GraphQL/Queries";
import Dropdown from "../Dropdown";
import CampaignCard from "./CampaignCard";
import {
  StationSVG,
  AdVisitSVG,
  AdImpact,
  TotalSpend,
  CalenderIcon,
  MoneySvg,
  PlaySvg,
  FileSvg,
  DownloadSvg,
} from "./AnalyticsSVG/index";
import "./style.css";
import BroadCasterSummaryTable from "./BroadCastSummaryTable/BroadCasterSummaryTable";
import WebAnalytics from "./WebAnalytics/WebAnalytics";
import GraphDummyData, { GraphForecastData } from "./dummydata";
import ForeCastAnalytics from "./ForeCastAnalytics/ForeCastAnalytics";
import NotAvailable from "./NotAvailable/NotAvailable";
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const datas = [
  {
    id: 1,
    name: "Today",
    noOfDays: 1,
    checked: true,
  },
  {
    id: 2,
    name: "Last 7 days",
    noOfDays: 7,
    checked: false,
  },
  {
    id: 3,
    name: "Last 30 days",
    noOfDays: 30,
    checked: false,
  },
  {
    id: 4,
    name: "Last Year",
    noOfDays: 365,
    checked: false,
  },
  {
    id: 5,
    name: "Custom Date",
    checked: false,
  },
];

export const SvgWrapper = ({ children }) => {
  return <div className="icon_wrap">{children}</div>;
};

const AnalyticPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useToast();

  // const { data: { getPortfolio = [] } = {} } = useSubscription(
  //   GET_PORTFOLIO_SUBSCRIPTION_CHARTS,
  //   {
  //     variables: {
  //       getPortfolioId: id,
  //     },
  //     onError(err) {
  //       toast({
  //         title: `${err?.message}`,
  //         status: "error",
  //         position: "top-right",
  //         duration: 6000,
  //         isClosable: true,
  //       });
  //     },
  //   }
  // );

  // console.log("getPortfolio- date-", getPortfolio?.data?.date);
  // console.log("getPortfolio--", getPortfolio);
  const [radioStationName, setRadioStationName] = useState("");
  const [radioStationId, setRadioStationId] = useState("");
  const [campaignName, setCampaignName] = useState([]);
  const [selectedMetricId, setSelectedMetricId] = useState("");
  const [isFilter, setIsFilter] = useState(false);

  const {
    data: { advertiser: { getRadioStationsForPortfolio = [] } = {} } = {},
    loading,
  } = useQuery(GET_RADIOSTATION_FOR_PORTFOLIO, {
    variables: {
      portfolioId: id,
    },
    onCompleted: (data) => {
      setRadioStationId(
        data?.advertiser.getRadioStationsForPortfolio[0].radioStationId
      );
      setRadioStationName(
        data?.advertiser.getRadioStationsForPortfolio[0].radioStationName
      );
    },
  });
  const [portfolioData, setPortfolioData] = useState({
    daySelected: datas[0].name,
    data: datas,
    totalDays: 1,
  });

  const {
    data: { advertiser: { getPortfolioMetrics = {} } = {} } = {},
    loading: metricsLoader,
  } = useQuery(GET_PORTFOLIO_METRICS, {
    skip: selectedMetricId === "",

    variables: {
      portfolioId: id,
      duration: portfolioData.totalDays,
      radioStationId: selectedMetricId,
    },
    onCompleted: () => {
      setIsFilter(false);
    },
    onError(err) {
      toast({
        title: `${err?.message}`,
        status: "error",
        position: "top-right",
        duration: 6000,
        isClosable: true,
      });
    },
  });

  const { data, loading: isLoading } = useQuery(
    GET_RADIO_STATIONS_FOR_PORTFOLIO_METRICS,
    {
      variables: {
        portfolioId: id,
      },
      onCompleted: (data) => {
        const { advertiser: { getRadioStationsForPortfolio = [] } = {} } =
          data || {};
        setCampaignName(getRadioStationsForPortfolio);
        if (campaignName?.length > 0) {
          setSelectedMetricId(campaignName[0]?.radioStationId);
        }
      },
    }
  );

  useEffect(() => {
    // Update selectedMetricId when campaignName changes
    if (campaignName.length > 0) {
      setSelectedMetricId(campaignName[0]?.radioStationId);
    }
  }, [campaignName]);

  // const data1 = {
  //   labels: getPortfolio?.map((item) => item.date),
  //   datasets: [
  //     {
  //       label: "Visits",
  //       data: getPortfolio?.map((item) => item.count),
  //       borderColor: "#EF4136",
  //       backgroundColor: "#EF4136",
  //       borderWidth: 2,
  //     },
  //   ],
  // };

  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: false,
  //     title: {
  //       display: false,
  //     },
  //   },
  //   scales: {
  //     x: {
  //       grid: {
  //         display: false,
  //       },
  //     },
  //   },
  // };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedMetricId(selectedValue);
    setIsFilter(true);
  };

  const handleOptionChange = (event) => {
    const selectedCampaign = event.target.value;
    setRadioStationName(selectedCampaign);
    setRadioStationId(getradioStationId(selectedCampaign));
  };
  const getradioStationId = (selectedCampaign) => {
    const campaignOption = getRadioStationsForPortfolio.find(
      (campaign) => campaign.radioStationName === selectedCampaign
    );
    return campaignOption ? campaignOption.radioStationId : "";
  };

  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const year = currentDate.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;
  return (
    <>
      {loading || metricsLoader || isLoading ? (
        <CardSkeleton />
      ) : (
        <CamapaignAnalyticsContainer>
          {/* HEADER SECTION */}
          <div className="header_section">
            <div>
              <p className="campaignName">
                {getPortfolioMetrics?.campaignName}
              </p>
            </div>

            <div className="calender_wrapper">
              <span>{formattedDate}</span>
              <CalenderIcon />
            </div>
          </div>

          {/* END OF HEADER SECTION */}

          {/* CARD SECTION */}
          <div className="card_container">
            <CampaignCard
              name="Total Spend"
              figure={`₦${getPortfolioMetrics?.totalAmount || 0}`}
              flag={
                <SvgWrapper>
                  <MoneySvg />
                </SvgWrapper>
              }
            />
            <CampaignCard
              name="Station"
              figure={getPortfolioMetrics?.numberOfStations || 0}
              flag={<StationSVG />}
            />
            <CampaignCard
              name="Slots Selected"
              figure={getPortfolioMetrics?.numberOfSlots || 0}
              flag={<AdImpact />}
            />
            <CampaignCard
              name="Slots Played"
              figure={getPortfolioMetrics?.spotPlays || 0}
              flag={
                <SvgWrapper>
                  <PlaySvg />
                </SvgWrapper>
              }
            />
          </div>
          {/* END OF CARD SECTION */}

          {/* TAB SECTION */}
          <div className="tab_wrappers">
            <Tabs isLazy>
              <div
                style={{
                  overflowX: "scroll",
                  color: "#838383",
                  lineHeight: "0.9",
                }}
              >
                <TabList>
                  <Tab
                    _selected={{
                      color: "#005E59",
                      mb: "2px",
                      borderBottom: "2px solid #005E59",
                      fontWeight: "600",
                    }}
                  >
                    BROADCAST SUMMARY
                  </Tab>
                  <Tab
                    _selected={{
                      color: "#005E59",
                      mb: "2px",
                      borderBottom: "2px solid #005E59",
                      fontWeight: "600",
                    }}
                  >
                    WEB ANALYTICS
                  </Tab>
                  <Tab
                    _selected={{
                      color: "#005E59",
                      mb: "2px",
                      borderBottom: "2px solid #005E59",
                      fontWeight: "600",
                    }}
                  >
                    FORECAST ANALYTICS
                  </Tab>
                  <Tab
                    _selected={{
                      color: "#005E59",
                      mb: "2px",
                      borderBottom: "2px solid #005E59",
                      fontWeight: "600",
                    }}
                  >
                    CREATIVE ANALYTICS
                  </Tab>
                </TabList>
                <div
                  style={{
                    borderTop: "1px solid #c4c4c4",
                    position: "relative",
                    top: "-0.1rem",
                  }}
                ></div>
              </div>
              <TabPanels>
                {/* broadcast summary panel */}
                <TabPanel>
                  <div className="panel_wrapper">
                    <div className="sub_header">
                      <div>
                        <p className="title">Radio Broadcast Summary</p>
                      </div>
                      <div className="select_field">
                        <select
                          className="custom-select"
                          value={selectedMetricId}
                          onChange={handleSelectChange}
                        >
                          {campaignName.map((metric) => (
                            <option
                              key={metric.radioStationId}
                              value={metric.radioStationId}
                            >
                              {metric.radioStationName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* cardsummary */}
                    <div className="cardSummary_wrapper">
                      <div className="cardSummary">
                        <p className="cardSummary_fig">
                          {getPortfolioMetrics.total || 0}
                        </p>

                        <p className="cardSummary_total">Total</p>
                      </div>
                      <div className="cardSummary">
                        <p
                          className="cardSummary_fig"
                          style={{ color: "#005E59" }}
                        >
                          {getPortfolioMetrics.completed || 0}
                        </p>
                        <p className="cardSummary_total">Completed</p>
                      </div>
                      {/* <div className="cardSummary">
                        <p
                          className="cardSummary_fig"
                          style={{ color: "#D40000" }}
                        >
                          {getPortfolioMetrics.missed || 0}
                        </p>
                        <p className="cardSummary_total">missed</p>
                      </div> */}
                      <div className="cardSummary">
                        <p
                          className="cardSummary_fig"
                          style={{ color: "#1F2EB4" }}
                        >
                          {getPortfolioMetrics.inProgress || 0}
                        </p>
                        <p className="cardSummary_total">In Progress</p>
                      </div>
                    </div>

                    {/* Table section */}
                    <div className="table_section">
                      {(!loading || !metricsLoader) &&
                        (isFilter ? (
                          <TabsSkeleton />
                        ) : (
                          <BroadCasterSummaryTable
                            data={getPortfolioMetrics?.broadcastSummary}
                          />
                        ))}
                    </div>

                    {/* End of Table section */}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="panel_wrapper">
                    <div className="chart_title">
                      <p>Web Analytics</p>
                    </div>
                    <WebAnalytics data={GraphDummyData} isLabel={true} />
                    {/* <NotAvailable /> */}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="panel_wrapper">
                    <div className="chart_title">
                      <p>Campaign Forecast</p>
                      <div style={{display: "flex", fontSize:"12px", marginTop: "1rem", alignItems: "center", gap:"1rem"}}>
                        <div style={{display:"flex", gap:"2px", alignItems:"center"}}>
                          <span >
                            <MdHorizontalRule style={{color:"#005E59", alignItems:"end", display:"flex", fontSize: "20px"}}/> 
                          </span>
                          <span>Impression</span>
                        </div>
                        <div style={{display:"flex", gap:"5px", alignItems:"center"}}>
                          <span style={{fontSize:"20px", color: "#FC9732", textAlign:"center"}}>- - -</span>
                          <span>Forecast</span>
                        </div>
                      </div>
                    </div>
                    <div style={{ paddingTop: "1rem" }}>
                      <ForeCastAnalytics />
                    </div>

                    {/* <NotAvailable /> */}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="downloadpanel_wrapper">
                    <div className="creative_wrapper">
                      <div className="creative_cont">
                        <div className="svgfile">
                          <FileSvg />
                        </div>
                        <div className="creative_txt">
                          <p>Creative Analysis</p>
                          <p>
                            Once the campaign is over you can download the
                            creative analysis
                          </p>
                        </div>
                      </div>
                      <div className="creative_download">
                        <DownloadSvg />
                        <p>Download</p>
                      </div>
                    </div>
                    {/* <NotAvailable /> */}
                  </div>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
          {/* END OF TAB SECTION */}
        </CamapaignAnalyticsContainer>
      )}
    </>
  );
};
export default AnalyticPage;
const CamapaignAnalyticsContainer = styled.div`
  padding-top: 50px;
  padding-left: 70px;
  padding-right: 55px;
  padding-bottom: 70px;
`;
const CardSkeleton = () => {
  return (
    <Stack spacing={10} padding="20px 60px">
      {/* HEADER SECTION */}
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Skeleton height="20px" width="120px" />
        </Box>
        <Flex alignItems="center">
          <Skeleton height="20px" width="80px" mr="10px" />
          <Skeleton height="20px" width="20px" />
        </Flex>
      </Flex>
      {/* END OF HEADER SECTION */}

      {/* CARD SECTION */}
      <Flex justifyContent="space-between">
        {[1, 2, 3, 4].map((index) => (
          <Box
            key={index}
            borderWidth="1px"
            borderRadius="md"
            p={4}
            width="300px"
            height="200px"
          >
            <Skeleton height="20px" width="120px" mb="20px" />
            <Skeleton height="40px" width="100px" />
            <Skeleton height="20px" width="40px" mt="10px" />
          </Box>
        ))}
      </Flex>
      {/* END OF CARD SECTION */}

      {/* TAB SECTION */}
      <Tabs isLazy>
        <TabList>
          <Tab>
            <Skeleton height="20px" width="150px" />
          </Tab>
          <Tab>
            <Skeleton height="20px" width="150px" />
          </Tab>
          <Tab>
            <Skeleton height="20px" width="150px" />
          </Tab>
          <Tab>
            <Skeleton height="20px" width="150px" />
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Skeleton height="300px" />
          </TabPanel>
          <TabPanel>
            <Skeleton height="300px" />
          </TabPanel>
          <TabPanel>
            <Skeleton height="300px" />
          </TabPanel>
          <TabPanel>
            <Skeleton height="300px" />
          </TabPanel>
        </TabPanels>
      </Tabs>
      {/* END OF TAB SECTION */}
    </Stack>
  );
};

const TabsSkeleton = () => {
  return (
    <Stack spacing={10} padding="20px 60px">
      {/* TAB SECTION */}
      <Skeleton height="300px" />

      {/* END OF TAB SECTION */}
    </Stack>
  );
};
