import React, { useEffect } from "react";
import "../css/newsletter.css";
import { toast } from "react-toastify";

const Newsletter = () => {
  useEffect(() => {
    const js = `
    (function (w, d, e, u, f, l, n) {
      (w[f] =
        w[f] ||
        function () {
          (w[f].q = w[f].q || []).push(arguments);
        }),
        (l = d.createElement(e)),
        (l.async = 1),
        (l.src = u),
        (n = d.getElementsByTagName(e)[0]),
        n.parentNode.insertBefore(l, n);
    })(
      window,
      document,
      "script",
      "https://assets.mailerlite.com/js/universal.js",
      "ml"
    );
    ml("account", "329133");
  `;
    const script = document.createElement("script");
    const scriptText = document.createTextNode(js);
    script.appendChild(scriptText);
    document.head.appendChild(script);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    try {
      const response = await fetch(
        "https://assets.mailerlite.com/jsonp/329133/forms/80133036814894485/subscribe",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      // console.log(data); // Handle the response as needed
      toast.success("Thank you for Subscribing to our Newsletter")
    } catch (error) {
      toast.error("An Error occurred")
    }
  };

  return (
    <>
      <section id="newsletter">
        <div>
          <div>
            <h2>New to Transmit?</h2>
            <p>Subscribe to our Newsletter to get updates on our latest offers!</p>
          </div>
          <div>

          <form
            class="ml-block-form"
            className="form-filed"
            onSubmit={handleSubmit}
          >
            <input
              type="email"
              class="form-control"
              name="fields[email]"
              placeholder="Enter Your Email Address"
              autoComplete="email"
              required
            />
            <button type="submit">Subscribe</button>
          </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Newsletter;
