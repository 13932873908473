import React, { useEffect } from "react";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";
import topLeft from "../../../transmitAssets/top-left.svg";
const TransmitRadioStationStepCard = ({ header, content, number, firstLetter }) => {
  useEffect(() => {
    Aos.init({
      duration: 2000,
      offset: 0,
    });
  }, []);
  return (
    <div className="cardStep" data-aos="flip-left">
      <div className="cardStepWrapper ">
      <img
                src={topLeft}
                alt="top left"
                className="topLeft-spiral"
                width="100px"
                height="100px"
              />
        <h1 className="cardStepTitle">{header}</h1>
        <p className="cardStepContent">{firstLetter}<span className="lower-text">{content}</span></p>
        <div className="cardStepNumber" data-aos="fade-down">
          {number}
        </div>
      </div>
    </div>
  );
};

export default TransmitRadioStationStepCard;
