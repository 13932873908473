import styled from "styled-components";

export const SocialLink = styled.a``;

const HoveredSVG = styled.svg`
  ${SocialLink} & {
    fill: white;
  }
  ${SocialLink}:hover & {
    fill: #f99b28;
  }
`;

export const InstagramLogo = () => {
  return (
    <HoveredSVG
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.40224 0.0920452C3.18133 0.147133 2.1231 0.44565 1.26861 1.29653C0.41114 2.15188 0.11636 3.21419 0.0611356 4.4224C0.0268068 5.17651 -0.173942 10.8736 0.408155 12.3677C0.800698 13.3757 1.57384 14.1506 2.59102 14.5444C3.06565 14.729 3.60745 14.8541 4.40224 14.8906C11.0479 15.1913 13.5113 15.0276 14.5501 12.3677C14.7345 11.8942 14.8613 11.353 14.8964 10.5602C15.2001 3.89758 14.8472 2.45338 13.6889 1.29653C12.7703 0.38014 11.6897 -0.243693 4.40224 0.0920452ZM10.5673 3.51758C10.5673 4.0104 10.9681 4.4109 11.4629 4.4109C11.9577 4.4109 12.3592 4.0104 12.3592 3.51758C12.3592 3.02477 11.9577 2.62427 11.4629 2.62427C10.9681 2.62427 10.5673 3.02477 10.5673 3.51758ZM3.64696 7.49061C3.64696 9.60181 5.36265 11.3133 7.4791 11.3133C9.59555 11.3133 11.3112 9.60181 11.3112 7.49061C11.3112 5.3794 9.59555 3.6687 7.4791 3.6687C5.36265 3.6687 3.64696 5.3794 3.64696 7.49061ZM4.99168 7.49095C4.99168 6.1212 6.10513 5.00977 7.47903 5.00977C8.85293 5.00977 9.96638 6.1212 9.96638 7.49095C9.96638 8.86145 8.85293 9.97288 7.47903 9.97288C6.10513 9.97288 4.99168 8.86145 4.99168 7.49095ZM4.46335 13.5513C3.73573 13.5186 3.34095 13.3972 3.07751 13.2952C2.41482 13.0377 1.91705 12.5419 1.66108 11.8831C1.21779 10.7478 1.3648 5.35589 1.40436 4.48341C1.44316 3.62881 1.6163 2.8479 2.21929 2.24491C2.96557 1.50048 3.92976 1.13571 10.4948 1.43199C11.3515 1.4707 12.1344 1.64341 12.7388 2.24491C13.4851 2.98934 13.8553 3.96082 13.5538 10.5006C13.5209 11.2265 13.3993 11.6203 13.2971 11.8831C12.6217 13.6139 11.0679 13.8543 4.46335 13.5513Z"
      />
    </HoveredSVG>
  );
};

export const TwitterLogo = () => {
  return (
    <HoveredSVG
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.11062 13C11.2426 13 14.5966 7.99797 14.5966 3.66069C14.5966 3.5183 14.5966 3.37671 14.5868 3.23592C15.2392 2.77195 15.8023 2.196 16.25 1.53684C15.6423 1.80242 14.9963 1.97681 14.3357 2.05361C15.0312 1.64324 15.5521 0.998483 15.8015 0.237739C15.1466 0.620111 14.4308 0.889691 13.6841 1.03448C12.4215 -0.287022 10.3098 -0.351017 8.96675 0.892091C8.10144 1.69363 7.73338 2.88874 8.00231 4.02866C5.32188 3.89587 2.82425 2.64956 1.131 0.599313C0.246187 2.0992 0.69875 4.01746 2.16369 4.98059C1.63313 4.96539 1.11394 4.8246 0.65 4.57022V4.61182C0.650813 6.1741 1.76962 7.5196 3.32475 7.82918C2.834 7.96117 2.31888 7.98037 1.82 7.88518C2.25631 9.22268 3.50837 10.1386 4.93431 10.165C3.75375 11.0785 2.29531 11.5745 0.793813 11.5729C0.528938 11.5721 0.264062 11.5569 0 11.5257C1.52506 12.4888 3.29875 13 5.11062 12.9976"
      />
    </HoveredSVG>
  );
};

export const FacebookLogo = () => {
  return (
    <HoveredSVG
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.5 0C3.35795 0 0 3.37521 0 7.53855C0 11.3181 2.76989 14.4387 6.37909 14.9839V9.53661H4.52352V7.555H6.37909V6.23644C6.37909 4.05334 7.43727 3.09492 9.24239 3.09492C10.1069 3.09492 10.5641 3.15934 10.7806 3.18881V4.91856H9.5492C8.78284 4.91856 8.51523 5.64877 8.51523 6.47184V7.555H10.7611L10.4564 9.53661H8.51523V15C12.1759 14.5007 15 11.3548 15 7.53855C15 3.37521 11.642 0 7.5 0Z" />
    </HoveredSVG>
  );
};

export const LinkedInLogo = () => {
  return (
    <HoveredSVG
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7142 16.7136H13.4285V10.9644C13.4285 9.38729 12.7328 8.50754 11.485 8.50754C10.1272 8.50754 9.32136 9.42425 9.32136 10.9644V16.7136H6.03564V6.03504H9.32136V7.23596C9.32136 7.23596 10.3523 5.42718 12.6753 5.42718C14.9991 5.42718 16.7142 6.84496 16.7142 9.77911C16.7142 12.7124 16.7142 16.7136 16.7142 16.7136ZM2.29157 4.32741C1.18347 4.32741 0.285645 3.42219 0.285645 2.30587C0.285645 1.19037 1.18347 0.285156 2.29157 0.285156C3.39886 0.285156 4.29668 1.19037 4.29668 2.30587C4.2975 3.42219 3.39886 4.32741 2.29157 4.32741ZM0.285645 16.7137H4.39279V6.03516H0.285645V16.7137Z"
      />
    </HoveredSVG>
  );
};


