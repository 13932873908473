import React, { Component } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";

import "./App.css";
import { Outlet, useNavigate } from "react-router-dom";
import { AUTH_TOKEN } from "./constant";
import ContextFile from "./bookCampaign/pages/contextFile";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { URL, WS_URL } from "./config/url";
import ScrollToTop from "./utils/scrollToTop";
import ErrorPage from "./components/ErrorPage/ErrorPage";

function App() {
  // const httpLink = createUploadLink({
  //   uri: URL,
  // });
  // Custom ErrorBoundary component
  class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = {
        hasError: false,
        error: null,
        errorInfo: null,
      };
    }

    componentDidCatch(error, errorInfo) {
      // You can log the error to an error reporting service here
      // For example, you can use Sentry or other error tracking tools
      console.error("ErrorBoundary caught an error:", error, errorInfo);
      this.setState({
        hasError: true,
        error: error,
        errorInfo: errorInfo,
      });
    }

    render() {
      if (this.state.hasError) {
        // You can customize the error UI here
        return (
          //FOR DEVELOPER view
          // <div>
          //   <h2>Something went wrong.</h2>
          //   {/* You can display a user-friendly error message */}
          //   <p>Oops! An error occurred. Please try again later.</p>
          //   {/* You can also display the error stack trace for debugging purposes */}
          //   {/* <details style={{ whiteSpace: "pre-wrap" }}>
          //     {this.state.error && this.state.error.toString()}
          //     <br />
          //     {this.state.errorInfo.componentStack}
          //   </details> */}
          // </div>

          //FOR USER view
          <ErrorPage/>
        );
      }
      // If no error occurred, render the children as-is
      return this.props.children;
    }
  }
  
  const cache = new InMemoryCache({
    typePolicies: {
      GuestQuery: {
        fields: {
          getBroadcasterAvailableSlots: {
            merge(existing = [], incoming) {
              return incoming;
            },
          },
        },
      },
    },
  });
  const token = localStorage.getItem(AUTH_TOKEN);
  const navigate = useNavigate();

  const httpLink = createUploadLink({
    uri: URL,
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: WS_URL,
      reconnect: true,
      connectionParams: {
        Authorization: token ? `${token}` : "",
      },
    })
  );

  const logoutLink = onError(({ networkError }) => {
    if (
      networkError?.statusCode === 401 &&
      networkError?.result?.errors[0]?.message !==
        "Incorrect Email or Password" &&
      networkError?.result?.errors[0]?.message !== "Account not approved"
    ) {
      toast.error(networkError?.result?.errors[0]?.message, {
        hideProgressBar: true,
        duration: 1000,
        position: "top-center",
        style: { width: "100%" },
      });
      localStorage.removeItem('token');
      navigate("/");
    }
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `${token}` : "",
        "Apollo-Require-Preflight": "true",
      },
    };
  });
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      // console.log({query, definition })
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );
  const client = new ApolloClient({
    link: ApolloLink.from([authLink, logoutLink, splitLink]),
    fetchOptions: {
      mode: "no-cors",
    },
    cache
  });

  return (
    <ContextFile>
      <ApolloProvider client={client}>
        <div className="App">
          <ErrorBoundary>
            <ScrollToTop />
            <ToastContainer style={{ zIndex: 999999999999 }}/>
            <Outlet />
          </ErrorBoundary>
        </div>
      </ApolloProvider>
    </ContextFile>
  );
}

export default App;
