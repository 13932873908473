import React, { useState } from "react";
import "../AdminTransactionTable/admintransaction.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import Label from "../Label/Label";
import { MdClose } from "react-icons/md";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { ADMIN_UPDATE_TRANSACTION_STATUS } from "../GraphQL/Mutation";
import { toast } from "react-toastify";

const AdminActionButton = ({
  label,
  tab,
  radioStation,
  amount,
  withdrawalMethod,
  date,
  advertiser,
  id,
  transactionId,
  paymentMethod,
  description,
  refetch
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [approveLoading, setApproveLoading] = useState(false);
  const [pendingLoading, setPendingLoading] = useState(false);

  const [UpdateTransactionStatus, { loading }] = useMutation(
    ADMIN_UPDATE_TRANSACTION_STATUS
  );

  const handleApproveTransactionUpdate = () => {
    setApproveLoading(true);
    UpdateTransactionStatus({
      variables: {
        transactionId: id.toString(),
        status: "Approved",
      },
      onCompleted: (data) => {
        refetch();
        toast.success("Updating user status successful");
        setApproveLoading(false);
        
      },
      onError(err) {
        toast.error("Updating user status Failed");
        setApproveLoading(false);
      },
    });
    onClose();
  };

  const handlePendingTransactionUpdate = () => {
    setPendingLoading(true);
    UpdateTransactionStatus({
      variables: {
        transactionId: id.toString(),
        status: "Pending",
      },
      onCompleted: (data) => {
        refetch();
        toast.success("Updating user status successful");
        setPendingLoading(false);
      },

      onError(err) {
        toast.error("Updating user status Failed");
        setPendingLoading(false);
      },
    });
    onClose();
  };

  return (
    <div>
      <Button variant="link" onClick={onOpen}>
        {tab === "Radio Vendor" && <Label label={label} />}

        {tab === "Advertiser" && (
          <p style={{ color: "#F99B28" }}>{transactionId}</p>
        )}
      </Button>

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent padding="20px">
          <div className="popover_wrapper">
            <ModalHeader>
              <div className="popover_head">
                {tab === "Radio Vendor" && <div>Withdrawal Details</div>}
                {tab === "Advertiser" && <div>Payment Details</div>}
                <div onClick={onClose}>
                  <MdClose className="popover_closebtn" />
                </div>
              </div>
            </ModalHeader>

            <ModalBody>
              {tab === "Radio Vendor" && (
                <div className="popover_content">
                  <div className="sub_content">
                    <div>
                      <p>Radio Station:</p>
                    </div>
                    <div className="popover_data">
                      <p className="popover_data">{radioStation || "----"}</p>
                    </div>
                  </div>

                  <div className="sub_content">
                    <div>
                      <p>Amount:</p>
                    </div>
                    <div>
                      <p className="popover_data">{amount || "----"}</p>
                    </div>
                  </div>
                  <div className="sub_content">
                    <div>
                      <p>Withdrawal Method:</p>
                    </div>
                    <div>
                      <p className="popover_data">
                        {withdrawalMethod || "----"}
                      </p>
                    </div>
                  </div>
                  <div className="sub_content">
                    <div>
                      <p>Date:</p>
                    </div>
                    <div>
                      <p className="popover_data">
                        {moment(date).format("YYYY-MM-DD HH:mm:ss") || "----"}
                      </p>
                    </div>
                  </div>
                  <div className="sub_content">
                    <div>
                      <p>Status:</p>
                    </div>
                    <div>
                      <Label label={label || "unknown"} />
                    </div>
                  </div>
                </div>
              )}

              {tab === "Advertiser" && (
                <div className="popover_content">
                  <div className="sub_content">
                    <div>
                      <p>Advertiser:</p>
                    </div>
                    <div>
                      <p className="popover_data">{advertiser || "----"}</p>
                    </div>
                  </div>

                  <div className="sub_content">
                    <div>
                      <p>Transaction ID:</p>
                    </div>
                    <div>
                      <p className="popover_data">{transactionId || "----"}</p>
                    </div>
                  </div>
                  <div className="sub_content">
                    <div>
                      <p>Amount:</p>
                    </div>
                    <div>
                      <p className="popover_data">{amount || "----"}</p>
                    </div>
                  </div>
                  <div className="sub_content">
                    <div>
                      <p>Date:</p>
                    </div>
                    <div>
                      <p className="popover_data">
                        {moment(date).format("YYYY-MM-DD HH:mm:ss") || "----"}
                      </p>
                    </div>
                  </div>
                  <div className="sub_content">
                    <div>
                      <p>Payment Method:</p>
                    </div>
                    <div>
                      <p className="popover_data">{paymentMethod || "----"}</p>
                    </div>
                  </div>
                  <div className="sub_content">
                    <div>
                      <p>Description:</p>
                    </div>
                    <div>
                      <p className="popover_data">{description || "----"}</p>
                    </div>
                  </div>
                </div>
              )}
            </ModalBody>

            {(label === "pending" || label === "Pending") &&
              tab === "Radio Vendor" && (
                <ModalFooter>
                  <div className="popup_footer">
                    <div
                      onClick={handlePendingTransactionUpdate}
                      style={{ color: "#000" }}
                      className="cancel"
                    >
                      {pendingLoading ? (
                        <i class="fa fa-circle-o-notch fa-spin"></i>
                      ) : (
                        " No, Cancel"
                      )}
                    </div>
                    <div
                      onClick={handleApproveTransactionUpdate}
                      className="approve"
                    >
                      {approveLoading === "Approved" ? (
                        <i class="fa fa-circle-o-notch fa-spin"></i>
                      ) : (
                        "Yes Approve"
                      )}
                    </div>
                  </div>
                </ModalFooter>
              )}
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AdminActionButton;
