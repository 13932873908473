import React from "react";

const StatusIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 13C12.8282 13 14.2427 13 15.121 12.121C16 11.2427 16 9.82825 16 7C16 4.17175 16 2.75725 15.121 1.879C14.2427 1 12.8282 1 10 1H7C4.17175 1 2.75725 1 1.879 1.879C1 2.75725 1 4.17175 1 7C1 9.82825 1 11.2427 1.879 12.121C2.36875 12.6115 3.025 12.8283 4 12.9235"
        stroke="#3B3950"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.25 7L7.9375 8.5L10.75 5.5"
        stroke="#3B3950"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99994 13C9.07294 13 8.05144 13.375 7.11919 13.8588C5.62069 14.6365 4.87144 15.0258 4.50244 14.7775C4.13344 14.53 4.20319 13.7612 4.34344 12.2245L4.37494 11.875"
        stroke="#3B3950"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default StatusIcon;
