import React, { useState, useEffect } from "react";
import "./whyTransmit.css";
import topRight from "../../transmitAssets/top-right.svg";
import bottomRIght from "../../transmitAssets/left-bottom.svg";
import topLeft from "../../transmitAssets/top-left.svg";
import Tilt from "react-parallax-tilt";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import Aos from "aos";
import "aos/dist/aos.css";

const WhyTransmit = () => {
  const [openOption, setOpenOption] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({
      duration: 2000,
      offset: 0,
    });
  }, []);
  useEffect(() => {
    // track user event
    // if start a campaign button is clicked
    openOption === true && mixpanel.track("start_campaign_home");
  }, openOption);

  const routeHandler = (route) => {
    // register user event
    switch (route) {
      case "/book-campaign/":
        mixpanel.track("do_it_myself");
        break;

      case "/talk-with-an-expert":
        mixpanel.track("help_me_do_it");
        break;

      default:
        break;
    }

    navigate(route);
  };
  const ListItems = [
    {
      title: "asy access",
      first: "E",
      descFirst: "O",
      description:
        "ur Platform Allows Business Owners Of All Sizes, Regardless Of Budget, To Advertise Remotely On Their Preferred Radio Stations. From The Comfort Of Their Homes/Offices, Advertisers Can Easily Run Radio Campaigns Across Multiple Radio Stations In Africa.",
    },
    {
      title: "lick",
      first: "C",
      descFirst: "E",
      description:
        "asily select radio stations that fit your campaign goals at a go. We optimize your plan automatically, ensuring you meet audience targeting and budget goals, maximizing campaign results.",
    },
    {
      title: "xecute",
      first: "E",
      descFirst: "N",
      description:
        "o More Back-And-Forth With Radio Stations, Your Inventory Is Guaranteed Once Booked. Find Out What's Available In Real Time. By Using Our Algorithm, Your Plan Is Automatically Optimized, Ensuring You Meet Audience Targeting And Budget Goals.",
    },
    {
      title: "etailed reporting",
      first: "D",
      descFirst: "N",
      description:
        "o more back-and-forth with radio stations, your inventory is guaranteed once booked. Find out what's available in real time. By using our algorithm, your plan is automatically optimized, ensuring you meet audience targeting and budget goals.",
    },
  ];

  return (
    <div className="whyTransmit-wrapper" data-aos="fade-up">
      <div className="transmit-first-sec-wrap">
        <div className="transmit-first-sec">
          <div className="whyTransmit-text" data-aos="fade-right">
            <p>
              Why <br />
              <span className="lower-case">transmit?</span>
            </p>
          </div>
          <article>
            <div
              className="whyTrans-btn"
              data-aos="fade-right"
              onClick={() => setOpenOption(!openOption)}
            >
              <p>Start campaign</p>
            </div>
          </article>
        </div>
        <div className="transmit-option">
          {openOption && (
            <div className="transmit-option-btn">
              <button
                onClick={() => routeHandler("/book-campaign/")}
                className="why-option-btn"
              >
                Yes, I can do it myself.
              </button>
              <button
                onClick={() => routeHandler("/talk-with-an-expert")}
                className="why-option-btn"
              >
                No, Help me with it
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="card_wrapper_sec">
        {ListItems.map(({ title, first, description, descFirst }, index) => (
          <Tilt tiltReverse={true} scale={1} key={index}>
            <div className="whyTransmit_card_wrapper" data-aos="flip-right">
              <img src={topRight} alt="top right" className="topRight-spiral" />
              <img
                src={topLeft}
                alt="top left"
                className="topLeft-spiral"
                width="100px"
                height="100px"
              />
              <div className="easy_wrapper">
                <div className="easy_access">
                  <p>
                    {first}
                    <span>{title}</span>
                  </p>
                </div>
                <div className="easy_content">
                  <p>
                    <span>{descFirst}</span>
                    <span className="lower-case">{description}</span>
                  </p>
                </div>
              </div>
              <img
                src={bottomRIght}
                alt="bottom right"
                className="bottomRight-spiral"
              />
            </div>
          </Tilt>
        ))}
      </div>
    </div>
  );
};

export default WhyTransmit;
