import {
  ChakraProvider,
  Box,
  useColorModeValue,
  extendTheme,
  useDisclosure,
} from "@chakra-ui/react";
import SidebarContent from "./SidebarContent";
import MobileNav from "./MobileNav";
import { Drawer, DrawerContent } from "@chakra-ui/react";
import Logout from "../../components/Modal/Logout/Logout";

const breakpoints = {
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

const theme = extendTheme({ breakpoints });

const Layout = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isLogout,
    onOpen: openLogout,
    onClose: closeLogout,
  } = useDisclosure();

  return (
    <ChakraProvider theme={theme}>
      {isLogout && <Logout isOpen={openLogout} onClose={closeLogout} />}
      <Box
        minH="100vh"
        bg={useColorModeValue("white", "gray.900")}
        pos="relative"
        fontFamily="Telegraf"
      >
        <SidebarContent
          onClose={onClose}
          display={{ base: "none", md: "block" }}
          openLogout={openLogout}
        />
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        <MobileNav onOpen={onOpen} />
        <Box
          ml={{ base: 0, md: "100px" }}
          p="4"
          bg={"white"}
          minH={"calc(100vh - 80px)"}
        >
          {children}
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default Layout;
