import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const ForeCastAnalytics = () => {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: 'Impression',
        data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5],
        color: '#FC9732' // Starting color for the line
      }
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },

      forecastDataPoints: {
        count: 7,
      },

      stroke: {
        width: 3,
        curve: "smooth",
        colors: ["#FC9732"],
      },
      xaxis: {
        type: "datetime",
        categories: [
          "1/11/2000",
          "2/11/2000",
          "3/11/2000",
          "4/11/2000",
          "5/11/2000",
          "6/11/2000",
          "7/11/2000",
          "8/11/2000",
          "9/11/2000",
          "10/11/2000",
          "11/11/2000",
          "12/11/2000",
          "1/11/2001",
          "2/11/2001",
          "3/11/2001",
          "4/11/2001",
          "5/11/2001",
          "6/11/2001",
        ],
        tickAmount: 10,
        labels: {
          formatter: function (value, timestamp, opts) {
            return opts.dateFormatter(new Date(timestamp), "dd MMM");
          },
        },
        grid: {
        borderColor: '#666',
        strokeDashArray: [3, 3], 
        xaxis: {
          lines: {
            show: true 
          }
        },
        yaxis: {
          lines: {
            show: true 
          }
        },
      },

        // title: {
        //   text: "Date",
        //   style: {
        //     fontSize: "14px",
        //     fontWeight: 600,
        //   },
        // },
      },
      yaxis: {
        min: -10,
        max: 40,
       tickAmount: 10,
        title: {
          text: "Impression",
          style: {
            fontSize: "14px",
            fontWeight: 600,
          },
        },
      },
      // title: {
      //   text: "Forecast",
      //   align: "left",
      //   style: {
      //     fontSize: "16px",
      //     color: "#666",
      //   },
      // },
       fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          gradientToColors: ['#005E59'],
          shadeIntensity: 1,
          type: 'horizontal',
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        },
      },
      legend: {
        show: true,
      },
    },
  });

  return (
    <div id="chart" style={{paddingTop:"1rem"}}>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={500}
      />
    </div>
  );
};

export default ForeCastAnalytics;
