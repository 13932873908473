import React from "react";
import LogoText from "../../transmitAssets/logo-and-text.svg";
import Amico from "../../transmitAssets/amico.svg";
import "./advertiserSignup-wrapper.css";

const AdvertiserSignupWrapper = ({ children }) => {
  return (
    <div className="overlay-body-advert">
      <div className="signup-modal-advert">
        <div className="modal-first-sec-advert">
          <div className="logo-wrapper-advert">
            <img src={LogoText} alt="LogoText" className="logoText-advert" />
          </div>

          <div className="brand-text-advert">
            <p>
              B
              <span className="lower-text-advert">
                rand your
                <br /> voice
              </span>
            </p>
          </div>

          <div className="logo-wrapper-amico-advert">
            <img src={Amico} alt="Amico" className="amico-advert" />
          </div>
        </div>

        {children}
      </div>
    </div>
  );
};

export default AdvertiserSignupWrapper;
