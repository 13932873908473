import React, { useMemo } from "react";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { ADMIN_GET_ALL_ADVERTISER_CAMPAIGNS_BY_ID } from "../components/GraphQL/Queries";
import { useQuery } from "@apollo/client";
import "./css/admin_advertiser_profile.css";
import { useNavigate } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import { useParams } from "react-router-dom";
import AdminTableCheckbox from "../components/AdminTableCheckbox/AdminTableCheckbox";
import { Center, ChakraProvider } from "@chakra-ui/react";
import { TableSpinner } from "../components/Spinner";

const AdminAdvertiserInvoice = () => {
  const { id } = useParams();
  const { data, loading, refetch } = useQuery(
    ADMIN_GET_ALL_ADVERTISER_CAMPAIGNS_BY_ID,
    {
      variables: { userId: id },
    }
  );
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const header = [
    {
      key: "Invoice No.",
      title: "Invoice No.",
    },
    {
      key: "Campaign Name",
      title: "Campaign Name",
    },
    {
      key: "Company Name",
      title: "Company Name",
    },
    {
      key: "Industry",
      title: "Industry",
    },
    {
      key: "Date",
      title: "Date",
    },
    {
      key: "Radio Station",
      title: "Radio Station",
    },
  ];

  const tableAdminData = useMemo(() => {
    return data?.admin?.getAllAdvertiserCampaignsById.map((columnData) => {
      return {
        id: columnData?.id,
        invoice: columnData?.invoice,
        campaignName: columnData?.campaignName,
        companyName: columnData?.companyName,
        industry: columnData?.industryName || "--",
        date: columnData?.date,
        radioStation: "published",
        rate: "+ 2",
      };
    });
  }, [data]);

  const handleClick = (id) => {
    // console.log("ID---", id);
  };
  return (
    <AdminDashboardLayout>
      {loading ? (
        <ChakraProvider>
          <Center h="100vh">
            <TableSpinner />
          </Center>
        </ChakraProvider>
      ) : (
        <>
          <div className="Home_Dashboard_wrap">
            <div className="profile-nav">
              <div className="profile-back" onClick={handleGoBack}>
                <span>
                  <MdArrowBackIos />
                </span>
                <span>Back</span>
              </div>
            </div>
            <div className="profile_tab_wrapper">
              <AdminTableCheckbox
                header={header}
                data={tableAdminData}
                loading={false}
                showEdit={true}
                handleClick={handleClick}
                refetch={refetch}
              />
            </div>
          </div>
        </>
      )}
    </AdminDashboardLayout>
  );
};

export default AdminAdvertiserInvoice;
