import { useMutation, useQuery } from "@apollo/client";
import React, { useRef } from "react";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { GET_CAMPAIGN_DETAILS } from "../../../../components/GraphQL/Queries";
import "../../../../bookCampaign/components/css/station-details.css";
import { MdDownload } from "react-icons/md";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  useBreakpointValue,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { EXPORT_ADMIN_CAMPAIGN } from "../../../../components/GraphQL/Mutation";

const BookedSlots = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const size = useBreakpointValue({ base: "sm", lg: "md" });
  const placement = useBreakpointValue({ base: "bottom", lg: "right" });

  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const {
    data: {
      broadcaster: {
        getCampaignDetails: {
          startDate = "",
          endDate = "",
          bookedSlots: {
            bookingDetails: {
              totalNoOfDays = 0,
              totalNoOfSpots = 0,
              totalNoOfSlots = 0,
            } = {},
            slots = [],
          } = {},
        } = {},
      } = {},
    } = {},
  } = useQuery(GET_CAMPAIGN_DETAILS, {
    variables: {
      campaignId: id,
    },
  });
  const [slotDates, setSlotDates] = useState({});
  const [currentDateSlots, setCurrentDateSlots] = useState([]);

  const [exportCampaign, { loading }] = useMutation(EXPORT_ADMIN_CAMPAIGN);

  const handleExport = () => {
    exportCampaign({
      variables: {
        campaignId: id,
      },
      onCompleted: (data) => {
        const exportLink = data?.broadcaster?.exportCampaign;

        if (exportLink) {
          window.location.href = exportLink;
        }
        toast({
          title: "Booking Exported Successfully",
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
      onError: ({ networkError }) => {
        toast({
          title: "Export Error",
          description: networkError.result.errors[0].message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    });
  };
  // console.log(currentDateSlots[0].date, "currentDateSlots");
  // console.log(slotDates, "slotDates");
  useEffect(() => {
    let tempSlotDates = {};
    slots &&
      [...new Set(slots.map((slot) => slot.date))].forEach((date) => {
        tempSlotDates[`${getDateFormat(date)}`] = [];
      });

    slots.forEach((slot) => {
      const currentDate = getDateFormat(slot.date);
      if (tempSlotDates[`${currentDate}`]) {
        let currDateArr = tempSlotDates[`${currentDate}`];
        tempSlotDates[`${currentDate}`] = currDateArr.concat(slot);
      }
    });
    setSlotDates({ ...tempSlotDates });
    setCurrentDateSlots(
      startDate && [...tempSlotDates[`${getDateFormat(startDate)}`]]
    );
  }, [slots, startDate]);

  const getTimeFormat = (time) => {
    return moment(new Date(time)).format("LT");
  };
  const getDateFormat = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  const renderDayContents = (day, date) => {
    const formattedDate = moment(date).format("DD/MM/YYYY"); // Format the date in DD/MM/YYYY format

    // Check if the formatted date is in slotData
    const isSpecial =
      slotDates[formattedDate] && slotDates[formattedDate].length > 0;

    return (
      <div className="day-container">
        {isSpecial ? (
          <div className="special-marker slot-available">
            <div
              className="calender_date_wrapper"
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                margin: "auto",
              }}
            >
              <div className="calender_day">
                <p>{day}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="special-marker-color">
            <div
              className="calender_date_wrapper-gray"
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                margin: "auto",
              }}
            >
              <div className="calender_day-gray">
                <p>{day}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const dayClassName = (date) => {
    const formattedDate = getDateFormat(date);
    return slotDates[formattedDate] && slotDates[formattedDate].length > 0
      ? ""
      : "unclickable-day";
  };
  const TimeSlot = ({ startTime, endTime, quantity }) => (
    <TableRow>
      <TableCell>{`${getTimeFormat(startTime && startTime)}  - ${getTimeFormat(
        endTime && endTime
      )}`}</TableCell>
      <TableCell>{quantity}</TableCell>
    </TableRow>
  );
  return (
    <div className="container">
      <div className="booking-hero">
        <div className="campaign-booking-hero">
          <h3>Campaign Bookings</h3>
        </div>

        <button
          className="campaign-download-booking"
          disabled={loading}
          onClick={handleExport}
        >
          <span>
            <MdDownload />
          </span>
          {loading ? <Spinner color="red.500" /> : <span>Export Booking </span>}
        </button>
      </div>

      <div mt="24px" mb="60px" style={{ width: "100%" }}>
        <DatePicker
          selected={startDate && new Date(startDate)}
          onChange={(date) => {
            onOpen();
            const currDate = getDateFormat(new Date(date));
            if (slotDates[`${currDate}`]) {
              setCurrentDateSlots([...slotDates[`${currDate}`]]);
              return;
            }
            setCurrentDateSlots([]);
          }}
          renderDayContents={renderDayContents}
          startDate={startDate && new Date(startDate)}
          endDate={endDate && new Date(endDate)}
          calendarClassName="custom-calendar-station"
          selectsRange
          inline
          dayClassName={dayClassName}
          ref={btnRef}
        />
      </div>

      <Drawer
        isOpen={isOpen}
        placement={placement}
        onClose={onClose}
        size={size}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <div className="drawer-container">
              <div className="slot-hero">
                <span className="slot-text-hero">Selected Slot </span>
                <span className="slot-hero-date">
                  {moment(currentDateSlots[0]?.date).format("dddd: DD/MM/YYYY")}
                </span>
              </div>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableCell>Time</TableCell>
                    <TableCell>No of Slots</TableCell>
                  </TableRow>
                </TableHeader>
                <tbody>
                  {currentDateSlots &&
                    currentDateSlots
                      .slice(0, 12)
                      .map((slot, index) => <TimeSlot key={index} {...slot} />)}
                </tbody>
              </Table>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default BookedSlots;

const Header = styled.h2`
  font-size: 20px;
  margin-bottom: 10px;
`;

const SelectedDate = styled.div`
  background-color: #ffebee;
  color: #d32f2f;
  padding: 5px 10px;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
`;

const TableHeader = styled.thead`
  border-bottom: 1px solid #e0e0e0;
`;

const TableRow = styled.tr`
  &:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
  }
`;

const TableCell = styled.td`
  padding: 15px;
  text-align: left;
`;
