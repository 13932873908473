import React from "react";
import "./talktoexpert.css";
import TalkExpert from "../../transmitAssets/talktoExpert.svg";
import Tilt from "react-parallax-tilt";
import { useNavigate } from "react-router-dom";

const TalkToExpert = () => {
  const navigate = useNavigate();
  return (
    <div className="talk_expert_wrapper">
      <div className="talk_expert_img">
        <Tilt tiltReverse={true} scale={1}>
          <img
            src={TalkExpert}
            alt="Talk To Expert"
            width="100%"
            className="talk_expert_self"
          />
        </Tilt>
      </div>
      <div className="talk_expert_text">
        <div className="talk_expert_wrapper_header">
          <p>
            <span className="talk_confuse">Confused? </span>
            <span>
              We <span className="lower_text">can</span> Help
            </span>
          </p>
        </div>
        <div className="talk_content">
          <p>
            G<span className="lower-text">et in</span> T
            <span className="lower-text">
              ouch with our expert media planners to
            </span>
            <br />
            <span className="lower_text">plan and place your ad</span>
          </p>
        </div>
        <div className="expert_touch"
        onClick={() => navigate("/talk-with-an-expert")}
        >
          <p>Get in Touch</p>
        </div>
      </div>
    </div>
  );
};

export default TalkToExpert;
