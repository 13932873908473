import React from "react";
import LogoText from "../../transmitAssets/logo-and-text.svg";
import Amico from "../../transmitAssets/amico.svg";
import loginSvg from "../../transmitAssets/login.svg";
import "./signwrap.css";

const SignupWrapper = ({ children, authType }) => {
  return (
    <div className="overlay-body">
      <div className="signup-modal">
        <div className="modal-first-sec">
          <div className="logo-wrapper">
            <img src={LogoText} alt="LogoText" className="logoText" />
          </div>

          {authType && (
            <div className="brand-text">
              <p>
                B
                <span className="lower-text">
                  rand your
                  <br /> voice
                </span>
              </p>
            </div>
          )}

          <div className="logo-wrapper-amico">
            <img
              src={authType === true ? Amico : loginSvg}
              alt="Amico"
              className="amico"
            />
          </div>
        </div>

        {children}
      </div>
    </div>
  );
};

export default SignupWrapper;
