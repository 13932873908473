import React, { useState, useMemo, useEffect } from "react";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { GET_ALL_ADMIN_ADVERTISER } from "../components/GraphQL/Queries";
import { useQuery } from "@apollo/client";
import { Box, Center, ChakraProvider } from "@chakra-ui/react";
import { TableSpinner } from "../components/Spinner";
import "./css/admin_advertiser.css";
import AdminTab from "../components/AdminTab/AdminTab";
import AdminTable from "../components/AdminTable/AdminTable";
import { useNavigate } from "react-router-dom";

const AdminAdvertiser = () => {
  const [selectedTab, setSelectedTab] = useState("All");
  const [TableData, setTableData] = useState(null);
  const navigate = useNavigate();

  const { data, loading, refetch } = useQuery(GET_ALL_ADMIN_ADVERTISER);

  const tableHeader = [
    {
      title: "RADIO STATION",
    },
    {
      title: "EMAIL",
    },
    {
      title: "PHONE",
    },
    {
      title: "DATE",
    },
  ];

  //   function to handleRow
  const handleRow = () => {};

  const tableAdminData = useMemo(() => {
    return data?.admin?.getAllAdvertisers.map((columnData) => {
      return {
        id: columnData?.id,
        station: columnData?.advertiser,
        email: columnData?.email,
        phone: columnData?.phone,
        date: columnData?.date,
        published: "published",
        state: "Active",
      };
    });
  }, [data]);

  const ActiveTableData = useMemo(() => {
    let activeData;
    if (tableAdminData) {
      activeData = tableAdminData?.filter((row) => row?.state === "Active");
      return activeData;
    }
  }, [tableAdminData]);

  const InactiveTableData = useMemo(() => {
    let inactiveData;
    if (tableAdminData) {
      inactiveData = tableAdminData?.filter((row) => row?.state === "Inactive");
      return inactiveData;
    }
  }, [tableAdminData]);

  const activeTableAdminDataLength = useMemo(() => {
    let activeRows;
    if (tableAdminData) {
      activeRows = tableAdminData?.filter((row) => row?.state === "Active");
      return activeRows.length;
    }
    return (activeRows = 0);
  }, [tableAdminData]);

  const inactiveTableAdminDataLength = useMemo(() => {
    let activeRows;
    if (tableAdminData) {
      activeRows = tableAdminData?.filter((row) => row?.state === "Inactive");
      return activeRows.length;
    }
    return (activeRows = 0);
  }, [tableAdminData]);

  //TABS LIST
  let tabs = [
    {
      key: "All",
      value: "All",
      total: tableAdminData?.length || 0,
    },
    {
      key: "Active",
      value: "Active",
      total: activeTableAdminDataLength || 0,
    },
    {
      key: "Inactive",
      value: "Inactive",
      total: inactiveTableAdminDataLength || 0,
    },
  ];

  useEffect(() => {
    if (selectedTab === "Active") {
      setTableData(ActiveTableData);
    } else if (selectedTab === "Inactive") {
      setTableData(InactiveTableData);
    } else {
      setTableData(tableAdminData);
    }
  }, [selectedTab, tableAdminData, ActiveTableData, InactiveTableData]);

  return (
    <AdminDashboardLayout>
      {loading ? (
        <ChakraProvider>
          <Center h="100vh">
            <TableSpinner />
          </Center>
        </ChakraProvider>
      ) : (
        <Box h="100%" bg="#fff">
          {/* TAB SECTION */}

          <AdminTab
            tabs={tabs}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
            tableAdminData={tableAdminData}
          />

          {/* Table section */}
          {/* <div>
                <div className="panelTab_wrapper"> */}
          <AdminTable
            header={tableHeader}
            data={TableData || []}
            loading={loading}
            refetch={refetch}
            showFilter={true}
            selectedTab={selectedTab}
            handleRow={handleRow}
            handleNavigate={(id) => navigate(`/admin/advertiser/profile/${id}`)}
          />
          {/* </div>
              </div> */}
        </Box>
      )}
    </AdminDashboardLayout>
  );
};

export default AdminAdvertiser;
