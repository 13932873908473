import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ErrorPage() {
  const navigate = useNavigate();

  useEffect(() => {
    // After 5 seconds, navigate the user back to the homepage
    const timeout = setTimeout(() => {
      navigate("/");
    }, 5000);

    // Cleanup the timeout when the component unmounts
    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <h2>Something went wrong.</h2>
      <p>Oops! An error occurred. Please try again later.</p>
      <p>You will be redirected to the homepage in a few seconds...</p>
    </div>
  );
}

export default ErrorPage;
