import React, { useState } from "react";
import "./css/page-one.css";
// import BudgetFilter from "../components/BudgetFilter.js";
import RecommendedStation from "../components/RecommendedStation/RecommendedStation";
import Newsletter from "../../components/Newsletter.js";
import { MyContext } from "../../pages/BookCampaign";
import SearchRadioStation from "../components/SearchRadioStation";
import Footer from "../../components/Footer/index.jsx";
import TalkToExpert from "../../components/TalkToExpert/index.jsx";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import SearchField from "../components/SearchField.jsx";
import CurrencyPopover from "../components/CurrencyPopover.jsx";
import { GET_SUPPORTED_CURRENCIES } from "../../components/GraphQL/Queries.js";
import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useDebounce } from "../../utils/functions.js";

const PageOne = () => {
  const { data, loading } = useQuery(GET_SUPPORTED_CURRENCIES);
  const { countrystateinfo, search, getSearchStatus, getLocation } =
    React.useContext(MyContext);
    const [broadcasterData, setBroadcasterData] = useState([]);
  const [filterLocation, setFilterLocation] = useState("");
  const [countrystatename, setCountryStateName] = useState({
    countryName: "",
    stateName: "",
  });
  const [budgetPrice, setBudgetPrice] = useState();
  // const getSearch = (data) => {
  //   setFilterLocation(data);
  // };
  // const priceFilterRange = (data) => {
  //   setBudgetPrice(data);
  // };
  const [checkBox, setCheckBox] = useState([]);
  const [countryName, setCountryName] = useState("");
  // State to store selected currency
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [isSearching, setIsSearching] = useState(false);
  const [searchVal, setSearchVal] = useState("");

  const debouncedSetSearchVal = useDebounce((searchTerm) => {
    setSearchVal(searchTerm);
  }, 2000);

  const HandleSearch = (searchTerm) => {
    // console.log("Search Term:", searchTerm);
    debouncedSetSearchVal(searchTerm);
  };

  useEffect(() => {
    if (countryName !== "") {
      return setSelectedCurrency(
        data?.guest?.getSupportedCurrencies?.find(
          (item) => item.country === countryName
        )
      );
    } else {
      return setSelectedCurrency({
        currency: "USD",
        country: "United States",
      });
    }
  }, [countryName]);

  // console.log("countrystatename jere", getLocation)  const [startDate, setStartDate] = useState(null);

  return (
    <div id="book-campaign">
                    {/* <DatePicker
      selected={startDate}
      onChange={(date) => setStartDate(date)}
    //   minDate={subDays(new Date(), 5)}
      placeholderText="Start Date"
    /> */}
      <main>
        <SearchRadioStation
          stationLocation={getLocation}
          status={getSearchStatus}
          setCountryStateNames={setCountryStateName}
          setCountryName={setCountryName}
          setIsSearching={setIsSearching}
          setSelectedCurrency={setSelectedCurrency}
          setBroadcasterData={setBroadcasterData}
          
        />
        <section id="select-station-sect">
          <div className="book-campaigns-wrapper">
            <div>
              <h2>Radio Station</h2>
            </div>
            <div className="search-me">
              <SearchField
                placeholder="Search for Radio Station by name..."
                onSearch={HandleSearch}
              />
            </div>
            <div>
              <CurrencyPopover
                loading={loading}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                currencies={data?.guest?.getSupportedCurrencies}
                showCurrency={true}
                status={getSearchStatus}
              />
            </div>
          </div>
          <div className="book-campaigns-wrapper-mobile">
            <div className="search-me-mobile">
              <SearchField
                placeholder="Search for Radio Station by name..."
                onSearch={HandleSearch}
              />
            </div>
          </div>
          <div id="station-sects">
            <div>
              {/* <BudgetFilter
              searchState={getSearch}
              priceFilter={priceFilterRange}
            /> */}
              <RecommendedStation
                countrystateobj={countrystateinfo}
                searchState={search}
                location={filterLocation}
                filterByPriceRange={budgetPrice}
                countrystatename={countrystatename}
                checkBox={checkBox}
                setCheckBox={setCheckBox}
                currency={selectedCurrency?.currency}
                isSearching={isSearching}
                search={searchVal}
                broadcasterData={broadcasterData}
                setBroadcasterData={setBroadcasterData}
              />
            </div>
          </div>
        </section>
      </main>
      <footer id={checkBox.length !== 0 ? "footer_sx" : undefined}>
        <div className="newsletter-wrapper">
          <Newsletter />
          <TalkToExpert />
        </div>
        {/* <MainFooterSect /> */}
        <Footer />
      </footer>
    </div>
  );
};

export default PageOne;
