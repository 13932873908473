import { Link, useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import transmitLogo from "../../transmitAssets/logo_black.png";

export const VendorRegistrationLayout = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <section
      id="signup-vendor"
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
        width: "100vw",
        textAlign: "center",
        paddingTop: "1rem",
      }}
    >
      <header>
        <nav>
          <Link to="/">
            <div style={{paddingRight:"20px"}}>
              <img
                src={transmitLogo}
                alt="transmitLogo"
                width="20%"
                className="image-logo"
              />
            </div>
          </Link>
          <h1>
            {pathname.includes("Broadcaster") || pathname.includes("vendor")
              ? "radio station registration"
              : "advertiser registration"}
          </h1>
        </nav>
      </header>
      <Container>{children}</Container>
    </section>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 650px;
  background: var(--primary-white-100);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 35px 45px;
  box-shadow: 0px 15.9578px 23.9367px rgba(59, 77, 92, 0.1);
  border-radius: 2px;
`;
