import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ADVERTISER_CAMPAIGN_DETAILS } from "../../../../../../components/GraphQL/Queries";
import "../../../css/station-booking.css";
import { TableSpinner } from "../../../../../../components/Spinner";
import DownloadDocument from "../../../../../../components/BookingDetails/DownloadDocument/DownloadDocument";
import { StationButtons } from "../../../../../../components/BookingDetails/StationButtons/StationButtons";
import BookingInfo from "../../../../../../components/BookingDetails/BookingInfoDetails/BookingInfo";

const AdertiserBookingDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, loading } = useQuery(ADVERTISER_CAMPAIGN_DETAILS, {
    variables: { campaignId: id },
  });

  const [campaignDetails, setCampaignDetails] = useState(
    data?.advertiser.getCampaignDetails.radioStationDetails[0]
  );
  const [activeButton, setActiveButton] = useState(
    data?.advertiser.getCampaignDetails.radioStationDetails[0]?.id
  );

  const singleCampaignHandler = (index) => {
    setActiveButton(index);
    const singleCampaign =
      data?.advertiser.getCampaignDetails.radioStationDetails.filter(
        (campaign) => {
          return campaign.id === index;
        }
      );
    setCampaignDetails(singleCampaign[0]);
  };
  const viewBookedSlotHandler = () => {
    navigate(
      `/dashboard/station/booked-slots/${data?.advertiser.getCampaignDetails.id}`
    );
  };
  // console.log(campaignDetails, "campaign details...");
  return (
    <>
      <div className="advertiser-booking-container">
        {loading ? (
          <TableSpinner />
        ) : (
          <>
            <DownloadDocument
              campaignDetails={data?.advertiser.getCampaignDetails}
            />
            <StationButtons
              radioStationDetails={
                data?.advertiser?.getCampaignDetails?.radioStationDetails
              }
              activeButton={activeButton}
              onButtonClick={singleCampaignHandler}
            />
            <BookingInfo
              campaignDetails={data?.advertiser?.getCampaignDetails?.radioStationDetails[0]}
              campaignId={data?.advertiser.getCampaignDetails.id}
              viewBookedSlotHandler={viewBookedSlotHandler}
              viewBookedSlotButton
            />
          </>
        )}
      </div>
    </>
  );
};

export default AdertiserBookingDetails;
