import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Box,
  VStack,
  Button,
  useToast,
  HStack,
  Link,
} from "@chakra-ui/react";
import styled from "styled-components";
import { GET_CAMPAIGN_DETAILS } from "../../../../components/GraphQL/Queries";

import { fmtCurrency } from "../../../../utils/functions";

import { UPDATE_CAMPAIGN_STATUS } from "../../../../components/GraphQL/Mutation";
import { TableSpinner } from "../../../../components/Spinner";
import {
  AdvertTypeIcon,
  ChevronDownIcon,
  ChevronRight,
  DaysIcon,
  DollarIcon,
  DownloadIcon,
  DurationIcon,
  EndDateIcon,
  LanguageIcon,
  OrderIcon,
  PaymentIcon,
  StartDateIcon,
  StatusIcon,
} from "../../../../components/icons";

const CampaignBookings = () => {
  const { id } = useParams();
  const {
    data: {
      broadcaster: {
        getCampaignDetails: {
          id: OrderId = "",
          status = "",
          startDate = "",
          endDate = "",
          advertType = "",
          duration,
          language,
          numberOfDays,
          numberOfSpots,
          totalAmount,
          links = "",
          campaignDocument = [],
        } = {},
      } = {},
    } = {},
  } = useQuery(GET_CAMPAIGN_DETAILS, {
    variables: {
      campaignId: id,
    },
  });

  const [update, { loading }] = useMutation(UPDATE_CAMPAIGN_STATUS, {
    refetchQueries: [
      { query: GET_CAMPAIGN_DETAILS, variables: { campaignId: id } }, // DocumentNode object parsed with gql
      "CampaignDocument", // Query name
    ],
  });
  const navigate = useNavigate();
  const toast = useToast();
  const statusColor =
    status === "Cancelled"
      ? "#FB2730"
      : status === "Approved"
      ? "#004643"
      : status === "Pending"
      ? "#8A5806"
      : "";
  const viewBookedSlotHandler = () => {
    navigate(`/dashboard/vendor/campaign-page/booked-slots/${OrderId}`);
  };
  const statusUpdateHandler = (status) => {
    update({
      variables: {
        campaignId: id,
        campaignStatus: status,
      },
      onCompleted: () => {
        toast({
          title: "Status Updated",
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
      onError: ({ networkError }) => {
        toast({
          title: "Status Update Error",
          description: networkError.result.errors[0].message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    });
  };

  return (
    <div className="advertiser-booking-container">
      <div>
        <h4>{`#${OrderId}`}</h4>
        <HStack alignItems={"center"} mt="18px">
          <DownloadDocumentContainer>
            <CampaignDocumentHeaderText>
              Campaign Document
            </CampaignDocumentHeaderText>
            {campaignDocument
              .map((campaign, i) => {
                let mutatedCampaign = { ...campaign };
                if (i === 0) {
                  mutatedCampaign["name"] = "Jingle Audio";
                }
                if (i === 1) {
                  mutatedCampaign["name"] = "Paid Announcement Script";
                }
                if (i === 2) {
                  mutatedCampaign["name"] = "APCON Certificate";
                }
                return mutatedCampaign;
              })
              .map(({ name = "", url = "" }) => {
                return (
                  <HStack mt="18px">
                    <DownloadContainer>
                      <BookingHeaderText>{name}</BookingHeaderText>
                    </DownloadContainer>
                    <Link href={url} target="_blank" isExternal>
                      <DownloadIcon />
                    </Link>
                  </HStack>
                );
              })}
          </DownloadDocumentContainer>
          <DownloadDocumentContainer>
            <CampaignDocumentHeaderText>Other Files</CampaignDocumentHeaderText>
            <HStack>
              <DownloadContainer>
                <BookingHeaderText>
                  <Link href={links} target="_blank" isExternal>
                    Files <ExternalLinkIcon mx="2px" />
                  </Link>
                </BookingHeaderText>
              </DownloadContainer>
            </HStack>
          </DownloadDocumentContainer>
        </HStack>
      </div>
      <StationDetailsCampaignWrapper>
        <StationDetailsCampaignHeader>
          <div>
            <OrderIcon />
            <BookingHeaderText>Order ID: {OrderId}</BookingHeaderText>
          </div>
          <div>
            <StatusIcon />
            Status: <BookingHeaderText> Status:</BookingHeaderText>
            <BookingHeaderText style={{ color: `${statusColor}` }}>
              {status}
            </BookingHeaderText>
            <Popover placement="bottom-end">
              <PopoverTrigger>
                <Box>
                  <ChevronDownIcon />
                </Box>
              </PopoverTrigger>
              <PopoverContent
                border="0.5px solid #868686"
                w="95px"
                h="116px"
                p="16px 16px"
              >
                {!loading ? (
                  <VStack spacing="8px">
                    <DropDownButton
                      status="Pending"
                      onClick={() => statusUpdateHandler("Pending")}
                    />
                    <DropDownButton
                      status="Approved"
                      onClick={() => statusUpdateHandler("Approved")}
                    />
                    <DropDownButton
                      status="Cancelled"
                      onClick={() => statusUpdateHandler("Cancelled")}
                    />
                  </VStack>
                ) : (
                  <TableSpinner />
                )}
              </PopoverContent>
            </Popover>
          </div>
          <div>
            <StartDateIcon />
            <BookingHeaderText>
              Start Date: {moment(new Date(startDate)).format("MMM Do YYYY")}
            </BookingHeaderText>
          </div>
          <div>
            <EndDateIcon />
            <BookingHeaderText>
              End Date: {moment(new Date(endDate)).format("MMM Do YYYY")}
            </BookingHeaderText>
          </div>

          <ViewSlotContainer onClick={viewBookedSlotHandler}>
            <ViewSlotText>View Booked Slots</ViewSlotText>
            <ChevronRight />
          </ViewSlotContainer>
        </StationDetailsCampaignHeader>
        <BookingDetailsContainer>
          <StationCardType>
            <AdvertTypeIcon />
            <span>Advert Type: {advertType}</span>
          </StationCardType>
          <StationCardType>
            <DurationIcon />
            <span>Duration: {duration}</span>
          </StationCardType>
          {/* <StationCardType>
                <SpotIcon />
                <span>Duration: {duration}</span>
              </StationCardType> */}

          <StationCardType>
            <LanguageIcon />
            <span>Language:{language}</span>
          </StationCardType>
          <StationCardType>
            <DaysIcon />
            <span>Number of Days: {numberOfDays}</span>
          </StationCardType>

          <StationCardType>
            <DollarIcon />
            <span>Number of Spot: {numberOfSpots}</span>
          </StationCardType>
          <StationCardType>
            <PaymentIcon />
            <span>Total amount: {fmtCurrency(totalAmount)}</span>
          </StationCardType>
        </BookingDetailsContainer>
      </StationDetailsCampaignWrapper>
    </div>
  );
};

export default CampaignBookings;

const DropDownButton = ({ status, onClick }) => {
  return (
    <Button p={0} m={0} h="100%" colorScheme="" onClick={onClick}>
      <BookingHeaderText>{status}</BookingHeaderText>
    </Button>
  );
};

const ViewSlotContainer = styled.div`
  width: 169px;
  height: 38px;
  border: 1px solid #f99b28;
  border-radius: 1.50209px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ViewSlotText = styled.p`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  color: #004643;
`;

const BookingHeaderText = styled.p`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #004643;
`;

const DownloadDocumentContainer = styled.div`
  width: 318px;
  min-height: 106px;
  border: 0.8px dashed #aba8a8;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  padding: 18px 18px 25px 18px;
  margin-bottom: 40px;
`;

const CampaignDocumentHeaderText = styled.h1`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #004643;
`;

const DownloadContainer = styled.div`
  width: 248px;
  height: 29px;
  background: #ffffff;
  border: 0.21px solid #3b3950;
  border-radius: 2px;
  padding: 0px 19px;
  display: flex;
  align-items: center;
`;

const BookingDetailsContainer = styled.div`
  padding: 40px 0px;
  padding-left: 30px;

  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  @media (min-width: 768px) {
    padding: 40px 60px;
    gap: 30px;
  }
`;
const StationCardType = styled.div`
  padding: 10px 0px;
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border: 1px solid #004643;
  border-radius: 2px;
  white-space: nowrap;
  & span {
    font-size: 14px;
    line-height: 20px;
  }
`;

const StationDetailsCampaignHeader = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 20px;
  padding: 30px;
  border-bottom: 0.8px solid #abd1c6;
  div {
    display: flex;
    align-items: center;
    gap: 6px;
    white-space: nowrap;
  }

  @media (min-width: 768px) {
    padding-left: 65px;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
  }
`;
const StationDetailsCampaignWrapper = styled.div`
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
`;
