import React, { useEffect, useState } from "react";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { ADMIN_GET_ADVERTISER_CAMPAIGN_DETAILS_BY_ID } from "../components/GraphQL/Queries";
import { useQuery } from "@apollo/client";

import "./css/adminadvertiser_campaign.css";

import { useNavigate, useParams } from "react-router-dom";
import DownloadDocument from "../components/BookingDetails/DownloadDocument/DownloadDocument";
import { StationButtons } from "../components/BookingDetails/StationButtons/StationButtons";
import BookingInfo from "../components/BookingDetails/BookingInfoDetails/BookingInfo";
import { Center, ChakraProvider } from "@chakra-ui/react";
import { TableSpinner } from "../components/Spinner";

const AdminAdvertiserCampaign = () => {
  const navigate = useNavigate();
  const [campaignDetails, setCampaignDetails] = useState([]);
  const [activeButton, setActiveButton] = useState(0);
  const { id } = useParams();
  const { data, loading } = useQuery(
    ADMIN_GET_ADVERTISER_CAMPAIGN_DETAILS_BY_ID,
    {
      variables: { campaignId: id },
    }
  );

  const handleGoBack = () => {
    navigate(-1);
  };

  //   dummy data for now
  const dummyData = {
    advertiser: {
      getCampaignDetails: {
        id: "47",
        startDate: "2023-11-18T00:00:00.000Z",
        endDate: "2023-11-18T00:00:00.000Z",
        campaignDocument: [
          {
            name: "12-f26748a8-c330-42f8-9b4f-9c15ac1ecb3a-file_example_MP3_700KB.wav",
            url: "https://radioadspreadblob.blob.core.windows.net/jingle-audio/12-f26748a8-c330-42f8-9b4f-9c15ac1ecb3a-file_example_MP3_700KB.wav?sv=2021-10-04&st=2023-11-28T12%3A51%3A23Z&se=2023-11-28T13%3A51%3A23Z&sr=b&sp=r&sig=lqMqVncNhMW3ikBVQCAvtdspRSvqKaXcPMVU2inRsT4%3D",
            __typename: "CampaignDocument",
          },
        ],
        radioStationDetails: [
          {
            id: "2",
            radioStation: "Breitenberg Inc",
            advertType: "Jingle",
            duration: "15 Sec",
            language: "English",
            numberOfDays: 1,
            numberOfSpots: 1,
            totalAmount: 3198.125,
            status: "Pending",
            paymentMethod: "Paystack",
            bookedSlots: {
              startDate: "2023-11-18T00:00:00.000Z",
              endDate: "2023-11-18T00:00:00.000Z",
              slots: [
                {
                  advertType: "Jingle15Sec",
                  date: "2023-11-18T00:00:00.000Z",
                  quantity: 1,
                  language: "English",
                  startTime: "1970-01-01T00:00:00.503Z",
                  endTime: "1970-01-01T00:59:00.503Z",
                  __typename: "SlotDetail",
                },
              ],
              bookingDetails: {
                totalNoOfDays: 1,
                totalNoOfSpots: 1,
                totalNoOfSlots: 1,
                __typename: "BookingDetails",
              },
              __typename: "BookedSlots",
            },
            __typename: "RadioStationDetails",
          },
        ],
        __typename: "getCampaignDetailsResponse",
      },
      __typename: "AdvertiserQuery",
    },
  };

  useEffect(() => {
    if (dummyData) {
      setCampaignDetails([
        dummyData?.advertiser.getCampaignDetails.radioStationDetails[0],
      ]);
      setActiveButton(
        dummyData?.advertiser.getCampaignDetails.radioStationDetails[0]?.id
      );
    }
  }, []);

  const singleCampaignHandler = (index) => {
    setActiveButton(index);
    const singleCampaign =
      dummyData?.advertiser.getCampaignDetails.radioStationDetails.filter(
        (campaign) => {
          return campaign.id === index;
        }
      );
    setCampaignDetails(singleCampaign);
  };
  const viewBookedSlotHandler = () => {
    navigate(
      `/dashboard/station/booked-slots/${dummyData?.advertiser.getCampaignDetails.id}`
    );
  };

  return (
    <AdminDashboardLayout>
      <div className="advertiser-booking-container">
        {loading ? (
          <ChakraProvider>
            <Center h="100vh">
              <TableSpinner />
            </Center>
          </ChakraProvider>
        ) : (
          <>
            <DownloadDocument
              campaignDetails={dummyData?.advertiser.getCampaignDetails}
            />
            <StationButtons
              radioStationDetails={
                dummyData?.advertiser.getCampaignDetails.radioStationDetails
              }
              activeButton={activeButton}
              onButtonClick={singleCampaignHandler}
            />
            <BookingInfo
              campaignId={dummyData?.advertiser.getCampaignDetails.id}
              campaignDetails={campaignDetails}
              viewBookedSlotHandler={viewBookedSlotHandler}
              viewBookedSlotButton={false}
            />
          </>
        )}
      </div>
    </AdminDashboardLayout>
  );
};

export default AdminAdvertiserCampaign;
