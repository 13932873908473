import React, { useEffect } from "react";
import "aos/dist/aos.css";
import Aos from "aos";
import "./style.css";
import TransmitRadioStationStepCard from "./CardStep/TransmitRadioStationStepCard";;

const RadiostationAccountProcess = () => {
  useEffect(() => {
    Aos.init({
      duration: 2000,
      offset: 0,
    });
  }, []);



  return (
    <div className="radioStationAccountProcess" data-aos="fade-down">
      <div className="radioStationAccountProcess">
        <div
          className="radioStationAccountProcessHeader"
          data-aos="fade-up-right"
        >
          <h1 className="radioStationAccountProcessHeaderTitle">
            Begin in <span className="radioStationAccountTotalProcess">3</span>{" "}
            steps
          </h1>
        </div>
        <button className="startCampaignBtn" data-aos="fade-up">
          Start campaign
        </button>
        <div className="transmitCardStepWrapper">
          <TransmitRadioStationStepCard
            header="Register"
            content="t Only Takes A Few Clicks To Gain Access To Advertisers Who Are Ready To Run Radio Campaigns."
            number="1"
            firstLetter="I"
          />
          <TransmitRadioStationStepCard
            header="Setup"
            content="o Live By Completing Your Profile, Uploading Your Program Schedule And Time-Slot Pricing Structure."
            number="2"
            firstLetter="G"
          />
          <TransmitRadioStationStepCard
            header="Start Earning"
            content="t Works Like Magic, Just Relax Once Setup Is Complete. Campaign Requests Will Start Coming In From Advertisers."
            number="3"
            firstLetter="I"
          />
        </div>
      </div>
    </div>
  );
};

export default RadiostationAccountProcess;
