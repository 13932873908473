import { useMutation } from "@apollo/client";
import React, { useEffect } from "react";
import { REFRESH_TRANSACTION } from "../../../../components/GraphQL/Mutation";
import { useNavigate } from "react-router";
import AdvertDashboardLayout from "../../ResponsiveAdvertiserDashboardLayout/AdvertDashboardLayout";
import {
  Box,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";

import SuccessPopup from "../../../../assets/gifs/success-popup.gif";

const Confirmwallet = () => {
  const navigate = useNavigate();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const [refreshTransaction] = useMutation(REFRESH_TRANSACTION);

  function handleRefreshFundWallet() {
    const queryParameters = new URLSearchParams(window.location.search);
    const transactionId = queryParameters.get("transactionId");
    refreshTransaction({
      variables: {
        transactionId,
      },
    });
    navigate("/dashboard/wallet");
  }

  useEffect(() => {
    onOpen();
  }, []);

  return (
    <AdvertDashboardLayout>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        onOpen={onOpen}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody
            pb={6}
            w="100%"
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Box w={200} h={200} margin="0 auto">
              <Image src={SuccessPopup} alt="success-popup" w="100%" />
            </Box>
            <Stack spacing={5}>
              <Text textAlign="center" as="b" fontSize="16px">
                Payment Successfully!
              </Text>
              <Text textAlign="center" fontSize="14px">
                T
                <span style={{ textTransform: "lowercase" }}>
                  o confirm your wallet status, please check your wallet page.
                </span>
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="#F99B28;"
              maxW="250px"
              padding="19px 60px"
              margin="0 auto"
              borderRadius="2px"
              onClick={() => handleRefreshFundWallet()}
            >
              Go to wallet
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AdvertDashboardLayout>
  );
};

export default Confirmwallet;
