import React from "react";
import { Table, Thead, Tbody, Th, Td } from "@chakra-ui/react";
import styled from "styled-components";

const AdminDashboardTable = ({ cardData }) => {
  return (
    <>
      <TableContainer>
        <TableWrapper>
          <Table>
            <Thead>
              <TableHeaderRow>
                <Th>Campaign ID</Th>
                <Th>Campaign Details</Th>
                <Th>Campaign Status</Th>
              </TableHeaderRow>
            </Thead>
            <Tbody>
              {cardData?.allCampaigns?.map(
                ({ campaignName, campaignStatus, id }) => (
                  <TableBodyRow key={id}>
                    <Td>{id}</Td>
                    <Td>{campaignName}</Td>
                    <Td>{campaignStatus}</Td>
                  </TableBodyRow>
                )
              )}
            </Tbody>
          </Table>
        </TableWrapper>
      </TableContainer>
    </>
  );
};

export default AdminDashboardTable;

const TableContainer = styled.div`
  max-height: 550px;
  width: 770px;
  height: 550px;
  overflow-y: auto;
  border: 1px solid #e8e8e8;
`;

const TableWrapper = styled.div`
  position: relative;
`;

const TableHeaderRow = styled.tr`
  background: #eff6f4;
  z-index: 1;
  color: #004643;
  white-space: nowrap;
  & > th {
    letter-spacing: 0.05em;
    line-height: 20px;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
  }
`;

const TableBodyRow = styled.tr`
  color: #3b3950;
  background: #fff;
  white-space: nowrap;
  border: 1px solid #efe6dc;
  & > td {
    letter-spacing: 0.05em;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    color: #3b3950;

    & span {
      background: #dff9dc;
      border-radius: 15px;
      padding: 5px 20px;
    }
  }
`;
