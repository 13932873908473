export const AUTH_TOKEN = "token";

export const registerOption = {
  name: { required: "Name is required" },
  fullname: { required: "Fullname is required" },
  positionHeld: { required: "specify position" },
  address: { required: "Address is required" },
  country: { required: "country is required" },
  state: { required: "state is required" },
  city: { required: "city is required" },
  zipcode: { required: "specify zipcode" },
  radioStationWebsite: { required: "Radio station website is required" },
  radioStationCategory: { required: "Radio station category is required" },
  radioStationDigitalStreaUrl: {
    required: "Radion state Digital Stream Url is reuired",
  },
  phoneNumber: {
    required: "PhoneNumber is required",
    minLength: {
      value: 8,
      message: "PhoneNumber must have at least 11 digits",
    },
  },
  email: { required: "Email is required" },
  username: { required: "Username is required" },
  password: {
    required: "Password is required",
    minLength: {
      value: 8,
      message: "Password must have at least 8 characters",
    },
  },
  confirmPassword: { required: "confirm password must match password" },
};

export const loginOption = {
  email: { required: "Email is required" },
  password: {
    required: "Password is required",
    minLength: {
      value: 8,
      message: "Password must have at least 8 characters",
    },
  },
};

export const AdvertTypeData = [
  {
    name: "Jingles",
    value: "Jingle",
    advertTypeDuration: [
      {
        dur: "15 Secs",
        val: "15Sec",
      },
      {
        dur: "30 Secs",
        val: "30Sec",
      },
      {
        dur: "45 Secs",
        val: "45Sec",
      },
      {
        dur: "60 Secs",
        val: "60Sec",
      },
    ],
  },
  {
    name: "Paid Announcements",
    value: "paidAdvertisements",
    advertTypeDuration: [
      {
        dur: "50 Words",
        val: "50Words",
      },
      {
        dur: "75 Words",
        val: "75Words",
      },
      {
        dur: "100 Words",
        val: "100Words",
      },
    ],
  },
];

export const JINGLE_FILE_TYPE = {
  "audio/mpeg": [],
  "audio/wav": [],
  "audio/aac": [],
};
export const PAID_ANNOUNCEMENT_FILE_TYPE = {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
  "application/pdf": [],
  "text/plain": [],
};
export const APCON_CERT_FILE_TYPE = {
  "image/jpeg": [],
  "image/png": [],
  "application/pdf": [],
};
export const CAMPAIGN_FILE_TYPE = {
  "image/jpeg": [],
  "image/png": [],
  "application/pdf": [],
};

export const getBroadcasterSlots = [
  {
    startTime: "1970-01-01T00:00:00.752Z",
    id: "48",
    endTime: "1970-01-01T00:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T01:00:00.752Z",
    id: "25",
    endTime: "1970-01-01T01:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T02:00:00.752Z",
    id: "26",
    endTime: "1970-01-01T02:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T03:00:00.752Z",
    id: "27",
    endTime: "1970-01-01T03:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T04:00:00.752Z",
    id: "28",
    endTime: "1970-01-01T04:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T05:00:00.752Z",
    id: "29",
    endTime: "1970-01-01T05:59:00.752Z",
    broadcasterId: "3",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T06:00:00.752Z",
    id: "30",
    endTime: "1970-01-01T06:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T07:00:00.752Z",
    id: "31",
    endTime: "1970-01-01T07:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T08:00:00.752Z",
    id: "32",
    endTime: "1970-01-01T08:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T09:00:00.752Z",
    id: "33",
    endTime: "1970-01-01T09:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T10:00:00.752Z",
    id: "34",
    endTime: "1970-01-01T10:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T11:00:00.752Z",
    id: "35",
    endTime: "1970-01-01T11:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T12:00:00.752Z",
    id: "36",
    endTime: "1970-01-01T12:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T13:00:00.752Z",
    id: "37",
    endTime: "1970-01-01T13:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T14:00:00.752Z",
    id: "38",
    endTime: "1970-01-01T14:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T15:00:00.752Z",
    id: "39",
    endTime: "1970-01-01T15:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T16:00:00.752Z",
    id: "40",
    endTime: "1970-01-01T16:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T17:00:00.752Z",
    id: "41",
    endTime: "1970-01-01T17:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T18:00:00.752Z",
    id: "42",
    endTime: "1970-01-01T18:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T19:00:00.752Z",
    id: "43",
    endTime: "1970-01-01T19:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T20:00:00.752Z",
    id: "44",
    endTime: "1970-01-01T20:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T21:00:00.752Z",
    id: "45",
    endTime: "1970-01-01T21:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T22:00:00.752Z",
    id: "46",
    endTime: "1970-01-01T22:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
  {
    startTime: "1970-01-01T23:00:00.752Z",
    id: "47",
    endTime: "1970-01-01T23:59:00.752Z",
    slotNumber: 0,
    isAvailable: false,
  },
];

export const Roles = [
  {
    key: "Vendor",
    value: "Vendor",
  },
  {
    key: "Advertiser",
    value: "Advertiser",
  },
  {
    key: "Withdrawal",
    value: "Withdrawal",
  },
  {
    key: "Role",
    value: "Role",
  },
  // {
  //   key: "Page Setting",
  //   value: "Page Setting"
  // },
];

export const Permissions = [
  {
    id: "1",
    role: "User",
    permission: [
      {
        id: "2",
        role: "Create",
      },
      {
        id: "3",
        role: "Edit",
      },
      {
        id: "4",
        role: "Delete",
      },
      {
        id: "5",
        role: "List",
      },
      {
        id: "6",
        role: "View",
      },
      {
        id: "7",
        role: "Action",
      },
    ],
  },
  {
    id: "2",
    role: "Vendor",
    permission: [
      {
        id: "2",
        role: "Create",
      },
      {
        id: "3",
        role: "Edit",
      },
      {
        id: "4",
        role: "Delete",
      },
      {
        id: "5",
        role: "List",
      },
      {
        id: "6",
        role: "View",
      },
      {
        id: "7",
        role: "Action",
      },
    ],
  },
  {
    id: "1",
    role: "Admin",
    permission: [
      {
        id: "2",
        role: "Create",
      },
      {
        id: "3",
        role: "Edit",
      },
      {
        id: "4",
        role: "Delete",
      },
      {
        id: "5",
        role: "List",
      },
      {
        id: "6",
        role: "View",
      },
      {
        id: "7",
        role: "Action",
      },
    ],
  },
  {
    id: "1",
    role: "Advertiser",
    permission: [
      {
        id: "2",
        role: "Create",
      },
      {
        id: "3",
        role: "Edit",
      },
      {
        id: "4",
        role: "Delete",
      },
      {
        id: "5",
        role: "List",
      },
      {
        id: "6",
        role: "View",
      },
      {
        id: "7",
        role: "Action",
      },
    ],
  },
];

export const adminOrders = [
  {
    id: "47",
    date: "2023-11-17T07:13:05.102Z",
    companyName: "Company",
    campaignName: "Test",
    industryName: "",
    invoice: "47",
    radioStation: [
      {
        __typename: "RadioStation",
        id: "2",
        name: "Breitenberg Inc",
      },
    ],
  },
  {
    id: "45",
    date: "2023-10-30T12:01:42.374Z",
    companyName: "Company",
    campaignName: "Test",
    industryName: "",
    invoice: "45",
    radioStation: [
      {
        __typename: "RadioStation",
        id: "2",
        name: "Breitenberg Inc",
      },
    ],
  },
  {
    id: "44",
    date: "2023-10-30T11:36:05.558Z",
    companyName: "Company",
    campaignName: "Test",
    industryName: "",
    invoice: "44",
    radioStation: [
      {
        __typename: "RadioStation",
        id: "2",
        name: "Breitenberg Inc",
      },
    ],
  },
  {
    id: "43",
    date: "2023-10-30T11:25:42.070Z",
    companyName: "Company",
    campaignName: "Test",
    industryName: "",
    invoice: "43",
    radioStation: [
      {
        __typename: "RadioStation",
        id: "2",
        name: "Breitenberg Inc",
      },
    ],
  },
  {
    id: "42",
    date: "2023-10-27T14:24:30.076Z",
    companyName: "khdkjhdjhd",
    campaignName: "TheOnis",
    industryName: "khdjhdkjd",
    invoice: "42",
    radioStation: [
      {
        __typename: "RadioStation",
        id: "2",
        name: "Breitenberg Inc",
      },
    ],
  },
  {
    id: "41",
    date: "2023-10-25T09:51:46.714Z",
    companyName: "Test",
    campaignName: "Test",
    industryName: "",
    invoice: "41",
    radioStation: [
      {
        __typename: "RadioStation",
        id: "2",
        name: "Breitenberg Inc",
      },
    ],
  },
  {
    id: "40",
    date: "2023-10-12T17:08:28.733Z",
    companyName: "beat fm",
    campaignName: "RadioAdSpread Naija FM",
    industryName: "beat",
    invoice: "40",
    radioStation: [
      {
        __typename: "RadioStation",
        id: "2",
        name: "Breitenberg Inc",
      },
      {
        __typename: "RadioStation",
        id: "3",
        name: "Grimes Group",
      },
    ],
  },
  {
    id: "39",
    date: "2023-10-05T13:13:25.777Z",
    companyName: "Company",
    campaignName: "Test",
    industryName: "",
    invoice: "39",
    radioStation: [
      {
        __typename: "RadioStation",
        id: "2",
        name: "Breitenberg Inc",
      },
    ],
  },
  {
    id: "38",
    date: "2023-10-05T13:03:23.075Z",
    companyName: "Company",
    campaignName: "Test",
    industryName: "",
    invoice: "38",
    radioStation: [
      {
        __typename: "RadioStation",
        id: "2",
        name: "Breitenberg Inc",
      },
    ],
  },
  {
    id: "37",
    date: "2023-10-05T12:58:14.016Z",
    companyName: "Company",
    campaignName: "Test",
    industryName: "",
    invoice: "37",
    radioStation: [
      {
        __typename: "RadioStation",
        id: "2",
        name: "Breitenberg Inc",
      },
    ],
  },
  {
    id: "36",
    date: "2023-09-29T03:51:18.180Z",
    companyName: "Company",
    campaignName: "Test",
    industryName: "",
    invoice: "36",
    radioStation: [
      {
        __typename: "RadioStation",
        id: "2",
        name: "Breitenberg Inc",
      },
    ],
  },
  {
    id: "34",
    date: "2023-09-17T20:06:18.798Z",
    companyName: "Company",
    campaignName: "Test Portfolio",
    industryName: "",
    invoice: "34",
    radioStation: [
      {
        __typename: "RadioStation",
        id: "2",
        name: "Breitenberg Inc",
      },
    ],
  },

  {
    id: "1",
    date: "2023-08-08T10:21:28.018Z",
    companyName: "Rowe - Vandervort",
    campaignName: "superstructure",
    industryName: "niches",
    invoice: "1",
    radioStation: [
      {
        __typename: "RadioStation",
        id: "3",
        name: "Grimes Group",
      },
      {
        __typename: "RadioStation",
        id: "5",
        name: "Quitzon - Stark",
      },
      {
        __typename: "RadioStation",
        id: "6",
        name: "Pacocha Inc",
      },
      {
        __typename: "RadioStation",
        id: "7",
        name: "Nitzsche - Von",
      },
      {
        __typename: "RadioStation",
        id: "8",
        name: "Hauck, Kihn and Beer",
      },
      {
        __typename: "RadioStation",
        id: "10",
        name: "Block, VonRueden and Mills",
      },
      {
        __typename: "RadioStation",
        id: "11",
        name: "Gerhold - Pfeffer",
      },
    ],
  },
];

export const listOfBanks =  [
  {
      "name": "Abbey Mortgage Bank",
      "slug": "abbey-mortgage-bank",
      "code": "801",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Above Only MFB",
      "slug": "above-only-mfb",
      "code": "51204",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Access Bank",
      "slug": "access-bank",
      "code": "044",
      "longCode": "044150149",
      "gateway": "emandate",
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Access Bank (Diamond)",
      "slug": "access-bank-diamond",
      "code": "063",
      "longCode": "063150162",
      "gateway": "emandate",
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "ALAT by WEMA",
      "slug": "alat-by-wema",
      "code": "035A",
      "longCode": "035150103",
      "gateway": "emandate",
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Amju Unique MFB",
      "slug": "amju-unique-mfb",
      "code": "50926",
      "longCode": "511080896",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "ASO Savings and Loans",
      "slug": "asosavings",
      "code": "401",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Bainescredit MFB",
      "slug": "bainescredit-mfb",
      "code": "51229",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Bowen Microfinance Bank",
      "slug": "bowen-microfinance-bank",
      "code": "50931",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Carbon",
      "slug": "carbon",
      "code": "565",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "CEMCS Microfinance Bank",
      "slug": "cemcs-microfinance-bank",
      "code": "50823",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Citibank Nigeria",
      "slug": "citibank-nigeria",
      "code": "023",
      "longCode": "023150005",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Coronation Merchant Bank",
      "slug": "coronation-merchant-bank",
      "code": "559",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Ecobank Nigeria",
      "slug": "ecobank-nigeria",
      "code": "050",
      "longCode": "050150010",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Ekondo Microfinance Bank",
      "slug": "ekondo-microfinance-bank",
      "code": "562",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Eyowo",
      "slug": "eyowo",
      "code": "50126",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Fidelity Bank",
      "slug": "fidelity-bank",
      "code": "070",
      "longCode": "070150003",
      "gateway": "emandate",
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Firmus MFB",
      "slug": "firmus-mfb",
      "code": "51314",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "First Bank of Nigeria",
      "slug": "first-bank-of-nigeria",
      "code": "011",
      "longCode": "011151003",
      "gateway": "ibank",
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "First City Monument Bank",
      "slug": "first-city-monument-bank",
      "code": "214",
      "longCode": "214150018",
      "gateway": "emandate",
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "FSDH Merchant Bank Limited",
      "slug": "fsdh-merchant-bank-limited",
      "code": "501",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Globus Bank",
      "slug": "globus-bank",
      "code": "00103",
      "longCode": "103015001",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "GoMoney",
      "slug": "gomoney",
      "code": "100022",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Guaranty Trust Bank",
      "slug": "guaranty-trust-bank",
      "code": "058",
      "longCode": "058152036",
      "gateway": "ibank",
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Hackman Microfinance Bank",
      "slug": "hackman-microfinance-bank",
      "code": "51251",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Hasal Microfinance Bank",
      "slug": "hasal-microfinance-bank",
      "code": "50383",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Heritage Bank",
      "slug": "heritage-bank",
      "code": "030",
      "longCode": "030159992",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Ibile Microfinance Bank",
      "slug": "ibile-mfb",
      "code": "51244",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Infinity MFB",
      "slug": "infinity-mfb",
      "code": "50457",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Jaiz Bank",
      "slug": "jaiz-bank",
      "code": "301",
      "longCode": "301080020",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Kadpoly MFB",
      "slug": "kadpoly-mfb",
      "code": "50502",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Keystone Bank",
      "slug": "keystone-bank",
      "code": "082",
      "longCode": "082150017",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Kredi Money MFB LTD",
      "slug": "kredi-money-mfb",
      "code": "50200",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Kuda Bank",
      "slug": "kuda-bank",
      "code": "50211",
      "longCode": "",
      "gateway": "digitalbankmandate",
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Lagos Building Investment Company Plc.",
      "slug": "lbic-plc",
      "code": "90052",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Links MFB",
      "slug": "links-mfb",
      "code": "50549",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Lotus Bank",
      "slug": "lotus-bank",
      "code": "303",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Mayfair MFB",
      "slug": "mayfair-mfb",
      "code": "50563",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Mint MFB",
      "slug": "mint-mfb",
      "code": "50304",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Paga",
      "slug": "paga",
      "code": "100002",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "PalmPay",
      "slug": "palmpay",
      "code": "999991",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Parallex Bank",
      "slug": "parallex-bank",
      "code": "104",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Parkway - ReadyCash",
      "slug": "parkway-ready-cash",
      "code": "311",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Paycom",
      "slug": "paycom",
      "code": "999992",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Petra Mircofinance Bank Plc",
      "slug": "petra-microfinance-bank-plc",
      "code": "50746",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Polaris Bank",
      "slug": "polaris-bank",
      "code": "076",
      "longCode": "076151006",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Providus Bank",
      "slug": "providus-bank",
      "code": "101",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "QuickFund MFB",
      "slug": "quickfund-mfb",
      "code": "51293",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Rand Merchant Bank",
      "slug": "rand-merchant-bank",
      "code": "502",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Rubies MFB",
      "slug": "rubies-mfb",
      "code": "125",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Sparkle Microfinance Bank",
      "slug": "sparkle-microfinance-bank",
      "code": "51310",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Stanbic IBTC Bank",
      "slug": "stanbic-ibtc-bank",
      "code": "221",
      "longCode": "221159522",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Standard Chartered Bank",
      "slug": "standard-chartered-bank",
      "code": "068",
      "longCode": "068150015",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Sterling Bank",
      "slug": "sterling-bank",
      "code": "232",
      "longCode": "232150016",
      "gateway": "emandate",
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Suntrust Bank",
      "slug": "suntrust-bank",
      "code": "100",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "TAJ Bank",
      "slug": "taj-bank",
      "code": "302",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Tangerine Money",
      "slug": "tangerine-money",
      "code": "51269",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "TCF MFB",
      "slug": "tcf-mfb",
      "code": "51211",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Titan Bank",
      "slug": "titan-bank",
      "code": "102",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Unical MFB",
      "slug": "unical-mfb",
      "code": "50871",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Union Bank of Nigeria",
      "slug": "union-bank-of-nigeria",
      "code": "032",
      "longCode": "032080474",
      "gateway": "emandate",
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "United Bank For Africa",
      "slug": "united-bank-for-africa",
      "code": "033",
      "longCode": "033153513",
      "gateway": "emandate",
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Unity Bank",
      "slug": "unity-bank",
      "code": "215",
      "longCode": "215154097",
      "gateway": "emandate",
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "VFD Microfinance Bank Limited",
      "slug": "vfd",
      "code": "566",
      "longCode": "",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Wema Bank",
      "slug": "wema-bank",
      "code": "035",
      "longCode": "035150103",
      "gateway": null,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  },
  {
      "name": "Zenith Bank",
      "slug": "zenith-bank",
      "code": "057",
      "longCode": "057150013",
      "gateway": "emandate",
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban"
  }
]