import {
  Flex,
  IconButton,
  Text,
  Button,
  useDisclosure,
  useColorModeValue,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  HStack,
  VStack,
  Skeleton,
} from "@chakra-ui/react";
import { HamburgerIcon, HelpIcon, NotificationIcon, Logo } from "../menuItems";
import Logout from "../../components/Modal/Logout/Logout";
import NotificationsPopOver from "../../components/Notifications/NotificationsPopOver";
import "../../pages/ResponsiveAdvertiserDashboard/ResponsiveAdvertiserDashboardLayout/style.css";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  LogOutIcon,
  PopoverText,
  ProfileIcon,
} from "../../pages/ResponsiveAdvertiserDashboard/ResponsiveAdvertiserDashboardLayout/components";
import { GET_NOTIFICATIONS, USER_INFO } from "../../components/GraphQL/Queries";
import { useQuery, useSubscription } from "@apollo/client";

const MobileNav = ({ onOpen, ...rest }) => {
  const { data, loading } = useQuery(USER_INFO);
  const { data: notificationData, loading: notificationLoading } =
    useSubscription(GET_NOTIFICATIONS);
  const navigate = useNavigate();
  const {
    isOpen: isLogout,
    onOpen: openLogout,
    onClose: closeLogout,
  } = useDisclosure();
  const {
    isOpen: isNotificationModal,
    onOpen: openNotificationModal,
    onClose: closeNotificationModal,
  } = useDisclosure();
  return (
    <>
      {/* {isLogout && <Logout isOpen={openLogout} onClose={closeLogout} />} */}
      <Flex
        flexDirection="row-reverse"
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={{ base: "black", md: useColorModeValue("white", "gray.900") }}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue("gray.200", "gray.700")}
        justifyContent={{ base: "space-between", md: "flex-end" }}
        borderRadius={{ base: "0 0 24px 24px", md: "0 0 0 0" }}
        {...rest}
      >
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          variant="none"
          aria-label="open menu"
          icon={<HamburgerIcon />}
        />
        <Text
          display={{ base: "flex", md: "none" }}
          fontSize="2xl"
          fontFamily="monospace"
          fontWeight="bold"
        >
          <Logo isMobile />
        </Text>
        <Flex
          spacing={{ base: "0", md: "6" }}
          w={{ md: "100%" }}
          justifyContent={{ md: "flex-end" }}
          alignItems="center"
          display={{ base: "none", md: "flex" }}
          gap="20px"
        >
          <Button
            padding="15px 30px"
            borderRadius="27px"
            bg="#F1613F"
            color="white"
            outline={'none'}
            _hover={{bg: '#ee5634'}}
            _active={{bg: '#ee5634'}}

            sx={{
              transition: 'transform 0.3s ease', // Animation duration and timing function
              _hover: {
                transform: 'translateY(-4px)', // Moves the button up 4px on hover
              },
            }}
            onClick={() => navigate("/book-campaign")}
          >
            New Campaign
          </Button>
          <Button variant="none" onClick={openNotificationModal}>
            <NotificationIcon />
          </Button>

          {/* <Popover placement="bottom-start">
            <PopoverTrigger> */}
              <Button variant="none">
                <HelpIcon />
              </Button>
            {/* </PopoverTrigger> */}

            {/* <PopoverContent
              w="158px"
              h="89px"
              bg="#ffffff"
              border="0.5px solid #aba8a8"
              borderRadius="2px"
              p="15px 0px"
            >
              <VStack>
                <Container onClick={() => navigate("/dashboard/profile")}>
                  <HStack>
                    <ProfileIcon />
                    <PopoverText>Profile</PopoverText>
                  </HStack>
                </Container>
                <Container onClick={openLogout}>
                  <HStack>
                    <LogOutIcon />
                    <PopoverText>Logout</PopoverText>
                  </HStack>
                </Container>
              </VStack>
            </PopoverContent>
          </Popover> */}
        </Flex>
        {isNotificationModal && (
          <NotificationsPopOver
            isNotificationModal={isNotificationModal}
            openNotificationModal={openNotificationModal}
            closeNotificationModal={closeNotificationModal}
            data={notificationData}
          />
        )}
        <Box display={{ md: "none" }}></Box>
      </Flex>
    </>
  );
};

export default MobileNav;
