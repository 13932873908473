import React from "react";

const DurationIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 28.125C12.4041 28.125 9.86654 27.3552 7.70815 25.913C5.54975 24.4709 3.86749 22.421 2.87409 20.0227C1.88069 17.6244 1.62077 14.9854 2.1272 12.4394C2.63363 9.89345 3.88367 7.55479 5.71923 5.71923C7.55479 3.88367 9.89345 2.63363 12.4394 2.1272C14.9854 1.62077 17.6244 1.88069 20.0227 2.87409C22.421 3.86749 24.4709 5.54975 25.913 7.70815C27.3552 9.86654 28.125 12.4041 28.125 15C28.125 18.481 26.7422 21.8194 24.2808 24.2808C21.8194 26.7422 18.481 28.125 15 28.125ZM15 3.75001C12.775 3.75001 10.5999 4.40981 8.74984 5.64597C6.89979 6.88214 5.45785 8.63915 4.60636 10.6948C3.75488 12.7505 3.53209 15.0125 3.96617 17.1948C4.40026 19.3771 5.47171 21.3816 7.04505 22.955C8.6184 24.5283 10.623 25.5998 12.8052 26.0338C14.9875 26.4679 17.2495 26.2451 19.3052 25.3937C21.3609 24.5422 23.1179 23.1002 24.354 21.2502C25.5902 19.4001 26.25 17.225 26.25 15C26.25 12.0163 25.0647 9.15484 22.955 7.04505C20.8452 4.93527 17.9837 3.75001 15 3.75001Z"
        fill="#F99B28"
      />
      <path
        d="M19.3031 20.625L14.0625 15.3844V6.5625H15.9375V14.6062L20.625 19.3031L19.3031 20.625Z"
        fill="#F99B28"
      />
    </svg>
  );
};

export default DurationIcon;
