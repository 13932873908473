import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CustomButtonDatePicker from "../../CustomDateRangePicker";

const Dropdown = ({portfolioData, setPortfolioData}) => {
 
  const [dropdown, setDropdown] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [dropdown]);

  const handleClick = (day) => {
    let newDateSelected;
    let totalDaySelected;
    const newData = portfolioData.data.map((eachData) => {
      if (eachData.name === day.name) {
        newDateSelected = day.name
        totalDaySelected = day.noOfDays
        return { ...eachData, checked: true };
      }
      return { ...eachData, checked: false };
    });
   setPortfolioData({...portfolioData, data:newData, daySelected: newDateSelected, totalDays: totalDaySelected});
    
    if(day.name === "Custom Date"){
      setDropdown(true)
    }else{
      setDropdown(false) 
  };
}

  const handleRangeSelect = (concatenatedDates, totalSelectedRangeDate) => {
       setPortfolioData({...portfolioData, daySelected: concatenatedDates, totalDays: totalSelectedRangeDate});
        setDropdown(false)
  };

  return (
    <>
        <DropdownContainer ref={ref}>
      <SelectedDayWrapper onClick={() => setDropdown(!dropdown)}>
        {portfolioData.daySelected}
      </SelectedDayWrapper>
      {dropdown && (
        <DropdownWrapper>
          {portfolioData.data.map((item) => (
            <DayListWrapper key={item.id} onClick={() => handleClick(item)}>
              <ButtonWrapper>
                <CheckedButton
                  style={{ background: item.checked ? "#fa9b00" : "transparent" }}
                ></CheckedButton>
              </ButtonWrapper>
              {item.name}
            </DayListWrapper>
          ))}
   {portfolioData.daySelected === "Custom Date" && dropdown &&<CustomButtonDatePicker onSelectRange={handleRangeSelect}/>}
        </DropdownWrapper>
      )}
    </DropdownContainer>
    </>
  );
};

export default Dropdown


const DropdownContainer = styled.div`
  position: relative;
`;

const SelectedDayWrapper = styled.div`
  width: 250px;
  border: 2px solid #fa9b00;
  padding: 8px;
  border-radius: 4px;
`;

const DropdownWrapper = styled.div`
  position: absolute;
  width: 250px;
  left: -50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 32px 24px 16px;
  gap: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(9, 11, 23, 0.1),
    0px 8px 24px rgba(9, 11, 23, 0.1);
  border-radius: 4px;
  margin-top: 5px;
  z-index: 99;
`;

const DayListWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 4px 0;
`;

const ButtonWrapper = styled.button`
  border: 2px solid #fa9b00;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const CheckedButton = styled.button`
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;
