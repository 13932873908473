import React from "react";

const Label = ({ label }) => {
  let textColor, backgroundColor;

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  console.log(label,'label...');

  switch (capitalizeFirstLetter(label)) {
    case "Approved":
      textColor = "#004643";
      backgroundColor = "#DFF9DC";
      break;
    case "Cancelled":
      textColor = "#C52D2F";
      backgroundColor = "#F8EBDA";
      break;
    case "Pending":
      textColor = "#8A5806";
      backgroundColor = "#F99B284D";
      break;
    case "Inactive":
      textColor = "#C52D2F";
      backgroundColor = "#F8EBDA";
      break;
      case "Active":
      textColor = "#004643";
      backgroundColor = "#DFF9DC";
      break;
    default:
      textColor = "#004643";
      backgroundColor = "white";
  }

  const labelStyle = {
    color: textColor,
    backgroundColor: backgroundColor,
    width: "79px",
    height: "23px",
  justifyContent: "center",
    display: "flex",
    borderRadius: "15px",
    alignItems: "center",
    fontSize: "12px"
  };

  return <div style={labelStyle}>{capitalizeFirstLetter(label)}</div>;
};

export default Label;
