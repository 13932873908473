import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { useQuery } from "@apollo/client";
import { GET_ORDER_BY_ID } from "../components/GraphQL/Queries";
import { TableSpinner } from "../components/Spinner";
import styled from "styled-components";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Box,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  TableContainer,
} from "@chakra-ui/react";

const AdminBookingDetails = () => {
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [slotDates, setSlotDates] = useState({});
  const [currentDateSlots, setCurrentDateSlots] = useState([]);
//   const [slot, setSlot] = useState([]);


  const { id } = useParams();
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_ORDER_BY_ID, {
    variables: {
      orderId: parseInt(id),
    },
    onCompleted: () => {
      setCampaignDetails(data?.admin.getOrderById.radioStationDetails[0]);
    //   setSlot(campaignDetails?.bookedSlots?.slots);
    
    },
  });

  const startDate = campaignDetails?.bookedSlots?.startDate;
  const endDate = campaignDetails?.bookedSlots?.endDate;

//   console.log("currentDateSlots",currentDateSlots)

  useEffect(() => {
    let tempSlotDates = {};
    campaignDetails?.bookedSlots?.slots &&
      [
        ...new Set(
          campaignDetails?.bookedSlots?.slots.map((slot) => slot.date)
        ),
      ].forEach((date) => {
        tempSlotDates[`${getDateFormat(date)}`] = [];
      });

    campaignDetails?.bookedSlots?.slots.forEach((slot) => {
      const currentDate = getDateFormat(slot.date);
      if (tempSlotDates[`${currentDate}`]) {
        let currDateArr = tempSlotDates[`${currentDate}`];
        tempSlotDates[`${currentDate}`] = currDateArr.concat(slot);
      }
    });
    setSlotDates({ ...tempSlotDates });
    setCurrentDateSlots(
      startDate && [...tempSlotDates[`${getDateFormat(startDate)}`]]
    );
  }, [campaignDetails?.bookedSlots?.slots, startDate]);

//   console.log(slot, "slots");
//   console.log(
//     campaignDetails?.bookedSlots?.slots,
//     "campaignDetails?.bookedSlots?.slots"
//   );
  const getTimeFormat = (time) => {
    return moment(new Date(time)).format("LT");
  };
  const getDateFormat = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  const renderDayContents = (day, date) => {
    const formattedDate = moment(date).format("DD/MM/YYYY"); // Format the date in DD/MM/YYYY format

    // Check if the formatted date is in slotData
    const isSpecial =
      slotDates[formattedDate] && slotDates[formattedDate].length > 0;

    return (
      <div className="day-container">
        {isSpecial ? (
          <div className="special-marker slot-available">
            <div
              className="calender_date_wrapper"
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                margin: "auto",
              }}
            >
              <div className="calender_day">
                <p>{day}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="special-marker-color">
            <div
              className="calender_date_wrapper-gray"
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                margin: "auto",
              }}
            >
              <div className="calender_day-gray">
                <p>{day}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const dayClassName = (date) => {
    const formattedDate = getDateFormat(date);
    return slotDates[formattedDate] && slotDates[formattedDate].length > 0
      ? ""
      : "unclickable-day";
  };

  return (
    <AdminDashboardLayout>
      {loading ? (
        <TableSpinner />
      ) : (
        <Container>
          <HStack mb="60px">
            <BackContainer onClick={() => navigate(-1)}>
              <ChevronLeft />
              <GoBackText>Back</GoBackText>
            </BackContainer>

            <CampaignIDText>#{id}</CampaignIDText>
          </HStack>

          <BookedSlotsHeaderText>Selected Spots</BookedSlotsHeaderText>

          <div mt="24px" mb="60px">
            <DatePicker
              selected={startDate && new Date(startDate)}
              onChange={(date) => {
                const currDate = getDateFormat(new Date(date));
                if (slotDates[`${currDate}`]) {
                  setCurrentDateSlots([...slotDates[`${currDate}`]]);
                  return;
                }
                setCurrentDateSlots([]);
              }}
              renderDayContents={renderDayContents}
              startDate={startDate && new Date(startDate)}
              endDate={endDate && new Date(endDate)}
              calendarClassName="custom-calendar"
              selectsRange
              inline
              dayClassName={dayClassName}
            />
          </div>

          <BookedSlotsHeaderText>Selected Slots</BookedSlotsHeaderText>

          <SpotContainer>
            <TableContainer w="377px">
              <Table variant="simple">
                <Thead height="60px" bg=" #eff6f4">
                  <Tr>
                    <Td>
                      <SpotHeaderText>Time</SpotHeaderText>
                    </Td>
                    <Td>
                      <SpotHeaderText>No of Spots</SpotHeaderText>
                    </Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentDateSlots &&
                    currentDateSlots
                      .slice(0, 12)
                      .map(
                        ({ startTime = "", endTime = "", quantity = "" }) => {
                          return (
                            <Tr>
                              <Td>
                                <SpotBodyText>
                                  {`${getTimeFormat(
                                    startTime && startTime
                                  )} - ${getTimeFormat(endTime && endTime)} `}
                                </SpotBodyText>
                              </Td>
                              <Td>
                                <SpotBodyText>{quantity}</SpotBodyText>
                              </Td>
                            </Tr>
                          );
                        }
                      )}
                </Tbody>
              </Table>
            </TableContainer>
            {currentDateSlots && currentDateSlots?.length > 12 && (
              <TableContainer w="377px">
                <Table variant="simple">
                  <Thead height="60px" bg=" #eff6f4">
                    <Tr>
                      <Td>
                        <SpotHeaderText>Time</SpotHeaderText>
                      </Td>
                      <Td>
                        <SpotHeaderText>No of Spots</SpotHeaderText>
                      </Td>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {currentDateSlots
                      .slice(11)
                      .map(
                        ({ startTime = "", endTime = "", quantity = "" }) => {
                          return (
                            <Tr>
                              <Td>
                                <SpotBodyText>
                                  {`${getTimeFormat(
                                    startTime && startTime
                                  )} - ${getTimeFormat(endTime && endTime)} `}
                                </SpotBodyText>
                              </Td>
                              <Td>
                                <SpotBodyText>{quantity}</SpotBodyText>
                              </Td>
                            </Tr>
                          );
                        }
                      )}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </SpotContainer>
          <BookedSlotsHeaderText>Booking Details</BookedSlotsHeaderText>
          <HStack mt="22px" spacing={20} mb={326}>
            <BookingDetailsContainer>
              <BookDetailsText>Total no of Days:</BookDetailsText>
              <BookDetailsValue>
                {campaignDetails?.bookedSlots?.bookingDetails?.totalNoOfDays}
              </BookDetailsValue>
            </BookingDetailsContainer>
            <BookingDetailsContainer>
              <BookDetailsText>Total no of Spots:</BookDetailsText>
              <BookDetailsValue>
                {campaignDetails?.bookedSlots?.bookingDetails?.totalNoOfSpots}
              </BookDetailsValue>
            </BookingDetailsContainer>
            <BookingDetailsContainer>
              <BookDetailsText>Total no of Slots:</BookDetailsText>
              <BookDetailsValue>
                {campaignDetails?.bookedSlots?.bookingDetails?.totalNoOfSlots}
              </BookDetailsValue>
            </BookingDetailsContainer>
          </HStack>
        </Container>
      )}
    </AdminDashboardLayout>
  );
};

export default AdminBookingDetails;

const BookingDetailsContainer = styled.div`
  width: 136px;
  height: 19px;
  display: flex;
  justify-content: space-between;
`;

const BookDetailsText = styled.p`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #004643;
`;

const BookDetailsValue = styled.p`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #004643;
`;

const SpotContainer = styled.div`
  width: 751px;
  height: 453px;
  margin-top: 36px;
  margin-bottom: 47px;
  background: #ffffff;
  display: flex;
`;

const SpotHeaderText = styled.p`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #004643;
`;
const SpotBodyText = styled.p`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #0e0e2c;
`;

const BookedSlotsHeaderText = styled.h1`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  color: #004643;
`;
const Container = styled.div`
  padding: 16px 70px;
`;

const BackContainer = styled.div`
  width: 41.5px;
  height: 12px;
  margin-right: 65.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
const ChevronLeft = () => {
  return (
    <svg
      width="6"
      height="11"
      viewBox="0 0 6 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 10L1 5.5L5.5 1"
        stroke="#F99B28"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const GoBackText = styled.p`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #f99b28;
`;

const CampaignIDText = styled.p`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  letter-spacing: 0.05em;

  color: #f99b28;
`;
