import React, { useState } from "react";
import { TableSpinner } from "../Spinner";
import moment from "moment";

import "./style.css";
import PaginationComponent from "../Pagination/PaginationComponent";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { overViewCard } from "../../utils/constant";


import SalesIcon from "../../assets/dashboardIcons/salesIcon.svg"
import PendingIcon from "../../assets/dashboardIcons/pendingIcon.svg"
import CommissionIcon from "../../assets/dashboardIcons/commission.svg"
import EarningIcon from "../../assets/dashboardIcons/earningIcon.svg"
import CampaignIcon from "../../assets/dashboardIcons/campaignImg.svg"

const ReportComponent = ({
  data,
  getPaymentHistory,
  loading,
  overViewCards,
}) => {
  const [showIndex, setShowIndex] = useState(0);
  const routeButton = ["Overview", "Statement", "Payment History"];

  const [currentPage, setCurrentPage] = useState(1);
  const [entries, setEntries] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const handlePagination = (action) => {
    if (action === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (action === "next" && data.length > currentPage * entries) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    console.log(e.target.value);
    setCurrentPage(1); // Reset current page when searching
  };

  const filteredData = data?.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const totalPages = Math.ceil(filteredData.length / entries);

  const paginatedData = filteredData?.slice(
    (currentPage - 1) * entries,
    currentPage * entries
  );

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <span
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </span>
      );
    }
    return pageNumbers;
  };

  console.log(paginatedData, ";;;;;;;;");

  return (
    <div className="reports_wrapper">
      <div className="report_tab_btn">
        {routeButton.map((route, index) => {
          return (
            <button
              onClick={() => {
                setShowIndex(index);
              }}
              className={index === showIndex ? "active-border" : ""}
            >
              {route}
            </button>
          );
        })}
      </div>
      <div className="report_content_wrapper">
        {showIndex === 0 && (
          <div className="overview-container">
            <div className="card-content">
              <img src={SalesIcon} alt="report_icon" />
              <div className="card-text">
                <span>{overViewCards?.sales}</span>
                <span>sales</span>
              </div>
            </div>
            <div className="card-content">
              <img src={PendingIcon} alt="report_icon" />
              <div className="card-text">
                <span>{overViewCards?.pending}</span>
                <span>Pending</span>
              </div>
            </div>
            <div className="card-content">
              <img src={CommissionIcon} alt="report_icon" />
              <div className="card-text">
                <span>{overViewCards?.commission}</span>
                <span>Commission</span>
              </div>
            </div>
            <div className="card-content">
              <img src={EarningIcon}alt="report_icon" />
              <div className="card-text">
                <span>{overViewCards?.totalEarnings}88</span>
                <span>Total Earnings</span>
              </div>
            </div>
            <div className="card-content">
              <img src={CampaignIcon} alt="report_icon" />
              <div className="card-text">
                <span>{overViewCards?.campaigns}</span>
                <span>Campaigns</span>
              </div>
            </div>
          </div>
        )}
        {showIndex === 1 && (
          <div className="statement-container">
            <div className="date-wrapper">
              <div className="start-date-wrapper">
                From: <input type="date" />
              </div>
              <div className="end-date-wrapper">
                To: <input type="date" />
              </div>
              <button>Show</button>
            </div>
            <section className="filter_entry_wrapper">
              <div className="filter_entry_dropdown">
                <span>Show</span>
                <select
                  name=""
                  id=""
                  value={entries}
                  onChange={(e) => setEntries(parseInt(e.target.value))}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                </select>
                <span>entries</span>
              </div>
              <div className="search_input_wrapper">
                <InputGroup maxW="250px" bg="#EFF6F4">
                  <InputRightElement cursor="pointer" py={4}>
                    <SearchIcon />
                  </InputRightElement>
                  <Input
                    type="text"
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                </InputGroup>
              </div>
            </section>
            <div class="table-body-container statement_table_wrapper">
              <table
                id="data-table"
                class="table table-striped table-bordered nowrap"
                width="100%"
              >
                <thead>
                  <tr>
                    <th>
                      <input name="select_all" value="1" type="checkbox" />
                    </th>
                    <th>transaction date</th>
                    <th>order ID</th>
                    <th>type</th>
                    <th>sales</th>
                    <th>commission</th>
                    <th>earning</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((info) => {
                    return (
                      <tr class="odd gradeX">
                        <td>
                          <input type="checkbox" name="name1" />
                        </td>
                        <td>
                          {moment(new Date(info.transactionDate)).format(
                            "MMM Do YYYY"
                          )}
                        </td>
                        <td>{info.campaignId}</td>
                        <td>{info.type}</td>
                        <td>{info.sales}</td>
                        <td>{info.commission}</td>
                        <td>{info.earning}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <section className="pagination-section">
              <PaginationComponent
                currentPage={currentPage}
                entries={entries}
                filteredDataLength={filteredData}
                handlePagination={handlePagination}
                renderPageNumbers={renderPageNumbers}
              />
            </section>
          </div>
        )}
        {showIndex === 2 && (
          <div className="payment-history-container">
            <section className="filter_entry_wrapper">
              <div className="filter_entry_dropdown">
                <span>Show</span>
                <select
                  name=""
                  id=""
                  value={entries}
                  onChange={(e) => setEntries(parseInt(e.target.value))}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                </select>
                <span>entries</span>
              </div>
              <div className="search_input_wrapper">
                <InputGroup maxW="250px" bg="#EFF6F4">
                  <InputRightElement cursor="pointer" py={4}>
                    <SearchIcon />
                  </InputRightElement>
                  <Input
                    type="text"
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                </InputGroup>
              </div>
            </section>
            <div className="table-body-container statement_table_wrapper">
              {!loading ? (
                <table>
                  <thead>
                    <tr>
                      <th>transaction date</th>
                      <th>amount</th>
                      <th>status</th>
                      <th>payment method</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getPaymentHistory?.map((info) => {
                      return (
                        <tr>
                          <td>
                            {moment(new Date(info.transactionDate)).format(
                              "MMM Do YYYY"
                            )}
                          </td>
                          <td>{info.amount}</td>
                          <td>{info.status}</td>
                          <td>{info.paymentMethod}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <TableSpinner />
              )}
            </div>

            {getPaymentHistory.length > 0 && (
              <section className="pagination-section">
                <PaginationComponent
                  currentPage={currentPage}
                  entries={entries}
                  filteredDataLength={filteredData}
                  handlePagination={handlePagination}
                  renderPageNumbers={renderPageNumbers}
                />
              </section>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportComponent;
