import React from "react";
import { TableSpinner } from "../../Spinner";
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import styled from "styled-components";
import { TableHeaderText, TableTimeText } from "../../TextCompnents";
import SubmitButtonComponent from "../../SubmitButton";

import "./style.css";

const JinglesRate = ({
  tabIndex,
  inventoryValues,
  updateSlotLoading,
  loading,
  slotData,
  setInventoryValues,
  //   handleChange,
  //   handleChangeWords,
  updateSlot,
}) => {
  const toast = useToast();

  function tConvert(date) {
    const obj = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return new Date(date).toLocaleTimeString("en-US", obj);
  }

  const submitSlot = () => {
    updateSlot({
      variables: {
        input: [...inventoryValues],
      },
      onCompleted: () => {
        toast({
          title: `Update Successful`,
          status: "success",
          isClosable: true,
          position: "top",
        });
      },
      onError: () => {
        toast({
          title: `An Error Occurred during update`,
          status: "error",
          isClosable: true,
          position: "top",
        });
      },
    });
  };
  const jingelsType = ["15 Seconds", "30 Seconds", "45 Seconds", "60 Seconds"];
  const annoucementType = ["50 Words", "75 Words", "100 Words"];

  const handleChange = (e, id, time) => {
    const inputValue = Number(e.target.value);
    const newInventoryState = inventoryValues.map((slot) => {
      if (slot.id === id) {
        switch (time) {
          case 15:
            slot.Jingle15SecPrice = inputValue;
            break;
          case 30:
            slot.Jingle30SecPrice = inputValue;
            break;
          case 45:
            slot.Jingle45SecPrice = inputValue;
            break;
          case 60:
            slot.Jingle60SecPrice = inputValue;
            break;
          default:
            break;
        }
      }
      return { ...slot };
    });
    setInventoryValues([...newInventoryState]);
  };
  const handleChangeWords = (e, id, words) => {
    const inputValue = Number(e.target.value);
    const newInventoryState = inventoryValues.map((slot) => {
      if (slot.id === id) {
        switch (words) {
          case 50:
            slot.Ann50WordsPrice = inputValue;
            break;
          case 75:
            slot.Ann75WordsPrice = inputValue;
            break;
          case 100:
            slot.Ann100WordsPrice = inputValue;
            break;
          default:
            break;
        }
      }
      return { ...slot };
    });
    setInventoryValues([...newInventoryState]);
  };

  let selectedTableData;
  if (tabIndex === 1) {
    selectedTableData = jingelsType;
  }
  if (tabIndex === 2) selectedTableData = annoucementType;
  return (
    <div className="">
      <InventoryContainer>
        <div>
          <TableContainer overflowY="scroll">
            {!loading ? (
              <Table>
                <Thead>
                  <Tr>
                    <Th> </Th>
                    {selectedTableData.map((rate, index) => (
                      <Th key={index}>
                        <TableHeaderText>{rate}</TableHeaderText>
                      </Th>
                    ))}
                  </Tr>
                </Thead>

                <Tbody>
                  {slotData.map((slot) => {
                    return (
                      <Tr key={slot.id}>
                        {/* <div className="price-field-wrapper"> */}
                        <td>
                          <Box bg={{ sm: "#004643" }} p={{ sm: "16px 5px" }}>
                            <TableTimeText>
                              {tConvert(slot.startTime)}-
                              {tConvert(slot.endTime)}
                            </TableTimeText>
                          </Box>
                        </td>
                        <td>
                          {tabIndex === 1 && (
                            <TableInput
                              type="number"
                              className="price-input-field"
                              name="Jingle15SecPrice"
                              onChange={(e) => handleChange(e, slot.id, 15)}
                              defaultValue={slot.Jingle15SecPrice}
                              // value={slot.Jingle15SecPrice || 0}
                            />
                          )}
                          {tabIndex === 2 && (
                            <TableInput
                              type="text"
                              className="price-input-field"
                              name="Ann50WordsPrice"
                              onChange={(e) =>
                                handleChangeWords(e, slot.id, 50)
                              }
                              defaultValue={slot.Ann50WordsPrice}
                              // value={slot.Jingle15SecPrice || 0}
                            />
                          )}
                        </td>
                        <td>
                          {tabIndex === 1 && (
                            <TableInput
                              type="text"
                              className="price-input-field"
                              name="Jingle30SecPrice"
                              onChange={(e) => handleChange(e, slot.id, 30)}
                              defaultValue={slot.Jingle30SecPrice}
                            />
                          )}
                          {tabIndex === 2 && (
                            <TableInput
                              type="text"
                              className="price-input-field"
                              name="Ann75WordsPrice"
                              onChange={(e) =>
                                handleChangeWords(e, slot.id, 75)
                              }
                              defaultValue={slot.Ann75WordsPrice}
                            />
                          )}
                        </td>
                        <td>
                          {tabIndex === 1 && (
                            <TableInput
                              type="text"
                              className="price-input-field"
                              name="Jingle45SecPrice"
                              onChange={(e) => handleChange(e, slot.id, 45)}
                              defaultValue={slot.Jingle45SecPrice}
                            />
                          )}
                          {tabIndex === 2 && (
                            <TableInput
                              type="text"
                              className="price-input-field"
                              name="Ann100WordsPrice"
                              onChange={(e) =>
                                handleChangeWords(e, slot.id, 100)
                              }
                              defaultValue={slot.Ann100WordsPrice}
                            />
                          )}
                        </td>

                        {tabIndex === 1 && (
                          <td>
                            <TableInput
                              type="text"
                              className="price-input-field"
                              name="Jingle60SecPrice"
                              onChange={(e) => handleChange(e, slot.id, 60)}
                              defaultValue={slot.Jingle60SecPrice}
                            />
                          </td>
                        )}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            ) : (
              <TableSpinner />
            )}
          </TableContainer>
        </div>
        <div className="save-btn jingles-btn">
          <button onClick={() => submitSlot()}>
            <SubmitButtonComponent updateSlotLoading={updateSlotLoading} />
          </button>
        </div>
      </InventoryContainer>
    </div>
  );
};

export default JinglesRate;

const InventoryContainer = styled.div`
  min-width: 359px;
  height: 542px;
  overflow-x: scroll;
  background: #ffffff;
  border: 0.8px solid #abd1c6;

  @media (min-width: 960px) {
    max-width: 1124px;
    height: 703px;
  }
`;

const TableInput = styled.input`
  width: 182px;
  height: 44px;
  border: 0.8px solid rgba(0, 70, 67, 0.7);
  border-radius: 0px 2px 2px 0px;
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
`;
