import React from "react";
import moment from "moment";
import styled from "styled-components";
import { fmtCurrency } from "../../../utils/functions";
import {
  AdvertTypeIcon,
  ChevronRight,
  DaysIcon,
  DollarIcon,
  DurationIcon,
  EndDateIcon,
  LanguageIcon,
  OrderIcon,
  PaymentIcon,
  SpotIcon,
  StartDateIcon,
  StatusIcon,
} from "../../icons";

const BookingInfo = ({
  campaignId,
  campaignDetails,
  viewBookedSlotHandler,
  viewBookedSlotButton,
}) => {
  // console.log("campaignDetails?.bookedSlots?.startDate",campaignDetails)
  return (
    <StationDetailsCampaignWrapper>
      <StationDetailsCampaignHeader>
        <div>
          <OrderIcon />
          <BookingHeaderText>Order ID: {campaignId}</BookingHeaderText>
        </div>
        <div>
          <StatusIcon />
          Status:{" "}
          <CampaignStatusWrapper>
            {campaignDetails?.status}
          </CampaignStatusWrapper>
        </div>
        <div>
          <StartDateIcon />
          <BookingHeaderText>
            Start Date:{" "}
            {moment(new Date(campaignDetails?.bookedSlots?.startDate)).format(
              "MMM Do YYYY"
            )}
          </BookingHeaderText>
        </div>
        <div>
          <EndDateIcon />
          <BookingHeaderText>
            End Date:{" "}
            {moment(new Date(campaignDetails?.bookedSlots?.endDate)).format(
              "MMM Do YYYY"
            )}
          </BookingHeaderText>
        </div>
        {viewBookedSlotButton && (
          <ViewSlotContainer onClick={viewBookedSlotHandler}>
            <ViewSlotText>View Booked Slots</ViewSlotText>
            <ChevronRight />
          </ViewSlotContainer>
        )}
      </StationDetailsCampaignHeader>
      <BookingDetailsContainer>
        <>
          <StationCardType>
            <AdvertTypeIcon />
            <span>Adert Type: {campaignDetails?.advertType}</span>
          </StationCardType>
          <StationCardType>
            <DurationIcon />
            <span>Duration: {campaignDetails?.duration}</span>
          </StationCardType>
          <StationCardType>
            <SpotIcon />
            <span>Number of Spot: {campaignDetails?.numberOfSpots}</span>
          </StationCardType>

          <StationCardType>
            <LanguageIcon />
            <span>Language: {campaignDetails?.language}</span>
          </StationCardType>
          <StationCardType>
            <DaysIcon />
            <span>Number of Days: {campaignDetails?.numberOfDays}</span>
          </StationCardType>

          <StationCardType>
            <DollarIcon />
            <span>
              Total amount:{fmtCurrency(campaignDetails?.totalAmount)}
            </span>
          </StationCardType>
          <StationCardType>
            <PaymentIcon />
            <span>Payment Method: {campaignDetails?.paymentMethod}</span>
          </StationCardType>
        </>
      </BookingDetailsContainer>
    </StationDetailsCampaignWrapper>
  );
};

export default BookingInfo;

const StationDetailsCampaignWrapper = styled.div`
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  max-width: 1100px;
  width: 100%;
`;

const StationDetailsCampaignHeader = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 20px;
  padding: 30px;
  border-bottom: 0.8px solid #abd1c6;
  div {
    display: flex;
    align-items: center;
    gap: 6px;
    white-space: nowrap;
  }

  @media (min-width: 768px) {
    padding-left: 65px;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
  }
`;

const CampaignStatusWrapper = styled.span`
  color: #2f8904;
`;

const BookingHeaderText = styled.p`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #004643;
`;

const ViewSlotContainer = styled.div`
  width: 169px;
  height: 38px;
  border: 1px solid #f99b28;
  border-radius: 1.50209px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ViewSlotText = styled.p`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #004643;
`;
const BookingDetailsContainer = styled.div`
  padding: 40px 0px;
  padding-left: 30px;

  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  @media (min-width: 768px) {
    padding: 40px 60px;
    gap: 30px;
  }
`;
const StationCardType = styled.div`
  padding: 10px 0px;
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border: 1px solid #004643;
  border-radius: 2px;
  white-space: nowrap;
  & span {
    font-size: 14px;
    line-height: 20px;
  }
`;
