import React from "react";

const DollarIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 1.04102V23.9577"
        stroke="#F99B28"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.7083 5.20898H9.89583C8.9289 5.20898 8.00157 5.5931 7.31784 6.27682C6.63411 6.96055 6.25 7.88788 6.25 8.85482C6.25 9.82175 6.63411 10.7491 7.31784 11.4328C8.00157 12.1165 8.9289 12.5007 9.89583 12.5007H15.1042C16.0711 12.5007 16.9984 12.8848 17.6822 13.5685C18.3659 14.2522 18.75 15.1795 18.75 16.1465C18.75 17.1134 18.3659 18.0408 17.6822 18.7245C16.9984 19.4082 16.0711 19.7923 15.1042 19.7923H6.25"
        stroke="#F99B28"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DollarIcon;
