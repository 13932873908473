import React from 'react';
import '../css/logo.css';

const Logo = ({ textColor, favColor })=> {
    return (
        <div id="logo">
            <svg width="146" height="32" viewBox="0 0 146 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M88.5494 17.6342C88.225 17.3221 87.8564 17.0552 87.4542 16.8412C87.0933 16.667 86.6932 16.58 86.289 16.588C85.9548 16.5907 85.6304 16.6963 85.3639 16.889C85.2245 16.9714 85.1098 17.0868 85.0309 17.2241C84.952 17.3614 84.9117 17.5158 84.9138 17.6724C84.9138 18.1501 85.2438 18.4988 85.914 18.7663C86.5841 19.0339 87.2692 19.3109 87.9743 19.5641C88.6897 19.8405 89.3245 20.2781 89.8246 20.8396C90.3849 21.5938 90.652 22.5112 90.5797 23.4335C90.6189 24.0529 90.5161 24.673 90.2786 25.2505C90.041 25.8281 89.6745 26.3492 89.2045 26.7774C88.2393 27.5852 86.986 28.0077 85.7039 27.9574C84.8951 27.9593 84.0924 27.8234 83.3335 27.5561C82.5744 27.296 81.8821 26.8839 81.3032 26.3475L83.1885 23.7727C83.4677 24.1253 83.8315 24.4087 84.2492 24.599C84.6669 24.7894 85.1263 24.8812 85.5889 24.8666C85.9906 24.8644 86.3837 24.7552 86.7241 24.5513C86.8907 24.4647 87.0292 24.336 87.1244 24.1793C87.2197 24.0226 87.268 23.844 87.2642 23.6628C87.2642 23.1851 86.9341 22.8268 86.264 22.5498L85.2388 22.1389L84.7388 21.9383L84.2037 21.7233C83.4931 21.4459 82.8634 21.0083 82.3684 20.4479C81.8047 19.7221 81.5331 18.8268 81.6033 17.9256C81.5842 17.1296 81.814 16.3462 82.2634 15.6756C82.6912 15.0586 83.2856 14.5634 83.9836 14.2425C84.6805 13.9166 85.4482 13.7529 86.224 13.7648C86.9676 13.7657 87.7041 13.9019 88.3943 14.166C89.0871 14.4336 89.7214 14.8225 90.2646 15.3125L88.5494 17.6342Z" fill={textColor} />
                <path d="M91.0846 22.6453C91.0764 21.7092 91.3583 20.7917 91.8948 20.0084C92.431 19.2085 93.18 18.559 94.0651 18.1262C94.8489 17.728 95.7132 17.4954 96.5993 17.4443C97.4854 17.3932 98.3727 17.5248 99.2009 17.83C99.9054 18.0724 100.552 18.4477 101.101 18.9335C101.65 19.4062 102.094 19.9797 102.406 20.6198C102.716 21.276 102.876 21.9876 102.876 22.7074C102.876 23.4272 102.716 24.1388 102.406 24.795C102.1 25.4343 101.659 26.0066 101.111 26.4765C100.56 26.9607 99.9145 27.3358 99.2109 27.58C97.773 28.077 96.1982 28.077 94.7602 27.58L94.5302 27.4845L94.3002 27.3889V31.4399H91.0947L91.0846 22.6453ZM94.2902 22.7122C94.2834 23.1489 94.4122 23.5778 94.6602 23.9447C94.9078 24.3208 95.2521 24.63 95.6604 24.8428C96.0593 25.0657 96.5134 25.1828 96.9756 25.1819C97.4409 25.1822 97.898 25.0652 98.3008 24.8428C98.71 24.6312 99.0546 24.3217 99.301 23.9447C99.5494 23.5777 99.6798 23.1492 99.676 22.7122C99.677 22.3811 99.606 22.0534 99.4674 21.75C99.3288 21.4465 99.1256 21.1739 98.8709 20.9495C98.369 20.4751 97.6916 20.2091 96.9856 20.2091C96.2796 20.2091 95.6021 20.4751 95.1003 20.9495C94.8431 21.1724 94.6381 21.4446 94.4985 21.7483C94.3589 22.0521 94.2879 22.3805 94.2902 22.7122Z" fill={textColor} />
                <path d="M108.648 20.3906C108.451 20.2735 108.225 20.2106 107.993 20.2091H107.813C106.998 20.2425 106.588 20.7823 106.588 21.838V27.6565H103.372V21.838C103.372 19.0451 104.852 17.5769 107.813 17.4336H107.993C108.218 17.4336 108.433 17.4623 108.648 17.4909V20.3906Z" fill={textColor} />
                <path d="M119.994 20.6007C120.295 21.2538 120.448 21.9603 120.444 22.674V22.76C120.446 22.795 120.446 22.8301 120.444 22.8651V23.0322H119.169H112.268C112.36 23.6166 112.667 24.1506 113.133 24.539C113.599 24.9275 114.194 25.1452 114.813 25.1533C115.15 25.1561 115.485 25.0928 115.795 24.967C116.106 24.8413 116.386 24.6559 116.618 24.4224L116.648 24.3842L116.678 24.3555V24.3268L118.404 26.6867C117.952 27.048 117.446 27.3427 116.903 27.5609C116.225 27.816 115.502 27.9457 114.773 27.9431C113.806 27.9428 112.856 27.706 112.013 27.2552C111.155 26.8051 110.437 26.1458 109.932 25.3443C109.481 24.6315 109.215 23.8255 109.158 22.9942C109.1 22.1629 109.253 21.3306 109.602 20.5673C109.895 19.9371 110.319 19.37 110.847 18.9001C111.369 18.4222 111.984 18.0473 112.658 17.7966C114.03 17.2807 115.556 17.2807 116.928 17.7966C117.615 18.051 118.24 18.4344 118.769 18.924C119.293 19.3971 119.71 19.9677 119.994 20.6007ZM114.798 20.2186C114.354 20.2175 113.917 20.3296 113.533 20.5434C113.265 20.7042 113.027 20.9059 112.828 21.1405H116.768C116.565 20.9166 116.327 20.7233 116.063 20.5673C115.681 20.3462 115.245 20.2259 114.798 20.2186Z" fill={textColor} />
                <path d="M145.023 22.76C145.026 23.6974 144.742 24.6152 144.208 25.4017C143.672 26.2014 142.923 26.8509 142.038 27.2839C141.253 27.6791 140.389 27.91 139.503 27.961C138.618 28.0121 137.731 27.8822 136.902 27.58C136.2 27.3328 135.555 26.9581 135.002 26.4765C134.454 26.0006 134.011 25.4259 133.697 24.7855C133.39 24.1285 133.231 23.4172 133.231 22.6979C133.231 21.9786 133.39 21.2673 133.697 20.6103C134.004 19.9727 134.445 19.4021 134.992 18.9336C135.546 18.4533 136.191 18.0787 136.892 17.8301C137.606 17.5796 138.362 17.4518 139.122 17.4527C139.88 17.4515 140.632 17.5792 141.343 17.8301L141.578 17.9256L141.808 18.0212V13.9702H145.013L145.023 22.76ZM141.818 22.6931C141.821 22.2577 141.691 21.8309 141.443 21.4654C141.196 21.0875 140.852 20.7766 140.443 20.5625C140.041 20.3411 139.586 20.2257 139.122 20.2282C138.658 20.2269 138.201 20.3422 137.797 20.5625C137.39 20.7785 137.046 21.089 136.797 21.4654C136.553 21.8323 136.425 22.2585 136.427 22.6931C136.423 23.0253 136.492 23.3547 136.631 23.6593C136.77 23.964 136.975 24.2371 137.232 24.4606C137.733 24.9347 138.41 25.2006 139.115 25.2006C139.82 25.2006 140.497 24.9347 140.998 24.4606C141.256 24.2369 141.462 23.9641 141.603 23.6597C141.745 23.3553 141.818 23.0261 141.818 22.6931Z" fill={textColor} />
                <path d="M132.271 20.5909C131.957 19.9576 131.513 19.3908 130.966 18.9238C130.418 18.4422 129.776 18.0702 129.075 17.8298C128.362 17.5737 127.607 17.4426 126.845 17.4429C126.082 17.4422 125.326 17.5767 124.615 17.8394C123.916 18.0778 123.273 18.4464 122.724 18.9238C122.179 19.3904 121.739 19.9575 121.429 20.5909C121.069 21.3521 120.911 22.1868 120.97 23.0203C121.03 23.8539 121.304 24.6605 121.769 25.368C122.306 26.1649 123.051 26.815 123.93 27.2549C124.826 27.7045 125.823 27.9374 126.835 27.9333C127.598 27.9364 128.354 27.8036 129.065 27.5416C129.165 27.5081 129.326 27.4508 129.536 27.36V27.6467L132.721 27.6753V22.6737C132.732 21.9564 132.578 21.2455 132.271 20.5909ZM129.541 22.4492C129.618 23.0889 129.447 23.7335 129.061 24.261C128.674 24.7885 128.099 25.1624 127.443 25.3118C126.787 25.4613 126.097 25.376 125.503 25.072C124.908 24.7681 124.451 24.2666 124.218 23.6625C123.985 23.0583 123.992 22.3935 124.237 21.7937C124.482 21.1939 124.949 20.7009 125.549 20.4078C126.15 20.1148 126.841 20.0421 127.494 20.2034C128.147 20.3648 128.715 20.7491 129.09 21.2836L130.126 21.1833L129.446 21.9858C129.488 22.1376 129.517 22.2926 129.531 22.4492H129.541Z" fill={textColor} />
                <path d="M0 27.7324V14.0318H4.00063C5.01832 14.0169 6.03175 14.1605 7.00111 14.457C7.8015 14.7005 8.50134 15.1785 9.00142 15.8232C9.45559 16.5015 9.7048 17.2864 9.72157 18.0915C9.73834 18.8965 9.52201 19.6903 9.09644 20.3853C8.73046 21.0114 8.14924 21.4974 7.45118 21.7611L11.0217 27.761H6.89609L3.57056 21.6034H3.53056V27.7324H0ZM3.53056 20.7818C4.0394 20.7982 4.54727 20.7286 5.0308 20.5764C5.39662 20.4613 5.71283 20.2347 5.93094 19.9315C6.15447 19.5882 6.2659 19.189 6.25099 18.785C6.2816 18.5109 6.23574 18.2339 6.11804 17.9822C6.00035 17.7305 5.81502 17.513 5.58088 17.3519C4.9599 17.0611 4.27105 16.9278 3.58057 16.9649L3.53056 20.7818Z" fill={textColor} />
                <path d="M18.9278 27.4844C18.7128 27.5752 18.5527 27.6325 18.4277 27.6659C17.7086 27.9319 16.9435 28.0664 16.1724 28.0624C15.1546 28.0648 14.1518 27.8286 13.2519 27.3745C12.3585 26.9328 11.6029 26.2737 11.0615 25.4637C10.5997 24.7472 10.3298 23.933 10.2757 23.0931C10.2217 22.2533 10.3851 21.4136 10.7515 20.6485C11.0635 20.0083 11.5074 19.4348 12.0567 18.9622C12.6113 18.4826 13.2585 18.111 13.962 17.8682C14.6798 17.6036 15.443 17.4692 16.2124 17.4717C16.9823 17.471 17.7462 17.602 18.4677 17.8587C19.1719 18.1045 19.8191 18.4794 20.373 18.9622C20.925 19.4347 21.3722 20.008 21.6882 20.6485C22.0089 21.3081 22.1712 22.0279 22.1633 22.7551V27.8045L18.9528 27.771L18.9278 27.4844ZM13.867 21.5227C13.6197 21.8954 13.4896 22.3281 13.4919 22.7695C13.4902 23.1025 13.5613 23.4322 13.7008 23.7374C13.8403 24.0426 14.0451 24.3166 14.3021 24.5417C14.8123 25.0137 15.4946 25.2775 16.2049 25.2775C16.9151 25.2775 17.5974 25.0137 18.1077 24.5417C18.3646 24.3166 18.5694 24.0426 18.7089 23.7374C18.8484 23.4322 18.9196 23.1025 18.9178 22.7695C18.9202 22.3281 18.79 21.8954 18.5427 21.5227C18.2948 21.1432 17.9508 20.8294 17.5426 20.6102C17.1338 20.397 16.6756 20.2852 16.2099 20.2852C15.7441 20.2852 15.2859 20.397 14.8771 20.6102C14.4652 20.8281 14.1177 21.142 13.867 21.5227Z" fill={textColor} />
                <path d="M34.5507 22.8363C34.5518 23.7809 34.2651 24.7055 33.7256 25.4971C33.187 26.3093 32.4308 26.9691 31.5353 27.4079C30.7451 27.8084 29.8743 28.0424 28.9815 28.0943C28.0887 28.1463 27.1946 28.0148 26.3595 27.7089C25.6503 27.4625 24.9993 27.0842 24.4442 26.5958C23.8859 26.1109 23.4368 25.5225 23.1239 24.8666C22.814 24.2045 22.6538 23.4875 22.6538 22.7623C22.6538 22.0371 22.814 21.32 23.1239 20.658C23.4327 20.012 23.8789 19.4344 24.4342 18.9621C24.9902 18.475 25.6409 18.0968 26.3495 17.8491C27.803 17.3434 29.3966 17.3434 30.8502 17.8491L31.0802 17.9446L31.3152 18.0402V13.9653H34.5507V22.8363ZM31.3152 22.7694C31.3195 22.3294 31.1892 21.8977 30.9402 21.5274C30.6922 21.1479 30.3483 20.8341 29.94 20.615C29.5371 20.3934 29.0798 20.278 28.6148 20.2806C28.1452 20.2786 27.6832 20.3939 27.2746 20.615C26.8596 20.8302 26.5086 21.1425 26.2544 21.5226C26.0055 21.8929 25.8751 22.3246 25.8794 22.7647C25.8775 23.0989 25.9491 23.4297 26.0896 23.7358C26.23 24.0419 26.4361 24.3165 26.6945 24.5417C27.1989 25.0225 27.8823 25.2924 28.5948 25.2924C29.3073 25.2924 29.9907 25.0225 30.4951 24.5417C30.7538 24.3174 30.9605 24.0438 31.1018 23.7386C31.243 23.4333 31.3157 23.1032 31.3152 22.7694Z" fill={textColor} />
                <path d="M61.2845 25.3442H55.5386L54.1284 27.7805H50.4878L58.414 13.8506L66.3353 27.7805H62.6947L61.2845 25.3442ZM59.8243 22.8076L58.414 20.3665L56.9988 22.8076H59.8243Z" fill={textColor} />
                <path d="M77.4021 22.8363C77.4073 23.7808 77.1221 24.706 76.582 25.4971C76.0434 26.3093 75.2871 26.9691 74.3917 27.4079C73.6006 27.8085 72.729 28.0426 71.8354 28.0945C70.9417 28.1464 70.0468 28.0149 69.2108 27.7089C68.5027 27.4604 67.8521 27.0823 67.2955 26.5958C66.7403 26.1094 66.2931 25.5213 65.9803 24.8666C65.6673 24.2053 65.5054 23.488 65.5054 22.7623C65.5054 22.0366 65.6673 21.3192 65.9803 20.658C66.289 20.0132 66.7333 19.4359 67.2855 18.9621C67.8424 18.476 68.4929 18.0979 69.2008 17.8491C70.6544 17.3434 72.248 17.3434 73.7016 17.8491L73.9366 17.9446L74.1716 18.0402V13.9653H77.4021V22.8363ZM74.1716 22.7694C74.1744 22.3289 74.0423 21.8972 73.7916 21.5274C73.5439 21.1476 73.1999 20.8338 72.7914 20.615C72.3866 20.3934 71.9277 20.2781 71.4612 20.2806C70.9932 20.2786 70.5328 20.3939 70.126 20.615C69.7177 20.8341 69.3738 21.1479 69.1258 21.5274C68.8769 21.8977 68.7465 22.3294 68.7508 22.7694C68.7483 23.1033 68.8191 23.4339 68.9587 23.74C69.0982 24.0461 69.3034 24.3208 69.5609 24.5465C70.0653 25.0272 70.7487 25.2972 71.4612 25.2972C72.1737 25.2972 72.8571 25.0272 73.3615 24.5465C73.619 24.3208 73.8242 24.0461 73.9637 23.74C74.1033 23.4339 74.1741 23.1033 74.1716 22.7694Z" fill={textColor} />
                <path d="M50.3437 20.6483C50.0325 20.0009 49.5867 19.4206 49.0335 18.9429C48.4783 18.457 47.8271 18.0819 47.1182 17.8394C45.667 17.3211 44.0687 17.3211 42.6175 17.8394C41.9107 18.0905 41.2606 18.4683 40.7022 18.9524C40.15 19.4274 39.7043 20.0043 39.392 20.6483C39.0311 21.4213 38.8755 22.2674 38.9392 23.1116C39.0029 23.9558 39.2838 24.7719 39.757 25.4874C40.2979 26.2979 41.0536 26.9571 41.9474 27.3983C42.7384 27.7988 43.61 28.0329 44.5037 28.0848C45.3973 28.1367 46.2922 28.0052 47.1282 27.6992C47.835 27.4481 48.4851 27.0703 49.0435 26.5862C49.5984 26.1042 50.0443 25.519 50.3537 24.8664C50.6636 24.2027 50.8237 23.4841 50.8237 22.7574C50.8237 22.0306 50.6636 21.312 50.3537 20.6483H50.3437ZM47.5983 22.4492C47.6793 23.0923 47.5106 23.7413 47.1242 24.2735C46.7378 24.8057 46.1606 25.1841 45.5018 25.337C44.8429 25.4899 44.1483 25.4067 43.5494 25.1032C42.9504 24.7997 42.4888 24.2969 42.2519 23.6901C42.015 23.0833 42.0192 22.4145 42.2638 21.8105C42.5084 21.2065 42.9764 20.7091 43.5792 20.4126C44.1819 20.116 44.8776 20.0409 45.5344 20.2014C46.1912 20.362 46.7636 20.747 47.1432 21.2836L48.1734 21.1833L47.4933 21.9859C47.5429 22.137 47.578 22.2921 47.5983 22.4492Z" fill={textColor} />
                <path d="M37.4807 6.80469L38.4108 27.795H35.1753L36.1054 6.81424L37.4807 6.80469Z" fill={favColor} />
                <path d="M34.7161 26.5H38.7567C39.1161 26.5 39.4608 26.6364 39.715 26.8792C39.9691 27.122 40.1119 27.4512 40.1119 27.7946H33.3608C33.3608 27.4512 33.5036 27.122 33.7578 26.8792C34.0119 26.6364 34.3566 26.5 34.7161 26.5Z" fill={favColor} />
                <path d="M36.7562 0.618196C35.8849 0.61563 35.0217 0.778119 34.2165 1.09627C33.4114 1.41441 32.6802 1.8819 32.0654 2.47169L32.8556 3.23124C33.3668 2.73944 33.9749 2.34913 34.6448 2.08278C35.3147 1.81643 36.0331 1.6793 36.7587 1.6793C37.4843 1.6793 38.2027 1.81643 38.8725 2.08278C39.5424 2.34913 40.1505 2.73944 40.6618 3.23124L41.4519 2.47169C40.8362 1.88176 40.1042 1.41422 39.2982 1.09608C38.4922 0.777948 37.6282 0.615525 36.7562 0.618196Z" fill={favColor} />
                <path d="M33.6255 3.98638L34.4206 4.74593C34.7248 4.45489 35.0861 4.22399 35.4839 4.06645C35.8816 3.90892 36.3079 3.82783 36.7385 3.82783C37.169 3.82783 37.5954 3.90892 37.9931 4.06645C38.3908 4.22399 38.7521 4.45489 39.0563 4.74593L39.8465 3.98638C39.0206 3.19992 37.9021 2.7583 36.736 2.7583C35.5699 2.7583 34.4513 3.19992 33.6255 3.98638Z" fill={favColor} />
                <path d="M36.7366 6.36968C37.3608 6.36968 37.8668 5.88632 37.8668 5.29006C37.8668 4.69381 37.3608 4.21045 36.7366 4.21045C36.1124 4.21045 35.6064 4.69381 35.6064 5.29006C35.6064 5.88632 36.1124 6.36968 36.7366 6.36968Z" fill={favColor} />
            </svg>
        </div>
    )
}

export default Logo;