import React, { useState, useRef } from "react";
import { Formik, Field, Form } from "formik";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Popover,
  PopoverTrigger,
  PopoverContent,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import { MdClose, MdExpandMore } from "react-icons/md";
import "./addpermission.css";
import { Roles } from "../../../constant";

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const AddPermission = ({ openModal, closeModal, isNew, user }) => {
  const [role, setRole] = useState("");
  const firstFieldRef = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const HandleSelected = (id) => {
    setRole(id);
    onClose();
  };

  return (
    <div>
      <Modal onClose={closeModal} isOpen={openModal} isCentered>
        <ModalOverlay />
        <ModalContent padding="0px">
          <ModalHeader margin="0">
            <div
              className={`${
                isNew === true
                  ? "permission_wrapper_head"
                  : "permission_wrapper_headed"
              }`}
            >
              {isNew && (
                <div className="permission_txt">
                  <p>Add a new role</p>
                </div>
              )}

              <div onClick={closeModal}>
                <MdClose className="permission_close_icon" />
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <>
              {!isNew && (
                <div className="permission_txts">
                  <p>Add new permission</p>
                </div>
              )}

              <Formik
                initialValues={{
                  user: user || "",
                  role: "",
                  permissions: [],
                }}
                onSubmit={async (values) => {
                  await sleep(500);
                  alert(JSON.stringify(values, null, 2));
                }}
              >
                {({ values }) => (
                  <Form>
                    {isNew && (
                      <div className="permission_input">
                        <label htmlFor="user" className="permission_label">
                          Users Name
                        </label>
                        <Field
                          id="user"
                          name="user"
                          placeholder="Type users name"
                          className="permission_field"
                        />
                      </div>
                    )}

                    <div className="permission_input">
                      <label htmlFor="role" className="permission_label">
                        Select role
                      </label>

                      <Popover
                        isOpen={isOpen}
                        initialFocusRef={firstFieldRef}
                        onOpen={onOpen}
                        onClose={onClose}
                        closeOnBlur={false}
                        placement="bottom"
                      >
                        <PopoverTrigger>
                          <div className="permission_dropdown_wrapper">
                            <div className="permission_dropdown_txt">
                              {role || "Select a new role"}
                            </div>
                            <div className="permission_dropdown">
                              <MdExpandMore />
                            </div>
                          </div>
                        </PopoverTrigger>
                        <PopoverContent
                          maxW="100%"
                          justifyContent="flex-start"
                          placement="right"
                          p="0"
                          border="0"
                        >
                          {/* <PopoverBody> */}
                          <div className="permission_popover_cont">
                            {Roles.map(({ key, value }, index) => {
                              return (
                                <div
                                  className="permission_pop_roles"
                                  onClick={() => HandleSelected(key)}
                                  key={index}
                                >
                                  <p>{value}</p>
                                </div>
                              );
                            })}
                          </div>
                          {/* </PopoverBody> */}
                        </PopoverContent>
                      </Popover>
                    </div>

                    <div
                      role="group"
                      aria-labelledby="checkbox-group"
                      className="permision_group_btn"
                    >
                      <label>
                        <Field
                          type="checkbox"
                          name="permissions"
                          value="1"
                          className="custom-checkbox"
                        />
                        <span className="permission_radio">Edit</span>
                      </label>
                      <label>
                        <Field
                          type="checkbox"
                          name="permissions"
                          value="2"
                          className="custom-checkbox"
                        />

                        <span className="permission_radio">List</span>
                      </label>
                      <label>
                        <Field
                          type="checkbox"
                          name="permissions"
                          value="3"
                          className="custom-checkbox"
                        />
                        <span className="permission_radio">Delete</span>
                      </label>
                      <label>
                        <Field
                          type="checkbox"
                          name="permissions"
                          value="4"
                          className="custom-checkbox"
                        />
                        <span className="permission_radio">View</span>
                      </label>
                      <label>
                        <Field
                          type="checkbox"
                          name="permissions"
                          value="5"
                          className="custom-checkbox"
                        />
                        <span className="permission_radio">Action</span>
                      </label>
                    </div>

                    <div className="permission_footer">
                      <button onClick={onClose} type="submit">
                        Save
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AddPermission;
