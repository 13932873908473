import React, { useState } from "react";
import AdminDashboardLayout from "./AdminDashboardLayout";
import {
  GET_ALL_ADMIN_ADVERTISER_TRANSACTIONS,
  GET_ALL_ADMIN_RADIO_VENDOR_TRANSACTIONS,
} from "../components/GraphQL/Queries";
import { useQuery } from "@apollo/client";
import {
  Center,
  ChakraProvider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { TableSpinner } from "../components/Spinner";
import "./css/admin_transaction.css";
import AdminTransactionTable from "../components/AdminTransactionTable/AdminTransactionTable";

const AdminTransaction = () => {
  const tabNames = ["Radio Vendor", "Advertiser"];
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (index) => {
    setSelectedTab(index);
  };

  // console.log("Active tab:", tabNames[selectedTab]);
  const { data, loading, refetch } = useQuery(
    GET_ALL_ADMIN_RADIO_VENDOR_TRANSACTIONS
  );
  const {
    data: data2,
    loading: loading2,
    refetch: refetch2,
  } = useQuery(GET_ALL_ADMIN_ADVERTISER_TRANSACTIONS);

  const radioStationData = data?.admin?.getAllRadioVendorTransactions;
  const advertiserData = data2?.admin?.getAllAdvertiserTransactions;

  // console.log("data1--", radioStationData);
  // console.log("data2--", advertiserData);

  const tableHeader = [
    {
      title: "RADIO STATION",
    },
    {
      title: "AMOUNT",
    },
    {
      title: "WITHDRAWAL METHOD",
    },
    {
      title: "DATE",
    },
    {
      title: "STATUS",
    },
  ];

  const tableHeader2 = [
    {
      title: "ADVERTISER",
    },
    {
      title: "TRANSACTION ID",
    },
    {
      title: "AMOUNT",
    },
    {
      title: "TRANSACTION DATE",
    },
    {
      title: "PAYMENT METHOD",
    },
    {
      title: "DESCRIPTION",
    },
  ];

  return (
    <AdminDashboardLayout>
      {loading ? (
        <ChakraProvider>
          <Center h="100vh">
            <TableSpinner />
          </Center>
        </ChakraProvider>
      ) : (
        <>
          <div className="Home_Dashboard_wrap">
            {/* TAB SECTION */}
            <div className="tab_wrapper_transaction">
              <Tabs onChange={handleTabChange} isLazy>
                <div
                  style={{
                    overflowX: "scroll",
                    color: "#838383",
                    lineHeight: "0.9",
                    position: "fixed",
                    width: "100%",
                    zIndex: "5",
                  }}
                >
                  <TabList>
                    <Tab
                      _selected={{
                        color: "#005E59",
                        mb: "2px",
                        borderBottom: "2px solid #005E59",
                        fontWeight: "600",
                      }}
                      width={"40%"}
                    >
                      Radio Vendor
                    </Tab>
                    <Tab
                      width={"40%"}
                      _selected={{
                        color: "#005E59",
                        mb: "2px",
                        borderBottom: "2px solid #005E59",
                        fontWeight: "600",
                      }}
                    >
                      Advertiser
                    </Tab>
                  </TabList>
                  <div
                    style={{
                      borderTop: "1px solid #c4c4c4",
                      position: "relative",
                      top: "-0.1rem",
                    }}
                  ></div>
                </div>
                <TabPanels>
                  {/* Radio Vendo panel */}
                  <TabPanel>
                    <div className="panel_wrapper">
                      <AdminTransactionTable
                        header={tableHeader}
                        data={radioStationData || []}
                        tab={tabNames[selectedTab]}
                        loading={loading}
                        refetch={refetch}
                      />
                    </div>
                  </TabPanel>
                  {/*Advertiser Panel */}
                  <TabPanel>
                    <div className="panel_wrapper">
                      <AdminTransactionTable
                        header={tableHeader2}
                        data={advertiserData || []}
                        tab={tabNames[selectedTab]}
                        loading={loading2}
                        refetch={refetch2}
                      />
                    </div>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </div>
          </div>
        </>
      )}
    </AdminDashboardLayout>
  );
};

export default AdminTransaction;
