import React from "react";


const cardWrapperStyle = {
  display: "flex",
  gap: "2rem",
  maxWidth: "270px",
  height: "100px",
  padding: "8px 40px",
  alignItems: "center",
  borderRadius: "4px",
  background: "#fff",
  boxShadow: "0px 4px 40px 0px rgba(171, 209, 198, 0.25)",
};

const nameStyle = {
  color: "rgba(0, 70, 67, 0.6)",
  fontFamily: "Adobe Clean",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  letterSpacing: "0.8px",
};

const figureStyle = {
  color: "#004643",
  fontFamily: "Adobe Clean",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  letterSpacing: "1.2px",
};

const CampaignCard = ({ name, figure, flag }) => {
  return (
    <div style={cardWrapperStyle}>
      <div>{flag}</div>
      <div>
        <div>
          <p style={nameStyle}>{name}</p>
        </div>
        <div>
          <p style={figureStyle}>{figure}</p>
        </div>
      </div>
    </div>
  );
};

export default CampaignCard;
