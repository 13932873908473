import React, { useEffect } from "react";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";

const UserTypeSummary = ({
  userTypeSummaryTag,
  userTypeSummarySubTag,
  isAdvertiser,
  firstLetter,
}) => {
  useEffect(() => {
    Aos.init({
      duration: 2000,
      offset: 0,
    });
  }, []);
  return (
    <div
      className={`userTypeSummaryWrapper ${isAdvertiser && "changeBackground"}`}
      data-aos="zoom-out-up"
    >
      <div className="userTypeSummaryLeft">
        <h1>
          {userTypeSummaryTag.charAt(0).toUpperCase()}
          <span style={{ textTransform: "lowercase" }}>
            {userTypeSummaryTag.slice(1)}
          </span>
        </h1>
      </div>
      <div className="userTypeSummaryRight" data-aos="fade-right">
        <p>
          <span>{firstLetter}</span>
          <span className="lower-text">{userTypeSummarySubTag}</span>
        </p>
      </div>
    </div>
  );
};

export default UserTypeSummary;
