import React, { useState } from "react";

import { Button, ButtonGroup, Flex, Text } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const TransmitPagination = ({
  data,
  isPerginate,
  handleNextOrPrevPage,
  totalPage,
  handlePerPageChange,
  isPerPage,
  currentPage,
  hasMore,
  itemsPerPage,
}) => {
  return (
    <>
      {isPerginate && data?.length > 0 && (
        <Flex alignItems={"center"} fontSize={"14px"} mt={"10px"}>
          <Text
            width={"32px"}
            height={"32px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            border={"0.5px solid #D9D9D9"}
          >
            {currentPage}
          </Text>{" "}
          <span className="lower-text" style={{ paddingLeft: "8px" }}>
            of
          </span>{" "}
          <Text padding={"10px 8px 10px 10px"}>{totalPage}</Text>
          <Flex>
            <ButtonGroup>
              <Button
                onClick={() => handleNextOrPrevPage(currentPage - 1)}
                minW={"24px"}
                p={0}
                m={0}
                variant={"none"}
                isDisabled={currentPage === 1}
              >
                <ChevronLeftIcon boxSize={"24px"} />{" "}
              </Button>
              <Button
                onClick={() => handleNextOrPrevPage(currentPage + 1)}
                minW={"24px"}
                p={0}
                m={0}
                variant={"none"}
                css={{ marginInlineStart: "0 !important" }}
                isDisabled={!hasMore}
              >
                <ChevronRightIcon boxSize={"24px"} />
              </Button>
            </ButtonGroup>
          </Flex>
          {isPerPage && (
            <Flex padding={"0px 8px"} className="perpages">
              <span>Entries per page</span>
              <select
                onChange={(e) => handlePerPageChange(e)}
                value={itemsPerPage}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
};

export default TransmitPagination;
