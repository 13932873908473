import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALLSLOT, GET_DISCOUNT } from "../components/GraphQL/Queries";
import {
  CREATE_VOLUME_DISCOUNT,
  UPDATE_BROADCASTER_SLOT,
} from "../components/GraphQL/Mutation";
import "./css/dashboard-station.css";
import VendorDashbaordLayout from "./ResponsiveAdvertiserDashboard/ResponsiveAdvertiserDashboardLayout/vendorDashboard/VendorDashbaordLayout";
import DiscountRate from "../components/UpdateRadioStationStationPrices/DiscountRate/DiscountRate";
import JinglesRate from "../components/UpdateRadioStationStationPrices/JinglesRate/JinglesRate";

const DashboardStation = () => {
  const stationHeader = [
    "Discounts Rates",
    "Jingles Rates",
    "Paid Announcement Rates",
  ];
  const [pageIndex, setPageIndex] = useState(0);
  const { data: slotData, loading } = useQuery(GET_ALLSLOT);

  const [inventoryValues, setInventoryValues] = useState([]);
  const [updateSlot, { loading: updateSlotLoading }] = useMutation(
    UPDATE_BROADCASTER_SLOT,
    {
      refetchQueries: [
        { query: GET_ALLSLOT }, // DocumentNode object parsed with gql
        "GetAllSlot", // Query name
      ],
    }
  );

  useEffect(() => {
    const allSlotData = slotData?.broadcaster?.getAllSlot ?? [];
    const newSlotData =
      allSlotData.length &&
      allSlotData.map((slot) => {
        const newSlot = { ...slot };
        delete newSlot.broadcasterId;
        delete newSlot.endTime;
        delete newSlot.startTime;
        delete newSlot.__typename;
        return { ...newSlot };
      });
    setInventoryValues(newSlotData);
  }, [slotData]);

  const [createDiscount, { loading: volDiscountLoad }] = useMutation(
    CREATE_VOLUME_DISCOUNT,
    {
      refetchQueries: [
        { query: GET_DISCOUNT }, // DocumentNode object parsed with gql
        "GetDiscount", // Query name
      ],
    }
  );

  const {
    data: {
      broadcaster: { getDiscount = {} } = {},
    } = {},
    loading: getDiscountLoad,
  } = useQuery(GET_DISCOUNT);

  console.log(getDiscount, slotData, '----', inventoryValues)

  return (
    <>
      <VendorDashbaordLayout>
        <h4 className="station-header">edit advert prices</h4>

        <div className="station-container">
          <div className="btn-option-wrapper">
            {stationHeader.map((btn, index) => {
              return (
                <button
                  onClick={() => setPageIndex(index)}
                  className={index === pageIndex ? "bg-color" : ""}
                >
                  {btn}
                </button>
              );
            })}
          </div>
          {pageIndex === 0 && (
            <DiscountRate
              createDiscount={createDiscount}
              getDiscount={getDiscount}
              setInventoryValues={setInventoryValues}
              getDiscountLoad={getDiscountLoad}
            />
          )}
        

        {pageIndex === 1 && (
          <JinglesRate
            inventoryValues={inventoryValues}
            slotData={slotData?.broadcaster.getAllSlot}
            loading={loading}
            updateSlotLoading={updateSlotLoading}
            setInventoryValues={setInventoryValues}
            // handleChange={handleChange}
            updateSlot={updateSlot}
            tabIndex={pageIndex}
          />
        )}
        {pageIndex === 2 && (
          <JinglesRate
            inventoryValues={inventoryValues}
            slotData={slotData?.broadcaster.getAllSlot}
            loading={loading}
            updateSlotLoading={updateSlotLoading}
            setInventoryValues={setInventoryValues}
            // handleChangeWords={handleChangeWords}
            updateSlot={updateSlot}
            tabIndex={pageIndex}
          />
        )}
        </div>
      </VendorDashbaordLayout>
    </>
  );
};

export default DashboardStation;

