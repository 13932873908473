import "./css/login-avp.css";
import { useNavigate } from "react-router-dom";
import { ADVERTISER_LOGIN } from "../components/GraphQL/Mutation";
import { useMutation } from "@apollo/client";
import { AUTH_TOKEN } from "../constant";
import { toast } from "react-toastify";
import { Formik } from "formik";
import logo_black from "../transmitAssets/logo_black.png";

const LoginAdminPortal = () => {
  const [login, { loading }] = useMutation(ADVERTISER_LOGIN);

  const navigate = useNavigate();

  function handleLogin(values) {
    login({
      variables: {
        input: {
          email: values.email,
          password: values.password,
        },
      },
      onCompleted: handleLoginComplete,
      onError(err) {
        toast.error("invalid details");
      },
    });
  }

  function handleLoginComplete(data) {
    // save jwt token
    localStorage.setItem(AUTH_TOKEN, data.auth.login.token);

    navigate("/admin/dashboard");
  }

  return (
    <div className="admin-form-container">
      <img src={logo_black} width="20%" />

      <h2>Admin Login</h2>

      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={handleLogin}
      >
        {({ values, handleSubmit, handleChange, isSubmitting }) => (
          <form className="admin-login-form" onSubmit={handleSubmit}>
            <div className="admin-input-container">
              <label htmlFor="Username" className="admin-label">
                Username
              </label>
              <input
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="John Doe"
                required
                className="admin-login-input"
              />
            </div>

            <div className="admin-input-container">
              <label htmlFor="password" className="admin-label">
                Password
              </label>
              <input
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                placeholder="Enter your Password"
                required
                className="admin-login-input"
              />
            </div>

            <button className="admin-login-button" type="submit">
              {loading ? (
                <i class="fa fa-circle-o-notch fa-spin"></i>
              ) : (
                "Log in"
              )}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default LoginAdminPortal;
