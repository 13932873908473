import React, { useState } from "react";
import "./css/broadcaster.css";
// import components for the broadcaster page
import TransmitNavbar from "../components/TransmitNavbar/Navbars.js";
import UserTypeSummary from "../components/UserTypeSummary/UserTypeSummary.js";
import RadiostationAccountProcess from "../components/TransmitRadioStationProcess/RadiostationAccountProcess.js";
import TransmitObjective from "../components/TransmitObjectives/TransmitObjective.js";
import Partners from "../components/Patners/index.jsx";
import Footer from "../components/Footer/index.jsx";
import RadioStationHero from "../components/RadioStationHero/index.jsx";

const Broadcaster = () => {
  const [a, setA] = useState(false);

  return (
    <div className="broadcaster-wrapper">
      <TransmitNavbar />
      <RadioStationHero />
      <div className="monitize-wrapper">
        <UserTypeSummary
          userTypeSummaryTag="Monetize your goals"
          userTypeSummarySubTag="hether You're A Small, Medium Or Large Radio Station, Instantly Boost Your Ad Revenue With transmit. Our Advanced AI-Driven Platform Ensures That You Have Full Control And Full Disclosure Over Every Order Placed For Your Ad Spot."
          firstLetter="W"
        />
      </div>
      <RadiostationAccountProcess />
      <TransmitObjective />
      <Partners />
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Broadcaster;
