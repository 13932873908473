import React, { useState, useEffect } from "react";
import "./AdminTableCheckbox.css";
import AdminTransactionLoader from "../Loader/AdminTransactionLoader/AdminTransactionLoader";
import moment from "moment";
import SearchIcon from "../AdminTransactionTable/searchIcon";
import editInvoice from "../../assets/dashboardIcons/editInvoice.svg";
import deleteInvoice from "../../assets/dashboardIcons/deleteInvoice.svg";
import exclamationIcon from "../../assets/exclamationIcon.png";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Input,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import { UpdateCampaignScheme } from "../../utils/validations";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  DELETE_ADMIN_CAMPAIGN,
  UPDATE_ADMIN_CAMPAIGN,
} from "../GraphQL/Mutation";
import { useMutation } from "@apollo/client";

const AdminTableCheckbox = ({
  data,
  header,
  loading,
  handleClick,
  refetch,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentPage, setCurrentPage] = useState(1);
  const [entries, setEntries] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [selectedData, setSelectedData] = useState([]);

  const [updateAdminCampaign, { loading: updateCampaignLoading }] = useMutation(
    UPDATE_ADMIN_CAMPAIGN
  );
  const [deleteAdminCampaign, { loading: deleteCampaignLoading }] = useMutation(
    DELETE_ADMIN_CAMPAIGN
  );

  const handlePagination = (action) => {
    if (action === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (action === "next" && data?.length > currentPage * entries) {
      setCurrentPage(currentPage + 1);
    }
  };
  const navigate = useNavigate();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset current page when searching
  };

  const filteredData = data?.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const totalPages = Math.ceil(filteredData?.length / entries);

  const paginatedData = filteredData?.slice(
    (currentPage - 1) * entries,
    currentPage * entries
  );

  // console.log("Selected Data---",selectedData[0]?.id)
  // const renderPageNumbers = () => {
  //   const pageNumbers = [];
  //   for (let i = 1; i <= totalPages; i++) {
  //     pageNumbers.push(
  //       <span
  //         key={i}
  //         className={i === currentPage ? "active" : ""}
  //         onClick={() => handlePageChange(i)}
  //       >
  //         {i}
  //       </span>
  //     );
  //   }
  //   return pageNumbers;
  // };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      // Display first two pages
      if (i <= 2) {
        pageNumbers.push(
          <span
            key={i}
            className={i === currentPage ? "active" : ""}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </span>
        );
      }
      // Display ellipsis for middle section
      else if (i > 2 && i < totalPages - 1 && Math.abs(i - currentPage) > 1) {
        // Display ellipsis only once in the middle section
        if (pageNumbers[pageNumbers.length - 1] !== ".") pageNumbers.push(" .");
      }
      // Display last two pages
      else if (i >= totalPages - 1) {
        pageNumbers.push(
          <span
            key={i}
            className={i === currentPage ? "active" : ""}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </span>
        );
      }
      // Display current and adjacent pages in the middle section
      else {
        pageNumbers.push(
          <span
            key={i}
            className={i === currentPage ? "active" : ""}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </span>
        );
      }
    }

    return pageNumbers;
  };

  const HandleSelected = (id, action) => {
    setSelectedAction(action);
    setSelectedId(id);
    if (action === "edit") {
      const filteredData = data?.filter((item) => item?.id === id);
      setSelectedData(filteredData);
    } else {
      setSelectedData([]);
    }
    onOpen();
  };

  const HandleClose = () => {
    setSelectedAction("");
    setSelectedId("");
    onClose();
  };

  const HandleActionButton = (id) => {
    // console.log(typeof(id))
    handleClick(id);
    deleteAdminCampaign({
      variables: {
        deleteCampaignId: id,
      },
      onCompleted: () => {
        toast.success("Campaign deleted successfully");
        refetch();
        onClose();
      },
      onError(err) {
        // toast.error(err.networkError.result.errors[0].message);
        toast.error("An error occur");
      },
    });
  };

  useEffect(() => {
    if (selectedData !== []) {
      resetForm({
        values: {
          campaignName: selectedData[0]?.campaignName || "",
          companyName: selectedData[0]?.companyName || "",
          industry: selectedData[0]?.industry || "",
        },
      });
    }
  }, [selectedData]);

  const { handleSubmit, handleChange, values, touched, errors, resetForm } =
    useFormik({
      initialValues: {
        campaignName: "",
        companyName: "",
        industry: "",
      },
      validationSchema: UpdateCampaignScheme,
      onSubmit() {
        updateAdminCampaign({
          variables: {
            input: {
              name: values.campaignName,
              industryName: values.industry,
              companyName: values.companyName,
              campaignId: selectedId,
            },
          },
          onCompleted: () => {
            toast.success("Details Updated successfully");
            refetch();
            onClose();
          },
          onError(err) {
            toast.error(err.networkError.result.errors[0].message);
          },
        });
      },
    });

  return (
    <>
      {loading ? (
        <AdminTransactionLoader />
      ) : (
        <div className="admin_invoice_wrapper">
          <section className="filter_entry_wrapper">
            <div className="filter_entry_dropdown">
              <span>Show</span>
              <select
                name=""
                id=""
                value={entries}
                onChange={(e) => setEntries(parseInt(e.target.value))}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <span>entries</span>
            </div>
            <div className="search_input_wrapper">
              <InputGroup maxW="250px" bg="#EFF6F4">
                <InputRightElement cursor="pointer" py={4}>
                  <SearchIcon />
                </InputRightElement>
                <Input
                  type="text"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </InputGroup>
            </div>
          </section>
          {/* TABLE SECTION */}

          <div className="admin_invoice_wrap">
            {/* <div className="admin_invoice_action">
              <div className="admin_invoice_action_btn">
                <img src={editInvoice} alt="editInvoice" />
              </div>
              <div className="admin_invoice_action_btn">
                <img src={deleteInvoice} alt="deleteInvoice" />
              </div>
            </div> */}
            <div className="admin_table-body-container-invoice">
              <table
                id="data-table"
                className="admin_table"
                width="100%"
                // height="0%"
              >
                <thead>
                  <tr>
                    <th>
                      <input name="select_all" value="1" type="checkbox" />
                    </th>
                    {header?.map((item, index) => {
                      return (
                        <th key={index}>
                          <div className="admin_table_header">
                            <span>{item?.title}</span>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.length !== 0 ? (
                    <>
                      {/* start of body */}
                      {paginatedData?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td>
                              <input
                                name="select_all"
                                value={item?.id}
                                type="checkbox"
                              />
                            </td>
                            <td
                              style={{
                                color: "#F99B28",
                                fontSize: "12px",
                                cursor: "pointer",
                              }}
                              //   onClick={() => handleClick(item?.id)}
                            >
                              {/* {item?.invoice} */}
                              <Popover>
                                <PopoverTrigger>
                                  <p>{item?.invoice}</p>
                                </PopoverTrigger>
                                <PopoverContent maxW="150px">
                                  <PopoverArrow />
                                  <PopoverCloseButton />
                                  <PopoverHeader>Action</PopoverHeader>
                                  <PopoverBody>
                                    <div className="admin_invoice_actioned">
                                      <div
                                        className="admin_invoice_action_btned"
                                        onClick={() =>
                                          HandleSelected(item?.id, "edit")
                                        }
                                      >
                                        <img
                                          src={editInvoice}
                                          alt="editInvoice"
                                        />
                                      </div>
                                      <div
                                        className="admin_invoice_action_btned"
                                        onClick={() =>
                                          HandleSelected(item?.id, "delete")
                                        }
                                      >
                                        <img
                                          src={deleteInvoice}
                                          alt="deleteInvoice"
                                        />
                                      </div>
                                      <div
                                        className="admin_invoice_action_eye"
                                        onClick={() =>
                                          navigate(
                                            `/admin/advertiser/profile/invoice/campaign/${item?.id}`
                                          )
                                        }
                                      >
                                        <MdOutlineRemoveRedEye className="admin_invoice_eye_icon" />
                                        <span>View</span>
                                      </div>
                                    </div>
                                  </PopoverBody>
                                </PopoverContent>
                              </Popover>
                            </td>
                            <td style={{ fontSize: "12px", color: "#004643" }}>
                              {item?.campaignName}
                            </td>
                            <td style={{ fontSize: "12px", color: "#004643" }}>
                              {item?.companyName}
                            </td>
                            <td style={{ fontSize: "12px", color: "#004643" }}>
                              {item?.industry}
                            </td>
                            <td style={{ fontSize: "12px", color: "#004643" }}>
                              {moment(item?.date).format("YYYY-MM-DD HH:mm:ss")}
                            </td>

                            <td style={{ fontSize: "10px", color: "#004643" }}>
                              <div className="admin-checkbox-rad">
                                <span className="admin-checkbox-rad-txt">
                                  {item?.radioStation}
                                </span>
                                <span className="admin-checkbox-rad-txt">
                                  {item?.rate}
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}

                      {/* end of body */}
                    </>
                  ) : (
                    <tr className="admin_not_found">
                      <td> No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="admin_footer_wrapper">
              <div>
                <section className="admin_pagination-section">
                  <div>
                    showing {"  "}
                    {`${(currentPage - 1) * entries + 1} to ${Math.min(
                      currentPage * entries,
                      filteredData?.length
                    )} of ${filteredData?.length} entries`}
                  </div>
                </section>
              </div>
              {/* Pagination section */}
              <div className="admin_pagination_invoice">
                <span onClick={() => handlePagination("prev")}>Previous</span>
                {renderPageNumbers()}
                <span onClick={() => handlePagination("next")}>Next</span>
              </div>
            </div>
          </div>

          {/* Popup Modal */}
          <Modal
            onClose={HandleClose}
            isOpen={isOpen}
            isCentered
            size={`${selectedAction === "edit" ? "md" : "lg"}`}
          >
            <ModalOverlay />
            <ModalContent padding="20px">
              <ModalBody>
                {selectedAction === "delete" && (
                  <div className="profile-popover_wrapper">
                    <div className="profile-footer">
                      <div className="profile-icon">
                        <img src={exclamationIcon} alt="exclamationIcon" />
                      </div>

                      <div className="profile-pop-txta">
                        <p>Delete Campaign?</p>
                      </div>
                      <div className="profile-pop-txtb">
                        <p>
                          Are you sure you want to delete this campaign? This
                          action cannot be undone.
                        </p>
                      </div>
                    </div>

                    <div className="profile-pop-footer">
                      <div
                        className="profile-pop-cancel"
                        onClick={() => HandleClose()}
                      >
                        <p>No, Cancel</p>
                      </div>
                      <div
                        className="profile-pop-del"
                        onClick={() => HandleActionButton(selectedId)}
                      >
                        <p>Yes, Delete</p>
                      </div>
                    </div>
                  </div>
                )}

                {selectedAction === "edit" && (
                  <div className="invoice-popover_wrapper">
                    {/* Form section */}
                    <div className="form-sec">
                      <form onSubmit={handleSubmit}>
                        <div className="profile-form-container">
                          <div
                            id="profile-input-container"
                            style={{ marginTop: "-25px" }}
                          >
                            <div className="profile-full-length">
                              <div className="form-input-wrapper">
                                {errors.campaignName &&
                                  touched.campaignName && (
                                    <span id="form-error">
                                      {" "}
                                      {errors.campaignName}
                                    </span>
                                  )}
                                <div className="input-cont invoice-full-length">
                                  <label
                                    htmlFor="campaign-name"
                                    className="profile-form-label"
                                  >
                                    Campaign Name
                                  </label>
                                  <input
                                    // id="radio-station"
                                    type="text"
                                    placeholder="Awoof Data"
                                    name="campaignName"
                                    value={values.campaignName}
                                    onChange={handleChange}
                                    // onBlur={handleBlur}
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="profile-full-length">
                              <div className="form-input-wrapper">
                                {errors.campaignName && touched.companyName && (
                                  <span id="form-error">
                                    {" "}
                                    {errors.companyName}
                                  </span>
                                )}
                                <div className="input-cont invoice-full-length">
                                  <label
                                    htmlFor="radio-station"
                                    className="profile-form-label"
                                  >
                                    Company Name{" "}
                                  </label>
                                  <input
                                    // id="radio-station"
                                    type="text"
                                    placeholder="MTN"
                                    name="companyName"
                                    value={values.companyName}
                                    onChange={handleChange}
                                    // onBlur={handleBlur}
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="profile-full-length">
                              <div className="input-cont invoice-full-length">
                                <label
                                  htmlFor="industry"
                                  className="profile-form-label"
                                >
                                  Industry
                                </label>
                                <input
                                  // id="radio-station"
                                  type="text"
                                  placeholder="Entertainment"
                                  name="industry"
                                  value={values.industry}
                                  onChange={handleChange}
                                  // onBlur={handleBlur}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="invoice-button">
                            <button
                              type="submit"
                              // disabled={confirmAgreement}
                              className={"profile-button"}
                            >
                              {loading ? (
                                <i class="fa fa-circle-o-notch fa-spin"></i>
                              ) : (
                                "Edit Campaign"
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* End of Form Section */}
                  </div>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
          {/* End of Popup Modal */}
        </div>
      )}
    </>
  );
};

export default AdminTableCheckbox;
