import { useEffect, useState } from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Text, useDisclosure } from '@chakra-ui/react';

import "./analyticsdata.css"
import { TableSpinner } from '../../Spinner';


const AnalyticsData = ({ isAuthLoading, isOpen, data, setPropertyID, onClose }) => {
    const [propertySummaries, setPropertySummaries] = useState([]);
    const [activeAccount, setActiveAccount] = useState(0);
  
    useEffect(() => {
      if (data) {
        setPropertySummaries(data?.accountSummaries[0]?.propertySummaries);
      }
    }, [data]);
  
    const modifiedData = data?.accountSummaries.map((summary) => ({
      displayName: summary.displayName,
      accountId: summary.account.split('/')[1],
      account: summary.account,
    }));
  
    const filterPropertySummaries = (selectedAccount, index) => {
      setActiveAccount(index);
      const selectedAccountSummary = data?.accountSummaries.find(
        (summary) => summary.account === selectedAccount
      );
      if (selectedAccountSummary) {
        setPropertySummaries(selectedAccountSummary.propertySummaries);
      }
    };
  
    return (
      <>
        <Modal isCentered isOpen={isOpen} onClose={onClose} size="2xl">
          {isAuthLoading ? (
            <TableSpinner />
          ) : (
            <ModalContent p={0}>
              <ModalHeader>Select analytic property</ModalHeader>
              <ModalCloseButton />
              <ModalBody p={0}>
                <div>
                  <div className="analytics-title-container">
                    <div className="analytics-account-title-wrapper">
                      <div className="analytics-account-title">
                        <h2>Analytics Accounts</h2>
                      </div>
                      <ul className="analytics-account-content">
                        {modifiedData?.map((accountInfo, index) => (
                          <li
                            className={`analytics-account-content-item ${
                              activeAccount === index && 'active-account'
                            }`}
                            onClick={() => filterPropertySummaries(accountInfo.account, index)}
                            key={index}
                          >
                            <button className="analytics-account-content-item-btn">
                              <div className="analytics-account-content-item-details">
                                <Text noOfLines={1} className="account-name">
                                  {accountInfo.displayName}
                                </Text>
                                <p>{accountInfo.accountId}</p>
                              </div>
                              <ChevronRightIcon />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="analytics-account-title-wrapper">
                      <div className="analytics-account-title">
                        <h2>Properties & Apps</h2>
                      </div>
                      <ul className="analytics-account-content">
                        {propertySummaries?.map((property, index) => (
                          <li className="analytics-account-content-item" key={index}>
                            <button
                              className="analytics-account-content-item-btn"
                              onClick={() => {
                                setPropertyID(property.property.split('/')[1])
                                onClose()
                            }}
                            >
                              <div className="analytics-account-content-item-details">
                                <span>{property.displayName}</span>
                                <p>{property.property.split('/')[1]}</p>
                              </div>
                              <div className="analytics-account-icon-wrapper">
                                <div className="chevron-icon">
                                  <ChevronRightIcon />
                                </div>
                              </div>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="analytics-account-title-wrapper">
                      <div className="analytics-account-title">
                        <h2>Views</h2>
                      </div>
                      <ul className="analytics-account-content">
                        <li className="analytics-account-content-item"></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </ModalContent>
          )}
        </Modal>
      </>
    );
  };
  
  export default AnalyticsData;
  