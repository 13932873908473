import React, { useState } from "react";
import { Box, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import styled from "styled-components";
import moment from "moment";
import { SearchIcon } from "@chakra-ui/icons";

import DownloadDocument from "../BookingDetails/DownloadDocument/DownloadDocument";
import { StationButtons } from "../BookingDetails/StationButtons/StationButtons";
import BookingInfo from "../BookingDetails/BookingInfoDetails/BookingInfo";
import { TableHeaderText } from "../TextCompnents";
import Label from "../Label/Label";

import { dummyCampaignDetails } from "../../utils/constant";
import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_ORDER_BY_ID } from "../GraphQL/Queries";
import { TableSpinner } from "../Spinner";
import { toast } from "react-toastify";

const AdvertiserOrders = ({ orders }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [entries, setEntries] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [openCampaignDetails, setOpenCampaignDetails] = useState(0);
  const [orderId, setOrderId] = useState(null);
  const [orderDetails, setOrderDetails] = useState({});
  const [activeButton, setActiveButton] = useState(null);
  const [loader, setLoader] = useState(false);

  const [executeQuery, { loading: orderDetailsLoading, data, error }] = useLazyQuery(
    GET_ORDER_BY_ID,
    {
      onCompleted: () => {
        setActiveButton(data.admin.getOrderById?.radioStationDetails[0]?.id);
        setOrderDetails(data.admin.getOrderById?.radioStationDetails[0]);
      },
    }
  );
if(error) toast(`Error! ${error.message}`);

  useEffect(() => {
    setLoader(true);
    if (orderId) {
      executeQuery({
        variables: {
          orderId,
        },
      });
    }
  }, [orderId, executeQuery]);

  const handleClick = (id) => {
    setOrderId(id);
    setOpenCampaignDetails(1);
  };
  // useEffect to handle the data response
  useEffect(() => {
    setLoader(false);
    if (data) {
      // Handle the data response as needed
      setOrderDetails(data);
      console.log(data);
    }
  }, [data]);

  const handlePagination = (action) => {
    if (action === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (action === "next" && orders?.length > currentPage * entries) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset current page when searching
  };

  const filteredData = orders?.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const totalPages = Math.ceil(filteredData?.length / entries);

  const paginatedData = filteredData?.slice(
    (currentPage - 1) * entries,
    currentPage * entries
  );

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <span
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </span>
      );
    }
    return pageNumbers;
  };

  const singleCampaignHandler = (index) => {
    setActiveButton(index);
    const singleCampaign =
      data?.admin?.getOrderById?.radioStationDetails.filter((campaign) => {
        return campaign.id === index;
      });
    setOrderDetails(singleCampaign[0]);
  };

  return (
    <>
    {orderDetailsLoading && <TableSpinner />}
      {!orderDetailsLoading && openCampaignDetails === 1 && (
        <Box px={30}>
          <DownloadDocument
            campaignDetails={data?.admin?.getOrderById}
            previousTab={setOpenCampaignDetails}
            isAdmin
          />
          <StationButtons
            radioStationDetails={data?.admin?.getOrderById?.radioStationDetails}
            activeButton={activeButton}
            onButtonClick={singleCampaignHandler}
          />
          <BookingInfo
            campaignDetails={orderDetails}
            campaignId={data?.admin?.getOrderById?.id}
          />
        </Box>
      )}
      {openCampaignDetails === 0 &&  (
        <Box px="30px">
          <section className="filter_entry_wrapper">
            <div className="filter_entry_dropdown">
              <span>Show</span>
              <select
                name=""
                id=""
                value={entries}
                onChange={(e) => setEntries(parseInt(e.target.value))}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <span>entries</span>
            </div>
            <div className="search_input_wrapper">
              <InputGroup maxW="250px" bg="#EFF6F4">
                <InputRightElement cursor="pointer" py={4}>
                  <SearchIcon />
                </InputRightElement>
                <Input
                  type="text"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </InputGroup>
            </div>
          </section>
          <section className="advertiser_order_table_container table-body-container">
            <table>
              <thead>
                <tr>
                  <th>
                    <input name="select_all" value="1" type="checkbox" />
                  </th>
                  <th>
                    <TableHeaderText>CAMPAIGN ID</TableHeaderText>
                  </th>

                  <th>
                    <TableHeaderText>TOTAL AMOUNT SPENT</TableHeaderText>
                  </th>
                  <th>
                    <TableHeaderText>TOTAL EARNINGS</TableHeaderText>
                  </th>
                  <th>
                    <TableHeaderText>DATE</TableHeaderText>
                  </th>
                  <th>
                    <TableHeaderText>STATUS</TableHeaderText>
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map(
                  ({ id, totalAmountSpent, totalEarnings, date, status }) => {
                    return (
                      <tr key={id}>
                        <td>
                          <input type="checkbox" name="name1" />
                        </td>
                        <td className="campaign-id">
                          <TableBodyText
                            style={{ color: "#F99B28", cursor: "pointer" }}
                            onClick={() => handleClick(id)}
                          >
                            #{id}
                          </TableBodyText>
                        </td>
                        <td>
                          <TableBodyText> ₦{totalAmountSpent}</TableBodyText>
                        </td>
                        <td>
                          {" "}
                          <TableBodyText> ₦{totalEarnings}</TableBodyText>
                        </td>
                        <td>
                          <TableBodyText>
                            {" "}
                            {moment(date).format("MMM Do YYYY")}
                          </TableBodyText>
                        </td>
                        <td>
                          <TableBodyText>
                            <Label label={status} />
                          </TableBodyText>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
            {paginatedData.length > 0 && (
              <div className="admin_footer_wrapper">
                <div>
                  <section className="admin_pagination-section">
                    <div>
                      showing {"  "}
                      {`${(currentPage - 1) * entries + 1}`} <span>to</span>{" "}
                      {`${Math.min(
                        currentPage * entries,
                        filteredData.length
                      )}`}{" "}
                      <span>of</span> {`${filteredData.length} `}{" "}
                      <span>entries</span>
                    </div>
                  </section>
                </div>
                {/* Pagination section */}
                <div className="admin_pagination">
                  <span onClick={() => handlePagination("prev")}>Previous</span>
                  {renderPageNumbers()}
                  <span onClick={() => handlePagination("next")}>Next</span>
                </div>
              </div>
            )}
          </section>
        </Box>
      )}
    </>
  );
};

export default AdvertiserOrders;

const TableBodyText = styled.p`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.05em;

  color: #004643;
`;
