import React, { useEffect, useRef, useState } from 'react'
import AdvertDashboardLayout from '../../ResponsiveAdvertiserDashboardLayout/AdvertDashboardLayout'
import "../css/profile.css"
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Stack, Text, VStack } from '@chakra-ui/react'
import { FaFileUpload } from 'react-icons/fa';
import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { GET_ADVERTISER_PROFILE, GET_NOTIFICATIONS } from '../../../../components/GraphQL/Queries'
import { toast } from 'react-toastify'
import { FaRegFilePdf, FaRegImage } from 'react-icons/fa6'
import { getTimeDifference, validateFormData } from '../../../../utils/functions'
import { Spinner } from '../../../../bookCampaign/components/RecommendedStation/components'
import { UPDATE_ADVERISER_PROFILE } from '../../../../components/GraphQL/Mutation'

const Profile = () => {

    const [activeTab, setActiveTab] = useState(1)
    const [selectedFile, setSelectedFile] = useState(null);

    const inputRef = useRef();

    const { data, loading } = useSubscription(GET_NOTIFICATIONS)
    const { data: advertiserProfile, loading: profileLoading } = useQuery(GET_ADVERTISER_PROFILE)
    const [updateProfile, { loading: updateProfileLoading }] = useMutation(UPDATE_ADVERISER_PROFILE)

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        country: "",
        state: "",
        city: "",
        phoneNo: "",
        address: "",
    })

    useEffect(() => {
        if (advertiserProfile) {
            const profile = advertiserProfile?.advertiser.getAdvertiserProfile;
            setFormData({
                firstName: profile?.fullname?.split(" ")[1] || '',
                lastName: profile?.fullname?.split(" ")[0] || '',
                country: profile?.country || '',
                state: profile?.state || '',
                city: profile?.city || '',
                phoneNo: profile?.phoneNumber || '',
                address: profile?.address || '',
            });
            // setSelectedFile(profile?.cacFileUrl)
        }
    }, [advertiserProfile]);

    const ACCEPTABLE_FILE_TYPES = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'application/pdf',
    ];

    const handleOnChange = (e) => {
        const { name, value } = e.target

        setFormData((prev) => ({ ...prev, [name]: value }))
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && ACCEPTABLE_FILE_TYPES.includes(file.type)) {
            setSelectedFile(file);
        } else {
            toast.error(`Please select a valid file type(png, jpeg, jpg, pdf).`)
        }
    };

    const handleButtonClick = () => {
        inputRef.current.click();
    };

    // const handleDelete = () => {
    //     setSelectedFile(null);
    //     inputRef.current.value = null;
    // };

    const handleUpdateProfile = async () => {
        const errors = await validateFormData(formData);
        if (errors) {
            // toast.error('Validation error')
            if (errors.firstName) {
                return toast.error(errors.firstName); // Display this to the user in the UI
            }
            if (errors.lastName) {
                return toast.error(errors.lastName); // Display this to the user in the UI
            }
            if (errors.phoneNo) {
                return toast.error(errors.phoneNo); // Display this to the user in the UI
            }
            if (errors.address) {
                return toast.error(errors.address); // Display this to the user in the UI
            }
            if (errors.city) {
                return toast.error(errors.city); // Display this to the user in the UI
            }
            if (errors.country) {
                return toast.error(errors.country); // Display this to the user in the UI
            }
        } else {
            updateProfile({
                variables: {
                    input: {
                        fullname: formData.firstName + ' ' + formData.lastName,
                        country: formData.country,
                        state: formData.state,
                        city: formData.city,
                        phoneNumber: formData.phoneNo,
                        address: formData.address
                    }
                },
                onCompleted: () => {
                    toast.success('Profile Updated')
                },
                onError(err) {
                    toast.error(err?.networkError?.result?.errors[0].message);
                }
            })
        }

    }

    const handleUploadCACFile = () => {
        updateProfile({
            variables: {
                cacFile: selectedFile
            },
            onCompleted: () => {
                toast.success('CAC File uploaded')
            },
            onError(err) {
                toast.error(err?.networkError?.result?.errors[0].message);
            }
        })
    }


    return (
        <AdvertDashboardLayout>
            {profileLoading ? <Box width={'100%'} height={'100vh'} display={'flex'} alignItems={'center'} justifyContent={'center'}> <Spinner /></Box> : <Stack>
                <Text as={'h1'} fontSize={'24px'} fontWeight={600} lineHeight={'29.09px'}>Profile</Text>

                <Box display={'flex'} gap={'80px'} flexDirection={{ base: "column", lg: 'row' }}>
                    <Box fontSize={'18px'} fontWeight={400} lineHeight={'32.4px'} maxW={'400px'} w={'100%'} border={'0.5px solid #DADADA'} borderRadius={'8px'} h={'fit-content'} bg={'#FBFBFB'}>
                        <Text padding={'14px 34px'} cursor={'pointer'} borderBottom={'0.5px solid #DADADA'} borderLeft={activeTab === 1 && '2px solid #010066'} onClick={() => setActiveTab(1)}>Billing Information</Text>
                        <Text padding={'14px 34px'} cursor={'pointer'} borderBottom={'0.5px solid #DADADA'} borderLeft={activeTab === 2 && '2px solid #010066'} onClick={() => setActiveTab(2)}>Uploads</Text>
                        <Text padding={'14px 34px'} cursor={'pointer'} borderLeft={activeTab === 3 && '2px solid #010066'} onClick={() => setActiveTab(3)}>Notifications</Text>
                    </Box>
                    <VStack 
                    maxW={{base:"full", md:'850px'}}
                     w={'100%'} 
                     spacing={8} 
                     justify={{base:"flex-start", md: "flex"}}
                     align={{base: "flex-start", md: "flex-start", lg: "center"}}

                     >
                        {activeTab === 1 && <Box border={'0.5px solid #D9D9D9'} p={'24px 60px 24px 20px'} w={'100%'} borderRadius={'8px'} pr={{base: "20px", md: "60px"}}>
                            <Text mb={'20px'}>Billing Information</Text>
                            <Stack spacing={8}>
                                {/* <form onSubmit={()=> console.log(formData)}> */}
                                <Flex 
                                gap={10} 
                                flexDirection={{ base: "column", md: 'row' }} 
                                w={'100%'}
                                >
                                    <FormControl>
                                        <FormLabel>First Name</FormLabel>
                                        <Input
                                            name='firstName'
                                            type='text'
                                            maxW={{ base: '100%', md: '352px' }}
                                            width={'100%'}
                                            placeholder='John'
                                            value={formData.firstName}
                                            onChange={handleOnChange}
                                        />
                                        {/* {isError && <FormErrorMessage>First Name is required.</FormErrorMessage>} */}
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Last Name</FormLabel>
                                        <Input
                                            name='lastName'
                                            type='text'
                                            maxW={{ base: '100%', md: '352px' }}
                                            width={'100%'}
                                            placeholder='Doe'
                                            value={formData.lastName}
                                            onChange={handleOnChange}

                                        />
                                        {/* {isError && <FormErrorMessage>Last Name is required.</FormErrorMessage>} */}
                                    </FormControl>
                                </Flex>
                                <Flex gap={10} flexDirection={{ base: "column", md: 'row' }} w={'100%'}>
                                    <FormControl>
                                        <FormLabel>Country</FormLabel>
                                        <Input
                                            name='country'
                                            type='text'
                                            maxW={{ base: '100%', md: '352px' }}
                                            width={'100%'}
                                            placeholder='Nigeria'
                                            value={formData.country}
                                            onChange={handleOnChange}

                                        />
                                        {/* {isError && <FormErrorMessage>First Name is required.</FormErrorMessage>} */}
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>State</FormLabel>
                                        <Input
                                            name='state'
                                            type='text'
                                            maxW={{ base: '100%', md: '352px' }}
                                            width={'100%'}
                                            placeholder='Lagos'
                                            value={formData.state}
                                            onChange={handleOnChange}

                                        />
                                        {/* {isError && <FormErrorMessage>Last Name is required.</FormErrorMessage>} */}
                                    </FormControl>
                                </Flex>
                                <Flex gap={10} flexDirection={{ base: "column", md: 'row' }} w={'100%'}>
                                    <FormControl>
                                        <FormLabel>City</FormLabel>
                                        <Input
                                            name='city'
                                            type='text'
                                            maxW={{ base: '100%', md: '352px' }}
                                            width={'100%'}
                                            placeholder='Yaba'
                                            value={formData.city}
                                            onChange={handleOnChange}

                                        />
                                        {/* {isError && <FormErrorMessage>First Name is required.</FormErrorMessage>} */}
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Phone No</FormLabel>
                                        <Input
                                            name='phoneNo'
                                            type='text'
                                            maxW={{ base: '100%', md: '352px' }}
                                            width={'100%'}
                                            placeholder='1100011'
                                            value={formData.phoneNo}
                                            onChange={handleOnChange}
                                        />
                                        {/* {isError && <FormErrorMessage>Last Name is required.</FormErrorMessage>} */}
                                    </FormControl>
                                </Flex>
                                <Flex gap={10} flexDirection={{ base: "column", md: 'row' }} w={'100%'}>
                                    <FormControl>
                                        <FormLabel>Address</FormLabel>
                                        <Input
                                            name='address'
                                            type='text'
                                            maxW={{ base: '100%', md: '352px' }}
                                            width={'100%'}
                                            placeholder='Lagos, Nigeria'
                                            value={formData.address}
                                            onChange={handleOnChange}
                                        />
                                        {/* {isError && <FormErrorMessage>First Name is required.</FormErrorMessage>} */}
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Zip Code</FormLabel>
                                        <Input
                                            type='text'
                                            maxW={{ base: '100%', md: '352px' }}
                                            width={'100%'}
                                            placeholder='1100011' />
                                        {/* {isError && <FormErrorMessage>Last Name is required.</FormErrorMessage>} */}
                                    </FormControl>
                                </Flex>
                                {/* </form> */}

                            </Stack>
                        </Box>
                        }
                        {activeTab === 2 && <Flex
                            flexDirection={'column'}
                            border={'0.5px solid #D9D9D9'}
                            p={4}
                            borderRadius="8px"
                            maxW={'850px'}
                            w={'100%'}
                        >
                            <Text fontSize="18px" color="#010066">Uploads</Text>
                            <Box
                                display="flex"
                                alignItems="center"
                            >
                                <Box display="flex" alignItems="center" gap={'30px'}>
                                    {selectedFile && selectedFile.type === 'application/pdf' && (
                                        <FaRegFilePdf style={{ fontSize: '50px' }} />
                                    )}

                                    {selectedFile && (
                                        (selectedFile.type === 'image/png' ||
                                            selectedFile.type === 'image/jpg' ||
                                            selectedFile.type === 'image/jpeg') && (
                                            <FaRegImage style={{ fontSize: '50px' }} />
                                        )
                                    )}
                                    {/* <FileIcon /> */}
                                    <VStack>
                                        {selectedFile && <Text mr={2}>{selectedFile.name}</Text>}
                                        {!selectedFile && <Flex alignItems={'center'}>
                                            <Input
                                                type="file"
                                                ref={inputRef}
                                                display="none"
                                                onChange={handleFileChange}
                                            />
                                            <Button
                                                onClick={handleButtonClick}
                                                leftIcon={<FaFileUpload />}
                                                padding={'10px 24px'}
                                                border={'1px solid #DADADA'}
                                                borderRadius={'24px'}
                                                variant="outline"
                                                mr={2}
                                                fontWeight={400}
                                            >
                                                Upload CAC File
                                            </Button>
                                        </Flex>}
                                    </VStack>
                                </Box>
                                {selectedFile && (
                                    <Button padding={'10px 24px'} variant={'none'} alignSelf={'flex-end'} fontWeight={400} _hover={{ color: '#f1613f' }} onClick={() => setSelectedFile(null)}>Delete</Button>
                                )}
                            </Box>
                        </Flex>
                        }
                        {activeTab === 3 && <Box maxW={{base: "100%", md:'400px'}} w={'100%'} bg={'white'} minH={'400px'} h={'100%'} padding={'20px 16px'} borderRadius={'32px'} boxShadow='md' overflowY={'auto'} >
                            <Text fontSize="18px" color="#010066" mb={4}>Notifications</Text>
                            <Stack spacing={4}>
                                {data?.getNotification.map((notification) => (
                                    <Flex alignItems={'center'} gap={'10px'}>
                                        <NotificationEllipse />
                                        <Flex flexDirection={'column'} gap={1}>
                                            <Text fontFamily={'Libre Franklin'} fontSize={'12px'} fontWeight={500} lineHeight={'14.54px'} >{notification.message}</Text>
                                            <Text fontFamily={'Libre Franklin'} fontSize={'11px'} fontWeight={400} lineHeight={'14px'}>{getTimeDifference(notification?.createdAt)}</Text>
                                        </Flex>
                                    </Flex>
                                ))}
                            </Stack>
                        </Box>}
                        {(activeTab === 1 || activeTab === 2) && (
                            <Button
                                padding={'15px 30px'}
                                borderRadius={'27.16px'}
                                bg={'#F1613F'}
                                color={'white'}
                                fontSize={'14px'}
                                lineHeight={'19.11px'}
                                display="flex"
                                alignSelf={'flex-start'}
                                h={'50px'}
                                _hover={{ bg: '#ee5634' }}
                                _active={{ bg: '#ee5634' }}
                                sx={{
                                    transition: 'transform 0.3s ease', // Animation duration and timing function
                                    _hover: {
                                        transform: 'translateY(-4px)', // Moves the button up 4px on hover
                                    },
                                }}
                                onClick={activeTab === 1 ? handleUpdateProfile : handleUploadCACFile}
                            >
                                {updateProfileLoading ? (
                                    <span className='loading'></span>
                                ) : (
                                    activeTab === 1 ? "Update Profile" : "Save"
                                )}
                            </Button>
                        )}

                    </VStack>
                </Box>
            </Stack>}
        </AdvertDashboardLayout>
    )
}

export default Profile


const NotificationEllipse = () => {
    return (
        <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4.5" r="4" fill="#F1613F" />
        </svg>

    )
}
