import React from "react";
import "./style.css";
import arrow from "./assets/arrow.svg";

const AdminDashboardCard = ({ svg, title, content }) => {
  return (
    <div className="dashboard_wrapper">
      <div className="icon-container">
        <img src={svg} alt="admin-icon" />
      </div>
      <div className="content-container">
        <div className="title">
          <p>{title}</p>
        </div>
        <div className="content">
          <p>{content}</p>
          <span className="hover-arrow">
            <img src={arrow} alt="arrow-icon" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardCard;
