import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  TableContainer,
  Tr,
  Button,
  Flex,
  Box,
  Tfoot,
  TableCaption,
} from "@chakra-ui/react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { CompletedSvg } from "../AnalyticsSVG/index";

const BroadCasterSummaryTable = ({ data }) => {
  const itemsPerPage = 10;
  const totalItems = data?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data?.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <Box
          key={i}
          p={2}
          m={1}
          cursor="pointer"
          bg={currentPage === i ? "#004643" : "#fff"}
          color={currentPage === i ? "white" : "#004643"}
          borderRadius="md"
          className={` ${currentPage ? "currentpage" : "notcurrent"}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Box>
      );
    }
    return pageNumbers;
  };
  // console.log("data--", data);
  return (
    <div>
      <TableContainer>
        <Table variant="simple" width={"100%"}>
          {(data?.length <= 0 || data === null) && (
            <TableCaption style={{ margin: "6rem" }}>
              No Data Available
            </TableCaption>
          )}
          <Thead>
            <Tr>
              <Th>Slot Date</Th>
              <Th>Slot Time</Th>
              <Th>Number of Ads</Th>
              <Th>Stream Time</Th>
              <Th className="stream">Stream Status</Th>
            </Tr>
          </Thead>
          {currentData?.length > 0 ? (
            currentData?.map((item, index) => (
              <Tbody>
                <Tr key={index}>
                  <Td>
                    {new Date(item.slotDate).toLocaleDateString(undefined, {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </Td>
                  <Td>
                    {new Date(`2023-01-01T${item.slotTime}`)
                      .toLocaleTimeString([], {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })
                      .toLocaleLowerCase()}
                  </Td>
                  <Td>{item.slotNumberOfAds}</Td>
                  <Td>
                    {new Date(
                      new Date(item.slotStreamedTime).toISOString().slice(0, -1)
                    )
                      .toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })
                      .replace(/(\d+:\d+)(:\d+ )?(\w+)/, "$1$3")
                      .toLowerCase()}
                  </Td>
                  <Td className="badge">
                    {item.streamedStatus === false ? (
                      Array.from({ length: item.slotNumberOfAds }, (_, i) => (
                        <div
                          key={i}
                          color={
                            i + 1 <= item.slotNumberOfAdsPlayed ? "" : "black"
                          }
                          className={` ${
                            i + 1 > item.slotNumberOfAdsPlayed ? "status" : ""
                          }`}
                        >
                          {i + 1 <= item.slotNumberOfAdsPlayed ? (
                            <CompletedSvg />
                          ) : (
                            i + 1
                          )}
                        </div>
                      ))
                    ) : (
                      <span>Completed</span>
                    )}
                  </Td>
                </Tr>
              </Tbody>
            ))
          ) : (
            <Tbody>
              <Tr>
                <Td colSpan="5" style={{ textAlign: "center" }}>
                  <div
                    style={{
                      height: "200px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p>No Data Available</p>
                  </div>
                </Td>
              </Tr>
            </Tbody>
          )}
        </Table>
      </TableContainer>

      {currentData?.length > 0 && (
        <Flex mt={4} align="center" justify="right">
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <MdKeyboardArrowLeft />
          </Button>
          {renderPageNumbers()}
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <MdKeyboardArrowRight />
          </Button>
        </Flex>
      )}
    </div>
  );
};

export default BroadCasterSummaryTable;
