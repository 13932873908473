import React, { useState } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Button,
  List,
  ListItem,
  Flex,
  Text,
  Box,
  Icon,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import "./css/currency.css";
import { MdKeyboardArrowDown, MdKeyboardControlKey } from "react-icons/md";
import {
  getCurrencyName,
  getCurrencySymbol,
  getCurrencySymbols,
  getFlagEmoji,
} from "../../utils/functions";

const CurrencyPopover = ({
  loading,
  currencies,
  selectedCurrency,
  setSelectedCurrency,
  showCurrency,
  p,
  flagr,
  updateFromFunc,
  adjustArrow,
  status,
}) => {
  const initialFocusRef = React.useRef();

  // Function to handle currency selection
  const handleCurrencySelect = (currency) => {
    status && status(false)
    setSelectedCurrency(currency); // Directly set selectedCurrency
    localStorage.setItem("currency", currency?.currency); // Update localStorage
    // HandleGetDraft && HandleGetDraft();

  };

  return (
    <Popover
      initialFocusRef={initialFocusRef}
      placement="left-start"
      className="all-pop"
    >
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            {!showCurrency ? (
              <button
                className={`${loading ? "laoding" : "change-currency"}`}
                isDisabled={loading}
              >
                {selectedCurrency?.currency}
              </button>
            ) : (
              <Button
                className={` ${
                  loading ? "loading" : "not-loading"
                } currency-btn-trigger`}
                _hover={{ bg: "gray.100" }}
                display="flex"
                alignItems="center"
                isDisabled={loading}
              >
                {" "}
                <Box as="span" fontSize="1.5rem" mr={2} className="pick-box">
                  {getFlagEmoji(selectedCurrency?.currency)}
                </Box>
                <Text>{selectedCurrency?.currency}</Text>
                <Box as="span" ml={2}>
                  {isOpen ? (
                    <MdKeyboardControlKey className="arrow-down" />
                  ) : (
                    <MdKeyboardArrowDown className="arrow-down" />
                  )}
                </Box>
              </Button>
            )}
          </PopoverTrigger>

          <PopoverContent zIndex={1500} className={`currency-content `}>
            {" "}
            {/* Higher z-index */}
            <div className="currency-headers">
              <div className={`${adjustArrow ? "arrow-shift" : "cancle-arrow"}`}>
                <PopoverArrow />
                <PopoverCloseButton />
              </div>
              <div className="header-text">
                <PopoverHeader>Choose your currency</PopoverHeader>
              </div>
            </div>
            <PopoverBody
              className={`my-content ${!showCurrency ? "stay" : "adjust"}`}
            >
              <List className="list-countries">
                {currencies?.length > 0 &&
                  currencies?.map((currency, index) => (
                    <ListItem
                      key={index}
                      // _hover={{ bg: "gray.100", cursor: "pointer" }}
                      p={p ?? 10}
                      onClick={() => {
                        handleCurrencySelect(currency);
                        !updateFromFunc &&
                          localStorage.setItem("currency", currency?.currency);
                        setTimeout(() => {
                          onClose();
                        }, 1300);
                      }}
                      className={`${
                        currencies?.length - 3 === index - 1 ? "" : "no-border"
                      } all-lists ${
                        currencies?.length - 1 === index ? "hidden" : ""
                      }  `}
                    >
                      <Flex alignItems="center" justifyContent="space-between"   p={p ?? 10}>
                        <Flex alignItems="center" className="pick-box">
                          <Box
                            as="span"
                            fontSize="1.5rem"
                            mr={flagr ? flagr : 3}
                             className="pick-box"
                          >
                            {currency?.country === "Others"
                              ? ""
                              : getFlagEmoji(currency?.currency)}
                          </Box>
                          <Text className="cap-me" fontSize="14px">
                            {currency?.country}{" "}
                            <span className="lower-me">
                              {currency?.country === "Others"
                                ? ""
                                : getCurrencyName(currency?.currency)}
                            </span>{" "}
                            {currency?.country === "Others"
                              ? ""
                              : `- ${getCurrencySymbols(currency?.currency)}`}
                          </Text>
                        </Flex>
                        {selectedCurrency?.currency === currency?.currency && (
                          <Icon
                            as={CheckIcon}

                            // color="green.500"
                          />
                        )}
                      </Flex>
                    </ListItem>
                  ))}
              </List>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default CurrencyPopover;
