import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Skeleton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import AdvertDashboardLayout from "../../ResponsiveAdvertiserDashboardLayout/AdvertDashboardLayout";
import {
  WALLET_BALANCE,
  TRANSAACTION_HISTORY,
} from "../../../../components/GraphQL/Queries";
import CardTwo from "../../assets/paymentCardTwo.svg";
// import CardThree from "../../assets/paymentCardThree.svg";
import "../css/wallet.css";
import moment from "moment";
import {
  REFRESH_TRANSACTION,
  FUND_WALLET,
} from "../../../../components/GraphQL/Mutation";
import { toast } from "react-toastify";
import { fmtCurrency } from "../../../../utils/functions";
import { MdAdd } from "react-icons/md";
import { refresh } from "aos";
import AdvertiserHomeDashboardLoader from "../../../../components/SkeletonLayoutLoader/AdvertiserHomeDashboard";
import CampaignTable from "../../../../components/CampaignTable";
import TransmitPagination from "../../../../components/TransmitPagination";

const RefreshButton = ({ status, transactionId, updateTransactionStatus }) => {
  const [refreshTransaction, { loading }] = useMutation(REFRESH_TRANSACTION, {
    context: {
      headers: {
        "apollo-require-preflight": true,
      },
    },
    async onCompleted(data) {
      updateTransactionStatus(transactionId);
      toast.success("Payment Confirmed");
    },
    refetchQueries: [{ query: WALLET_BALANCE }],
    onError: (error) => {
      toast.error(error?.networkError?.result?.errors[0]?.message);
    },
  });

  function handleRefresh(e) {
    e.preventDefault();

    refreshTransaction({
      variables: {
        transactionId,
      },
    });
  }

  return (
    <button
      onClick={handleRefresh}
      disabled={status === "Approved" ? true : false}
      className="refresh-btn"
    >
      {loading ? <i className="fa fa-circle-o-notch fa-spin"></i> : "Refresh"}
    </button>
  );
};

const Wallet = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [transactions, setTransactions] = useState([]);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const [noMoney, setNoMoney] = useState(false);

  const walletBalance = useQuery(WALLET_BALANCE);
  const { loading, data, refetch } = useQuery(TRANSAACTION_HISTORY, {
    variables: {
      page,
      itemsPerPage,
      sort,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [amount, setAmount] = useState(0);

  const totalPages =
    data?.advertiser?.getTransactionHistory?.totalCount / itemsPerPage;

  const handleNextOrPrevPage = (newPage) => {
    setPage(newPage);
    refetch({
      page: newPage,
      itemsPerPage,
      sort,
    });
  };

  const handlePerPageChange = (e) => {
    const selectedValue = Number(e.target.value);
    setItemsPerPage(selectedValue);
  };

  // eslint-disable-next-line no-unused-vars
  const [fundWallet, { loading: FundWalletLoader, data: FundWalletData }] =
    useMutation(FUND_WALLET, {
      variables: {
        amount: parseInt(amount),
        confirmPageUrl: `${window.location.origin}/dashboard/confirm-payment`,
      },
      async onCompleted(data) {
        const result = data.advertiser.initializeFundWallet;

        switch (result.paymentMethod) {
          case "Paystack":
            window.location.href = result.authorizationUrl;

            break;

          default:
        }
      },
      onError(error) {
        if (!amount) {
          toast.error("Enter required amount");
        } else {
          toast.error(error?.networkError?.result?.errors[0]?.message);
        }
      },
    });

  /**
   * Updates the status of a transaction with the given ID to "Approved".
   *
   * @param {number} id - The ID of the transaction to update.
   */
  function updateTransactionStatus(id) {
    // Find the transaction with the given ID.
    let temp = transactions.filter((trnx) => trnx.id === id)[0];

    if (temp) {
      // Update the status of the transaction to "Approved".
      temp = {
        ...temp,
        status: "Approved",
      };

      // Replace the old transaction with the updated one and sort the array by transaction date.
      setTransactions(
        [...transactions.filter((trnx) => trnx.id !== id), temp].sort(
          (a, b) => {
            return new Date(b.transactionDate) - new Date(a.transactionDate);
          }
        )
      );
    }
  }

  useEffect(() => {
    // transactionDetails();

    if (data)
      setTransactions(data?.advertiser?.getTransactionHistory?.transactions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const walletItems = [
    {
      value: 5000,
      title: "50K",
    },
    {
      value: 100000,
      title: "100K",
    },
    {
      value: 200000,
      title: "200K",
    },
    {
      value: 500000,
      title: "500K",
    },
    {
      value: 1000000,
      title: "1M",
    },
    {
      value: 2000000,
      title: "2M",
    },
  ];

  const HandleAmount = () => {
    if (amount <= 0) {
      return setIsError(true);
    }
    setIsError(false);
    fundWallet();
  };

  const HanldeFund = (value) => {
    console.log("value", value);
  };

  const columnData = [
    {
      title: `Transaction ID`,
      isDropDown: false,
      isFilter: false,
      options: [],
    },
    {
      title: `Transaction Date`,
      isDropDown: false,
      isFilter: false,
      options: [],
    },
    {
      title: `Payment Method`,
      isDropDown: false,
      isFilter: false,
      options: [],
    },
    {
      title: `Amount`,
      isDropDown: false,
      isFilter: true,
      options: [],
    },
    {
      title: `Status`,
      isDropDown: true,
      isFilter: false,
      options: [
        {
          value: "Pending",
        },
        {
          value: "Approved",
        },
        {
          value: "Cancelled",
        },
      ],
    },
  ];

  const shuffledData =
    data?.advertiser?.getTransactionHistory?.transactions?.map((trnx) => ({
      transactionID: trnx.transactionId || "-- -- --",
      date: moment(trnx.transactionDate).format("MMM Do YYYY"),
      paymentMethod: trnx.paymentMethod || "-- -- --",
      amount: trnx.amount.toLocaleString("en-US") || "-- -- --",
      status: trnx.status || "-- -- --",
      refresh: (
        <RefreshButton
          key={trnx.id}
          status={trnx.status}
          transactionId={trnx.id}
          updateTransactionStatus={updateTransactionStatus}
        />
      ),
    }));

  const columns = [
    { key: "transactionID", label: "Transaction ID" },
    { key: "date", label: "Transaction Date" },
    { key: "paymentMethod", label: "Payment Method" },
    { key: "amount", label: "Amount" },
    { key: "status", label: "Status" },
    { key: "refresh", label: "refresh" },
  ];
  return (
    <AdvertDashboardLayout>
      <div className="wallet-container">
        <div>
          <div className="wallet-header">
            <h4 className="wallet-head">wallets</h4>
          </div>
          <div className="wallet-balance-container">
            <div className="wallet-balance-one">
              <div>
                <h4 className="balance-txt">Balance</h4>
                <span className="current-balance">
                  {loading ? (
                    <Skeleton width="80px" height="20px" />
                  ) : (
                    fmtCurrency(
                      walletBalance.data?.advertiser.getWalletBalance
                        .currentBalance
                    )
                  )}
                </span>
              </div>

              <div>
                <button
                  className={`${
                    loading ? "withdraw-btn-disabled" : "withdraw-btn"
                  }`}
                  onClick={() => {
                    if (
                      walletBalance.data?.advertiser.getWalletBalance
                        .currentBalance == 0
                    ) {
                      setNoMoney(true);
                      return setTimeout(() => {
                        setNoMoney(false);
                      }, 10000);
                    }
                    onOpen();
                  }}
                  disabled={loading}
                >
                  Withdraw
                </button>
              </div>
            </div>
            {/* <div className="fund-wrap">
              <h4 className="fund-text">Fund Wallet</h4>
              <div className="input-funds">
                {walletItems.map(({ value, title }, index) => {
                  return (
                    <div
                      key={index}
                      className="wallet-fund"
                      onClick={() => HanldeFund(value)}
                    >
                      {title}
                    </div>
                  );
                })}
                <div className="wallet-fund">
                  <MdAdd className="wallet-add" />
                </div>
              </div>
            </div> */}
          </div>
          {noMoney && (
            <span className="sapa">
              You do not have enough money in your wallet to initiate withdrawal
            </span>
          )}
        </div>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Process Withdrawal</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className="payment-card-wrapper">
                <div>
                  <div className="active-payment-method">
                    <div>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_1708_1641)">
                          <path
                            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                            stroke="#F99B28"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9 12L11 14L15 10"
                            stroke="#F99B28"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1708_1641">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <img src={CardTwo} alt="paystack" />
                  </div>
                  <span> Pay with Paystack</span>
                </div>
              </div>
              <div className="campaign-form-card-wrapper">
                <div className="amount_input_wrap">
                  <label htmlFor="campaign_name">Amount:</label>
                  <input
                    type="number"
                    placeholder="Enter an amount"
                    onChange={(e) => setAmount(e.target.value)}
                    min={1}
                  />
                </div>
                {isError && (
                  <span className="isError">Enter a valid amount</span>
                )}
              </div>
            </ModalBody>

            <ModalFooter className="proceed-wrap">
              <button
                onClick={() => HandleAmount()}
                className={`${amount === 0 ? "disabled-btn" : "proceed"}`}
                disabled={amount === 0}
              >
                {FundWalletLoader ? (
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                ) : (
                  "Proceed"
                )}
              </button>
              <button
                colorScheme="blue"
                mr={3}
                onClick={() => {
                  setIsError(false);
                  onClose();
                }}
                className="close-withdraw"
              >
                Close
              </button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <div className="history-wrapper">
          <div>
            <h3 className="history-txt">Transaction History</h3>
          </div>

          <TransmitPagination
            data={shuffledData}
            column={columnData}
            isPerPage={true}
            columns={columns}
            isClickable={true}
            hasMore={data?.advertiser?.getTransactionHistory?.hasMore}
            totalPage={totalPages}
            isPerginate={true}
            currentPage={data?.advertiser?.getTransactionHistory?.page}
            handleNextOrPrevPage={handleNextOrPrevPage}
            itemsPerPage={itemsPerPage}
            handlePerPageChange={handlePerPageChange}
          />
        </div>
        <div className="station-table-container">
          {!loading ? (
            <>
              <div className="dashboard-table-containers">
                <CampaignTable
                  data={shuffledData}
                  column={columnData}
                  isPerPage={true}
                  columns={columns}
                  isClickable={true}
                  hasMore={data?.advertiser?.getTransactionHistory?.hasMore}
                  totalPage={totalPages}
                  isPerginate={false}
                  currentPage={data?.advertiser?.getTransactionHistory?.page}
                  handleNextOrPrevPage={handleNextOrPrevPage}
                  itemsPerPage={itemsPerPage}
                  handlePerPageChange={handlePerPageChange}
                />
              </div>
            </>
          ) : (
            <AdvertiserHomeDashboardLoader header={true} />
          )}
        </div>
      </div>
    </AdvertDashboardLayout>
  );
};

export default Wallet;
