import React, { useState, useEffect } from "react";
import "./transmitHero.css";
import wave from "../../transmitAssets/wave.gif";
import HeroImage from "../../transmitAssets/radioHero.gif";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import Aos from "aos";
import "aos/dist/aos.css";
import Tilt from "react-parallax-tilt";

const TransmitLogo = () => {
  const [openOption, setOpenOption] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({
      duration: 2000,
      offset: 0,
    });
  }, []);
  useEffect(() => {
    // track user event
    // if start a campaign button is clicked
    openOption === true && mixpanel.track("start_campaign_home");
  }, openOption);

  const routeHandler = (route) => {
    // register user event
    switch (route) {
      case "/book-campaign/":
        mixpanel.track("do_it_myself");
        break;

      case "/talk-with-an-expert":
        mixpanel.track("help_me_do_it");
        break;

      default:
        break;
    }

    navigate(route);
  };
  return (
    <div className="transmitHero-wrapper" data-aos="fade-up">
      <div className="hero-sec1">
        <div className="hero-text" data-aos="fade-right">
          <p>
            The{" "}
            <span className="text-lower">
              <span className="hero-color"> new </span>
              age <br />
              <span className="wave-wrap">
                <img src={wave} alt="wave" className="wave" />
              </span>
              {"  "}
              of radio <br />
              advertisement
            </span>
          </p>
        </div>
        <div className="hero-motto" data-aos="fade-left">
          <p>
            C
            <span className="text-lower">
              reativity meets connectivity for unparalleled audience engagement
              and impact!
            </span>
          </p>
        </div>
        <article>
          <div
            className="hero-btn"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
            onClick={() => setOpenOption(!openOption)}
          >
            <p>Start campaign</p>
          </div>
        </article>
        <div>
          {openOption && (
            <div className="option-btn">
              <button
                onClick={() => routeHandler("/book-campaign/")}
                className="hero-option-btn"
              >
                Yes, I can do it myself.
              </button>
              <button
                onClick={() => routeHandler("/talk-with-an-expert")}
                className="hero-option-btn"
              >
                No, Help me with it
              </button>
            </div>
          )}
        </div>
      </div>

      <div
        className="hero-img-wrapper"
        data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
      >
        <Tilt tiltReverse={true} scale={1}>
          <img src={HeroImage} className="hero-image" alt="hero Image" />
        </Tilt>
      </div>
    </div>
  );
};

export default TransmitLogo;
