import React from "react";

import { useQuery } from "@apollo/client";
import SalesIcon from "../assets/dashboardIcons/salesIcon.svg";
import PendingIcon from "../assets/dashboardIcons/pendingIcon.svg";
import CommissionIcon from "../assets/dashboardIcons/commission.svg";
import EarningIcon from "../assets/dashboardIcons/earningIcon.svg";
import CampaignIcon from "../assets/dashboardIcons/campaignImg.svg";
import "./css/dashboard-report.css";

import VendorDashbaordLayout from "./ResponsiveAdvertiserDashboard/ResponsiveAdvertiserDashboardLayout/vendorDashboard/VendorDashbaordLayout";

import { GET_PAYMENT_HISTORY } from "../components/GraphQL/Queries";
import ReportComponent from "../components/Report/ReportComponent";

const DashboardReport = () => {
 
  const {
    data: { broadcaster: { getPaymentHistory = [] } = {} } = {},
    loading,
  } = useQuery(GET_PAYMENT_HISTORY);
  const data = [
    {
      date: "Mar 28th 2022",
      orderId: "#472356",
      type: "Jingle",
      sales: "₦ 3,225.00",
      commission: "₦483.75",
      earnings: "₦2,741.25",
      amount: "₦2,741.25",
      status: "pending",
      paymentMethod: "flutterwave",
    },
    {
      date: "Mar 28th 2022",
      orderId: "#472356",
      type: "Jingle",
      sales: "₦ 3,225.00",
      commission: "₦483.75",
      earnings: "₦2,741.25",
      amount: "₦2,741.25",
      status: "pending",
      paymentMethod: "flutterwave",
    },
    {
      date: "Mar 28th 2022",
      orderId: "#472356",
      type: "Jingle",
      sales: "₦ 3,225.00",
      commission: "₦483.75",
      earnings: "₦2,741.25",
      amount: "₦2,741.25",
      status: "pending",
      paymentMethod: "flutterwave",
    },
    {
      date: "Mar 28th 2022",
      orderId: "#472356",
      type: "Jingle",
      sales: "₦ 3,225.00",
      commission: "₦483.75",
      earnings: "₦2,741.25",
      amount: "₦2,741.25",
      status: "pending",
      paymentMethod: "flutterwave",
    },
  ];
  console.log(getPaymentHistory)

  return (
    <VendorDashbaordLayout>
      <div className="report-container">
        <h2>Reports</h2>
        <ReportComponent 
        loading={loading}
        data={data}
        getPaymentHistory={getPaymentHistory}
        />
      </div>
    </VendorDashbaordLayout>
  );
};

export default DashboardReport;
