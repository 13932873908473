import { Spinner } from '@chakra-ui/react';
import React from 'react'
import styled from 'styled-components';
import { ButtonText } from '../TextCompnents';

const SubmitButtonComponent = ({ updateSlotLoading }) => {
  return (
    <SubmitButton>
    {!updateSlotLoading ? (
      <ButtonText>Save</ButtonText>
    ) : (
      <Spinner size="xs" color="white" />
    )}
  </SubmitButton>
  )
}

export default SubmitButtonComponent;

const SubmitButton = styled.button`
  width: 210px;
  height: 44px;
  background: #f99b28;
  border-radius: 2px 2px 0px 0px;
`;