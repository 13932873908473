import React, { useState } from "react";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { GET_ALL_ADMIN_RADIO_VENDOR_TRANSACTIONS } from "../components/GraphQL/Queries";
import { useQuery } from "@apollo/client";
import "./css/admin_roles.css";

import AdminRoleTable from "../components/AdminRoleTable/AdminRoleTable";
import AddPermission from "../components/Modal/AddPermission/AddPermission";

const AdminRoles = () => {
  const [open, setOpen] = useState(false);
  const { data, loading, refetch } = useQuery(
    GET_ALL_ADMIN_RADIO_VENDOR_TRANSACTIONS
  );

  const Open = () => {
    setOpen(true);
  };
  const Close = () => {
    setOpen(false);
  };

  const tableHeader = [
    {
      title: "NAME",
    },
    {
      title: "EMAIL ADDRESS",
    },
    {
      title: "ROLE",
    },
  ];

  const tableData = [
    {
      id: "1",
      name: "Mercy Oluwatobi",
      email: "Exampleaddress@outlook.com",
      role: "Editor",
    },
    {
      id: "1",
      name: "Mercy Oluwatobi",
      email: "Exampleaddress@outlook.com",
      role: "Admin",
    },
    {
      id: "1",
      name: "Mercy Oluwatobi",
      email: "Exampleaddress@outlook.com",
      role: "Account",
    },
    {
      id: "1",
      name: "Mercy Oluwatobi",
      email: "Exampleaddress@outlook.com",
      role: "Admin",
    },
    {
      id: "1",
      name: "Mercy Oluwatobi",
      email: "Exampleaddress@outlook.com",
      role: "Admin",
    },
    {
      id: "1",
      name: "Mercy Oluwatobi",
      email: "Exampleaddress@outlook.com",
      role: "Editor",
    },
    {
      id: "1",
      name: "Mercy Oluwatobi",
      email: "Exampleaddress@outlook.com",
      role: "Editor",
    },
    {
      id: "1",
      name: "Mercy Oluwatobi",
      email: "Exampleaddress@outlook.com",
      role: "Editor",
    },
    {
      id: "1",
      name: "Mercy Oluwatobi",
      email: "Exampleaddress@outlook.com",
      role: "Editor",
    },
    {
      id: "1",
      name: "Mercy Oluwatobi",
      email: "Exampleaddress@outlook.com",
      role: "Editor",
    },
    {
      id: "1",
      name: "Mercy Oluwatobi",
      email: "Exampleaddress@outlook.com",
      role: "Editor",
    },
    {
      id: "1",
      name: "Mercy Oluwatobi",
      email: "Exampleaddress@outlook.com",
      role: "Editor",
    },
    {
      id: "1",
      name: "Mercy Oluwatobi",
      email: "Exampleaddress@outlook.com",
      role: "Editor",
    },
    {
      id: "1",
      name: "Mercy Oluwatobi",
      email: "Exampleaddress@outlook.com",
      role: "Editor",
    },
    {
      id: "1",
      name: "Mercy Oluwatobi",
      email: "Exampleaddress@outlook.com",
      role: "Editor",
    },
  ];

  //   function to handleRow
  const handleRow = () => {};

  return (
    <AdminDashboardLayout>
      {/* {loading ? (
        <ChakraProvider>
          <Center h="100vh">
            <TableSpinner />
          </Center>
        </ChakraProvider>
      ) : ( */}
      <>
        <div className="Home_Dashboard_wrap">
          <div className="admin_role_header">
            <div className="admin_role_btn" onClick={Open}>
              <p>Add a new role</p>
            </div>
          </div>

          <div className="profile_tab_wrapper">
            <AdminRoleTable
              header={tableHeader}
              data={tableData || []}
              loading={loading}
              refetch={refetch}
              showFilter={true}
              handleRow={handleRow}
            />
          </div>
        </div>
        <AddPermission openModal={open} closeModal={Close} isNew={true} />
      </>
    </AdminDashboardLayout>
  );
};

export default AdminRoles;
