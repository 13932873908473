import React from "react";
import TransmitAuthLayout from "./TransmitAuthLayout";
import "./css/login.css"

const TransmitLogin = () => {
  return (
    <div>
      <TransmitAuthLayout authType="login">
        <div className="transmitLoginWrapper">
          <div className="transmitLoginHeader">
            <h1 className="transmitLoginTitle">Login</h1>
            {/* <h1 className="transmitLoginTitle">Login<h1> */}
            <p className="transmitLoginSubTitle">Log In to your dashboard</p>
          </div>

          <div className="transmitLoginFormWrapper">
            <div className="formInputWrapper">
              <label className="formInputLabel">Email</label>
              <input type="text" className="formInput" />
            </div>

            <div className="formInputWrapper">
              <label className="formInputLabel">Password</label>
              <input type="text" className="formInput" />
            </div>
          </div>

          <div className="rememberMeAndForgotPasswordWrapper">
            <div className="rememberMeWrapper">
              <input type="checkbox" />
              <p>Remember me</p>
            </div>
            <div className="forgotPasswordWrapper">Forgot Password</div>
          </div>

          <button
            //   type="button"
            className="transmitBtn btn-continue"
          >
            Login
          </button>

          <div>
            Don’t have an account? Sign Up as an{" "}
            <span className="changeTextColor">Advertiser</span> or as a{" "}
            <span className="changeTextColor">Radio Station</span>
          </div>
        </div>
      </TransmitAuthLayout>
    </div>
  );
};

export default TransmitLogin;
