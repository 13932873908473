import { Box } from "@chakra-ui/react";


const DateRangePicker = ({ isDraftDate, startDate, setStartDate, endDate, setEndDate, date, setDate }) => {
    return (
        <Box display={'flex'}>
            <DateInput
                selectedDate={!isDraftDate ? startDate : date}
                onDateChange={(date) => { !isDraftDate ? setStartDate(date) : setDate(date) }}
                placeholder={!isDraftDate ? "Start Date" : "Date"}
                id={!isDraftDate ? "start-date" : 'date'}
            />
            {!isDraftDate && <DateInput
                selectedDate={endDate}
                onDateChange={(date) => setEndDate(date)}
                placeholder="End Date"
                id="end-date"
            />}
        </Box>
    );
};

export default DateRangePicker;

const DateInput = ({ id, placeholder, selectedDate, onDateChange }) => {
    const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        onDateChange(selectedDate);
    };

    return (
        <div id="select-date" className='campaignList-select-date'>
            <div>
                <div>
                    <input
                        id={id}
                        type="date"
                        onChange={handleDateChange}
                        value={selectedDate || ''}
                        style={{
                            color: selectedDate ? '#000' : 'red',
                            background: '#fff',
                            border: '1px solid #ccc',
                            padding: '0.5rem',
                            borderRadius: '4px',
                            outline: 'none',
                            
                        }}
                    />
                    <input
                        className="formatted-date"
                        type="text"
                        placeholder={placeholder}
                        value={selectedDate || ''}
                        readOnly
                        style={{
                            color: selectedDate ? '#000' : 'red',
                            background: '#fff',
                            // border: '1px solid #ccc',
                            padding: '0.5rem',
                            borderRadius: '4px',
                            outline: 'none',
                            fontWeight: "400",
                            fontSize: '16px'
                        }}
                    />
                </div>
            </div>
        </div>
    );
};