import React, { useState } from "react";
import "./adminstatestable.css";
import filtersvg from "../../assets/icons/filter_up_down.svg";
import AdminTransactionLoader from "../Loader/AdminTransactionLoader/AdminTransactionLoader";
import SearchIcon from "../AdminTransactionTable/searchIcon";
import { Switch } from "@chakra-ui/react";

const AdminStatesTable = ({
  data,
  header,
  loading,
  refetch,
  showFilter,
  isState,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [entries, setEntries] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [switchStates, setSwitchStates] = useState({});

  const handleChange = (id) => {
    // Update the local state for the specific switch ID
    setSwitchStates((prevStates) => ({
      ...prevStates,
      [id]: !prevStates[id],
    }));
  };

  const handlePagination = (action) => {
    if (action === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (action === "next" && data.length > currentPage * entries) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset current page when searching
  };

  const filteredData = data?.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const totalPages = Math.ceil(filteredData.length / entries);

  const paginatedData = filteredData?.slice(
    (currentPage - 1) * entries,
    currentPage * entries
  );

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <span
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </span>
      );
    }
    return pageNumbers;
  };

  return (
    <>
      {loading ? (
        <AdminTransactionLoader />
      ) : (
        <div className="admin_wrapper_states">
          <div
            className={`${
              isState === true
                ? "admin_table_head_states"
                : "admin_table_head_station"
            }`}
          >
            <div className="admin_entry">
              <span>Show</span>
              <select
                value={entries}
                onChange={(e) => setEntries(parseInt(e.target.value))}
              >
                <option>10</option>
                <option>20</option>
                <option>50</option>
              </select>
              <span>entries</span>
            </div>

            <div className="input-box form-control">
              <input
                type="text"
                className="search-input form-control"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearch}
              />
              <SearchIcon />
            </div>
          </div>
          {/* TABLE SECTION */}

          <div className="">
            <div
              className={`${
                isState === true
                  ? "admin_table-body-container_states"
                  : "admin_table-body-container_station"
              }`}
            >
              <table
                id="data-table"
                className="admin_table"
                width="75%"
                // height="0%"
              >
                <thead>
                  <tr>
                    {header.map((item, index) => {
                      return (
                        <th key={index} style={{ textAlign: "center" }}>
                          <div className="admin_table_header_stated">
                            <span>{item?.title}</span>
                            {showFilter && (
                              <span>
                                <img src={filtersvg} alt="filtersvg" />
                              </span>
                            )}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.length !== 0 ? (
                    <>
                      {/* start of body */}
                      {paginatedData?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td
                              style={{
                                color: "#004643",
                                fontSize: "12px",
                                cursor: "pointer",
                                textAlign: "center",
                              }}
                            >
                              {item?.name}
                            </td>
                            <td
                              style={{
                                fontSize: "12px",
                                color: "#004643",
                                textAlign: "center",
                              }}
                            >
                              <Switch
                                key={item.id}
                                id={item.id}
                                size="md"
                                colorScheme="green"
                                isChecked={
                                  item?.status || switchStates[item.id]
                                }
                                onChange={() => handleChange(item.id)}
                              />
                            </td>
                          </tr>
                        );
                      })}

                      {/* end of body */}
                    </>
                  ) : (
                    <tr className="admin_not_found">
                      <td> No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <div className="admin_footer_wrapper_states">
        <div>
          <section className="admin_pagination-section">
            <div>
              showing {"  "}
              {`${(currentPage - 1) * entries + 1} to ${Math.min(
                currentPage * entries,
                filteredData.length
              )} of ${filteredData.length} entries`}
            </div>
          </section>
        </div>
        {/* Pagination section */}
        <div className="admin_pagination">
          <span onClick={() => handlePagination("prev")}>Previous</span>
          {renderPageNumbers()}
          <span onClick={() => handlePagination("next")}>Next</span>
        </div>
      </div>
    </>
  );
};

export default AdminStatesTable;
