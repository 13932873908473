import React, { useState, useEffect, useRef } from "react";
import "./partners.css";
import Bussiness from "../../transmitAssets/bussiness.svg";
import BussinessDay from "../../transmitAssets/bussinessDay.svg";
import independent from "../../transmitAssets/independent.svg";
import market from "../../transmitAssets/marketEdge.svg";
import vanguard from "../../transmitAssets/vanguard.svg";
import Aos from "aos";
import "aos/dist/aos.css";
import von from "../../transmitAssets/von.svg";
import { animate, motion, useMotionValue } from "framer-motion";

const Partners = () => {
  const FAST_DURATION = 40;
  const SLOW_DURATION = 75;

  const [duration, setDuration] = useState(FAST_DURATION);

  const xTranslation = useMotionValue(0);

  const containerRef = useRef(null);

  const [mustFinish, setMustFinish] = useState(false);
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    Aos.init({
      duration: 2000,
      offset: 0,
    });
  }, []);

  useEffect(() => {
    let controls;
    let finalPosition = -containerRef.current.clientWidth * 3.5;

    if (mustFinish) {
      controls = animate(xTranslation, [xTranslation.get(), finalPosition], {
        ease: "linear",
        duration: duration * (1 - xTranslation.get() / finalPosition),
        onComplete: () => {
          setMustFinish(false);
          setRerender(!rerender);
        },
      });
    } else {
      controls = animate(xTranslation, [0, finalPosition], {
        ease: [0.645, 0.045, 0.355, 1],
        duration: duration,
        repeat: Infinity,
        repeatType: "loop",
        repeatDelay: 0,
      });
    }

    return controls?.stop;
  }, [rerender, xTranslation, duration]);
  const imageUrl = [
    {
      name: "marketingedge-image",
      img: market,
      path: "https://marketingedge.com.ng/space-universe-launches-ai-driven-global-radio-ad-platform-radioadspread/",
    },
    {
      name: "businessday-image",
      img: BussinessDay,
      path: "https://businessday.ng/news/article/tech-firm-eases-radio-advertisement-launches-digital-platform/",
    },
    {
      name: "vanguardlogo-image",
      img: vanguard,
      path: "https://www.vanguardngr.com/2022/12/radio-advertisement-goes-digital-as-group-unveils-technology-in-lagos/",
    },
    {
      name: "independentlogo-image",
      img: independent,
      path: "https://independent.ng/radioadspread-launches-platform-to-bridge-gap-between-advertisers-radio-stations/",
    },
    {
      name: "businessinsider-image",
      img: Bussiness,
      path: "https://africa.businessinsider.com/local/markets/radio-advertisement-booking-goes-digital/b4wqewq",
    },
    {
      name: "von-image",
      img: von,
      path: "https://von.gov.ng/nigerian-technology-company-launches-online-radio-advertisement-platform/",
    },
  ];
  return (
    <div className="partners-wrapper" data-aos="fade-up">
      <div className="as-featured" data-aos="fade-right">
        <p>As Featured In</p>
      </div>
      <div className="motion-wrapper" data-aos="fade-up">
        <motion.div
          className="partners-container"
          style={{ x: xTranslation }}
          ref={containerRef}
          onHoverEnd={() => {
            setMustFinish(true);
            setDuration(FAST_DURATION);
          }}
          onHoverStart={() => {
            setMustFinish(true);
            setDuration(SLOW_DURATION);
          }}
        >
          {[
            ...imageUrl,
            ...imageUrl,
            ...imageUrl,
            ...imageUrl,
            ...imageUrl,
            ...imageUrl,
            ...imageUrl,
            ...imageUrl,
            ...imageUrl,
            ...imageUrl,
            ...imageUrl,
            ...imageUrl,
            ...imageUrl,
            ...imageUrl,
          ].map(({ name, img, path }, index) => {
            return (
              <div className="partners-img" key={index}>
                <a href={path}>
                  <img
                    src={img}
                    className="partners-image"
                    alt={name}
                    //   width="50%"
                    //   height="50%"
                  />
                </a>
              </div>
            );
          })}
        </motion.div>
      </div>
    </div>
  );
};

export default Partners;
