import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import { useDropzone } from "react-dropzone";

import { fileAtom } from "../../atom/fileAtom";
import DiscountRate from "../UpdateRadioStationStationPrices/DiscountRate/DiscountRate";
import JinglesRate from "../UpdateRadioStationStationPrices/JinglesRate/JinglesRate";
import { useMutation } from "@apollo/client";
import { UPDATE_RADIOSTATION_DETAILS_ADMIN } from "../GraphQL/Queries";
import { useFormik } from "formik";
import { UPDATE_BROADCASTER_PROFILEDETAILS_BY_ADMIN } from "../GraphQL/Mutation";

const EditAboutRadioStation = ({
  editRadioStationDetails,
  listOfDiscounts,
  slotRates,
  broadcasterId,
  updateBroadcasterSlot,
  createDiscount,
  getDiscountLoad,
  updateSlotLoading,
}) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [inventoryValues, setInventoryValues] = useState([]);

  const [file, setFile] = useAtom(fileAtom);
  const toast = useToast();

  const [editRadioStation, { loading: editRadioStationLoading }] = useMutation(
    UPDATE_BROADCASTER_PROFILEDETAILS_BY_ADMIN
  );

  // const { data } = useQuery(UPDATE_RADIOSTATION_DETAILS_ADMIN);

  console.log(editRadioStationDetails, "nnnnnn");

  useEffect(() => {
    const allSlotData = slotRates ?? [];
    const newSlotData =
      allSlotData?.length &&
      allSlotData.map((slot) => {
        const newSlot = { ...slot };
        delete newSlot.broadcasterId;
        delete newSlot.endTime;
        delete newSlot.startTime;
        delete newSlot.__typename;
        return { ...newSlot };
      });
    setInventoryValues(newSlotData);
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles[0].size / 1024 / 1024 > 1
      ? toast({
          title: "File too Large",
          description: "FIle is too large. Maximum size is 1MB",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        })
      : setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  const stationHeader = [
    "Discounts Rates",
    "Jingles Rates",
    "Paid Announcement Rates",
  ];

  const { handleSubmit, handleChange, values } = useFormik({
    enableReinitialize: true,
    initialValues: {
      radioStation: editRadioStationDetails?.radioStation,
      tagline: editRadioStationDetails?.tagline,
      state: editRadioStationDetails?.state,
      aboutUs: editRadioStationDetails?.aboutUs,
      logo: editRadioStationDetails?.logo,
    },
    onSubmit: (values) => {
      // Prepare mutation variables
      const mutationVariables = {
        input: {
          broadcasterId,
          radioStation: values.radioStation,
          tagline: values.tagline,
          state: values.state,
          aboutUs: values.aboutUs,
        },
      };

      // Add logo to mutation variables if file is present
      if (file) {
        mutationVariables.input.logo = file;
      }

      editRadioStation({
        variables: mutationVariables,

        onCompleted: () => {
          toast({
            title: "Radio station profile updated successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        },
        onError: () => {
          toast({
            title: "Error updating radio station details, Try Again",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        },
      });
    },
  });

  console.log(file, "file");

  return (
    <Box pl={55} pr={65}>
      <Box
        className="edit_vendor_form_container"
        borderBottom="0.8px solid #DADADA"
        pb={50}
      >
        <form onSubmit={handleSubmit}>
          <Flex>
            <Stack w="80%" spacing={6}>
              <FormControl isRequired maxW={400} w="100%">
                <FormLabel>Radio Station</FormLabel>
                <Input
                  name="radioStation"
                  placeholder="Beat FM"
                  value={values.radioStation}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl isRequired maxW={400} w="100%">
                <FormLabel>Tagline</FormLabel>
                <Input
                  name="tagline"
                  placeholder="The HeartBeat Of Lagos"
                  value={values.tagline}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl isRequired maxW={400} w="100%">
                <FormLabel>State</FormLabel>
                <Input
                  name="state"
                  placeholder="Lagos State"
                  value={values.state}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl isRequired maxW={400} w="100%">
                <FormLabel>About</FormLabel>
                <Textarea
                  name="aboutUs"
                  placeholder="Here is a sample placeholder"
                  h={200}
                  value={values.aboutUs}
                  onChange={handleChange}
                />
              </FormControl>
              <Button
                p="10px 25px"
                w="150px"
                color="#004643"
                bg="#fff"
                border="1px solid #F99B28"
                mt={4}
                type="submit"
                loadingText="Saving"
                isLoading={editRadioStationLoading}
              >
                Save
              </Button>
            </Stack>
            <Box maxW={465} w="100%">
              <Text mb={4}>Logo</Text>
              <Box border="0.8px dashed #ABA8A8" p="10px 30px">
                <img
                  src={values.logo}
                  style={{
                    width: "250px",
                    height: "250px",
                    margin: "0 auto",
                  }}
                  alt="logo"
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  w="100%"
                  gap={2}
                >
                  <Text>Drag and Drop files here</Text>
                  <Text>File Format: JPEG/ PNG/ GIF</Text>
                  <Button
                    color="#F99B28"
                    variant="outline"
                    w={150}
                    borderRadius="none"
                  >
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />

                      <Box> {file ? "file replaced" : "Replace Image"}</Box>
                    </div>
                  </Button>
                  <Text>Maximum Size: 500KB</Text>
                </Box>
              </Box>
            </Box>
          </Flex>
        </form>
      </Box>

      <Box>
        <div className="station-container admin_view">
          <div className="btn-option-wrapper">
            {stationHeader.map((btn, index) => {
              return (
                <button
                  onClick={() => setPageIndex(index)}
                  className={index === pageIndex ? "bg-color" : ""}
                >
                  {btn}
                </button>
              );
            })}
          </div>
          {pageIndex === 0 && (
            <DiscountRate
              createDiscount={createDiscount}
              getDiscount={listOfDiscounts || []}
              setInventoryValues={setInventoryValues}
              userId={broadcasterId}
              getDiscountLoad={getDiscountLoad}
            />
          )}

          {pageIndex === 1 && (
            <JinglesRate
              inventoryValues={inventoryValues}
              slotData={slotRates}
              // loading={loading}
              updateSlotLoading={updateSlotLoading}
              setInventoryValues={setInventoryValues}
              // handleChange={handleChange}
              updateSlot={updateBroadcasterSlot}
              tabIndex={pageIndex}
            />
          )}
          {pageIndex === 2 && (
            <JinglesRate
              inventoryValues={inventoryValues}
              slotData={slotRates}
              // loading={loading}
              updateSlotLoading={updateSlotLoading}
              setInventoryValues={setInventoryValues}
              // handleChangeWords={handleChangeWords}
              updateSlot={updateBroadcasterSlot}
              tabIndex={pageIndex}
            />
          )}
        </div>
      </Box>
    </Box>
  );
};

export default EditAboutRadioStation;
