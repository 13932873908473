import { useState } from "react";
// import AuthLayout from "./AsuthLayout";
// import TransmitNavbar from "../TransmitNavbar";

import "./css/signup.css";

import TransmitAuthLayout from "./TransmitAuthLayout";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../transmitAssets/transmitLogo.svg";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
import { AUTH_TOKEN } from "../../constant";
import { BroadcasterSignupSchema } from "../../utils/validations";
import { useFormik } from "formik";
import { Country, State } from "country-state-city";
import { BROADCASTER_REGISTER } from "../GraphQL/Mutation";
import { useMutation } from "@apollo/client";

const TransmitSignUp = () => {
  const [stepper, setStepper] = useState(1);

  const [formData, setFormData] = useState({
    nameOfRadioStation: "",
    fullName: "",
    positionHeld: "",
    address: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    url: "",
    website: "",
    frequency: "",
    category: "",
    country: "",
    state: "",
    termsAccepted: false,
  });

  //   const [signUp] = useMutation(SIGN_UP_MUTATION, {
  //     variables: { input: formData },
  //     onError: (error) => {
  //       console.error("Sign up failed:", error);
  //     },
  //     onCompleted: (data) => {
  //       console.log("Sign up successful:", data);
  //       // Redirect or perform any actions after successful sign up
  //     },
  //   });

  // const handleContinue = () => {
  //     if (stepper === 1) {
  //       setStepper(2);
  //     } else {
  //       // Perform sign up mutation
  //       signUp();
  //     }
  //   };

  // const handleChange = (e) => {
  //     const { name, value, type, checked } = e.target;
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       [name]: type === "checkbox" ? checked : value,
  //     }));
  //   };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmAgreement, setConfirmAgreement] = useState(true);
  const [broadcasterSignup, { loading }] = useMutation(BROADCASTER_REGISTER);
  const [states, setStates] = useState([]);
  const [frequencyType, setFrequencyType] = useState("FM");

  const [countrystatename, setCountryStateName] = useState({
    countryName: "",
    stateName: "",
    cityName: "",
  });
  const handleCountry = (e) => {
    const { value } = e.target;
    console.log(value, "mmmmm");

    const getCountryCode = Country.getAllCountries().find(
      (country) => country.name === value
    ).isoCode;
    const getStates = State.getStatesOfCountry(getCountryCode);
    setStates(getStates);
    setCountryStateName({ ...countrystatename, countryName: value });
    setFieldValue("country", value);
  };
  const handleState = (e) => {
    const { value } = e.target;
    setCountryStateName({ ...countrystatename, stateName: value });
    setFieldValue("state", value);
  };

  const navigate = useNavigate();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      nameOfRadioStation: "",
      fullname: "",
      positionHeld: "",
      address: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      radioStationDigitalStreaUrl: "",
      radioStationWebsite: "",
      frequency: "",
      radioStationCategory: "",
      country: "",
      state: "",
    },
    validationSchema: BroadcasterSignupSchema,
    onSubmit: (values) => {
      console.log('aaaaaaaaaa')
      broadcasterSignup({
        variables: {
          input: {
            nameOfRadioStation: values.nameOfRadioStation,
            fullname: values.fullname,
            positionHeld: values.positionHeld,
            address: values.address,
            email: values.email,
            phoneNumber: values.phoneNumber,
            password: values.password,
            confirmPassword: values.confirmPassword,
            radioStationDigitalStreaUrl: values.radioStationDigitalStreaUrl,
            radioStationWebsite: values.radioStationWebsite,
            frequency: values.frequency + " " + frequencyType,
            radioStationCategory: values.radioStationCategory,
            country: values.country,
            state: values.state,
          },
        },
        onCompleted: (data) => {
          localStorage.setItem(AUTH_TOKEN, data.auth.broadcasterRegister.token);
          mixpanel.identify(data?.auth?.broadcasterRegister?.id);
          toast(
            "Sign Up Successful, A Verification Code Has been sent to your email"
          );
          navigate("/signup/verify-account/Broadcaster");
        },
        onError: (err) => {
          toast(err?.networkError?.result?.errors[0]?.message);
        },
      });
    },
  });

  return (
    <div className="transmitAuth">
      <div className="transmitAuthContainer">
        {/* <TransmitNavbar /> */}

        <TransmitAuthLayout authType="signup">
          <div className="transmitSignUpWrapper">
            <div className="transmitLogoForSignupWrapper">
              <img src={logo} alt="logo" />
            </div>
            <div className="transmitSignUpTitleWrapper">
              <h1 className="transmitSignUpTitle">Create account</h1>
              <p className="transmitSignUpSubTitle">
                {stepper === 1 && "Tell us a little about your radio station"}
                {stepper === 2 && "Almost there!"}
              </p>
            </div>
            <div className="formStepperWrapper">
              <span
                className={`stepper ${stepper === 1 ? " currentStepper" : ""} `}
              ></span>
              <span
                className={`stepper ${stepper === 2 ? " currentStepper" : ""} `}
              ></span>
            </div>
            <form onSubmit={handleSubmit}>
              {stepper === 1 && (
                <div className="transmitFormContainer">
                  <div className="formInputWrapper">
                    <label className="formInputLabel">
                      Name of radio station
                    </label>
                    <input
                      type="text"
                      className="formInput"
                      name="nameOfRadioStation"
                      value={values.nameOfRadioStation}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="formInputMultiple">
                    <div className="formInputWrapper">
                      <label className="formInputLabel">Full Name</label>
                      <input
                        type="text"
                        className="formInput"
                        name="fullname"
                        value={values.fullname}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="formInputWrapper">
                      <label className="formInputLabel">Position Held</label>
                      <input
                        type="text"
                        className="formInput"
                        name="positionHeld"
                        value={values.positionHeld}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="formInputWrapper">
                    <label className="formInputLabel">Address</label>
                    <input
                      type="text"
                      className="formInput"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="formInputMultiple">
                    <div className="formInputWrapper">
                      <label className="formInputLabel">Email</label>
                      <input
                        type="text"
                        className="formInput"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="formInputWrapper">
                      <label className="formInputLabel">Phone number</label>
                      <input
                        type="text"
                        className="formInput"
                        name="phoneNumber"
                        value={values.phoneNumber}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="formInputMultiple">
                    <div className="formInputWrapper">
                      <label className="formInputLabel">Password</label>
                      <input
                        type="text"
                        className="formInput"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="formInputWrapper">
                      <label className="formInputLabel">Confirm Password</label>
                      <input
                        type="text"
                        className="formInput"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <button
                    type="button"
                    className="transmitBtn btn-continue"
                    onClick={() => setStepper(2)}
                  >
                    Continue
                  </button>
                  <div className="trasnmitLogin">
                    Already have an account?{" "}
                    <Link to="/auth/login">
                      <span className="loginLink">Login</span>
                    </Link>
                  </div>
                </div>
              )}
              {stepper === 2 && (
                <div className="transmitFormContainer">
                  <div className="formInputWrapper">
                    <label className="formInputLabel">
                      Radio Station Digital Stream URL
                    </label>
                    <input
                      type="text"
                      className="formInput"
                      name="radioStationDigitalStreaUrl"
                      value={values.radioStationDigitalStreaUrl}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="formInputMultiple">
                    <div className="formInputWrapper">
                      <label className="formInputLabel">
                        Radio station website
                      </label>
                      <input
                        type="text"
                        className="formInput"
                        name="radioStationWebsite"
                        value={values.radioStationWebsite}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="formInputWrapper">
                      <label className="formInputLabel">
                        Radio station frequency
                      </label>
                      <input
                        type="text"
                        className="formInput"
                        name="frequency"
                        value={values.frequency}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="formInputMultiple">
                    <div className="formInputWrapper">
                      <label className="formInputLabel">
                        Radio station category
                      </label>
                      <input
                        type="text"
                        className="formInput"
                        name="radioStationCategory"
                        value={values.radioStationCategory}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="formInputWrapper">
                      <label className="formInputLabel">Country</label>
                      <input
                        type="text"
                        className="formInput"
                        name="country"
                        value={values.country}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="formInputMultiple">
                    <div className="formInputWrapper">
                      <label className="formInputLabel">State</label>
                      <input
                        type="text"
                        className="formInput"
                        name="state"
                        value={values.state}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="formInputWrapper"></div>
                  </div>

                  <div className="transmitTerms">
                    <input type="checkbox" />
                    <div>
                      I acknowledge that i have read and accepted the{" "}
                      <span className="changeTextColor">Terms </span>and{" "}
                      <span className="changeTextColor">Conditions </span>and{" "}
                      <span className="changeTextColor">Privacy Policy</span>
                    </div>
                  </div>

                  <button
                      type="submit"
                    className="transmitBtn btn-continue"
                  >
                    Create Account
                  </button>
                  <div className="trasnmitLogin">
                    Already have an account?{" "}
                    <Link to="/auth/login">
                      <span className="loginLink">Login</span>
                    </Link>
                  </div>
                </div>
              )}
            </form>
          </div>
        </TransmitAuthLayout>
      </div>
    </div>
  );
};

export default TransmitSignUp;
