import React from "react";
import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Stack,
  Text,
} from "@chakra-ui/react";

const NotificationLoader = ({ isLoader }) => {
  const skeletonLoading = [1, 2, 3, 4];
  return (
    <Modal
      isCentered
      isOpen={isLoader}
      scrollBehavior="inside"
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader px={0} bg="#004643" color="#fff">
          <Flex
            flexDirection="column"
            gap={6}
            px={6}
            borderBottom="1px"
            borderColor="#004643"
          >
            <Text fontSize={20}>Notifications</Text>
            <SkeletonText noOfLines={1} skeletonHeight="4" w={100} />
          </Flex>
        </ModalHeader>
        <ModalBody px={0}>
          {skeletonLoading.map((loader) => (
            <Flex
              gap={4}
              borderBottom="1px"
              borderColor="#004643"
              py={4}
              px={6}
            >
              <Flex>
                <SkeletonCircle size="10" />
                <Stack m={2}>
                  <Skeleton width="400px" height="30px" />
                  <Skeleton width="70px" height="20px" />
                </Stack>
              </Flex>
            </Flex>
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NotificationLoader;
