import React, { useState } from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
} from "@chakra-ui/react";
import AdminDashboardLayout from "./AdminDashboardLayout";
import SearchIcon from "../components/AdminTransactionTable/searchIcon";
import { useFormik } from "formik";
import EditModal from "../components/Modal/PlatformFees/EditModal";
import filterSvg from "../assets/icons/filter_up_down.svg";

const PlatformFeesData = [
  {
    id: 1,
    radioStation: "Lagos Talks 91.3 FM",
    vat: "8.5",
    commission: "6.2",
  },
  {
    id: 2,
    radioStation: "Lagos Talks 91.3 FM",
    vat: "8.5",
    commission: "6.2",
  },
  {
    id: 3,
    radioStation: "Lagos Talks 91.3 FM",
    vat: "8.5",
    commission: "6.2",
  },
  {
    id: 4,
    radioStation: "Lagos Talks 91.3 FM",
    vat: "8.5",
    commission: "6.2",
  },
  {
    id: 5,
    radioStation: "Lagos Talks 91.3 FM",
    vat: "8.5",
    commission: "6.2",
  },
  {
    id: 6,
    radioStation: "Naija Talks 91.3 FM",
    vat: "8.5",
    commission: "6.2",
  },
  {
    id: 7,
    radioStation: "Lagos Talks 91.3 FM",
    vat: "8.5",
    commission: "6.3",
  },
];

const AdminPlatformFees = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [entries, setEntries] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [fees, setFees] = useState({});
  console.log(fees);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handlePagination = (action) => {
    if (action === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (
      action === "next" &&
      PlatformFeesData?.length > currentPage * entries
    ) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset current page when searching
  };

  const filteredData = PlatformFeesData?.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const totalPages = Math.ceil(filteredData?.length / entries);

  const paginatedData = filteredData?.slice(
    (currentPage - 1) * entries,
    currentPage * entries
  );

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <span
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </span>
      );
    }
    return pageNumbers;
  };

  const { handleSubmit, handleChange, values } = useFormik({
    enableReinitialize: true,
    initialValues: {
      radioStation: fees.radioStation,
      vat: fees.vat,
      commission: fees.commission,
    },
  });
  return (
    <AdminDashboardLayout>
      <EditModal
        isOpen={isOpen}
        onClose={onClose}
        values={values}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
      <Box px={30} h="100%" bg="#fff">
        <Box py={50}>
          <section className="filter_entry_wrapper">
            <div className="filter_entry_dropdown">
              <span>Show</span>
              <select
                name=""
                id=""
                value={entries}
                onChange={(e) => setEntries(parseInt(e.target.value))}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <span>entries</span>
            </div>
            <div className="search_input_wrapper">
              <InputGroup maxW="250px" bg="#EFF6F4">
                <InputRightElement cursor="pointer" py={4}>
                  <SearchIcon />
                </InputRightElement>
                <Input
                  type="text"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </InputGroup>
            </div>
          </section>
        </Box>

        {/* second section  */}

        <section className="station-table-container">
          <table>
            <thead>
              <tr style={{ background: "#EFF6F4" }}>
                <th>
                  <input name="select_all" value="1" type="checkbox" />
                </th>
                <th>
                  <Flex gap={10}>
                  <span>Radio Station</span>
                  <span>
                    <img src={filterSvg} alt="filter-icon" />
                  </span>
                  </Flex>
       
                </th>
                <th>
                  <Flex gap={10}>
                  <span>VAT</span>
                  <span>
                    <img src={filterSvg} alt="filter-icon" />
                  </span>
                  </Flex>

                </th>
                <th>
                  <Flex gap={10}>
                  <span>Commission</span>
                  <span>
                    <img src={filterSvg} alt="filter-icon" />
                  </span>
                  </Flex>

                  </th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map(({ id, radioStation, vat, commission }) => {
                return (
                  <tr key={id}>
                    <td>
                      <input name="select_all" value="1" type="checkbox" />
                    </td>
                    <td className="campaign-id">
                      <span
                        style={{ color: "#F99B28", cursor: "pointer" }}
                        onClick={() => {
                          setFees({
                            radioStation,
                            vat,
                            commission,
                          });
                          onOpen();
                        }}
                      >
                        {radioStation}
                      </span>
                    </td>
                    <td>{vat}</td>
                    <td>{commission}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
        {paginatedData.length > 1 && (
          <div className="admin_footer_wrapper">
            <div>
              <section className="admin_pagination-section">
                <div>
                  showing {"  "}
                  {`${(currentPage - 1) * entries + 1}`} <span>to</span>{" "}
                  {`${Math.min(currentPage * entries, filteredData.length)}`}{" "}
                  <span>of</span> {`${filteredData.length} `}{" "}
                  <span>entries</span>
                </div>
              </section>
            </div>
            {/* Pagination section */}
            <div className="admin_pagination">
              <span onClick={() => handlePagination("prev")}>Previous</span>
              {renderPageNumbers()}
              <span onClick={() => handlePagination("next")}>Next</span>
            </div>
          </div>
        )}
      </Box>
    </AdminDashboardLayout>
  );
};

export default AdminPlatformFees;
