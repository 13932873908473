import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { GET_RADIOSTATIONS_FOR_ADMIN } from "../components/GraphQL/Queries";

import AdminDashboardLayout from "./AdminDashboardLayout";
import AdminTab from "../components/AdminTab/AdminTab";
import AdminTable from "../components/AdminTable/AdminTable";


const AdminRadioStation = () => {
  const [selectedTab, setSelectedTab] = useState("All");
  const [TableData, setTableData] = useState(null);

  const {
    data: { admin: { getAllBroadcasters = [] } = {} } = {},
    loading,
    // refetch,
  } = useQuery(GET_RADIOSTATIONS_FOR_ADMIN);

  const navigate = useNavigate();

  const tableHeader = [
    {
      title: "RADIO STATION",
    },
    {
      title: "EMAIL",
    },
    {
      title: "PHONE",
    },
    {
      title: "DATE",
    },
  ];

  //   function to handleRow
  const handleRow = () => {};

  const tableAdminData = useMemo(() => {
    return getAllBroadcasters.map((columnData) => {
      return {
        id: columnData?.id,
        station: columnData?.radioStation,
        email: columnData?.email,
        phone: columnData?.phone,
        date: columnData?.date,
        status: columnData?.status ? "Active" : "Inactive",
        isEmailVerified: columnData?.isEmailVerified
      };
    });
  }, [getAllBroadcasters]);

  const ActiveTableData = useMemo(() => {
    let activeData;
    if (tableAdminData) {
      activeData = tableAdminData?.filter((row) => row?.status === "Active");
      return activeData;
    }
  }, [tableAdminData]);

  const InactiveTableData = useMemo(() => {
    let inactiveData;
    if (tableAdminData) {
      inactiveData = tableAdminData?.filter((row) => row?.status === "Inactive");
      return inactiveData;
    }
  
  }, [tableAdminData]);

  const activeTableAdminDataLength = useMemo(() => {
    let activeRows;
    if (tableAdminData) {
      activeRows = tableAdminData?.filter((row) => row?.status === "Active");
      return activeRows.length;
    }
    return (activeRows = 0);
  }, [tableAdminData]);

  const inactiveTableAdminDataLength = useMemo(() => {
    let activeRows;
    if (tableAdminData) {
      activeRows = tableAdminData?.filter((row) => row?.status === "Inactive");
      return activeRows.length;
    }
    return (activeRows = 0);
  }, [tableAdminData]);

  //TABS LIST
  let tabs = [
    {
      key: "All",
      value: "All",
      total: tableAdminData?.length || 0,
    },
    {
      key: "Active",
      value: "Active",
      total: activeTableAdminDataLength || 0,
    },
    {
      key: "Inactive",
      value: "Inactive",
      total: inactiveTableAdminDataLength || 0,
    },
  ];

  useEffect(() => {
    if (selectedTab === "Active") {
      setTableData(ActiveTableData);
    } else if (selectedTab === "Inactive") {
      setTableData(InactiveTableData);
    } else {
      setTableData(tableAdminData);
    }
  }, [selectedTab, tableAdminData, ActiveTableData, InactiveTableData]);

  
  return (
    <AdminDashboardLayout>
      <AdminVendorContainer>
        <AdminTab
          tabs={tabs}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          tableAdminData={tableAdminData}
        />
        <AdminTable
          header={tableHeader}
          data={TableData || []}
          loading={loading}
          // refetch={refetch}
          showFilter={true}
          selectedTab={selectedTab}
          handleRow={handleRow}
          handleNavigate={(id) => navigate(`/admin/radio-station/${id}`)}
        />
      </AdminVendorContainer>
      <Outlet />
    </AdminDashboardLayout>
  );
};

export default AdminRadioStation;


const AdminVendorContainer = styled.div`
  background-color: #fff;
  height: 100%;
  padding-bottom: 60px;
`;
