import React from "react";
import { GET_EXCHANGE_RATES } from "../components/GraphQL/Queries";
import { useQuery } from "@apollo/client";
import { Center, ChakraProvider } from "@chakra-ui/react";
import { TableSpinner } from "../components/Spinner";
import AdminDashboardLayout from "./AdminDashboardLayout";
import AdminExchangeRateTable from "../components/AdminExhangeRateTable/AdminExchangeRateTable";

const AdminExchangeRates = () => {
  const { data, loading, refetch } = useQuery(GET_EXCHANGE_RATES);

  const tableHeader = [
    {
      title: "COUNTRY",
    },
    {
      title: "CURRENCY NAME",
    },
    {
      title: "CURRENCY",
    },
    {
      title: "CURRENT RATE",
    },
    {
      title: "EDIT",
    },
  ];
// const loading = false
  console.log("data--", data);

  return (
    <AdminDashboardLayout>
      {loading ? (
        <ChakraProvider>
          <Center h="100vh">
            <TableSpinner />
          </Center>
        </ChakraProvider>
      ) : (
        <>
          <div className="Home_Dashboard_wrap">
            {/* FIRST SECTION FOR THE CARD COMPONENT */}
          <AdminExchangeRateTable
          header={tableHeader}
          refetch={refetch}
          data={data?.admin?.getExchangeRates || []}
          />
          </div>
        </>
      )}
    </AdminDashboardLayout>
  );
};

export default AdminExchangeRates;