import React, { useState, useEffect } from "react";
import "./css/program-schedule.css";
import VendorDashbaordLayout from "./ResponsiveAdvertiserDashboard/ResponsiveAdvertiserDashboardLayout/vendorDashboard/VendorDashbaordLayout";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_PROGRAM_SCHEDULE } from "../components/GraphQL/Queries";
import {
  ADD_PROGRAMME_SCHEDULE,
  DELETE_PROGRAMME_SCHEDULE,
  UPDATE_PROGRAMME_SCHEDULE,
} from "../components/GraphQL/Mutation";

import ProgramScheduleComponent from "../components/ProgramSchedule/ProgramSchedule";

const ProgramSchedulePage = () => {

  const { data: { broadcaster: { getAllProgramSchedule = [] } = {} } = {} } =
    useQuery(GET_ALL_PROGRAM_SCHEDULE);

  const [addProgramSchedule, { loading }] = useMutation(
    ADD_PROGRAMME_SCHEDULE,
    {
      refetchQueries: [
        { query: GET_ALL_PROGRAM_SCHEDULE }, // DocumentNode object parsed with gql
        "GetAllProgramSchedule", // Query name
      ],
    }
  );
  const [updateProgramSchedule, { loading: updateProgLoading }] = useMutation(
    UPDATE_PROGRAMME_SCHEDULE,
    {
      refetchQueries: [
        { query: GET_ALL_PROGRAM_SCHEDULE }, // DocumentNode object parsed with gql
        "GetAllProgramSchedule", // Query name
      ],
    }
  );

  const [deleteProgramSchedule, { loading: deleteProgLoading }] = useMutation(
    DELETE_PROGRAMME_SCHEDULE,
    {
      refetchQueries: [
        { query: GET_ALL_PROGRAM_SCHEDULE }, // DocumentNode object parsed with gql
        "GetAllProgramSchedule", // Query name
      ],
    }
  );
 
  const [day, setDay] = useState("Sunday");
  const [programList, setProgramList] = useState([]);
  const [allChecked, setAllChecked] = useState(false);


  const handleChangeAllCheckBox = () => {
    const newProgList = programList.map((program) => {
      if (program.weekDay === day && allChecked === false) {
        program.selected = true;
        setAllChecked(true);
      }
      if (program.weekDay === day && allChecked === true) {
        program.selected = false;
        setAllChecked(false);
      }
      return program;
    });
    setProgramList([...newProgList]);
  };

  const handleChangeCheckBox = ({ id, weekDay }) => {
    const newProgList = programList.map((program) => {
      if (program.weekDay === weekDay && program.id === id) {
        program.selected = !program.selected;
      }
      return program;
    });

    setProgramList([...newProgList]);
    setAllChecked(false);
  };
  useEffect(() => {
    const mutatedProgramSchedule = getAllProgramSchedule.map((program) => {
      program = { ...program };
      program["selected"] = false;
      program["isDisabled"] = true;
      return program;
    });
    setProgramList(mutatedProgramSchedule);
  }, [getAllProgramSchedule]);


  return (
    <>
      <VendorDashbaordLayout>
        <ProgramScheduleComponent
          programList={programList}
          handleChangeAllCheckBox={handleChangeAllCheckBox}
          handleChangeCheckBox={handleChangeCheckBox}
          addProgramSchedule={addProgramSchedule}
          updateProgramSchedule={updateProgramSchedule}
          deleteProgramSchedule={deleteProgramSchedule}
          loading={loading}
          updateProgLoading={updateProgLoading}
          deleteProgLoading={deleteProgLoading}
        />
      </VendorDashbaordLayout>
    </>
  );
};

export default ProgramSchedulePage;
