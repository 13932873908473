import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BROADCASTER_REGISTER } from "../../components/GraphQL/Mutation";
import { useMutation } from "@apollo/client";
import "../css/signup-vendor.css";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { AUTH_TOKEN } from "../../constant";
import { CloseIcon } from "@chakra-ui/icons";
import { useLoginModalState } from "../../hook";
import countrydata from "../../countries+states.json";
import Login from "../login";
import {
  RadiosStationSignupSchema,
  validateFirstFiveFields,
} from "../../utils/validations";
import mixpanel from "mixpanel-browser";
import AdvertiserSignupWrapper from "../../components/AdvertiserSignupWrapper";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const SignupVendor = ({ closeModal }) => {
  const { loginModal, setLoginModal } = useLoginModalState();

  const [showPassword, setShowPassword] = useState(false);
  const [confirmAgreement, setConfirmAgreement] = useState(false);
  const [broadcasterSignup, { loading }] = useMutation(BROADCASTER_REGISTER);
  const [states, setStates] = useState([]);
  const [frequencyType, setFrequencyType] = useState("FM");
  const [stepper, setStepper] = useState(1);

  const navigate = useNavigate();
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: "",
      fullname: "",
      address: "",
      country: "",
      state: "",
      city: "",
      email: "",
      phoneNumber: "",
      radioStationWebsite: "",
      radioStationDigitalStreaUrl: "",
      radioStationCategory: "",
      positionHeld: "",
      // zipcode: "",
      password: "",
      confirmPassword: "",
      frequency: "",
    },
    validationSchema: RadiosStationSignupSchema,
    onSubmit: (values) => {
      broadcasterSignup({
        variables: {
          input: {
            name: values.name,
            fullname: values.fullname,
            address: values.address,
            country: values.country,
            state: values.state,
            city: values.city,
            email: values.email,
            phoneNumber: values.phoneNumber,
            // zipcode: values.zipcode,
            password: values.password,
            confirmPassword: values.confirmPassword,
            radioStationWebsite: values.radioStationWebsite,
            radioStationDigitalStreaUrl: values.radioStationDigitalStreaUrl,
            radioStationCategory: values.radioStationCategory,
            positionHeld: values.positionHeld,
            frequency: values.frequency,
          },
        },
        onCompleted: (data) => {
          console.log("hhhhh");
          localStorage.setItem(AUTH_TOKEN, data.auth.broadcasterRegister.token);
          mixpanel.identify(data?.auth?.broadcasterRegister?.id);
          toast.success(
            "Sign Up Successful, A Verification Code Has been sent to your email"
          );
          navigate("/signup/verify-account/Broadcaster");
        },
        onError: (err) => {
          toast.error(err?.networkError?.result?.errors[0]?.message);
        },
      });
    },
  });

  const HandleStepper = async (values) => {
    const result = await validateFirstFiveFields(values);
    if (result.valid) {
      setStepper(2);
    } else {
      toast.error("Enter Valid Datas");
      return;
    }
  };

  const handleCountry = (e) => {
    const { value } = e.target;
    const getStateData = countrydata.find(
      (country) => country.name === value
    ).states;
    setStates(getStateData);
     setFieldValue("country", value);
  };


  return (
    <>
      {loginModal && <Login closeModal={setLoginModal} />}

      <AdvertiserSignupWrapper>
        <div className="advertiser-form-wrapper">
          <div className="advertiser-first-sec">
            <div className="create-account-first">
              <p className="create-acct-texted">Create Account</p>
              <p className="select-acct-type">
                {stepper === 1 && "Tell us a little about your radio station"}
                {stepper === 2 && "Almost there!"}
              </p>
            </div>

            <div
              className="login-modal-header"
              onClick={() => closeModal(false)}
            >
              <CloseIcon />
            </div>
          </div>

          <div className="advertiser-second-sec">
            <div className="formStepperWrapper">
              <span
                className={`stepper ${stepper === 1 ? " currentStepper" : ""} `}
                onClick={() => setStepper(1)}
              ></span>
              <span
                className={`stepper ${stepper === 2 ? " currentStepper" : ""} `}
                onClick={() => HandleStepper(values)}
              ></span>
            </div>

            <div>
              <form onSubmit={handleSubmit}>
                <div className="form-container">
                  <div className="input-container">
                    {stepper === 1 && (
                      <>
                        <div className="form-input-wrapper-advertiser">
                          {errors.name && touched.name && (
                            <span className="form-error"> {errors.name}</span>
                          )}
                          <div className="full-length">
                            <label className="title-advert" htmlFor="name">
                              Name of radio station
                            </label>
                            <input
                              type="text"
                              className="formInput"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                            />
                          </div>
                        </div>

                        <div className="advertiser-row">
                          <div className="form-input-wrapper-advertiser">
                            {errors.fullname && touched.fullname && (
                              <span className="form-error">
                                {" "}
                                {errors.fullname}
                              </span>
                            )}
                            <div className="full-length">
                              <label
                                className="title-advert"
                                htmlFor="fullname"
                              >
                                full Name
                              </label>
                              <input
                                type="text"
                                className="formInput"
                                name="fullname"
                                value={values.fullname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                              />
                            </div>
                          </div>
                          <div className="form-input-wrapper-advertiser">
                            {errors.positionHeld && touched.positionHeld && (
                              <span className="form-error">
                                {" "}
                                {errors.positionHeld}
                              </span>
                            )}
                            <div className="full-length">
                              <label
                                className="title-advert"
                                htmlFor="positionHeld"
                              >
                                Position Held
                              </label>
                              <input
                                type="text"
                                className="formInput"
                                name="positionHeld"
                                value={values.positionHeld}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-input-wrapper-advertiser">
                          {errors.address && touched.address && (
                            <span className="form-error">
                              {" "}
                              {errors.address}
                            </span>
                          )}
                          <div className="full-length">
                            <label className="title-advert" htmlFor="address">
                              Address
                            </label>
                            <input
                              type="text"
                              className="formInput"
                              name="address"
                              value={values.address}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                            />
                          </div>
                        </div>

                        <div className="advertiser-row">
                          <div className="form-input-wrapper-advertiser">
                            {errors.email && touched.email && (
                              <span className="form-error">
                                {" "}
                                {errors.email}
                              </span>
                            )}
                            <div className="full-length">
                              <label className="title-advert" htmlFor="email">
                                Email
                              </label>
                              <input
                                type="email"
                                className="formInput"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                              />
                            </div>
                          </div>
                          <div className="form-input-wrapper-advertiser">
                            {errors.phoneNumber && touched.phoneNumber && (
                              <span className="form-error">
                                {" "}
                                {errors.phoneNumber}
                              </span>
                            )}
                            <div className="full-length">
                              <label
                                className="title-adverts"
                                htmlFor="phoneNumber"
                              >
                                Phone Number
                              </label>
                              {/* <input
                                type="text"
                                className="formInput"
                                name="phoneNumber"
                                value={values.phoneNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                              /> */}
                              <div className="input-cont">
                                <PhoneInput
                                  isValid={(value, country) => {
                                    const countryCodeLength =
                                      country.dialCode.length;
                                    const formattedValue = value.replace(
                                      /\D/g,
                                      ""
                                    ); // Remove non-digit characters

                                    if (
                                      formattedValue.length <= countryCodeLength
                                    ) {
                                      return false; // Invalid: input is too short
                                    }

                                    const expectedPrefix = value.substring(
                                      0,
                                      countryCodeLength
                                    );
                                    if (expectedPrefix !== country.dialCode) {
                                      return false; // Invalid: input does not match country code
                                    }

                                    return true; // Valid input
                                  }}
                                  country={"ng"}
                                  value={values.phoneNumber}
                                  onChange={(value) =>
                                    setFieldValue("phoneNumber", `+${value}`)
                                  }
                                  onBlur={handleBlur("phoneNumber")}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="advertiser-row">
                          <div className="form-input-wrapper-advertiser">
                            {errors.password && touched.password && (
                              <span className="form-error">
                                {" "}
                                {errors.password}
                              </span>
                            )}
                            <div className="full-length">
                              <label
                                htmlFor="login-password"
                                className="title-advert"
                              >
                                Password
                              </label>
                              <input
                                // id="login-password"
                                type="password"
                                placeholder=""
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                              />
                              <button
                                type="button"
                                id="show-password"
                                onClick={() => setShowPassword(!showPassword)}
                              ></button>
                            </div>
                          </div>
                          <div className="form-input-wrapper-advertiser">
                            {errors.confirmPassword &&
                              touched.confirmPassword && (
                                <span className="form-error">
                                  {" "}
                                  {errors.confirmPassword}
                                </span>
                              )}
                            <div className="full-length">
                              <label
                                htmlFor="confirmPassword"
                                className="title-advert"
                              >
                                confirmPassword{" "}
                              </label>
                              <input
                                // id="login-password"
                                type="password"
                                placeholder=""
                                name="confirmPassword"
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <button
                          type="button"
                          className="transmitBtn btn-continue"
                          onClick={() => HandleStepper(values)}
                        >
                          Continue
                        </button>
                      </>
                    )}

                    {stepper === 2 && (
                      <>
                        <div className="form-input-wrapper-advertiser">
                          {errors.radioStationDigitalStreaUrl &&
                            touched.radioStationDigitalStreaUrl && (
                              <span className="form-error">
                                {" "}
                                {errors.radioStationDigitalStreaUrl}
                              </span>
                            )}
                          <div className="full-length">
                            <label
                              className="title-advert"
                              htmlFor="radioStationDigitalStreaUrl"
                            >
                              Radio Station Digital Stream URL
                            </label>
                            <input
                              type="text"
                              className="formInput"
                              name="radioStationDigitalStreaUrl"
                              value={values.radioStationDigitalStreaUrl}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                            />
                          </div>
                        </div>

                        <div className="advertiser-row">
                          <div className="form-input-wrapper-advertiser">
                            {errors.radioStationWebsite &&
                              touched.radioStationWebsite && (
                                <span className="form-error">
                                  {" "}
                                  {errors.radioStationWebsite}
                                </span>
                              )}
                            <div className="full-length">
                              <label
                                className="title-advert"
                                htmlFor="radioStationWebsite"
                              >
                                Radio station website
                              </label>
                              <input
                                type="text"
                                className="formInput"
                                name="radioStationWebsite"
                                value={values.radioStationWebsite}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                              />
                            </div>
                          </div>
                          <div className="form-input-wrapper-advertiser">
                            {errors.frequency && touched.frequency && (
                              <span className="form-error">
                                {" "}
                                {errors.frequency}
                              </span>
                            )}
                            <div className="full-length">
                              <label
                                className="title-advert"
                                htmlFor="radioStationCategory"
                              >
                                Radio station frequency
                              </label>
                              <input
                                type="text"
                                className="formInput"
                                name="frequency"
                                value={values.frequency}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="advertiser-row">
                          <div className="form-input-wrapper-advertiser">
                            {errors.radioStationCategory &&
                              touched.radioStationCategory && (
                                <span className="form-error">
                                  {" "}
                                  {errors.radioStationCategory}
                                </span>
                              )}
                            <div className="full-length">
                              <label
                                className="title-advert"
                                htmlFor="radioStationCategory"
                              >
                                Radio station category
                              </label>
                              <input
                                type="text"
                                className="formInput"
                                name="radioStationCategory"
                                value={values.radioStationCategory}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                              />
                            </div>
                          </div>
                          <div className="form-input-wrapper-advertiser">
                            {errors.country && touched.country && (
                              <span className="form-error">
                                {" "}
                                {errors.country}
                              </span>
                            )}
                            <div className="full-length">
                              <label
                                className="title-advert"
                                htmlFor="radioStationCategory"
                              >
                                Country
                              </label>

                              <select
                                className="formInput"
                                name="country"
                                value={values.country}
                                onChange={handleCountry}
                                onBlur={handleBlur}
                                required
                              >
                                <option value="" disabled>
                                  Select a country
                                </option>
                                {countrydata.map((country) => (
                                  <option
                                    key={country.code}
                                    value={country.code}
                                  >
                                    {country.name}
                                  </option>
                                ))}
                              </select>
                              {/* <input
                                type="text"
                                className="formInput"
                                name="country"
                                value={values.country}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                              /> */}
                            </div>
                          </div>
                        </div>

                        <div className="advertiser-row">
                          <div className="form-input-wrapper-advertiser">
                            {errors.state && touched.state && (
                              <span className="form-error">
                                {" "}
                                {errors.state}
                              </span>
                            )}
                            <div className="full-length">
                              <label className="title-advert" htmlFor="state">
                                State
                              </label>
                              <select
                                className="formInput"
                                name="state"
                                value={values.state}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                              >
                                <option value="" disabled>
                                  Select a state
                                </option>
                                {states.map((state) => (
                                  <option
                                    key={state.code}
                                    value={state.code}
                                  >
                                    {state.name}
                                  </option>
                                ))}
                              </select>
                              {/* <input
                                type="text"
                                className="formInput"
                                name="state"
                                value={values.state}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                              /> */}
                            </div>
                          </div>
                          <div className="form-input-wrapper-advertiser">
                            {errors.city && touched.city && (
                              <span className="form-error"> {errors.city}</span>
                            )}
                            <div className="full-length">
                              <label className="title-advert" htmlFor="city">
                                City
                              </label>
                              <input
                                type="text"
                                className="formInput"
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="tac-pp">
                          <input
                            type="checkbox"
                            checked={confirmAgreement}
                            onChange={() =>
                              setConfirmAgreement(!confirmAgreement)
                            }
                          />
                          <p>
                            I{" "}
                            <span className="lower-text">
                              acknowledge that I have read and accept the{" "}
                              <Link href="https://more.radioadspread.com/knowledgebase/terms-and-conditions/">
                                <span className="advert-color">Terms</span> and{" "}
                                <span className="advert-color">Conditions</span>
                              </Link>{" "}
                              and{" "}
                              <Link href="https://more.radioadspread.com/knowledgebase/privacy-policy/">
                                <span className="advert-color">
                                  Privacy Policy
                                </span>
                              </Link>
                            </span>
                          </p>
                        </div>
                        <button
                          type="submit"
                          //  className="transmitBtn btn-continue"
                          disabled={confirmAgreement === false}
                          className={`transmitBtn btn-continue ${
                            !confirmAgreement ? "disable-btn" : ""
                          }`}
                        >
                          {loading ? (
                            <i class="fa fa-circle-o-notch fa-spin"></i>
                          ) : (
                            "Create Account"
                          )}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </form>
              {/* <div className="after-form">
                <div className="tac-pp">
                  <input
                    type="checkbox"
                    onClick={() => setConfirmAgreement(!confirmAgreement)}
                  />
                  <p>
                    I{" "}
                    <span className="lower-text">
                      acknowledge that I have read and accept the{" "}
                      <Link href="https://more.radioadspread.com/knowledgebase/terms-and-conditions/">
                        <span className="advert-color">Terms</span> and{" "}
                        <span className="advert-color">Conditions</span>
                      </Link>{" "}
                      and{" "}
                      <Link href="https://more.radioadspread.com/knowledgebase/privacy-policy/">
                        <span className="advert-color">Privacy Policy</span>
                      </Link>
                    </span>
                  </p>
                </div>
                <div className="login-button">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={confirmAgreement}
                    className={confirmAgreement ? "disable-btn" : "enable-btn"}
                  >
                    {loading ? (
                      <i class="fa fa-circle-o-notch fa-spin"></i>
                    ) : (
                      "Create Account"
                    )}
                  </button>
                </div>
                <div className="already-acct">
                  <div>
                    <p>
                      Already have an account?{" "}
                      <button
                        onClick={() => setLoginModal(true)}
                        className="login-url"
                      >
                        Log in
                      </button>
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="already-acct" style={{ marginTop: "20px" }}>
                <div>
                  <p>
                    Already have an account?{" "}
                    <button
                      onClick={() => {
                        setLoginModal((prevState) => !prevState); // Toggle loginModal state
                        closeModal(false); // Close the modal
                      }}
                      className="login-url"
                    >
                      Log in
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdvertiserSignupWrapper>
    </>
  );
};

export default SignupVendor;
