import React, { useState } from "react";
import AdminDashboardLayout from "./AdminDashboardLayout";
// import { GET_ALL_ADMIN_RADIO_VENDOR_TRANSACTIONS } from "../components/GraphQL/Queries";
import { useQuery } from "@apollo/client";
import "./css/adminRolesPermission.css";
import { useNavigate, useParams } from "react-router-dom";
import { MdArrowBackIos, MdCancel } from "react-icons/md";
import AddPermission from "../components/Modal/AddPermission/AddPermission";
// import CancelIcon from "../assets/CancelIcon";
import { Permissions } from "../constant";

const AdminRolesPermission = () => {
  const [open, setOpen] = useState(false);
//   const { data, loading, refetch } = useQuery(
//     GET_ALL_ADMIN_RADIO_VENDOR_TRANSACTIONS
//   );
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  // get id from url
//   const { id } = useParams();

  const Open = () => {
    setOpen(true);
  };
  const Close = () => {
    setOpen(false);
  };

  return (
    <AdminDashboardLayout>
      {/* {loading ? (
        <ChakraProvider>
          <Center h="100vh">
            <TableSpinner />
          </Center>
        </ChakraProvider>
      ) : ( */}
      <>
        <div className="">
          <div className="admin_permission_header">
            <div className="profile-nav">
              <div className="profile-back" onClick={handleGoBack}>
                <span>
                  <MdArrowBackIos />
                </span>
                <span>Back</span>
              </div>
              <div className="admin_role_permission_header">
                <div className="admin_role_permission_btn" onClick={Open}>
                  <p>Add a new role</p>
                </div>
              </div>
            </div>

            <div className="profile_tab_wrapper">
              <div className="permission_profile_wrap">
                <div className="permission_profile">
                  <span className="permission_name">Name:</span>
                  <span className="permission_value">Mercy Oluwatobi</span>
                </div>
                <div className="permission_profile">
                  <span className="permission_name">Email:</span>
                  <span className="permission_value">
                    examplemail@radioadspread.com
                  </span>
                </div>
                <div className="permission_profile">
                  <span className="permission_name">Role:</span>
                  <span className="permission_value">Editor</span>
                </div>
              </div>
            </div>
            <div className="permission_bar"></div>
            {/* List of Permissions section */}
            <div className="permission_sec">
              <p>Permissions</p>
            </div>
            {/* Map through permissions gotten from api response */}

            <div className="permission_list">
              {Permissions?.map((item, index) => {
                return (
                  <div className="permission_list_wrap" key={index}>
                    <div className="permission_list_txt">
                      <p>{item?.role}</p>
                    </div>
                    <div className="permission_list_role_wrap">
                      {item?.permission?.map((roles, indexRole) => {
                        return (
                          <div className="permission_list_role" key={indexRole}>
                            <div className="permission_list_role_txt">
                              <p>{roles?.role}</p>
                            </div>
                            <div className="permission_list_role_btn">
                              {/* <CancelIcon /> */}
                              <MdCancel className="permission_icon"/>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <AddPermission openModal={open} closeModal={Close} isNew={false} />
      </>
    </AdminDashboardLayout>
  );
};

export default AdminRolesPermission;
