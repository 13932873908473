import React from "react";
import "./admintab.css";

const AdminTab = ({ tabs, setSelectedTab, selectedTab }) => {
  return (
    <div className="tabwrapper">
      {tabs.map(({ key, value, total }, index) => {
        return (
          <div
            key={index}
            onClick={() => setSelectedTab(value)}
            className="tabs"
          >
            <span className={`tabs_key ${selectedTab === key ? "selectedtab" : ""}`}>
              {key} ({total})
            </span>
            {tabs.length - 1 !== index && <span className="pie">|</span>}
          </div>
        );
      })}
    </div>
  );
};

export default AdminTab;
