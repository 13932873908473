import React from "react";
import "./style.css";

const PlainDashboardCard = ({ svg, title, content }) => {
  return (
    <div className="plain_dashboard_wrapper">
      <div className="plain_icon-container">
        <img src={svg} alt="admin-icon" />
      </div>
      <div className="plain_content-container">
        <div className="plain_title">
          <p>{title}</p>
        </div>
        <div className="plain_content">
          <p>&#x20A6; {content ? Math.floor(parseFloat(content)) : "0"}</p>
        </div>
      </div>
    </div>
  );
};

export default PlainDashboardCard;
