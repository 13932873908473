import React from 'react'

const ChevronLeftIcon = () => {
  return (
    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.5 10L1 5.5L5.5 1" stroke="#F99B28" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  )
}

export default ChevronLeftIcon