import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../../transmitAssets/logo-and-text.svg";
import hamburger from "../../transmitAssets/hamburger.svg";
import mobileLogo from "../../transmitAssets/transmitLogo.svg";
import SignupModal from "../Modal/signup/SignupModal";
import Login from "../../pages/login";
import styled from "styled-components";
import { ExistingLoginUser, isTokenValid } from "../../utils/functions";
import { useLoginModalState } from "../../hook";
import "./style.css";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
// import AdvertiserSignup from "../Modal/AdvertiserSignup";
import AdvertiserSignup from "../../pages/signup/advertiserSignup";
import SignupVendor from "../../pages/signup/VendorSignup";
import { EXTERNAL_URL } from "../../config/url";
import { ChevronDownIcon } from "@chakra-ui/icons";
const TransmitNavbar = () => {
  const navigate = useNavigate();
  const { loginModal, setLoginModal } = useLoginModalState();

  const [openModal, setOpenModal] = useState(false);
  const [loginDropdown, setLoginDropdown] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setLoginDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [loginDropdown]);

  const modalHandler = () => {
    onClose();
    setOpenModal(true);
  };

  const [activeMenu, setActiveMenu] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onClose: closeModal } = useDisclosure();
  const [open, setOpen] = useState(false);
  const [openRadioStationSignupModal, setOpenRadioStationSignupModal] =
    useState(false);
  const [advertiserSignupType, setAdvertiserSignUpType] =
    useState("Individual");
  const [openAccordion, setOpenAccordion] = useState(false);

  const OpenAdvertiser = (accountType) => {
    setOpenModal(false);
    setOpen(true);
    setAdvertiserSignUpType(accountType);
    onClose();
    setOpenAccordion();
  };
  const openRadioStationSignupModalHandler = () => {
    setOpenModal(false);
    setOpenRadioStationSignupModal(true);
  };

  const CloseAdvertiser = () => {
    setOpen(false);
  };

  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? "selectedMenu" : "";
  };

  const tokenFromLocalStorage = localStorage.getItem("token");

  const tokenStillValid = isTokenValid(tokenFromLocalStorage);

  const navigateUserType = () => {
    const advertType = ExistingLoginUser(tokenFromLocalStorage);
    if (advertType === "Broadcaster") {
      return navigate("/dashboard/vendor");
    }
    if (advertType === "Advertiser") {
      return navigate("/dashboard");
    } else {
      navigate("/");
    }
  };

  return (
    <>
      {openModal && (
        <SignupModal
          closeModal={setOpenModal}
          OpenAdvertiser={OpenAdvertiser}
          openRadioStationSignupModalHandler={
            openRadioStationSignupModalHandler
          }
        />
      )}
      {loginModal && (
        <Login
          closeModal={setLoginModal}
          openAdvertiserSignupModal={setOpen}
          openRadioStationSignupModal={setOpenRadioStationSignupModal}
        />
      )}
      {open && (
        <AdvertiserSignup
          closeModal={setOpen}
          advertiserSignupType={advertiserSignupType}
          setAdvertiserSignUpType={setAdvertiserSignUpType}
        />
      )}
      {openRadioStationSignupModal && (
        <SignupVendor closeModal={setOpenRadioStationSignupModal} />
      )}
      <div className="transmitNavbarWrapper">
        <div className="transmitDesktop">
          <div className="transmitMenuListWrapper">
            <ul className="transmitMenuLists">
              <li
                className={`transmitMenuList ${isActive("/")}`}
                // onClick={() => handleSetActiveMenu(1)}
              >
                <Popover placement="bottom-start">
                  <PopoverTrigger>
                    <div
                      style={{
                        cursor: "pointer",
                        color: "#fff",
                        display: "flex",
                        gap: "4px",
                        alignItems: "center",
                      }}
                    >
                      Advertiser
                      <ChevronDownIcon boxSize={20} />
                      {/* <img src={UserIcon} alt="user-icon" /> */}
                    </div>
                  </PopoverTrigger>

                  <PopoverContent
                    w="158px"
                    h="89px"
                    bg="#ffffff"
                    border="0.5px solid #aba8a8"
                    borderRadius="2px"
                    //  py="15px"
                    // _hover={{ background: "gray" }}
                  >
                    <div className="popOverContentWrapper">
                      <div onClick={() => OpenAdvertiser("Individual")}>
                        <p>Individual</p>
                      </div>

                      <div onClick={() => OpenAdvertiser("company")}>
                        <p>Company</p>
                      </div>
                      <div onClick={() => OpenAdvertiser("Agency")}>
                        <p>Agency</p>
                      </div>
                    </div>
                  </PopoverContent>
                </Popover>
                {/* <Link to="/"></Link> */}
              </li>
              <li className={`transmitMenuList ${isActive("/radio-station")}`}>
                <Link to="/radio-station">Radio station</Link>
              </li>
              <li
                className={`transmitMenuList ${isActive(
                  "/talk-with-an-expert"
                )}`}
                // onClick={() => handleSetActiveMenu(1)}
              >
                <Popover placement="bottom-start">
                  <PopoverTrigger>
                    <div
                      style={{
                        cursor: "pointer",
                        color: "#fff",
                        display: "flex",
                        gap: "4px",
                        alignItems: "center",
                      }}
                    >
                      Services
                      <ChevronDownIcon boxSize={20} />
                      {/* <img src={UserIcon} alt="user-icon" /> */}
                    </div>
                  </PopoverTrigger>

                  <PopoverContent
                    bg="#ffffff"
                    // w="400px"
                    // h="200px"
                    border="0.5px solid #aba8a8"
                    borderRadius="2px"
                    //  py="15px"
                    // _hover={{ background: "gray" }}
                  >
                    <div className="popOverContentWrapperSerivce">
                      <div>
                        <Link
                          to="/talk-with-an-expert"
                          className="service-cont"
                        >
                          <p>Jingle Production</p>
                        </Link>
                      </div>

                      <div>
                        <Link
                          to="/talk-with-an-expert"
                          className="service-cont"
                        >
                          <p>APCON Jingle Certification</p>
                        </Link>
                      </div>
                    </div>
                  </PopoverContent>
                </Popover>
                {/* <Link to="/"></Link> */}
              </li>
              <li className={`transmitMenuList ${isActive("/our-team")}`}>
                <Link to="/our-team">The Team</Link>
              </li>
              <li className={`transmitMenuList ${isActive("/blog")}`}>
                <Link to={`${EXTERNAL_URL}/blog/`}>Blog</Link>
              </li>
              <li className={`transmitMenuList ${isActive("/faq")}`}>
                <Link to={`${EXTERNAL_URL}/faq/`}>FAQ</Link>
              </li>
            </ul>
          </div>

          <div className="desktopLogo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="desktopLogos">
          <img src={mobileLogo} alt="logo" className="mobileTransmitLogos" />
          </div>

          {!tokenStillValid ? (
            <div className="authBtns">
              <div
                //  to="/auth/signup"
                onClick={modalHandler}
              >
                <span className="transmitSignupBtn transmitRegisterBtn">
                  Sign up
                </span>
              </div>
              <div
                onClick={() => setLoginModal(!loginModal)}
                // to="/auth/login"
              >
                <span className="transmitLoginBtn transmitRegisterBtn">
                  Login
                </span>
              </div>
            </div>
          ) : (
            <div
              //  to="/auth/signup"
              onClick={() => navigateUserType()}
              className="authBtns"
            >
              <span className="transmitSignupBtn transmitRegisterBtn">
                Dashboard
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="transmitMobile">
        <div className="transmitMobileContent">
          <img src={mobileLogo} alt="logo" className="mobileTransmitLogo" />
          <img
            src={hamburger}
            alt="hamburger"
            className="drawerIcon"
            onClick={onOpen}
          />
        </div>
        <Drawer
          isOpen={isOpen}
          placement="right"
          size="md"
          // initialFocusRef={firstField}
          onClose={onClose}
        >
          <DrawerOverlay />
          <DrawerContent backgroundColor="black" p={30}>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row-reverse"
            >
              <DrawerCloseButton color="white" size="xl" fontSize={20} />
              <DrawerHeader borderBottomWidth="1px">
                <div className="transmitMobileHeader">
                  <img src={logo} alt="logo" className="drawerTransmitLogo" />
                  {/* <img src={mobileLogo} alt="logo" className="mobileTransmitLogo" /> */}
                </div>
              </DrawerHeader>
            </Box>

            <DrawerBody pt={40}>
              <Stack spacing="50px">
                <ul className="mobileMenuList">
                  <li
                    className={`mobileTransmitMenuList`}

                    // onClick={() => setActiveMenu(1)}
                  >
                    {/* <Link to="/">Advertiser</Link> */}
                    <Accordion>
                      <AccordionItem>
                        <h2>
                          <AccordionButton
                            color={
                              location.pathname === "/" ? "#f1613f" : "#fff"
                            }
                            // _expanded={{ bg: "tomato", color: "white" }}
                            display="flex"
                            gap="10"
                            onClick={() => setOpenAccordion(!openAccordion)}
                          >
                            <Box
                              as="span"
                              textAlign="left"
                              fontSize={openAccordion ? "18px" : "16px"}
                              fontWeight="600"
                              color={
                                location.pathname === "/" ? "#f1613f" : "#fff"
                              }
                            >
                              Advertiser
                            </Box>
                            <AccordionIcon fontSize="14px" />
                          </AccordionButton>
                        </h2>
                        {openAccordion && (
                          <Flex px={20} py={10} flexDirection="column" gap={8}>
                            <AccordionPanel color="white" fontSize="14px">
                              <div onClick={() => OpenAdvertiser("Individual")}>
                                <p>Individual</p>
                              </div>
                            </AccordionPanel>
                            <AccordionPanel color="white" fontSize="14px">
                              <div onClick={() => OpenAdvertiser("company")}>
                                <p>Company</p>
                              </div>
                            </AccordionPanel>
                            <AccordionPanel color="white" fontSize="14px">
                              <div onClick={() => OpenAdvertiser("Agency")}>
                                <p>Agency</p>
                              </div>
                            </AccordionPanel>
                          </Flex>
                        )}
                      </AccordionItem>
                    </Accordion>
                  </li>
                  <li
                    className={`mobileTransmitMenuList ${
                      location.pathname === "/radio-station" &&
                      "activeTransmitMenuList"
                    }`}
                    // onClick={() => setActiveMenu(2)}
                  >
                    <Link to="/radio-station">Radio station</Link>
                  </li>
                  <li
                    className={`mobileTransmitMenuList ${
                      location.pathname === "/our-team" &&
                      "activeTransmitMenuList"
                    }`}
                    // onClick={() => setActiveMenu(3)}
                  >
                    <Link to="/our-team">The Team</Link>
                  </li>
                  <li
                    className={`mobileTransmitMenuList ${
                      location.pathname === "/blog" && "activeTransmitMenuList"
                    }`}
                    // onClick={() => setActiveMenu(3)}
                  >
                    <Link to={`${EXTERNAL_URL}/blog/`}>Blog</Link>
                  </li>
                  <li
                    className={`mobileTransmitMenuList ${
                      location.pathname === "" && "activeTransmitMenuList"
                    }`}
                    // onClick={() => setActiveMenu(4)}
                  >
                    <Link to={`${EXTERNAL_URL}/faq/`}>FAQ</Link>
                  </li>
                </ul>
                {!tokenStillValid ? (
                  <div className="authBtns">
                    <div
                      //  to="/auth/signup"
                      onClick={modalHandler}
                    >
                      <span className="transmitSignupBtn transmitRegisterBtn">
                        Sign up
                      </span>
                    </div>
                    <div
                      onClick={() => {
                        onClose();
                        setLoginModal(!loginModal);
                      }}
                      // to="/auth/login"
                    >
                      <span className="transmitLoginBtn transmitRegisterBtn">
                        Login
                      </span>
                    </div>
                  </div>
                ) : (
                  <div
                    //  to="/auth/signup"
                    onClick={() => navigateUserType()}
                  >
                    <span className="transmitSignupBtn transmitRegisterBtn">
                      Dashboard
                    </span>
                  </div>
                )}
              </Stack>
            </DrawerBody>

            {/* <DrawerFooter borderTopWidth='1px'>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue'>Submit</Button>
          </DrawerFooter> */}
          </DrawerContent>
        </Drawer>
      </div>
    </>
  );
};

export default TransmitNavbar;
