import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import React from "react";

const EditModal = ({ isOpen, onClose, values, handleChange, handleSubmit }) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{values.radioStation}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <Stack spacing={6}>
                <FormControl>
                  <FormLabel>VAT</FormLabel>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values.vat}
                    placeholder="26, Keffi Street, Ikoyi, Lagos "
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>RadioAdSpread Commission</FormLabel>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values.commission}
                    placeholder="26, Keffi Street, Ikoyi, Lagos "
                  />
                </FormControl>
              </Stack>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button bg="#ff9b28" color="#fff" margin="0 auto" px={10} _hover="#ff9328">
              save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditModal;
