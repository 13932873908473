import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TabPanel, Box, TabPanels, Tab, TabList, Tabs } from "@chakra-ui/react";
import { useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";

import AdminDashboardLayout from "./AdminDashboardLayout";
import ReportComponent from "../components/Report/ReportComponent";
import ProgramScheduleComponent from "../components/ProgramSchedule/ProgramSchedule";
import RadioStationProfileDetails from "../components/AdminRadioStationTabs/ProfileDetails";
import EditAboutRadioStation from "../components/AdminRadioStationTabs/EditAboutRadioStation";
import AdvertiserOrders from "../components/AdminRadioStationTabs/AdvertiserOrders";
import { TableSpinner } from "../components/Spinner";

import {
  GET_BROADCASTERDETAILS_BY_ID,
  // GET_RADIOSTATIONS_FOR_ADMIN,
} from "../components/GraphQL/Queries";
import {
  BATCH_UPDATE_BROADCASTER_SLOT_BY_ADMIN,
  CREATE_BROADCASTER_DISCOUNT_BY_ADMIN,
  CREATE_BROADCASTER_PROGRAMSCHEDULE_BY_ADMIN,
  DELETE_BROADCASTER_PROGRAMSCHEDULE_BY_ADMIN,
  UPDATE_BROADCASTER_PROGRAMSCHEDULE_BY_ADMIN,
} from "../components/GraphQL/Mutation";
import { toast } from "react-toastify";

const AdminDasboardVendor = () => {
  const [openCampaignDetails, setOpenCampaignDetails] = useState(0);
  const [day, setDay] = useState("Sunday");
  const [programList, setProgramList] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const { id } = useParams();

  // PROFILE DETAILS QUERY
  const {
    data: { admin: { getBroadcasterDetailsById = {} } = {} } = {},
    loading: profileDetailsLoading,
    error,
  } = useQuery(GET_BROADCASTERDETAILS_BY_ID, {
    variables: {
      broadcasterId: id,
    },
  });

  const [updateBroadcasterSlot, { loading: updateSlotLoading }] = useMutation(
    BATCH_UPDATE_BROADCASTER_SLOT_BY_ADMIN,
    {
      refetchQueries: [
        { query: GET_BROADCASTERDETAILS_BY_ID },
        "GetBroadcasterDetailsById",
      ],
    }
  );
  const [createDiscount, { loading: getDiscountLoad }] = useMutation(
    CREATE_BROADCASTER_DISCOUNT_BY_ADMIN,
    {
      refetchQueries: [
        { query: GET_BROADCASTERDETAILS_BY_ID },
        "GetBroadcasterDetailsById",
      ],
    }
  );

  // PROGRAM SCHEDULE MUTATIONS
  const [addProgramSchedule, { loading: createProgramScheduleLoading }] =
    useMutation(CREATE_BROADCASTER_PROGRAMSCHEDULE_BY_ADMIN, {
      refetchQueries: [
        { query: GET_BROADCASTERDETAILS_BY_ID },
        "GetBroadcasterDetailsById",
      ],
    });
  const [updateProgramSchedule, { loading: updateProgramScheduleLoading }] =
    useMutation(UPDATE_BROADCASTER_PROGRAMSCHEDULE_BY_ADMIN);
  const [deleteProgramSchedule, { loading: deleteProgramScheduleLoading }] =
    useMutation(DELETE_BROADCASTER_PROGRAMSCHEDULE_BY_ADMIN);

  const tabMenu = [
    "Profile details",
    "Edit Radio Station",
    "Orders",
    "Program Schedule",
    "Reports",
  ];

  useEffect(() => {
    const mutatedProgramSchedule =
      getBroadcasterDetailsById?.programSchedule?.map((program) => {
        program = { ...program };
        program["selected"] = false;
        program["isDisabled"] = true;
        return program;
      });
    setProgramList(mutatedProgramSchedule);
  }, [getBroadcasterDetailsById?.programSchedule]);

  const handleChangeAllCheckBox = () => {
    const newProgList = programList.map((program) => {
      if (program.weekDay === day && allChecked === false) {
        program.selected = true;
        setAllChecked(true);
      }
      if (program.weekDay === day && allChecked === true) {
        program.selected = false;
        setAllChecked(false);
      }
      return program;
    });
    setProgramList([...newProgList]);
  };

  const handleChangeCheckBox = ({ id, weekDay }) => {
    const newProgList = programList.map((program) => {
      if (program.weekDay === weekDay && program.id === id) {
        program.selected = !program.selected;
      }
      return program;
    });

    setProgramList([...newProgList]);
    setAllChecked(false);
  };

  if (error) return toast(`Error! ${error.message}`);

  return (
    <AdminDashboardLayout>
      <Box h="100%" bg="#fff">
        {profileDetailsLoading ? (
          <TableSpinner />
        ) : (
          <TabsWrapper>
            <TabListWrapper>
              {tabMenu.map((tab, index) => (
                <TabListItem
                  key={index}
                  active={index === tabIndex}
                  onClick={() => setTabIndex(index)}
                >
                  {tab}
                </TabListItem>
              ))}
            </TabListWrapper>

            <TabPanelsWrapper>
              {tabIndex === 0 && (
                <RadioStationProfileDetails
                  profile={getBroadcasterDetailsById?.profileDetails}
                  bankDetails={getBroadcasterDetailsById?.bankInformation}
                />
              )}

              {tabIndex === 1 && (
                <EditAboutRadioStation
                  editRadioStationDetails={
                    getBroadcasterDetailsById?.radioStationDetails
                  }
                  listOfDiscounts={
                    getBroadcasterDetailsById?.discountRates || []
                  }
                  slotRates={getBroadcasterDetailsById?.rates}
                  broadcasterId={id}
                  createDiscount={createDiscount}
                  updateBroadcasterSlot={updateBroadcasterSlot}
                  getDiscountLoad={getDiscountLoad}
                  updateSlotLoading={updateSlotLoading}
                />
              )}

              {tabIndex === 2 && (
                <AdvertiserOrders orders={getBroadcasterDetailsById?.orders} />
              )}

              {tabIndex === 3 && (
                <ProgramScheduleComponent
                  programList={getBroadcasterDetailsById?.programSchedule || []}
                  handleChangeAllCheckBox={handleChangeAllCheckBox}
                  handleChangeCheckBox={handleChangeCheckBox}
                  isAdmin
                  addProgramSchedule={addProgramSchedule}
                  updateProgramSchedule={updateProgramSchedule}
                  deleteProgramSchedule={deleteProgramSchedule}
                  loading={createProgramScheduleLoading}
                  updateProgLoading={updateProgramScheduleLoading}
                  deleteProgLoading={deleteProgramScheduleLoading}
                  userId={id}
                />
              )}

              {tabIndex === 4 && (
                <ReportComponent
                  overViewCards={getBroadcasterDetailsById?.reportOverview}
                  data={getBroadcasterDetailsById?.statement || []}
                  getPaymentHistory={
                    getBroadcasterDetailsById?.paymentHistory || []
                  }
                />
              )}
            </TabPanelsWrapper>
          </TabsWrapper>
        )}
      </Box>
    </AdminDashboardLayout>
  );
};

export default AdminDasboardVendor;

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const TabListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  border-bottom: 0.8px solid #dadada;
`;
const TabPanelsWrapper = styled.div``;

const TabListItem = styled.div`
  fontsize: 18px;
  lineheight: 21.6px;
  max-width: 230px;
  width: 100%;
  padding: 30px 0 20px;
  cursor: pointer;
  color: #8d8b8b;
  color: ${(props) => props.active && "#004643"};
  border-bottom: ${(props) => props.active && "2px solid #004643"};

  // margin: 1px;
  text-align: start;

  &:nth-child(1) {
    padding-left: 30px;
  }
  &:nth-child(5) {
    padding-right: 30px;
  }
`;

// color={index === tabIndex ? "#004643" : "#8D8B8B"}
// borderBottom={index === tabIndex ? "1px solid #004643" : ""}
