import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

const CustomButtonDatePicker = ({ onSelectRange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const calculateTotalDays = () => {
    if (startDate && endDate) {
      const totalDays =
        Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
      return totalDays;
    }
    return 0;
  };

  const handleFilterClick = () => {
    const formattedStartDate = moment(startDate).format("LL");
    const formattedEndDate = moment(endDate).format("LL");
    const concatenatedDates = `${formattedStartDate} - ${formattedEndDate}`;
    onSelectRange(concatenatedDates, calculateTotalDays());
  };

  const handleResetClick = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <div>
      <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
        <CustomDateContainer>
          <DateWrapper>
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
            />
            <i
              class="fa fa-calendar"
              aria-hidden="true"
              style={{ position: "absolute", zIndex: -0.05, right: "5px" }}
            ></i>
          </DateWrapper>
          <DateWrapper>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End Date"
            />
            <i
              class="fa fa-calendar"
              aria-hidden="true"
              style={{ position: "absolute", zIndex: -0.05, right: "5px" }}
            ></i>
          </DateWrapper>

          <p style={{textTransform: "lowercase"}}>{calculateTotalDays()} days selected</p>
        </CustomDateContainer>
      </div>

      <ButtonWrapper>
        <ResetButton type="button" onClick={handleResetClick}>
          Reset
        </ResetButton>
        <FilterButton type="button" onClick={handleFilterClick}>
          Filter
        </FilterButton>
      </ButtonWrapper>
    </div>
  );
};

export default CustomButtonDatePicker;

const CustomDateContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const DateWrapper = styled.div`
  border: 1px solid black;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 4;
  margin-top: 30px;
  justify-content: space-between;
`;

const ResetButton = styled.button`
padding: 5px 15px;
border: 1px solid black;
border-radius: 4px;

`

const FilterButton = styled.button`
  background: #fa9b00;
  padding: 5px 15px;
  color: #fff;
  border-radius: 4px;
`;
