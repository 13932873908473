import React from "react";

const PaginationComponent = ({
  currentPage,
  entries,
  filteredDataLength,
  handlePagination,
  renderPageNumbers,
}) => {
  //   const [currentPage, setCurrentPage] = useState(1);
  // console.log(filteredDataLength, currentPage, entries);
  return (
    <>
      {filteredDataLength.length > 0 && (
        <div className="admin_footer_wrapper">
          <div>
            <section className="admin_pagination-section">
              <div>
                showing {"  "}
                {`${(currentPage - 1) * entries + 1}`} <span>to</span>{" "}
                {`${Math.min(currentPage * entries, filteredDataLength.length)}`}{" "}
                <span>of</span> {`${filteredDataLength.length} `} <span>entries</span>
              </div>
            </section>
          </div>
          {/* Pagination section */}
          <div className="admin_pagination">
            <span onClick={() => handlePagination("prev")}>Previous</span>
            {renderPageNumbers()}
            <span onClick={() => handlePagination("next")}>Next</span>
          </div>
        </div>
      )}
    </>
  );
};

export default PaginationComponent;
