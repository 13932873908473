import React, { useState } from 'react';
import "./css/station-details.css";

const IncrementDecrement = ({ initialValue, onChange }) => {
  const [count, setCount] = useState(Math.max(0, initialValue)); // Ensure initial value is at least 0

  const handleDecrement = () => {
    if (count > 0) {
      setCount(count - 1);
      onChange(count - 1);
    }
  };

  const handleIncrement = () => {
    setCount(count + 1);
    onChange(count + 1);
  };

  return (
    <div className="input_wrapper">
      <div className="input_decrement" onClick={handleDecrement}>
        <p>-</p>
      </div>
      <div className="input_field_txt">

      <input
        type="number"
        placeholder=""
        value={count}
        onChange={(e) => {
          const newValue = parseInt(e.target.value);
          setCount(Math.max(0, newValue)); // Enforce minimum on input change
          onChange(Math.max(0, newValue));
        }}
        
      />
      </div>
      <div className="input_increment" onClick={handleIncrement}>
        <p>+</p>
      </div>
    </div>
  );
};

export default IncrementDecrement;
