import React, { useState } from "react";
import "./admintransaction.css";
import filtersvg from "../../assets/icons/filter_up_down.svg";
import AdminActionButton from "../AdminActionButton/AdminActionButton";
import AdminTransactionLoader from "../Loader/AdminTransactionLoader/AdminTransactionLoader";
import SearchIcon from "./searchIcon";
import moment from "moment";

const AdminTransactionTable = ({ data, header, tab, loading, refetch }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [entries, setEntries] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const handlePagination = (action) => {
    if (action === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (action === "next" && data.length > currentPage * entries) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset current page when searching
  };

  const filteredData = data?.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const totalPages = Math.ceil(filteredData.length / entries);

  const paginatedData = filteredData?.slice(
    (currentPage - 1) * entries,
    currentPage * entries
  );

  // const renderPageNumbers = () => {
  //   const pageNumbers = [];
  //   for (let i = 1; i <= totalPages; i++) {
  //     pageNumbers.push(
  //       <span
  //         key={i}
  //         className={i === currentPage ? "active" : ""}
  //         onClick={() => handlePageChange(i)}
  //       >
  //         {i}
  //       </span>
  //     );
  //   }
  //   return pageNumbers;
  // };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    
    for (let i = 1; i <= totalPages; i++) {
      // Display first two pages
      if (i <= 2) {
        pageNumbers.push(
          <span
            key={i}
            className={i === currentPage ? "active" : ""}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </span>
        );
      }
      // Display ellipsis for middle section
      else if (i > 2 && i < totalPages - 1 && Math.abs(i - currentPage) > 1) {
        // Display ellipsis only once in the middle section
        if (pageNumbers[pageNumbers.length - 1] !== ".")
          pageNumbers.push(" .");
      }
      // Display last two pages
      else if (i >= totalPages - 1) {
        pageNumbers.push(
          <span
            key={i}
            className={i === currentPage ? "active" : ""}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </span>
        );
      }
      // Display current and adjacent pages in the middle section
      else {
        pageNumbers.push(
          <span
            key={i}
            className={i === currentPage ? "active" : ""}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </span>
        );
      }
    }
    
    return pageNumbers;
  };
  
  return (
    <>
      {loading ? (
        <AdminTransactionLoader />
      ) : (
        <div className="wrapper">
          <div className="table_head">
            <div className="entry">
              <span>Show</span>
              <select
                value={entries}
                onChange={(e) => setEntries(parseInt(e.target.value))}
              >
                <option>10</option>
                <option>20</option>
                <option>50</option>
              </select>
              <span>entries</span>
            </div>

            <div className="input-box form-control">
              <input
                type="text"
                className="search-input form-control"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearch}
              />
              <SearchIcon />
            </div>
          </div>
          {/* TABLE SECTION */}

          <div className="admintransaction_table_wrapper">
            <div className="table-body-container">
              <table
                id="data-table"
                className="table "
                width="100%"
                // height="0%"
              >
                <thead>
                  <tr>
                    {header.map((item, index) => {
                      return (
                        <th key={index}>
                          <div className="table_header">
                            <span>{item?.title}</span>
                            <span>
                              <img src={filtersvg} />
                            </span>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.length !== 0 ? (
                    <>
                      {/* start of body */}
                      {tab === "Radio Vendor" &&
                        paginatedData?.map((item, index) => {
                          return (
                            <tr className="odd gradeX" key={index}>
                              <td style={{ color: "#F99B28" }}>
                                {item?.radioStation}
                              </td>
                              <td>{item?.amount}</td>
                              <td>{item.withdrawalMethod}</td>
                              <td>
                                {moment(item.date).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )}
                              </td>
                              <td>
                                <AdminActionButton
                                  label={item?.status}
                                  tab={tab}
                                  radioStation={item?.radioStation}
                                  amount={item?.amount}
                                  withdrawalMethod={item?.withdrawalMethod}
                                  date={item?.date}
                                  id={item?.id}
                                  refetch={refetch}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      {tab === "Advertiser" &&
                        paginatedData?.map((item, index) => {
                          return (
                            <tr className="odd gradeX" key={index}>
                              <td>{item?.advertiser}</td>
                              <td style={{ color: "#F99B28" }}>
                                <AdminActionButton
                                  label={item?.status}
                                  tab={tab}
                                  advertiser={item?.advertiser}
                                  amount={item?.amount}
                                  withdrawalMethod={item?.withdrawalMethod}
                                  date={item?.date}
                                  id={item?.id}
                                  transactionId={item?.id}
                                  paymentMethod={item?.withdrawalMethod}
                                  description={item?.description}
                                  refetch={refetch}
                                />
                              </td>
                              <td>{item.amount}</td>
                              <td>
                                {moment(item.date).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )}
                              </td>
                              <td>{item.withdrawalMethod}</td>
                              <td>{item.description}</td>
                            </tr>
                          );
                        })}
                      {/* end of body */}
                    </>
                  ) : (
                    <tr className="not_found">
                      <td> No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="footer_wrapper">
              <div>
                <section className="pagination-section">
                  <div>
                    showing {' '}
                    {`${(currentPage - 1) * entries + 1} to ${Math.min(
                      currentPage * entries,
                      filteredData.length
                    )} of ${filteredData.length} entries`}
                  </div>
                </section>
              </div>
              {/* Pagination section */}
              <div className="pagination">
                <span onClick={() => handlePagination("prev")}>Previous</span>
                {renderPageNumbers()}
                <span onClick={() => handlePagination("next")}>Next</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminTransactionTable;
