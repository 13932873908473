import React, { useState } from "react";
import "./adminroletable.css";
import filtersvg from "../../assets/icons/filter_up_down.svg";
import AdminTransactionLoader from "../Loader/AdminTransactionLoader/AdminTransactionLoader";
// import moment from "moment";
import SearchIcon from "../AdminTransactionTable/searchIcon";
import { useNavigate } from "react-router-dom";

const AdminRoleTable = ({ data, header, loading, refetch, showFilter }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [entries, setEntries] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  const handlePagination = (action) => {
    if (action === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (action === "next" && data.length > currentPage * entries) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset current page when searching
  };

  const filteredData = data?.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const totalPages = Math.ceil(filteredData.length / entries);

  const paginatedData = filteredData?.slice(
    (currentPage - 1) * entries,
    currentPage * entries
  );

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <span
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </span>
      );
    }
    return pageNumbers;
  };

  return (
    <>
      {loading ? (
        <AdminTransactionLoader />
      ) : (
        <div className="admin_role_wrapper">
          <div className="admin_table_role_head">
            <div className="admin_entry">
              <span>Show</span>
              <select
                value={entries}
                onChange={(e) => setEntries(parseInt(e.target.value))}
              >
                <option>10</option>
                <option>20</option>
                <option>50</option>
              </select>
              <span>entries</span>
            </div>

            <div className="input-box form-control">
              <input
                type="text"
                className="search-input form-control"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearch}
              />
              <SearchIcon />
            </div>
          </div>
          {/* TABLE SECTION */}

          <div className="admin_role_table">
            <div className="admin_table-role-container">
              <table
                id="data-table"
                className="admin_table"
                width="100%"
                // height="0%"
              >
                <thead>
                  <tr>
                    {header.map((item, index) => {
                      return (
                        <th key={index}>
                          <div className="admin_table_header">
                            <span>{item?.title}</span>
                            {showFilter && (
                              <span>
                                <img src={filtersvg} alt="filter"/>
                              </span>
                            )}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.length !== 0 ? (
                    <>
                      {/* start of body */}
                      {paginatedData?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td
                              style={{
                                color: "#F99B28",
                                fontSize: "12px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                navigate(`/admin/roles/permission/${item?.id}`)
                              }
                            >
                              {item?.name}
                            </td>
                            <td style={{ fontSize: "12px", color: "#004643" }}>
                              {item?.email}
                            </td>
                            <td style={{ fontSize: "12px", color: "#004643" }}>
                              {item.role}
                            </td>
                          </tr>
                        );
                      })}

                      {/* end of body */}
                    </>
                  ) : (
                    <tr className="admin_not_found">
                      <td> No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="admin_role_footer_wrapper">
            <div>
              <section className="admin_pagination-section">
                <div>
                  showing {"  "}
                  {`${(currentPage - 1) * entries + 1} to ${Math.min(
                    currentPage * entries,
                    filteredData.length
                  )} of ${filteredData.length} entries`}
                </div>
              </section>
            </div>
            {/* Pagination section */}
            <div className="admin_pagination">
              <span onClick={() => handlePagination("prev")}>Previous</span>
              {renderPageNumbers()}
              <span onClick={() => handlePagination("next")}>Next</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminRoleTable;
