import React, { useState, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import StationDetails from "../components/StationDetails.js";
import MainFooterSect from "../../components/MainFooterSect.js";
import TimeSchedule from "../components/TimeSchedule";
import { GET_BROADCASTERS } from "../../components/GraphQL/Queries";
import "./css/page-two.css";
import { Button, Center, ChakraProvider, Spinner } from "@chakra-ui/react";
import { AdvertTypeData } from "../../constant.js";
import moment from "moment";
import {
  advertiserUserType,
  fmtCurrency,
  getCurrencySymbol,
  getCurrencySymbols,
} from "../../utils/functions";
import { request } from "graphql-request";
import { BROADCASTER_SLOTS_GQL } from "../../components/GraphQL/Queries";
import { URL } from "../../config/url";
import { useAtom } from "jotai";
import { loginModalAtom, isBookingState } from "../../atom/advertiserModal";
import { AUTH_TOKEN } from "../../constant.js";
// import { TIME_SCHEDULE } from "../../components/GraphQL/Queries";
import { useCreateDraftFromBookings, useFormatAmount } from "../../hook.js";
import { BsXCircle } from "react-icons/bs";
import mixpanel from "mixpanel-browser";
import Footer from "../../components/Footer/index.jsx";

const PageTwo = () => {
  const { loading, createDraft } = useCreateDraftFromBookings();

  // login modal
  const [, setLoginModal] = useAtom(loginModalAtom);
  const [, setIsbooking] = useAtom(isBookingState);

  const getSelectedStations = localStorage
    .getItem("selectedStations")
    .split(",");
  const currency = localStorage.getItem("currency") ?? "NGN";
  const symbol = getCurrencySymbols(currency);
  const formatAmount = useFormatAmount;

  const noOfSelectedStation = getSelectedStations.length;

  const [mutatedbroadcasterData, setMutatedBroadcasterData] = useState([]);

  const [broadcasterId, setBroadcasterId] = useState(null);
  const [title, setTitle] = useState("");
  const [activeStation, setActiveStation] = useState(0);
  const [getPrice, setGetPrice] = useState(0);
  const [loadingState, setLoadingState] = useState("idle");
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [tagLine, setTagLine] = useState("");

  //timeschedule modal state
  const [isModal, setIsModal] = useState(false);
  const tokenFromLocalStorage = localStorage.getItem("token");
  const accountType =
    tokenFromLocalStorage && advertiserUserType(tokenFromLocalStorage);

  //get all available rado statons
  const { data } = useQuery(GET_BROADCASTERS, {
    variables: {
      page: 1,
      itemsPerPage: 1000,
      currency,
    },
    onCompleted: (data) => {
      const getSelectedStations = localStorage
        .getItem("selectedStations")
        .split(",");

      setTitle(
        data?.guest.getBroadcasters.broadcasters.filter((a) => {
          return getSelectedStations.includes(a.id);
        })[0]?.name
      );

      setBroadcasterId(
        data?.guest.getBroadcasters.broadcasters.filter((a) => {
          return getSelectedStations.includes(a.id);
        })[0]?.id
      );

      setGetPrice(
        data?.guest.getBroadcasters.broadcasters.filter((a) => {
          return getSelectedStations.includes(a.id);
        })[0]?.startingPrice
      );
      setTagLine(
        data?.guest.getBroadcasters.broadcasters.filter((a) => {
          return getSelectedStations.includes(a.id);
        })[0]?.tagLine
      );
    },
  });

  const handleStationButtonClick = (station, index) => {
    setTitle(station.name);
    setActiveStation(index);
    setGetPrice(station.startingPrice);
    setBroadcasterId(station.id);
    setTagLine(station.tagLine);
  };

  const handleCancelButtonClick = (id, index) => {
    setShowCancelButton(false);

    const activeStation = mutatedbroadcasterData.filter(
      (radioStation) => radioStation.guest.id !== id
    );
    setMutatedBroadcasterData(activeStation);

    // Find the previous station (index - 1) or the next station (index + 1) to select it after deletion
    const previousIndex = index - 1;
    const nextIndex = index + 1;

    // // Filter out the station with the deleteId from the filterData
    const updatedFilterData = filterData.filter((station) => station.id !== id);

    // // Extract the remaining ids from the updatedFilterData array
    const remainingIds = updatedFilterData.map(({ id }) => id);

    // // Update the localStorage with the remaining ids
    localStorage.setItem("selectedStations", remainingIds);
    // // Update the state with the filtered data
    setFilterData(updatedFilterData);

    if (updatedFilterData.length > 0) {
      let selectedStationIndex;

      if (previousIndex >= 0 && previousIndex < updatedFilterData.length) {
        selectedStationIndex = previousIndex;
      } else if (nextIndex >= 0 && nextIndex < updatedFilterData.length) {
        selectedStationIndex = nextIndex;
      } else {
        // If both previous and next stations don't exist, select the first station in the updated list
        selectedStationIndex = 0;
      }

      const selectedStation = updatedFilterData[selectedStationIndex];
      handleStationButtonClick(selectedStation, selectedStationIndex);
    } else {
      // If no stations left after deletion, reset the title and other states
      setTitle("");
      setActiveStation(-1);
      setGetPrice(null);
      setBroadcasterId(null);
      setTagLine("");
    }

    // Parse the stored selectedStations from the local storage
    const storedStations = localStorage.getItem("selectedStations");
    const selectedStations = storedStations
      ? storedStations.split(",").map(Number)
      : [];

    // Remove the clicked id from the array
    const updatedStations = selectedStations.filter(
      (stationId) => stationId !== id
    );

    // Convert the updated array back to a string and save it to the local storage
    localStorage.setItem("selectedStations", updatedStations.join(","));
  };

  useEffect(() => {
    setLoadingState("loading");

    const getSelectedStations = localStorage
      .getItem("selectedStations")
      .split(",");

    const filteredrData = data?.guest.getBroadcasters.broadcasters.filter(
      (item) => {
        return getSelectedStations.includes(item.id);
      }
    );
    setFilterData(filteredrData);

    const filterData = data?.guest.getBroadcasters.broadcasters.filter(
      (item) => {
        return getSelectedStations.includes(item.id);
      }
    );

    filterData &&
      Promise.all([
        ...filterData?.map((broadcaster) => {
          return request({
            url: URL,
            document: BROADCASTER_SLOTS_GQL,
            variables: { broadcasterId: broadcaster?.id, currency },
          });
        }),
      ])
        .then((data) => {
          let currDate;

          if (accountType === "Agency") {
            currDate = moment(new Date()).format("YYYY-MM-DD");
          } else {
            currDate = moment(new Date()).add(1, "day").format("YYYY-MM-DD");
          }

          const currentDate = new Date();
          const dateOffset = accountType === "Agency" ? 0 : 1;

          // Create a new date object with the offset
          const adjustedDate = new Date(currentDate);
          adjustedDate.setDate(currentDate.getDate() + dateOffset);

          const currDateFormatted = adjustedDate.toISOString().split("T")[0];

          let mutatedSlotData = [...data].map((broadcaster, i) => {
            broadcaster = { ...broadcaster };
            broadcaster["guest"]["id"] = filterData[i]["id"];
            broadcaster["guest"]["name"] = filterData[i]["name"];
            broadcaster["guest"]["language"] = "";
            broadcaster["guest"]["duration"] = "";
            broadcaster["guest"]["advertType"] = "";
            broadcaster["guest"]["advertTypeData"] = [...AdvertTypeData];
            broadcaster["guest"]["startDate"] = new Date(currDateFormatted);
            broadcaster["guest"]["endDate"] = new Date(currDateFormatted);
            broadcaster["guest"]["dateRange"] = {};
            broadcaster["guest"]["dateRange"][`${currDate}`] = {
              slotData: [
                ...broadcaster?.guest?.getBroadcasterSlots.map((slot) => {
                  slot = { ...slot };
                  slot["date"] = currDate;
                  slot["adverType"] = null;
                  slot["slotNumber"] = 0;
                  slot["language"] = "";
                  return slot;
                }),
              ],
              totalSlot: 0,
              totalPrice: 0,
            };

            broadcaster["guest"]["totalDays"] = 0;
            broadcaster["guest"]["totalSpots"] = 0;
            broadcaster["guest"]["totalPrice"] = 0;
            broadcaster["guest"]["allSlots"] = [];
            broadcaster["guest"]["isChecked"] = false;
            return broadcaster;
          });
          setLoadingState("completed");

          setMutatedBroadcasterData(mutatedSlotData);
        })
        .catch((err) => console.log(err));
  }, [data]);

  const [language, setLanguage] = useState("");

  const currentBroadcasterData = useMemo(() => {
    if (!mutatedbroadcasterData) return null;

    return mutatedbroadcasterData.find((broadcaster) => {
      return broadcaster?.guest?.name === title;
    })?.guest;
  }, [mutatedbroadcasterData, title]);

  useEffect(() => {
    //prefilleed states with data from array first object
    const getSelectedStations = localStorage
      .getItem("selectedStations")
      .split(",");

    setTitle(
      data?.guest.getBroadcasters.broadcasters.filter((a) => {
        return getSelectedStations.includes(a.id);
      })[0]?.name
    );

    setBroadcasterId(
      data?.guest.getBroadcasters.broadcasters.filter((a) => {
        return getSelectedStations.includes(a.id);
      })[0]?.id
    );

    setGetPrice(
      data?.guest.getBroadcasters.broadcasters.filter((a) => {
        return getSelectedStations.includes(a.id);
      })[0]?.startingPrice
    );
    setTagLine(
      data?.guest.getBroadcasters.broadcasters.filter((a) => {
        return getSelectedStations.includes(a.id);
      })[0]?.tagLine
    );
  }, [data]);

  //timeschedule modal function
  const openModal = () => {
    setIsModal(true);
  };

  const closeModal = () => {
    setIsModal(false);
  };

  const allSlotData =
    mutatedbroadcasterData &&
    mutatedbroadcasterData
      ?.map((broadcaster) => {
        return [...(broadcaster.guest?.allSlots || [])];
      })
      .flat()
      .filter(
        (slotData) =>
          slotData?.jingleType !== null || slotData?.slotNumber !== 0
      )
      .map(({ id, slotNumber, date, broadcasterId, advertType, language }) => {
        return {
          slotId: id,
          quantity: slotNumber,
          language: language,
          date: new Date(date),
          broadcasterId: broadcasterId,
          advertType: advertType,
        };
      });

  const handleSubmit = () => {
    // track user event
    mixpanel.track("proceed_to_checkout");

    const token = localStorage.getItem(AUTH_TOKEN);

    // save booking to localstorage
    localStorage.setItem("booking", JSON.stringify(allSlotData));

    if (!token) {
      // set true that a guest a booking a campaign
      setIsbooking(true);

      return setLoginModal(true);
    }

    createDraft([...allSlotData]);
  };

  const handleMouseLeaveWithDelay = () => {
    // Set a delay of 500ms (adjust the duration as needed)
    const id = setTimeout(() => {
      setShowCancelButton(false);
    }, 2000);

    setTimeoutId(id);
  };

  const handleMouseEnter = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
  };

  return (
    <>
      {isModal && (
        <TimeSchedule closeModal={closeModal} stationId={broadcasterId} />
      )}
      <div>
        <main>
          <section id="station-detail">
            <div>
              <div id="station-detail-btn">
                {filterData?.map((station, index) => {
                  return (
                    <div id="station-detail-btn-card" key={index}>
                      <button
                        key={index}
                        onClick={() => handleStationButtonClick(station, index)}
                        className={
                          index === activeStation ? "active-station-btn" : ""
                        }
                        onMouseEnter={() => {
                          setShowCancelButton(true);
                          handleMouseEnter();
                        }}
                        onMouseLeave={handleMouseLeaveWithDelay}
                      >
                        {station.name}
                      </button>
                      {
                        // showCancelButton &&
                        //   index === activeStation &&
                        filterData.length > 1 && (
                          <div
                            onClick={() =>
                              handleCancelButtonClick(station.id, index)
                            }
                            className="cancel-btn"
                          >
                            <BsXCircle
                              className="cancel-icon"
                              style={{
                                color:
                                  index === activeStation
                                    ? "white"
                                    : "rgb(124, 121, 121)",
                              }}
                            />
                          </div>
                        )
                      }
                    </div>
                  );
                })}
              </div>

              <ChakraProvider>
                {loadingState === "loading" || loadingState === "idle" ? (
                  <Center h="50vh">
                    <Spinner size="xl" />
                  </Center>
                ) : (
                  <>
                    <StationDetails
                      title={title}
                      tagline={tagLine}
                      totalStation={noOfSelectedStation}
                      totalPrice={getPrice}
                      id={broadcasterId}
                      isOpen={openModal}
                      stationNo={filterData?.length}
                      broadcasterData={currentBroadcasterData}
                      setMutatedBroadcasterData={setMutatedBroadcasterData}
                      mutatedbroadcasterData={mutatedbroadcasterData}
                      setLanguage={setLanguage}
                      broadcasterId={broadcasterId}
                      accountType={accountType}
                      // data={timeSchedule}
                    />

                    <dl id="booking-details">
                      <h2>Booking Details</h2>
                      <div>
                        <dt>Radio Stations:</dt>
                        <dd>{filterData?.length || 0}</dd>
                      </div>
                      <div>
                        <dt>Total no of Days:</dt>
                        <dd>
                          {mutatedbroadcasterData &&
                            mutatedbroadcasterData.reduce((acc, curr) => {
                              return acc + curr?.guest?.totalDays;
                            }, 0)}
                        </dd>
                      </div>
                      <div>
                        <dt>Total no of Spots:</dt>
                        <dd>
                          {" "}
                          {mutatedbroadcasterData &&
                            mutatedbroadcasterData.reduce((acc, curr) => {
                              return acc + curr?.guest?.totalSpots;
                            }, 0)}
                        </dd>
                      </div>
                      <div>
                        <dt>Total Amount: </dt>
                        <dd>
                          {/* {`${fmtCurrency(
                            mutatedbroadcasterData &&
                              mutatedbroadcasterData.reduce((acc, curr) => {
                                return acc + curr?.guest?.totalPrice;
                              }, 0)
                          )}`} */}
                          {`${formatAmount(
                            mutatedbroadcasterData &&
                              mutatedbroadcasterData.reduce((acc, curr) => {
                                return acc + curr?.guest?.totalPrice;
                              }, 0)
                          )}`}
                        </dd>
                      </div>
                    </dl>
                    <Button
                      isLoading={loading}
                      loadingText="Loading"
                      spinnerPlacement="start"
                      h="44px"
                      borderRadius={10}
                      background="#F1613F"
                      colorScheme="#F99B28"
                      maxW={210}
                      color="white"
                      isDisabled={!allSlotData.length}
                      onClick={handleSubmit}
                      className="checkout-btned"
                    >
                      Proceed to Checkout
                    </Button>
                  </>
                )}
              </ChakraProvider>
            </div>
          </section>
        </main>
        <footer style={{ boxSizing: "content-box" }}>
          <Footer />
        </footer>
      </div>
    </>
  );
};

export default PageTwo;
