import React from "react";
import { Skeleton } from "@chakra-ui/react";
import "../../AdminTransactionTable/admintransaction.css";

const AdminTransactionLoader = () => {
  return (
    <div className="wrapper">
      <div className="table_head">
        <div className="entry">
          <span>
            <Skeleton height="40px" width="200px" />
          </span>
        </div>
        <div>
          <Skeleton height="40px" width="200px" />
        </div>
      </div>

      <div className="">
        <div className="table-body-container">
          <table id="data-table" className="table " width="100%" height="100%">
            <thead>
              <th>
                <Skeleton height="20px" width="100px" />
              </th>
              <th>
                <Skeleton height="20px" width="100px" />
              </th>
              <th>
                <Skeleton height="20px" width="100px" />
              </th>
              <th>
                <Skeleton height="20px" width="100px" />
              </th>
              <th>
                <Skeleton height="20px" width="100px" />
              </th>
            </thead>
            <tbody>
              <tr className="odd gradeX">
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
              </tr>
              <tr className="odd gradeX">
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
              </tr>
              <tr className="odd gradeX">
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
              </tr>
              <tr className="odd gradeX">
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
              </tr>
              <tr className="odd gradeX">
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
              </tr>
              <tr className="odd gradeX">
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
              </tr>
              <tr className="odd gradeX">
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
                <td>
                  <Skeleton height="20px" width="100px" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminTransactionLoader;
