import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon, EmailIcon } from "@chakra-ui/icons";
import moment from "moment/moment";
import { useMutation } from "@apollo/client";
import { VIEWED_NOTIFICATION } from "../GraphQL/Mutation";
import NotificationLoader from "../SkeletonLayoutLoader/NotificationLoader";
import { useEffect } from "react";

const NotificationsPopOver = ({
  isNotificationModal,
  closeNotificationModal,
  data,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [currentPage, setCurrentPage] = useState(1);
  const [notification, setNotification] = useState({
    message: "",
    date: "",
  });
  const [showLoading, setShowLoading] = useState(true);

  const [clickedMessage] = useMutation(VIEWED_NOTIFICATION);

  const pageSize = 10;

  const startIdx = (currentPage - 1) * pageSize;
  const endIdx = startIdx + pageSize;
  const currentNotifications = data?.getNotification.slice(startIdx, endIdx);

  const totalPages = Math.ceil(data?.getNotification.length / pageSize);

  const handleNextPage = () =>
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));

  const handlePrevPage = () =>
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));

  const handleCheckedMessage = (id) => {
    clickedMessage({
      variables: {
        notificationId: parseInt(id),
      },
    });
  };

  useEffect(() => {
    // Show loading for a few seconds before revealing the content
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (showLoading) {
    return showLoading && <NotificationLoader isLoader={showLoading} />;
  }

  return (
    <>
      <Modal
        isCentered
        isOpen={isNotificationModal}
        onClose={closeNotificationModal}
        scrollBehavior="inside"
        size="lg"
      >
        <ModalOverlay />
        <ModalContent maxH="600px">
          <ModalHeader px={0} bg="#004643" color="#fff">
            <Flex
              flexDirection="column"
              gap={6}
              px={6}
              borderBottom="1px"
              borderColor="#004643"
            >
              <Text fontSize={20}>Notifications</Text>
              <Text fontSize={18}>
                Inbox ({data?.getNotification.length || 0})
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton color="#fff" boxSize={8} />
          <ModalBody px={0}>
            {" "}
            {
              data?.getNotification.length > 0
              ? (
              currentNotifications.map((notification) => {
                return (
                  <Flex
                    key={notification.id}
                    gap={4}
                    borderBottom="1px"
                    borderColor="#004643"
                    py={4}
                    px={6}
                    onClick={() => {
                      setNotification(notification);
                      onOpen();
                      handleCheckedMessage(notification.id);
                    }}
                    color={notification.viewed ? "gray" : "#004643"}
                    _hover={{ bg: "#F5F5F5", cursor: "pointer" }}
                  >
                    <EmailIcon w={6} h={6} />
                    <Box display="flex" flexDirection="column">
                      <Text fontSize={16}>
                        {notification.message.substring(0, 50)}...
                      </Text>
                      <Text fontSize={12}>
                        {moment(notification.createdAt).format("Do MMM, YYYY")}
                      </Text>
                    </Box>
                  </Flex>
                );
              })
              )
              : (
                <Box textAlign="center" mt={10} fontSize={20}>
                  No Notification Yet.
                </Box>
              )
            }
          </ModalBody>
          <ModalFooter>
            {currentNotifications?.length > 0 && (
              <Flex gap={4} alignItems="center">
                <Button
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  cursor={currentPage === 1 ? "not-allowed" : "pointer"}
                  _hover={{ bg: "#004643", color: "#fff" }}
                >
                  <ChevronLeftIcon boxSize={6} />
                </Button>
                <span>
                  {" "}
                  Page {currentPage} of {totalPages}{" "}
                </span>
                <Button
                  onClick={handleNextPage}m
                  disabled={currentPage === totalPages}
                  cursor={
                    currentPage === totalPages ? "not-allowed" : "pointer"
                  }
                  _hover={{ bg: "#004643", color: "#fff" }}
                >
                  <ChevronRightIcon boxSize={6} />
                </Button>
              </Flex>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isOpen && (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
          <ModalOverlay
            bg="blackAlpha.300"
            backdropFilter="blur(10px) hue-rotate(90deg)"
          />
          <ModalContent color="#004643">
            <ModalHeader fontSize={20}>{notification.title}</ModalHeader>
            <ModalCloseButton _hover={{ color: "#fff", bg: "#004643" }} />
            <ModalBody>
              <Text fontSize={18}>{notification.message}</Text>
            </ModalBody>
            <ModalFooter>
              <Button
                _hover={{ color: "#fff", bg: "#004643" }}
                onClick={onClose}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default NotificationsPopOver;
