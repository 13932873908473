import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { GET_ALL_ORDERS } from "../components/GraphQL/Queries";
import SearchIcon from "../components/AdminTransactionTable/searchIcon";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { TableSpinner } from "../components/Spinner";

const AdminOrders = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [entries, setEntries] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const { data, loading } = useQuery(GET_ALL_ORDERS);

  console.log(data?.admin.getAllOrders, "0000");
  const handlePagination = (action) => {
    if (action === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (
      action === "next" &&
      data?.admin.getAllOrders?.length > currentPage * entries
    ) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset current page when searching
  };

  const filteredData = data?.admin.getAllOrders?.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const totalPages = Math.ceil(filteredData?.length / entries);

  const paginatedData = filteredData?.slice(
    (currentPage - 1) * entries,
    currentPage * entries
  );

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <span
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </span>
      );
    }
    return pageNumbers;
  };

  return (
    <AdminDashboardLayout>
      {loading ? (
        <TableSpinner />
      ) : (
        <Box px={30} h="100%" bg="#fff">
          <Box py={50}>
            <section className="filter_entry_wrapper">
              <div className="filter_entry_dropdown">
                <span>Show</span>
                <select
                  name=""
                  id=""
                  value={entries}
                  onChange={(e) => setEntries(parseInt(e.target.value))}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                </select>
                <span>entries</span>
              </div>
              <div className="search_input_wrapper">
                <InputGroup maxW="250px" bg="#EFF6F4">
                  <InputRightElement cursor="pointer" py={4}>
                    <SearchIcon />
                  </InputRightElement>
                  <Input
                    type="text"
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                </InputGroup>
              </div>
            </section>
          </Box>

          {/* second section  */}

          <section className="station-table-container">
            <table>
              <thead>
                <tr style={{ background: "#EFF6F4" }}>
                  <th>Invoice No.</th>
                  <th>Campaign Name</th>
                  <th>Company Name</th>
                  <th>Industry</th>
                  <th>Date</th>
                  <th>Radio Station</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData?.map((tableinfo, index) => {
                  return (
                    <tr key={index}>
                      <td className="campaign-id">
                        <Link to={`/admin/order/${tableinfo.id}`}>
                          <span style={{ color: "#F99B28" }}>
                            {`#${tableinfo.invoice}`}
                          </span>
                        </Link>
                      </td>
                      <td>{tableinfo.campaignName}</td>
                      <td>{tableinfo.companyName}</td>
                      <td>{tableinfo.industryName}</td>

                      <td>
                        {moment(new Date(tableinfo.date)).format("MMM Do YYYY")}
                      </td>
                      <td className="station-last-column">
                        <p>{tableinfo.radioStation[0]?.name}</p>
                        {tableinfo.radioStation.length > 1 && (
                          <span>
                            + {tableinfo.radioStation.slice(1).length}
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </section>
          {paginatedData?.length && (
            <div className="admin_footer_wrapper">
              <div>
                <section className="admin_pagination-section">
                  <div>
                    showing {"  "}
                    {`${(currentPage - 1) * entries + 1}`} <span>to</span>{" "}
                    {`${Math.min(currentPage * entries, filteredData.length)}`}{" "}
                    <span>of</span> {`${filteredData.length} `}{" "}
                    <span>entries</span>
                  </div>
                </section>
              </div>
              {/* Pagination section */}
              <div className="admin_pagination">
                <span onClick={() => handlePagination("prev")}>Previous</span>
                {renderPageNumbers()}
                <span onClick={() => handlePagination("next")}>Next</span>
              </div>
            </div>
          )}
        </Box>
      )}
    </AdminDashboardLayout>
  );
};

export default AdminOrders;
