import React from "react";

const SpotIcon = () => {
  return (
    <svg
      width="23"
      height="26"
      viewBox="0 0 23 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.625 1.2381V3.71429H2.875V23.5238H17.25V12.381H20.125V24.7619C20.125 25.0903 19.9736 25.4052 19.704 25.6374C19.4344 25.8696 19.0687 26 18.6875 26H1.4375C1.05625 26 0.690618 25.8696 0.421034 25.6374C0.151451 25.4052 0 25.0903 0 24.7619V2.47619C0 2.14783 0.151451 1.83291 0.421034 1.60072C0.690618 1.36854 1.05625 1.2381 1.4375 1.2381H8.625ZM11.5 7.42857C12.2625 7.42857 12.9938 7.68946 13.5329 8.15383C14.0721 8.61821 14.375 9.24804 14.375 9.90476H11.5V7.42857ZM11.5 3.71429C13.4062 3.71429 15.2344 4.36649 16.5823 5.52743C17.9302 6.68837 18.6875 8.26295 18.6875 9.90476H15.8125C15.8125 8.91967 15.3581 7.97493 14.5494 7.27837C13.7406 6.5818 12.6437 6.19048 11.5 6.19048V3.71429ZM11.5 0C14.55 0 17.4751 1.04353 19.6317 2.90104C21.7884 4.75854 23 7.27786 23 9.90476H20.125C20.125 7.93458 19.2163 6.0451 17.5988 4.65197C15.9813 3.25884 13.7875 2.47619 11.5 2.47619V0Z"
        fill="#F99B28"
      />
    </svg>
  );
};

export default SpotIcon;
