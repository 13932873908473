import React, { useEffect, useState } from "react";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { useFormik } from "formik";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdAdd,
  MdOutlineRemove,
} from "react-icons/md";
import { GET_ALL_ADMIN_ADVERTISER_BY_ID } from "../components/GraphQL/Queries";
import { useQuery, useMutation } from "@apollo/client";
import "./css/admin_advertiser_profile.css";
import { AdminAdvertiserProfileSchema } from "../utils/validations";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Country, State } from "country-state-city";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  ChakraProvider,
  Center,
} from "@chakra-ui/react";
import exclamationIcon from "../assets/exclamationIcon.png";
import { useParams } from "react-router-dom";
import { TableSpinner } from "../components/Spinner";
import {
  ADMIN_UPDATE_ACCOUNT_STATUS,
  ADMIN_UPDATE_SPECIAL_ADVISER_DISCOUNT,
  UPDATE_ADVERTISER_DETAILS,
} from "../components/GraphQL/Mutation";

const AdminAdvertiserProfile = () => {
  // get id from url
  const { id } = useParams();
  const [states, setStates] = useState([]);
  const [inputDiscount, setInputDiscount] = useState(null);
  const [showSpecial, setShowSpecial] = useState(false);
  const { data, loading, refetch, error } = useQuery(
    GET_ALL_ADMIN_ADVERTISER_BY_ID,
    {
      variables: { advertiserId: id },
    }
  );
  const [UpdateAccountStatus, { loading: updateLoading }] = useMutation(
    ADMIN_UPDATE_ACCOUNT_STATUS
  );

  const [UpdateAdvertiserDetails, { loading: updateAdLoading }] = useMutation(
    UPDATE_ADVERTISER_DETAILS
  );

  const [
    UpdateSpecialAdvertiserDiscount,
    { loading: updateAdvertiserLoading },
  ] = useMutation(ADMIN_UPDATE_SPECIAL_ADVISER_DISCOUNT);

  const HandleUpdateDiscount = (e) => {
    setInputDiscount(e.target.value);
  };

  const HandleUpdateDiscountSubmit = () => {
    if (inputDiscount !== null) {
      UpdateSpecialAdvertiserDiscount({
        variables: {
          userId: id,
          discount: parseInt(inputDiscount, 10),
        },
        onCompleted: () => {
          toast.success("Special advertiser discount successfully updated");
          setShowSpecial(false);
        },
        onError(err) {
          toast.error("An Error occur");
        },
      });
    }
  };

  const HandleUpdateAccountStatus = () => {
    UpdateAccountStatus({
      variables: {
        userId: id,
        status: !AccountStatus,
      },
      onCompleted: () => {
        toast.success("Account Status Updated Successfully");
        refetch();
        onClose();
      },
      onError(err) {
        toast.error("An Error occur");
        onClose();
      },
    });
  };

  const HandleSubmit = () => {
    UpdateAdvertiserDetails({
      variables: {
        input: {
          state: values.state,
          phoneNumber: values.phoneNumber,
          name: values.companyName,
          industry: values.industry,
          country: values.country,
          city: values.city,
          advertiserId: id,
          address: values.address,
          accountType: values.accountType,
          // zipCode: values.zipCode,
        },
      },
      onCompleted: () => {
        toast.success("Account Details Updated Successfully");
        refetch();
      },
      onError(err) {
        toast.error("An error occur");
        // toast.error(err.networkError.result.errors[0].message);
      },
    });
  };

  useEffect(() => {
    if (!loading && data) {
      const advertiserData = data?.admin?.getAdvertiserById;
      resetForm({
        values: {
          companyName: advertiserData?.name || "",
          industry: advertiserData?.industry || "",
          address: advertiserData?.address || "",
          country: advertiserData?.country || "",
          state: advertiserData?.state || "",
          city: advertiserData?.city || "",
          zipCode: advertiserData?.zipCode || "",
          phoneNumber: advertiserData?.phone || "",
          accountType: advertiserData?.accountType || "Individual",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setFieldValue,
    touched,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      companyName: "",
      industry: "",
      address: "",
      country: "",
      state: "",
      city: "",
      zipCode: "",
      phoneNumber: "",
      accountType: "Individual",
      // email: "",
    },
    validationSchema: AdminAdvertiserProfileSchema,
  });
  const [countrystatename, setCountryStateName] = useState({
    countryName: values.country || "",
    stateName: "",
    cityName: "",
  });
  const handleCountry = (e) => {
    const { value } = e.target;

    const getCountryCode = Country.getAllCountries().find(
      (country) => country.name === value
    ).isoCode;
    const getStates = State.getStatesOfCountry(getCountryCode);
    setStates(getStates);
    setCountryStateName({ ...countrystatename, countryName: value });
  };
  const handleState = (e) => {
    const { value } = e.target;
    setCountryStateName({ ...countrystatename, stateName: value });
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  let AccountStatus = data?.admin?.getAdvertiserById?.accountStatus;

  return (
    <AdminDashboardLayout>
      {loading ? (
        <ChakraProvider>
          <Center h="100vh">
            <TableSpinner />
          </Center>
        </ChakraProvider>
      ) : (
        <>
          <div className="Home_Dashboard_wrap">
            <div className="profile-nav">
              <div className="profile-back" onClick={handleGoBack}>
                <span>
                  <MdArrowBackIos />
                </span>
                <span>Back</span>
              </div>
              <div className="profile-deactivate" onClick={onOpen}>
                <p>
                  {AccountStatus === true
                    ? "Deactivate Account"
                    : "Active Account"}
                </p>
              </div>
            </div>
            <div className="profile_tab_wrapper">
              {/* Form section */}
              <div className="form-sec">
                <form onSubmit={handleSubmit}>
                  <div className="profile-form-container">
                    <div
                      id="profile-input-container"
                      style={{ marginTop: "-25px" }}
                    >
                      <div className="advertiser-type-checkbox">
                        <label
                          className={`checkbox-label ${
                            values.accountType === "Individual"
                              ? "checked-txt"
                              : "unchecked-txt"
                          }`}
                        >
                          <input
                            type="checkbox"
                            onChange={() =>
                              setFieldValue("accountType", "Individual")
                            }
                            checked={values.accountType === "Individual"}
                            disabled={`${values.accountType !== "Individual"}`}
                          />
                          Individual
                        </label>
                        <label
                          className={`checkbox-label ${
                            values.accountType === "Company"
                              ? "checked-txt"
                              : "unchecked-txt"
                          }`}
                        >
                          <input
                            type="checkbox"
                            onChange={() =>
                              setFieldValue("accountType", "Company")
                            }
                            checked={values.accountType === "Company"}
                            disabled={`${values.accountType !== "Company"}`}
                          />
                          Company
                        </label>
                      </div>
                      {/* {console.log(values.accountType)} */}
                      {values.accountType === "Company" && (
                        <>
                          <div className="profile-full-length">
                            <div className="form-input-wrapper">
                              {errors.companyName && touched.companyName && (
                                <span> {errors.companyName}</span>
                              )}
                              <div className="input-cont profile-half-length">
                                <label
                                  htmlFor="radio-station"
                                  className="profile-form-label"
                                >
                                  Company name
                                </label>
                                <input
                                  // id="radio-station"
                                  type="text"
                                  placeholder={values.companyName}
                                  name="companyName"
                                  value={values.companyName}
                                  onChange={(value) => handleChange(value)}
                                  onBlur={handleBlur}
                                  required
                                />
                              </div>
                            </div>
                            <div className="input-cont profile-half-length">
                              <label
                                htmlFor="radio-station"
                                className="profile-form-label"
                              >
                                Industry
                              </label>
                              <input
                                // id="radio-station"
                                type="text"
                                placeholder={values.industry}
                                name="industry"
                                value={values.industry}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                required
                              />
                            </div>
                          </div>

                          <div className="profile-full-length">
                            <div className="form-input-wrapper">
                              {errors.phoneNumber && touched.phoneNumber && (
                                <span
                                  id="form-error"
                                  style={{ marginTop: "-14px" }}
                                >
                                  {" "}
                                  {errors.phoneNumber}
                                </span>
                              )}
                              <div className="input-cont profile-half-length">
                                <label
                                  htmlFor="phone-no"
                                  className="profile-form-label"
                                >
                                  Contact No.{" "}
                                  <span className="form-required">*</span>
                                </label>
                                <input
                                  // id="radio-station"
                                  type="text"
                                  placeholder={values.phoneNumber}
                                  name="phoneNumber"
                                  value={values.phoneNumber}
                                  onChange={handleChange}
                                  // onBlur={handleBlur}
                                  required
                                />
                              </div>
                            </div>

                            <div className="input-cont profile-half-length">
                              <label
                                htmlFor="address"
                                className="profile-form-label"
                              >
                                Address (street, buildings)
                              </label>
                              <input
                                // id="radio-station"
                                type="text"
                                placeholder={values.address}
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                required
                              />
                            </div>
                          </div>

                          <div className="profile-full-length">
                            <div className="form-input-wrapper">
                              <div className="input-cont profile-half-length">
                                <label
                                  htmlFor="country"
                                  className="profile-form-label"
                                >
                                  Country
                                </label>
                                <select
                                  id="countries"
                                  className="country-dropdown"
                                  onChange={(e) => handleCountry(e)}
                                >
                                  <option selected disabled>
                                    {values.country === ""
                                      ? "Select Country"
                                      : values.country}
                                  </option>
                                  {Country.getAllCountries().map(
                                    (getcountry, index) => (
                                      <option
                                        value={getcountry.name}
                                        key={index}
                                      >
                                        {getcountry.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>

                            <div className="input-cont profile-half-length">
                              <label
                                htmlFor="state"
                                className="profile-form-label"
                              >
                                State
                              </label>
                              <select
                                id="states"
                                className="state-dropdown"
                                onChange={(e) => handleState(e)}
                              >
                                <option selected disabled>
                                  {values.country === ""
                                    ? "Select State"
                                    : values.state}
                                </option>
                                {states.map((getState, index) => (
                                  <option value={getState.name} key={index}>
                                    {getState.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="profile-full-length">
                            <div className="form-input-wrapper">
                              <div className="input-cont profile-half-length">
                                <label
                                  htmlFor="city"
                                  className="profile-form-label"
                                >
                                  City
                                </label>
                                <input
                                  // id="radio-station"
                                  type="text"
                                  placeholder={values.city}
                                  name="city"
                                  value={values.city}
                                  onChange={handleChange}
                                  // onBlur={handleBlur}
                                  required
                                />
                              </div>
                            </div>
                            <div className="input-cont profile-half-length">
                              <label htmlFor="zipcode">Zipcode</label>
                              <input
                                id="zipcode"
                                type="text"
                                placeholder="Enter zipcode"
                                name="zipcode"
                                value={values.zipcode}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {values.accountType === "Individual" && (
                        <>
                          <div className="profile-full-length">
                            <div className="form-input-wrapper">
                              {errors.companyName && touched.companyName && (
                                <span id="form-error">
                                  {" "}
                                  {errors.companyName}
                                </span>
                              )}
                              <div className="profile-half-length ">
                                <label
                                  htmlFor="radio-station"
                                  className="profile-form-label"
                                >
                                  Company name{" "}
                                  <span className="form-required">*</span>
                                </label>
                                <input
                                  // id="radio-station"
                                  type="text"
                                  placeholder={values.companyName}
                                  name="companyName"
                                  value={values.companyName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-input-wrapper">
                              {errors.address && touched.address && (
                                <span> {errors.address}</span>
                              )}
                              <div className="profile-half-length">
                                <label
                                  htmlFor="radio-station"
                                  className="profile-form-label"
                                >
                                  Address (street, buildings){" "}
                                </label>
                                <input
                                  // id="radio-station"
                                  type="text"
                                  placeholder={values.address}
                                  name="address"
                                  value={values.address}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          <div className="profile-full-length">
                            <div className="form-input-wrapper">
                              {errors.phoneNumber && touched.phoneNumber && (
                                <span
                                  id="form-error"
                                  style={{ marginTop: "-14px" }}
                                >
                                  {" "}
                                  {errors.phoneNumber}
                                </span>
                              )}
                              <div className="input-cont profile-half-length">
                                <label
                                  htmlFor="phone-no"
                                  className="profile-form-label"
                                >
                                  Contact No.{" "}
                                  <span className="form-required">*</span>
                                  <input
                                    // id="radio-station"
                                    type="text"
                                    placeholder={values.phoneNumber}
                                    name="phoneNumber"
                                    value={values.phoneNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                  />
                                </label>
                              </div>
                            </div>

                            <div className="form-input-wrapper">
                              <div className="input-cont profile-half-length">
                                <label htmlFor="country">Country</label>
                                <select
                                  id="countries"
                                  className="country-dropdown"
                                  onChange={(e) => handleCountry(e)}
                                >
                                  <option selected disabled>
                                    {values.country === ""
                                      ? "Select Country"
                                      : values.country}
                                  </option>
                                  {Country.getAllCountries().map(
                                    (getcountry, index) => (
                                      <option
                                        value={getcountry.name}
                                        key={index}
                                      >
                                        {getcountry.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="profile-full-length">
                            <div className="form-input-wrapper">
                              <div className="input-cont profile-half-length">
                                <label htmlFor="state">State</label>
                                <select
                                  id="states"
                                  className="state-dropdown"
                                  onChange={(e) => handleState(e)}
                                >
                                  <option selected disabled>
                                    {values.country === ""
                                      ? "Select State"
                                      : values.state}
                                  </option>
                                  {states.map((getState, index) => (
                                    <option value={getState.name} key={index}>
                                      {getState.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="form-input-wrapper">
                              <div className="input-cont profile-half-length">
                                <label htmlFor="city">City</label>
                                <input
                                  // id="radio-station"
                                  type="text"
                                  placeholder={values.city}
                                  name="city"
                                  value={values.city}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          <div className="profile-full-length">
                            <div className="input-cont profile-half-length">
                              <label htmlFor="zipCode">Zip Code</label>
                              <input
                                // id="radio-station"
                                type="text"
                                placeholder={"Enter zipcode"}
                                name="zipCode"
                                value={values.zipCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                // required
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div id="profile-button">
                      <button
                        type="submit"
                        // disabled={errors !== {}}
                        className={"profile-button"}
                        onClick={HandleSubmit}
                      >
                        {updateAdLoading ? (
                          <i class="fa fa-circle-o-notch fa-spin"></i>
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {/* End of Form Section */}

              {/* Status section */}
              <div className="profile-campaign">
                <div className="profile-status-wrap">
                  <div className="profile-status">
                    <span className="profile-status-label">Status: </span>
                    <span className="profile-status-val">
                      {AccountStatus === true ? "Active" : "Inactive"}
                    </span>
                  </div>
                  <div
                    className="profile-all-campaign"
                    onClick={() =>
                      navigate(`/admin/advertiser/profile/invoice/${id}`)
                    }
                  >
                    <span>View all campaigns</span>
                    <span>
                      <MdArrowForwardIos />
                    </span>
                  </div>
                </div>

                <>
                  {!showSpecial && (
                    <div className="profile-special-wrap">
                      <span>Special Advertiser Status</span>
                      <span>
                        <MdAdd
                          className="profile-mdAdd"
                          onClick={() => setShowSpecial(true)}
                        />
                      </span>
                    </div>
                  )}
                  {showSpecial && (
                    <div className="profile-special-wrapper-two">
                      <div className="profile-special-header">
                        <div>
                          <p className="profile-status">
                            Special Advertiser Status
                          </p>
                        </div>
                        <div>
                          <MdOutlineRemove
                            className="profile-MdRemove"
                            onClick={() => setShowSpecial(false)}
                          />
                        </div>
                      </div>

                      <div>
                        <div>
                          <p className="profile-status-txt">
                            Please input discount % for this advertiser.
                          </p>
                        </div>
                        <div>
                          <p className="profile-status-txt">
                            Discount default % is set to 15 if special
                            advertiser status is activated.
                          </p>
                        </div>
                      </div>

                      <div className="profile-footer">
                        <div>
                          <input
                            className="profile-discount-input"
                            type="number"
                            placeholder="Input discount"
                            value={inputDiscount}
                            onChange={HandleUpdateDiscount}
                          />
                        </div>

                        <div
                          className="profile-update-btn"
                          onClick={HandleUpdateDiscountSubmit}
                        >
                          <p>
                            {updateAdvertiserLoading ? (
                              <i class="fa fa-circle-o-notch fa-spin"></i>
                            ) : (
                              "Update"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              </div>
              {/* End of status section */}
            </div>

            {/* Popup Modal */}
            <Modal onClose={onClose} isOpen={isOpen} isCentered width={"543px"}>
              <ModalOverlay />
              <ModalContent padding="20px">
                <ModalBody>
                  <div className="profile-popover_wrapper">
                    <div className="profile-footer">
                      <div className="profile-icon">
                        <img src={exclamationIcon} alt="exclamationIcon" />
                      </div>

                      <div className="profile-pop-txt">
                        <p>
                          Are you sure you want to{" "}
                          {AccountStatus === true ? "Deactivate" : "activate"}{" "}
                          this account?
                        </p>
                      </div>
                    </div>

                    <div className="profile-pop-footer">
                      <div className="profile-pop-cancel" onClick={onClose}>
                        <p>No, Cancel</p>
                      </div>
                      <div
                        className="profile-pop-del"
                        onClick={HandleUpdateAccountStatus}
                      >
                        <p>
                          {updateLoading ? (
                            <i className="fa fa-circle-o-notch fa-spin"></i>
                          ) : (
                            `${AccountStatus ? "Yes Delete" : "Yes Activate"}`
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </ModalContent>
            </Modal>
            {/* End of Popup Modal */}
          </div>
        </>
      )}
      ;
    </AdminDashboardLayout>
  );
};
export default AdminAdvertiserProfile;
