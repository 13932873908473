import React, { useState, useCallback } from "react";
import { useFormik } from "formik";
import { useToast, useDisclosure } from "@chakra-ui/react";
import { useMutation, useQuery } from "@apollo/client";

//Mutation Query
import { CREATE_PORTFOLIO } from "../../../../../../components/GraphQL/Mutation";
import PortfolioCreatedModal from "../../../../../../components/Modal/analytics/PortfolioCreatedModal";
import { PortfolioSchema } from "../../../../../../utils/validations";
import "../../../css/create-portfolio.css";
import { GET_CAMPAIGNS_FOR_PORTFOLIOS } from "../../../../../../components/GraphQL/Queries";
import { CLIENT_ID, SCOPES, GOOGLE_ACCOUNT_SUMMARIES } from "../../../../../../utils/apiKeys";
import AnalyticsData from "../../../../../../components/Modal/analytics/AnalyticsData";


const AdvertiserCreatePortfolio = () => {
  const [openModal, setOpenModal] = useState(false);

  const [campaignName, setCampaignName] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [propertyId, setPropertyId] = useState("")

  console.log(propertyId, 'id');

    // STATES TO HANDLE GOOGLE ANALYTICS
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [authError, setAuthError] = useState(null);
    const [isAuthLoading, SetISAuthLoading] = useState(false)
    //END OF STATES TO HANDLE GOOGLE ANALYTIC

    const {onOpen, isOpen, onClose } = useDisclosure()

  const toast = useToast();
  const { data: { advertiser: { getCampaignForPortfolio = [] } = {} } = {} } =
    useQuery(GET_CAMPAIGNS_FOR_PORTFOLIOS);


  const [createPortfolio, { loading }] = useMutation(CREATE_PORTFOLIO, {
    context: {
      headers: {
        "apollo-require-preflight": true,
      },
    },
    onCompleted: () => {
      setOpenModal(true);
    },
    onError: (errors) => {
      toast({
        title: `${errors.networkError.result.errors[0].message}`,
        status: "error",
        position: "top-right",
        isClosable: true,
      });
    },
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      title: "",
      propertyId: "",
      metric: "session",

      campaignId: "",

    },
    validationSchema: PortfolioSchema,
    onSubmit() {
      createPortfolio({
        variables: {
          input: {
            title: values.title,
            propertyId: propertyId,
            metric: values.metric,
            campaignId: campaignId,
          },
        },
      });
    },
  });

  const handleOptionChange = (event) => {
    const selectedCampaign = event.target.value;
    setCampaignName(selectedCampaign);
    setCampaignId(getCampaignId(selectedCampaign));
  };

  const getCampaignId = (selectedCampaign) => {

    const campaignOption = getCampaignForPortfolio.find(
      (campaign) => campaign.name === selectedCampaign
    );
    return campaignOption ? campaignOption.id : "";

  };

    /**
   * BEGINNIG OF GOOGLE ANALYTICS FUNCTIONS
   */
    const google = window.google;

    const HandleAccess = () => {
      google.accounts.oauth2
        .initTokenClient({
          client_id: CLIENT_ID,
          scope: SCOPES,
          callback: handleResponse,
        })
        .requestAccessToken();

    };
  
    const fetchData = async (token) => {
      try {
        const response = await fetch(GOOGLE_ACCOUNT_SUMMARIES, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          // throw new Error("Failed to fetch data");
          setError(true);
          toast({
            title: `${response.error}`,
            status: "error",
            position: "top-right",
            isClosable: true,
          });
        }
        const responseData = await response.json();
        // console.log("Data for Account Summary--", responseData);
        setData(responseData);
        
          onOpen()
        
        
      } catch (error) {
        // console.log("Error---", error);
        setError(true);
        toast({
          title: `${errors}`,
          status: "error",
          position: "top-right",
          isClosable: true,
        });
      }
    };

    // if(data) return  onOpen()
    console.log("DATA FROM GOOGLE ANALYTICS -----",data)
  
    const handleResponse = (tokenResponse) => {
      SetISAuthLoading(true)
      console.log("Token response loading---", tokenResponse);
      if (tokenResponse && tokenResponse.access_token) {
        // console.log("Token response---", tokenResponse);
        /**
         * Before you can have access to the client account summary enable this on the developer console
         * Google Analytics Admin API
         *
         * https://analyticsadmin.googleapis.com/v1alpha/accountSummaries
         */
        // setAuthError(false);
        fetchData(tokenResponse.access_token);
        SetISAuthLoading(false)
       
      }
      SetISAuthLoading(false)
    };
  
    /**
     * END OF GOOGLE ANALYTICS FUNCTIONS
     */




  return (
    <>
      {openModal && <PortfolioCreatedModal closeModal={setOpenModal} />}
      {onOpen && <AnalyticsData isAuthLoading={isAuthLoading} isOpen={isOpen} onClose={onClose} data={data} setPropertyID={(id) => setPropertyId(id)}/>}
      <div className="create-portfolio-container">
        <div className="create-portfolio-header">
          <h4>create analytics portfolio</h4>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="portfolio-input-container">
            <div className="form-input-wrapper">
              {errors.title && touched.title && <span>{errors.title}</span>}
              <div>
                <span>Portfolio title</span>
                <fieldset className="portfolio-input">
                  <input
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    placeholder="Enter your portfolio title"
                    required
                  />
                </fieldset>
              </div>
            </div>

            <div className="form-input-wrapper">
              {/* {errors.propertyId && touched.propertyId && (
                <span>{errors.propertyId}</span>
              )} */}
              <div>
                <span>Property ID</span>
                <fieldset className="portfolio-input">
                  <input
                    name="propertyId"
                    value={propertyId}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    type="text"
                    placeholder="Enter property ID"
                    // required
                    readOnly
                  />
                </fieldset>
                <button className="analytics-btn" onClick={()=>HandleAccess()} type="button">Connect Google Analytics</button>
            </div>
              </div>
            
            <div className="form-input-wrapper">
              {errors.metric && touched.metric && <span>{errors.metric}</span>}
              <div>
                <span>Default Metric to Track</span>
                <fieldset className="portfolio-input">
                  <select
                    name="metric"
                    value={values.metric}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    defaultValue="sessions"
                    style={{ width: "100%" }}
                  >
                    <option value="sessions">Sessions</option>
                    <option value="new users">New Users</option>
                    <option value="promotion views">Promotion Views</option>
                  </select>
                </fieldset>
              </div>
            </div>
            <div className="form-input-wrapper">
              {errors.metric && touched.metric && <span>{errors.metric}</span>}
              <div>
                <span>Select campaign for portfolio</span>
                <fieldset className="portfolio-input">
                  <select
                    name="campaign"
                    value={campaignName}
                    onChange={handleOptionChange}
                    onBlur={handleBlur}
                    // defaultValue="sessions"
                    style={{ width: "100%" }}
                  >
                    <option value="">Select</option>
                    {getCampaignForPortfolio?.map((campaign) => (
                      <option value={campaign.name}>{campaign.name}</option>
                    ))}
                  </select>
                </fieldset>
              </div>
            </div>
          </div>

          <div className="portfolio-btn-container">
            <button type="submit">
              {loading ? (
                <i class="fa fa-circle-o-notch fa-spin"></i>
              ) : (
                "create portfolio"
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AdvertiserCreatePortfolio;
