import React, { useState } from "react";
import moment from "moment";
import { useQuery } from "@apollo/client";

import { GET_ADVERTISER_CAMPAIGNS, GET_RADIOSTATION_BOOKED_BY_ADVERTISER } from "../../../../../../components/GraphQL/Queries";

import AdvertiserCampaignsLoader from "../../../../../../components/SkeletonLayoutLoader/AdvertiserCampaignsPage";
import { EmptyState } from "../../../../../../components/Modal/ScheduleModal";

import { Button, Flex, Input, InputGroup, InputRightElement, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from "@chakra-ui/react";
import DateRangePicker from "../../../../../../TransmitDashboard/DateRangePicker";
import { MdArrowDropDown } from "react-icons/md";
import { SearchIcon } from "@chakra-ui/icons";
import CampaignTable from "../../../../../../components/CampaignTable";
import { compareDates } from "../../../../../../utils/functions";
// import "../../../css/station-dashboard.css"

const AdvertiserCampaignsHomepage = () => {
  const [selectedItem, setSelectedItem] = useState('Radio Station');
  const [broadcasterId, setBroadcasterId] = useState(null)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  const { data, loading, refetch } = useQuery(GET_ADVERTISER_CAMPAIGNS, {
    variables: {
      page,
      itemsPerPage,
      broadcasterId,
      startDate: compareDates(startDate, endDate) ? new Date(startDate) : null,
      endDate: compareDates(startDate, endDate) ? new Date(endDate) : null
    },
    notifyOnNetworkStatusChange: true,
  });
  const { data: radioStationBookedData } = useQuery(GET_RADIOSTATION_BOOKED_BY_ADVERTISER)

  const totalPages = data?.advertiser.getCampaigns?.totalCount / itemsPerPage

  const handleNextOrPrevPage = (newPage) => {
    setPage(newPage);
    refetch({
      page: newPage,
      itemsPerPage,
      broadcasterId,
      startDate: startDate !== null && new Date(startDate),
      endDate: endDate !== null && new Date(endDate)
    });
  };

  const handlePerPageChange = (e) => {
    const selectedValue = Number(e.target.value);
    setItemsPerPage(selectedValue);
  };


  const columnData = [
    {
      title: `Campaign ID`,
      isDropDown: false,
      isFilter: false,
      options: [],
    },
    {
      title: `Start Date`,
      isDropDown: false,
      isFilter: false,
      options: [],
    },
    {
      title: `End Date`,
      isDropDown: false,
      isFilter: false,
      options: [],
    },
    {
      title: `Radio Station`,
      isDropDown: false,
      isFilter: false,
      options: [],
    },
    {
      title: `Campaign Status`,
      isDropDown: true,
      isFilter: false,
      options: [
        {
          value: "Pending",
        },
        {
          value: "Approved",
        },
        {
          value: "Cancelled",
        },
      ],
    },
    {
      title: `Amount`,
      isDropDown: false,
      isFilter: true,
      options: [],
    },
  ];

  const shuffledData = data?.advertiser.getCampaigns?.campaigns.map(
    (campaign) => {
      // Get the radio station name or use a placeholder if it's missing
      const radioStation =
        campaign?.radioStation && campaign?.radioStation.length > 0 ? (
          <p className="radio-badge">
            {campaign?.radioStation[0].name}
            {campaign?.radioStation?.length > 1 && (
              <span className="avatar-badge">
                +{campaign?.radioStation?.length - 1}
              </span>
            )}
          </p>
        ) : (
          "-- -- --"
        )

      return {
        id: campaign.id,
        startDate: moment(campaign.startDate).format("DD/MM/YYYY") || "-- -- --",
        endDate: moment(campaign.endDate).format("DD/MM/YYYY") || "-- -- --",
        radioStation: radioStation,
        status: campaign.status || "-- -- --",
        amount: `₦${parseInt(campaign.amount).toLocaleString()}` || "-- -- --",
      }

    }
  );
  const columns = [
    { key: "id", label: "ID" },
    { key: "startDate", label: "Start Date" },
    { key: "endDate", label: "End Date" },
    { key: "radioStation", label: "Radio Station" },
    { key: "status", label: "Status" },
    { key: "amount", label: "Amount" },
  ];

  return (

    <>
      <Stack spacing={8} pt={10} color={'#000'}>
        <Flex justifyContent={'space-between'} alignItems={{ base: 'flex-start', md: 'center' }} flexDirection={{ base: 'column', md: 'row' }} gap={'10px'}>
          <Text as={'h1'} fontSize={'24px'} fontWeight={600} lineHeight={'29.09px'}>Campaign List</Text>
          <InputGroup maxW={{ md: '500px' }}>
            <Input type='text' placeholder='Search Campaign' _focus={{ borderColor: 'black', boxShadow: '0 0 0 1px black' }} />
            <InputRightElement>
              <SearchIcon />
            </InputRightElement>

          </InputGroup>
        </Flex>

        <Flex alignItems={'center'} flexWrap={'wrap'} gap={{ base: '20px' }}>
          <Menu>
            <MenuButton display={'flex'} alignItems={'center'} as={Button} rightIcon={<MdArrowDropDown size={20} />} fontWeight={400} border={'0.5px solid #D5D4DF'} padding={'8px 16px'} variant={'none'} bg={'white'} h={'42px'} borderRadius={'4px'}>
              {selectedItem}
            </MenuButton>
            <MenuList h={'200px'} overflowY={'auto'}>
              {radioStationBookedData?.advertiser.getRadioStationBookedByUser.map(({ id, name }) => (
                <MenuItem onClick={() => {
                  setSelectedItem(name)
                  setBroadcasterId(id)
                }
                } key={id}>{name}</MenuItem>
              ))}
            </MenuList>
          </Menu>
          <DateRangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Flex>
      </Stack>
      {loading ? (
        <AdvertiserCampaignsLoader />
      ) : (
        data?.advertiser.getCampaigns.campaigns.length > 0 && (
          <>
            <Stack spacing={8} pt={10} color={'#000'}>
              <CampaignTable
                data={shuffledData}
                column={columnData}
                isPerPage={true}
                columns={columns}
                isClickable={true}
                hasMore={data?.advertiser.getCampaigns.hasMore}
                totalPage={totalPages}
                currentPage={data?.advertiser.getCampaigns.page}
                isPerginate={true}
                handleNextOrPrevPage={handleNextOrPrevPage}
                itemsPerPage={itemsPerPage}
                handlePerPageChange={handlePerPageChange}
              />
            </Stack>
          </>
        )
      )}
      {data?.advertiser.getCampaigns.campaigns.length === 0 && (
        <EmptyState
          title="You do not have a campaign"
          text="Create a new campaign in order to view your booked campaigns"
          btnText="Create new campaign"
          route="/book-campaign/"
        />
      )}
    </>
  );
};

export default AdvertiserCampaignsHomepage;

