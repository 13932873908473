import { Center, Spinner } from "@chakra-ui/react";
import "./css/style.css";
export const Loader = ({description}) => {
  return (
    <div className="wrapper_loader">
      <div className="wrapper_bodys">
        <Center h="100vh" w="100%">
          <div>
            <div className="spin">
              <Spinner
                thickness="5px"
                speed="0.65s"
                emptyColor="gray.200"
                color="#f1613f"
                size="xl"
              />
            </div>

            <div>
              <h1>{description}</h1>
            </div>
          </div>
        </Center>
      </div>
    </div>
  );
};
