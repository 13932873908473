import React from "react";
import { useQuery } from "@apollo/client";
import AdvertDashboardLayout from "../../ResponsiveAdvertiserDashboardLayout/AdvertDashboardLayout";
import CardImageOne from "../../assets/cardIcon1.svg";
import CardImageTwo from "../../assets/cardIcon2.svg";
import CardImageThree from "../../assets/cardIcon3.svg";
import CardImageFOur from "../../assets/cardIcon4.svg";
import { DASHBOARD_DATA } from "../../../../components/GraphQL/Mutation";
import "../css/dashboard.css";
import AdvertiserHomeDashboardLoader from "../../../../components/SkeletonLayoutLoader/AdvertiserHomeDashboard";
import CampaignTable from "../../../../components/CampaignTable";
import moment from "moment";

const Dashboard = () => {
  const { data, loading } = useQuery(DASHBOARD_DATA);

  let cardData = [
    {
      image: CardImageOne,
      title: "Active Campaigns",
      val: data?.advertiser.getDashboardData.activeCampaign || 0,
    },
    {
      image: CardImageTwo,
      title: "Pending Campaigns",
      val: data?.advertiser.getDashboardData.pendingCampaign || 0,
    },
    {
      image: CardImageThree,
      title: "Completed Campaigns",
      val: data?.advertiser.getDashboardData.completedCampaign || 0,
    },
    {
      image: CardImageFOur,
      title: "All Campaigns",
      val: data?.advertiser.getDashboardData.totalCampaign || 0,
    },
  ];

  const columnData = [
    {
      title: `Campaign ID`,
      isDropDown: false,
      isFilter: false,
      options: [],
    },
    {
      title: `Start Date`,
      isDropDown: false,
      isFilter: false,
      options: [],
    },
    {
      title: `End Date`,
      isDropDown: false,
      isFilter: false,
      options: [],
    },
    {
      title: `Radio Station`,
      isDropDown: false,
      isFilter: false,
      options: [],
    },
    {
      title: `Campaign Status`,
      isDropDown: false,
      isFilter: false,
      options: [
        {
          value: "Pending",
        },
        {
          value: "Approved",
        },
        {
          value: "Cancelled",
        },
      ],
    },
    {
      title: `Amount`,
      isDropDown: false,
      isFilter: false,
      options: [],
    },
  ];

  const shuffledData = data?.advertiser?.getDashboardData?.recentCampaigns.map(
    (campaign) => {
      // Format the start and end dates or use a placeholder if they are missing
      const startDate = campaign?.startDate
        ? moment(campaign?.startDate).format("DD/MM/YYYY")
        : "-- -- --";
      const endDate = campaign?.endDate
        ? moment(campaign?.endDate).format("DD/MM/YYYY")
        : "-- -- --";

      // Get the radio station name or use a placeholder if it's missing
      const radioStation =
        campaign?.radioStation && campaign?.radioStation.length > 0 ? (
          <p className="radio-badge">
            {campaign?.radioStation[0].name}
            {campaign?.radioStation?.length > 1 && (
              <span className="avatar-badge">
                +{campaign?.radioStation?.length - 1}
              </span>
            )}
          </p>
        ) : (
          "-- -- --"
        );

      // Return the structured data
      return {
        id: campaign.id,
        startDate: startDate,
        endDate: endDate,
        radioStation: radioStation,
        status: campaign.campaignStatus || "-- -- --",
        amount: campaign.amount || "-- -- --",
      };
    }
  );

  const columns = [
    { key: "id", label: "ID" },
    { key: "startDate", label: "Start Date" },
    { key: "endDate", label: "End Date" },
    { key: "radioStation", label: "Radio Station" },
    { key: "status", label: "Status" },
    { key: "amount", label: "Amount" },
  ];

  // console.log(data?.advertiser.getDashboardData.recentCampaigns, "data")
  return (
    <AdvertDashboardLayout>
      <div className="dashboard-home-container">
        {loading ? (
          <AdvertiserHomeDashboardLoader />
        ) : (
          <>
            <div>
              <h3>Overview</h3>
            </div>
            <div className="dashboard-card-container">
              {cardData.map(({ image, title, val }, index) => {
                return (
                  <div className="card-content-wrapper" key={index}>
                    <div>
                      <img src={image} alt="campaign-icon" />
                    </div>
                    <div>
                      <span>{title}</span>
                      <span className="card-dashboard-val">{val}</span>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="dashboard-table-container">
              <h3>Summary of Active Campaign</h3>

              <CampaignTable
                data={shuffledData}
                column={columnData}
                isPerPage={false}
                columns={columns}
                isClickable={false}
                isPerginate={false}
              />
            </div>
          </>
        )}
      </div>
    </AdvertDashboardLayout>
  );
};

export default Dashboard;
