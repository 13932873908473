import React from "react";
import { ResponsiveLine } from "@nivo/line";

const WebAnalytics = ({ data, isLabel }) => {
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const month = date.toLocaleString("default", { month: "short" });
    const day = date.getDate();
    return `${month} ${day}`;
  };

  // Find the current date ("Today")
  const currentDate = "2023-09-11";

  // Find the x-coordinate for the current date ("Today")
  let todayX = null;
  for (const series of data) {
    for (const point of series.data) {
      if (point.x === currentDate) {
        todayX = point.x;
        break;
      }
    }
    if (todayX !== null) {
      break;
    }
  }

  // Calculate the x-coordinate for the marker position
  const markerX = todayX !== null ? todayX : null;

  // Add a marker for the current date ("Today") with conditional rendering
  const markers =
    markerX !== null
      ? [
          {
            axis: "x",
            value: markerX,
            lineStyle: {
              strokeDasharray: "4 4",
              stroke: "#ABA8A8",
            },
            legend: "Today",
            legendPosition: "middle",
            legendOffsetY: 10,
            legendTextColor: "#004643",
            renderMarker: ({
              legend,
              legendPosition,
              legendOffsetX,
              legendOffsetY,
            }) => (
              <g>
                <line
                  x1={0}
                  x2={legendPosition === "middle" ? legendOffsetX : 0}
                  y1={0}
                  y2={0}
                  stroke={
                    legendPosition === "middle" ? "transparent" : "#004643"
                  }
                  strokeDasharray="4 4"
                />
                <text
                  x={0}
                  y={legendPosition === "middle" ? legendOffsetY : 0}
                  fill="#004643"
                  textAnchor="start"
                  alignmentBaseline="middle"
                  fontWeight="bold"
                  fontSize="14"
                >
                  {legend}
                </text>
              </g>
            ),
          },
        ]
      : [];

  return (
    <div style={{ width: "100%", height: "500px" }}>
      <ResponsiveLine
      tooltip={(tooltip) => {
        return (
          <div style={{ background: "#fff", padding: "8px", borderRadius: "4px", boxShadow: "0 0 10px rgba(0,0,0,0.1)", fontSize: "10px" }}>
            <div style={{ marginBottom: "4px" }}>
              <strong>Date:</strong> {formatDate(tooltip.point.data.x)}
            </div>
            <div>
              <strong>Impression:</strong> {tooltip.point.data.yFormatted}
            </div>
          </div>
        );
      }}
        data={data}
        markers={markers} // Add the markers
        margin={{ top: 60, right: 50, bottom: 60, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        yFormat=" >-.2f"
        curve="cardinal"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: (date) => formatDate(date),
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Impression",
          legendOffset: -50,
          legendPosition: "middle",
        }}
        enableGridX={false}
        colors={["#005E59", "#FC9732"]}
        enablePoints={false}
        pointSize={10}
        pointColor={{ theme: "grid.line.stroke" }}
        pointBorderWidth={2}
        pointBorderColor={{ theme: "background" }}
        pointLabelYOffset={-19}
        useMesh={true}
        theme={{
          axis: {
            legend: {
              text: {
                fill: "#004643",
              },
            },
          },
        }}
        legends={[
          {
            anchor: "top-right",
            direction: "row",
            justify: false,
            translateX: 53,
            translateY: -43,
            itemWidth: 150,
            itemHeight: 20,
            itemsSpacing: 4,
            symbolSize: 16,
            symbolShape: "circle",
            itemDirection: "left-to-right",
            itemTextColor: "#777",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        layers={[
          "grid",
          "markers",
          "axes",
          "areas",
          "crosshair",
          "lines",
          "points",
          "slices",
          "mesh",
          "legends",
        ]}
      />

      {isLabel && (
        <div
          style={{
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "10px",
            color: "#004643",
            textAlign: "center",
            marginTop: "-2rem",
          }}
        >
          07 Jan 2023 - 07 Feb 2023 to 01 Aug 2023 - 24 Aug 2023
        </div>
      )}
    </div>
  );
};

export default WebAnalytics;
