import React, { useState, useEffect } from "react";
import "./css/landing-page.css";
// import components for the landing page
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import Footer from "../components/Footer/index.jsx";
import Partners from "../components/Patners/index.jsx";
import TransmitNavbar from "../components/TransmitNavbar/Navbars.js";
import TransmitLogo from "../components/TransmitHero/index.jsx";
import WhyTransmit from "../components/WhyTransmitSec/index.jsx";
import UserTypeSummary from "../components/UserTypeSummary/UserTypeSummary.js";
import TransmitObjective from "../components/TransmitObjectives/TransmitObjective.js";

const LandingPage = () => {
  useEffect(() => {
    const scriptTagManager = document.createElement("script");
    scriptTagManager.async = true;
    scriptTagManager.src =
      "https://www.googletagmanager.com/gtag/js?id=G-VB1L096GYE";
    document.head.appendChild(scriptTagManager);

    const js = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-VB1L096GYE');
  `;
    const script = document.createElement("script");
    const scriptText = document.createTextNode(js);
    script.appendChild(scriptText);
    document.head.appendChild(script);
  }, []);

  const [startCampaign] = useState(true);

  return (
    <div className="body-wrap">
      <TawkMessengerReact
        propertyId="63f499f94247f20fefe1c52d"
        widgetId="1gppniu96"
      />

      <TransmitNavbar />
      <TransmitLogo />

      <UserTypeSummary
        userTypeSummaryTag="Target and analyze your ad"
        userTypeSummarySubTag="ur advanced algorithms analyze demographics, interests, and real-time listening data, enabling businesses to reach their audience with unparalleled accuracy."
        firstLetter="O"
        isAdvertiser
      />
      <WhyTransmit />

      <TransmitObjective />

      <Partners />
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default LandingPage;
