import React, { useState, useEffect } from "react";
import "./css/station.css";
import { useFormatAmount } from "../../hook";

const Station = ({
  id,
  img,
  name,
  clearSelect,
  startingPrice,
  setCheckBox,
  discountedStatingPrice,
  discountPercentage,
  checkBox,
  currency,
}) => {
  const [check, setCheck] = useState(checkBox.includes(id));

  const formatAmount = useFormatAmount;

  useEffect(() => {
    if (clearSelect) {
      setCheck(false);
    }
  }, [clearSelect]);

  const selectedStations = localStorage.getItem("selectedStations") ?? "";

  const handleCheck = (id) => {
    let mutatedSelectedStation = selectedStations.length
      ? [...selectedStations.split(",")]
      : [];
    setCheck(!check);

    if (check) {
      // If check is true, remove the id from checkBox and mutatedSelectedStation
      setCheckBox((prev) => prev.filter((station) => station !== id));
      mutatedSelectedStation = mutatedSelectedStation.filter(
        (station) => station !== id
      );
    } else {
      // If check is false, add the id to checkBox and mutatedSelectedStation
      setCheckBox((prev) => [...new Set([...prev, id])]);
      mutatedSelectedStation.push(id);
    }

    if (mutatedSelectedStation.length) {
      localStorage.setItem(
        "selectedStations",
        mutatedSelectedStation.join(",")
      );
    } else {
      localStorage.setItem("selectedStations", "");
    }
  };

  return (
    <div
      key={id}
      onClick={() => handleCheck(id)}
      className={`station-box ${
        checkBox.includes(id) ? "station-border" : ""
      } `}
    >
      <input
        type="checkbox"
        className="toggle-select"
        checked={checkBox.includes(id)}
        onChange={() => handleCheck(id)}
      />

      <div className="station-img">
        <img src={img} alt={name} />
      </div>
      <div className="txt-cnt">
        <h3 className="station-title">{name}</h3>
        <h4>Starting From</h4>
        {discountPercentage > 0 && (
          <h4>
            <span id="discount-price">{`${currency}${formatAmount(
              startingPrice,
              true
            )}`}</span>
            {discountPercentage}%
          </h4>
        )}
        <h3>{`${currency} ${formatAmount(discountedStatingPrice, true)}`}</h3>
      </div>
    </div>
  );
};

export default Station;
