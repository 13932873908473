import React, { useState } from "react";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { GET_ALL_ADMIN_RADIO_VENDOR_TRANSACTIONS } from "../components/GraphQL/Queries";
import { useQuery } from "@apollo/client";
import {
  Center,
  ChakraProvider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { TableSpinner } from "../components/Spinner";
import "./css/admin_states.css";
import AdminStatesTable from "../components/AdminStatesTable/AdminStatesTable";

const AdminStates = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [country, setCountry] = useState("Nigeria");

  const handleTabChange = (index) => {
    setSelectedTab(index);
  };

  // console.log("Active tab:", tabNames[selectedTab]);
  const { data, loading, refetch } = useQuery(
    GET_ALL_ADMIN_RADIO_VENDOR_TRANSACTIONS
  );

  const tableHeader = [
    {
      title: "STATES",
    },
    {
      title: "RECOMMENDED",
    },
  ];

  const tableHeader2 = [
    {
      title: "RADIO STATIONS",
    },
    {
      title: "RECOMMENDED",
    },
  ];

  const datas = [
    {
      name: "Abia state",
      id: "1",
      status: true,
    },
    {
      name: "Rivers state",
      id: "2",
      status: false,
    },
    {
      name: "Lagos state",
      id: "3",
      status: false,
    },
    {
      name: "Ogun state",
      id: "4",
      status: false,
    },
    {
      name: "Oyo state",
      id: "5",
      status: false,
    },
    {
      name: "Delta state",
      id: "6",
      status: false,
    },
    {
      name: "Kaduna state",
      id: "7",
      status: false,
    },
    {
      name: "Kano state",
      id: "8",
      status: false,
    },
    {
      name: "Cross River state",
      id: "9",
      status: false,
    },
    {
      name: "Federal Capital Territory",
      id: "10",
      status: false,
    },
  ];

  return (
    <AdminDashboardLayout>
      {/* {loading ? (
        <ChakraProvider>
          <Center h="100vh">
            <TableSpinner />
          </Center>
        </ChakraProvider>
      ) : ( */}
      <>
        <div className="Home_Dashboard_wrap_state">
          {/* TAB SECTION */}
          <div className="state_tab_wrapper">
            <Tabs onChange={handleTabChange} isLazy>
              <div
                style={{
                  overflowX: "scroll",
                  color: "#838383",
                  lineHeight: "0.9",
                  position: "fixed",
                  width: "100%",
                  zIndex: "5",
                }}
              >
                <TabList>
                  <Tab
                    _selected={{
                      color: "#005E59",
                      mb: "2px",
                      borderBottom: "2px solid #005E59",
                      fontWeight: "600",
                    }}
                    width={"40%"}
                  >
                    States
                  </Tab>
                  <Tab
                    width={"50%"}
                    _selected={{
                      color: "#005E59",
                      mb: "2px",
                      borderBottom: "2px solid #005E59",
                      fontWeight: "600",
                    }}
                  >
                    Radio Stations
                  </Tab>
                </TabList>
                <div
                  style={{
                    borderTop: "1px solid #c4c4c4",
                    position: "relative",
                    top: "-0.1rem",
                  }}
                ></div>
              </div>
              <TabPanels>
                {/* States panel */}
                <TabPanel>
                  <div className="panel_wrapper">
                    <div className="admin_country_select">
                      <div className="admin_country_wrap">
                        <label>Select Country</label>
                        <select
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          <option>Nigeria</option>
                          <option>United State</option>
                          <option>United Kingdom</option>
                        </select>
                      </div>
                    </div>
                    <AdminStatesTable
                      header={tableHeader}
                      data={datas || []}
                      loading={loading}
                      refetch={refetch}
                      showFilter={true}
                      isState={true}
                      // handleRow={handleRow}
                    />
                  </div>
                </TabPanel>
                {/*Radio Stations Panel */}
                <TabPanel>
                  <div className="panel_wrapper">
                    <AdminStatesTable
                      header={tableHeader2}
                      data={datas || []}
                      loading={loading}
                      refetch={refetch}
                      showFilter={true}
                      isState={false}
                      // handleRow={handleRow}
                    />
                  </div>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        </div>
      </>
      {/* )} */}
    </AdminDashboardLayout>
  );
};

export default AdminStates;
